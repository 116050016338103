import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';
import { Tooltip, DatePicker } from 'antd'
import { teacher_report } from '../../axios/http'
import '../../css/homepage.css'
import locale from 'antd/es/date-picker/locale/zh_CN'
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn')
const dateFormat = 'YYYY-MM-DD'
function fun_date(aa) {
    var date1 = new Date()
    var date2 = new Date(date1)
    date2.setDate(date1.getDate() + aa)
    var time2 = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate()
    return time2
}
class Myself extends Component {
    constructor(props) {
        super(props)
        this.state = {
            zhanbi: '占比=错题学生录入数÷带生总数 ',
            pingjunzhengli: '平均整理=错题总数÷错题学生录入数',
            infoList: {
                beike: {
                    total_count: 0
                },
                jiaoyan: {},
                wanfudao: {},
                zhoumoban: {}
            },
            params: {
                teacher_id: '',
                starttime: '',
                endtime: ''
            },
            time: new Date(),
            endtime: fun_date(7)
        }
    }
    componentDidMount() {
        const params = { ...this.state.params }
        params.teacher_id = this.props.location.search.split('=')[1]
        this.getInfo(params)
    }
    getInfo = (params) => {
        teacher_report({ ...params }).then(res => {
            this.setState({
                infoList: res.data,
                params
            })
        })
    }
    dateChange = (e, dataString) => {
        const params = { ...this.state.params }
        params.starttime = dataString
        this.setState({
            time: dataString
        })
        this.getInfo(params)
    }
    onchangeEnd = (e, dataString) => {
        const params = { ...this.state.params }
        params.endtime = dataString
        this.setState({
            endtime: dataString
        })
        this.getInfo(params)
    }
    render() {
        const { infoList } = this.state
        const option = {
            title: {
                text: `本周上课班级数${infoList.beike.total_count}`,
                left: 'center'
            },
            legend: {
                orient: 'vertical',
                left: 'left',
                data: ['已审核', '审核中', '未上传']
            },
            color: ['#FF6C60', '#57C8F2', '#F8D347'],
            series: [
                {
                    name: '访问来源',
                    type: 'pie',
                    radius: '55%',
                    center: ['50%', '50%'],
                    data: [
                        { value: infoList.beike.yishenhe, name: '已审核' },
                        { value: infoList.beike.shenhezhong, name: '审核中' },
                        { value: infoList.beike.weishangchuan, name: '未上传' }
                    ],
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };
        return (
            <div>
                <div className="m-Hmain">
                <img src={'https://rep-jiaoxue.oss-cn-shanghai.aliyuncs.com/upload/ioc_xuexi.jpg'} alt="" />
                </div>
            </div>
        );
    }
}

export default Myself;