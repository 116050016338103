import React, { useState, useEffect } from 'react';
import { Table, Select } from 'antd';
import { bind_student_data } from '../../axios/http'
const { Option } = Select

const Student_report = (props) => {
  const [data, dataSet] = useState()
  const [company_id, company_idSet] = useState('')

  useEffect(() => {
    bind_student_dataFun()
  }, [company_id])

  const bind_student_dataFun = () => {
    let data = {
      company_id: company_id
    }
    bind_student_data(data).then(res => {
      dataSet(res.data.list)
    })
  }

  const columns = [
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (text) => (
        <div>{text == 0 ? '试听' : text == 1 ? '在读' : text == 2 ? '停课' : text == 3 ? '休学' : '已退校'}</div>
      )
    }, {
      title: '绑定的学生数量',
      dataIndex: 'bind_student_count',
      key: 'bind_student_count'
    }, {
      title: '总学生数量',
      dataIndex: 'student_count',
      key: 'student_count'
    }
  ];

  const change = e => {
    company_idSet(e ? e : '')
  }

  return (
    <div>
      <div className="flex">
        <div style={{ marginRight: '10px' }}>
          校区：
          <Select placeholder="请选择" style={{ width: 200 }} onChange={change} allowClear>
            <Option value={1}>亚诺教育阳山校区</Option>
            <Option value={6}>瑞迪艺术科技城校区</Option>
            <Option value={30}>亚诺教育沧浪校区</Option>
          </Select>
        </div>
      </div>
      <Table rowKey={(record, index) => index} pagination={false} columns={columns} dataSource={data} />
    </div >
  )
}

export default Student_report