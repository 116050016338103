import React, { useState, useEffect } from 'react';
import axios from 'axios'

const Yanuo_excel = (props) => {
  const [data, dataSet] = useState()
  const [height, heightSet] = useState()

  useEffect(() => {
    handleSize()
  }, [document.body.clientHeight])

  const handleSize = () => {
    let height = document.body.clientHeight  - 166
    heightSet(height)
  }

  return (
    <div style={{ width: '100%' }}>
      <iframe style={{ width: '100%', height: height }} src='https://jiaoxue.zhongxiaoxue.cn/yanuo.html' />
    </div >
  )
}

export default Yanuo_excel