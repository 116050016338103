import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Input,
  Button,
  Select,
  Radio,
  Checkbox,
  InputNumber,
  Upload,
  DatePicker,
  Divider,
  Modal,
  Switch,
  Table,
  message,
  Popconfirm
} from 'antd';
import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { UploadOutlined } from '@ant-design/icons';
import { ChromePicker } from 'react-color'
import axios from 'axios'
import {
  get_active_detail,
  get_common_field,
  get_common_type,
  create_active,
  edit_active_active,
  del_active
} from '../../../axios/http'
import moment from 'moment'
import E from 'wangeditor'
const { $, BtnMenu, DropListMenu, PanelMenu, DropList, Panel, Tooltip } = E
const { Option } = Select
const { TextArea } = Input;
const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
  width: '100%'
};
const { RangePicker } = DatePicker;

let dragingIndex = -1;
let editor = null

class BodyRow extends React.Component {
  render() {
    const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
    const style = { ...restProps.style, cursor: 'move' };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += ' drop-over-downward';
      }
      if (restProps.index < dragingIndex) {
        className += ' drop-over-upward';
      }
    }

    return connectDragSource(
      connectDropTarget(<tr {...restProps} className={className} style={style} />),
    );
  }
}

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource('row', rowSource, connect => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow),
);

const req = (url, params) => {
  return axios({
    method: "POST",
    url: url,
    headers: { 'Content-Type': 'multipart/form-data;charset=utf-8' },
    data: params
  });
};

class Submitdrawdetail extends Component {

  constructor(props) {
    super(props)
    this.state = {
      active_id: this.props.active_id ? this.props.active_id : '',
      state: false,
      model: {
        state: '-1',
        is_must_student: '-1',
        is_must_visit: '-1',
        category_id: '',
        type_id: '',
        start_time: moment().format('YYYY-MM-DD'),
        end_time: moment().format('YYYY-MM-DD')
      },
      name: '',
      code: 0,
      message: '',
      visible: false,
      field_list: [],
      field: '',
      check: '',
      type_id: '',
      index: '',
      title: '',
      tips: '',
      height: '',
      content: undefined,
      checked: true,
      commonType: [],
      field_json_str: {},
      commonField: [],
      field_value: [],
      id_list: [],
      is_deit: false,
      edit_index: ''
    }
  }

  componentDidMount = () => {
    get_common_type().then(res => {
      this.setState({
        commonType: res.data
      })
    })
    get_common_field().then(res => {
      this.setState({
        commonField: res.data
      })
    })
    // 注：class写法需要在componentDidMount 创建编辑器
    editor = new E("#div1")
    editor.config.pasteFilterStyle = false
    editor.config.zIndex = 500
    editor.config.customUploadImg = function (resultFiles, insertImgFn) {
      var formData = new FormData();
      formData.append('upload_control', resultFiles[0]); //添加图片信息的参数
      formData.append('type', 7);
      req('https://jiaoxueapi.yanuojiaoyu.com/api/upload/upload_file', formData).then(res => {
        if (res.data.code == 0) {
          insertImgFn(res.data.data.full_path)
        } else {
          message.error(res.data.message)
        }
      })
    }
    editor.config.onchange = (content) => {
      document.getElementById("html_str").innerHTML = content
      this.setState({
        content
      })
    }
    /**一定要创建 */
    editor.create()
    let list = []
    for (var i = 1; i <= 20; i++) {
      list.push('extend_' + i)
    }
    if (this.state.active_id) {
      get_active_detail({ active_id: this.state.active_id }).then(res => {
        let model = res.data.model,
          field_json_str = JSON.parse(res.data.model.field_json_str || '{}'),
          field_list = field_json_str.field_list ? field_json_str.field_list : []
        document.getElementById("html_str").innerHTML = model.html_str
        for (var i = 0; i < field_list.length; i++) {
          for (var j = 0; j <= list.length; j++) {
            if (field_list[i].field == list[j]) {
              list.splice(0, j + 1)
            }
          }
        }
        editor.txt.html(model.html_str)
        this.setState({
          content: model.html_str,
          model: model,
          field_json_str: field_json_str,
          field_list: field_list,
          code: res.code,
          id_list: list
        })
        this.setBG()
      })
    } else {
      this.setState({
        id_list: list
      })
    }
    window.addEventListener('resize', this.handleSize);
    this.handleSize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleSize);
    this.setState = (state, callback) => {
      return
    }
    editor.destroy()
  }

  handleSize = () => {
    let height = document.body.clientHeight - 192
    this.setState({
      height
    });
  }

  setBG = e => {
    document.getElementById('enter_main').style.backgroundImage = `url("${this.state.model.background_body_image}")`
  }

  changeModel = (e, id) => {
    let list = JSON.stringify(this.state.model),
      newList = JSON.parse(list)
    newList[id] = typeof (e) == 'undefined' ? '' : typeof (e) == 'boolean' ? e ? 1 : -1 : typeof (e) == 'object' ? e.target.value : e
    this.setState({
      model: newList
    })
  }

  visibleChange = e => {
    this.setState({
      visible: !this.state.visible,
      index: '',
      field: '',
      type_id: '',
      field_value: [],
      checked: true,
      addField: '',
      title: '',
      is_deit: false
    })
  }

  onOk = e => {
    let list = JSON.stringify(this.state.field_list),
      newList = JSON.parse(list)
    if (this.state.is_deit) {
      let obj = {
        index: this.state.index,
        field: this.state.field,
        is_check: this.state.checked ? 1 : - 1,
        item_list: this.state.field_value ? this.state.field_value : null,
        sort: newList.length + 1,
        tips: this.state.tips,
        title: this.state.title,
        type_id: this.state.type_id
      }
      newList[this.state.edit_index] = obj
      if (this.state.index == 18) {
        let id_list = this.state.id_list
        id_list.shift()
        this.setState({
          id_list
        })
      }
      this.setState({
        field_list: newList
      })
    } else {
      newList.push({
        index: this.state.index,
        field: this.state.field,
        is_check: this.state.checked ? 1 : - 1,
        item_list: this.state.field_value ? this.state.field_value : null,
        sort: newList.length + 1,
        tips: this.state.tips,
        title: this.state.title,
        type_id: this.state.type_id
      })
      if (this.state.index == 18) {
        let id_list = this.state.id_list
        id_list.shift()
        this.setState({
          id_list
        })
      }
      this.setState({
        field_list: newList
      })
    }
    this.visibleChange()
  }

  chooseField = e => {
    if (e == 18) {
      if (this.state.id_list.length > 0) {
        this.setState({
          index: e,
          type_id: '',
          field: this.state.id_list[0],
        })
      } else {
        message.warn('额外字段已用完！')
      }
    } else {
      let val = this.state.commonField[e]
      this.setState({
        index: e,
        field: val.field,
        type_id: val.type_id,
        title: val.name,
        field_value: val.field_value ? JSON.parse(val.field_value) : []
      })
    }
  }

  chooseType = e => {
    this.setState({
      type_id: e
    })
  }

  switchChange = e => {
    this.setState({
      checked: e
    })
  }

  back = e => {
    this.props.back()
  }

  components = {
    body: {
      row: DragableBodyRow,
    },
  };

  moveRow = (dragIndex, hoverIndex) => {
    const { field_list } = this.state;
    const dragRow = field_list[dragIndex];
    this.setState(
      update(this.state, {
        field_list: {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
        },
      }),
    );
  };

  moveRow2 = (dragIndex, hoverIndex) => {
    const { field_value } = this.state;
    const dragRow = field_value[dragIndex];
    this.setState(
      update(this.state, {
        field_value: {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
        },
      }),
    );
  };

  save = e => {
    let data = this.state.model,
      field_json_str = this.state.field_json_str,
      field_list = this.state.field_list,
      f = false,
      must_list = ['name', 'state', 'start_time', 'end_time', 'field_json_str', 'type_id']
    field_json_str['field_list'] = field_list
    data['field_json_str'] = JSON.stringify(field_json_str)
    data['html_str'] = this.state.content ? this.state.content : ''
    must_list.some(item => {
      if (data[item]) { } else {
        f = true
        return true
      }
    })
    if (field_json_str.success_message) { } else {
      f = true
    }
    if (f) {
      message.warn('有选项未填写，请检查！')
      return
    }
    if (this.state.active_id) {
      data['active_id'] = this.state.active_id
      edit_active_active(data).then(res => {
        if (res.code == 0) {
          message.success('修改成功')
          this.back()
        } else {
          message.warn(res.message)
        }
      })
    } else {
      create_active(data).then(res => {
        if (res.code == 0) {
          message.success('添加成功')
          this.back()
        } else {
          message.warn(res.message)
        }
      })
    }
  }

  upload = (info, id) => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
      if (id == 'preview_img') {
        let list = JSON.stringify(this.state.field_json_str),
          newList = JSON.parse(list)
        newList['preview_img'] = info.file.response.data.full_path
        this.setState({
          field_json_str: newList
        })
      } else {
        let list = JSON.stringify(this.state.model),
          newList = JSON.parse(list)
        newList[id] = info.file.response.data.full_path
        if (id == 'background_body_image') {
          document.getElementById('enter_main').style.backgroundImage = `url("${info.file.response.data.full_path}")`
        }
        this.setState({
          model: newList
        })
      }
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  }

  timeChange = e => {
    let list = JSON.stringify(this.state.model),
      newList = JSON.parse(list)
    newList['start_time'] = moment(e[0]).format('YYYY-MM-DD')
    newList['end_time'] = moment(e[1]).format('YYYY-MM-DD')
    this.setState({
      model: newList
    })
  }

  messageChange = e => {
    let list = JSON.stringify(this.state.field_json_str),
      newList = JSON.parse(list)
    newList['success_message'] = e.target.value
    this.setState({
      field_json_str: newList
    })
  }

  preview_img = e => {
    let list = JSON.stringify(this.state.field_json_str),
      newList = JSON.parse(list)
    newList['preview_img'] = e.target.value
    this.setState({
      field_json_str: newList
    })
  }

  preview_text = e => {
    let list = JSON.stringify(this.state.field_json_str),
      newList = JSON.parse(list)
    newList['preview_text'] = e.target.value
    this.setState({
      field_json_str: newList
    })
  }

  addfieldChange = e => {
    this.setState({
      addField: e.target.value
    })
  }

  addField = e => {
    let list = JSON.stringify(this.state.field_value),
      newList = JSON.parse(list)
    newList.push({
      id: this.state.addField,
      name: this.state.addField
    })
    this.setState({
      field_value: newList,
      addField: ''
    })
  }

  titleChange = e => {
    this.setState({
      title: e.target.value
    })
  }

  delcol1 = index => {
    let list = this.state.field_list,
      id_list = this.state.id_list
    if (list[index].field.split('_')[0] == 'extend') {
      id_list.push(list[index].field)
      this.setState({
        id_list
      })
    }
    list.splice(index, 1)
    this.setState({
      field_list: list
    })
  }

  delcol2 = index => {
    let list1 = JSON.stringify(this.state.field_value),
      list = JSON.parse(list1)
    list.splice(index, 1)
    this.setState({
      field_value: list
    })
  }

  tipsChange = e => {
    this.setState({
      tips: e.target.value
    })
  }

  changefieldJSON = (e, id) => {
    let list = JSON.stringify(this.state.field_json_str),
      newList = JSON.parse(list)
    newList[id] = e.target.value
    this.setState({
      field_json_str: newList
    })
  }

  changeColor = (e, id) => {
    let list = JSON.stringify(this.state.field_json_str),
      newList = JSON.parse(list)
    newList[id] = e.hex
    this.setState({
      field_json_str: newList
    })
  }

  preview = () => {
    document.getElementById("html_str").innerHTML = this.state.content;
  }

  handleChange = (content) => {
    this.state.content = content;
    this.preview()
  }

  edit = e => {
    let value = this.state.field_list[e]
    console.log(value)
    this.setState({
      edit_index: e,
      is_deit: true,
      index: value.index,
      field: value.field,
      is_check: value.is_check,
      item_list: value.item_list,
      sort: value.sort,
      tips: value.tips,
      title: value.title,
      type_id: value.type_id,
      field_value: value.item_list,
      visible: !this.state.visible,
    })
  }

  del = () => {
    del_active({ active_id: this.state.active_id }).then(res => {
      if (res.code === 0) {
        message.success('删除成功！')
        this.back()
      }
    })
  }

  render() {
    let { content } = this.state;
    const props = {
      name: 'upload_control',
      action: 'https://jiaoxueapi.yanuojiaoyu.com/api/upload/upload_file',
      showUploadList: false,
      headers: {
        username: encodeURI(localStorage.getItem("username")),
        token: localStorage.getItem("token"),
        companyid: localStorage.getItem("company_id")
      },
      data: {
        type: 7
      }
    };
    const columns = [
      {
        title: '排序',
        dataIndex: 'name',
        render: (text, record, index) => (
          <div>{index + 1}</div>
        )
      },
      {
        title: '标题',
        dataIndex: 'title'
      },
      {
        title: '类型',
        dataIndex: 'type_id',
        render: text => {
          let t = ''
          this.state.commonType.some(item => {
            if (item.id == text) {
              t = item.name
              return true
            }
          })
          return t
        }
      },
      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record, index) => (
          <>
            <span style={{ cursor: 'pointer', color: '#1890ff' }} onClick={() => this.edit(index)}>修改</span>
            /
            <span style={{ cursor: 'pointer', color: '#1890ff' }} onClick={() => this.delcol1(index)}>删除</span>
          </>
        )
      }
    ];
    const columns2 = [
      {
        title: '排序',
        dataIndex: 'index',
        render: (text, record, index) => (
          <div>{index + 1}</div>
        )
      },
      {
        title: '选项',
        dataIndex: 'name'
      },
      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record, index) => (
          <>
            <span style={{ cursor: 'pointer', color: '#1890ff' }} onClick={() => this.delcol2(index)}>删除</span>
          </>
        )
      }
    ];
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '50%' }}>
          <Button onClick={this.back} type="primary">返回</Button>
          <div>
            {
              this.state.active_id ?
                <Popconfirm placement="top" title={'是否确认删除？'} onConfirm={this.del} okText="是" cancelText="否">
                  <Button type="primary" danger>删除</Button>
                </Popconfirm>
                :
                ''
            }
            <Button onClick={this.save} style={{ marginLeft: '20px' }} type="primary">保存</Button>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: '50%', padding: '10px', borderRight: '#aaa solid 1px', overflow: 'auto', height: this.state.height + 'px' }}>
            <Divider orientation="left">基础内容</Divider>
            <div className="sd_title"><span style={{ color: 'red' }}>*</span>链接预览图：
              <Upload {...props} onChange={e => this.upload(e, 'preview_img')}>
                <Button size='small'><UploadOutlined />上传</Button>
              </Upload>
            </div>
            <Input value={this.state.field_json_str ? this.state.field_json_str.preview_img : ''} allowClear onChange={this.preview_img}></Input>
            <div className="sd_title"><span style={{ color: 'red' }}>*</span>预览文字：</div>
            <Input value={this.state.field_json_str ? this.state.field_json_str.preview_text : ''} onChange={this.preview_text}></Input>
            <div className="sd_title"><span style={{ color: 'red' }}>*</span>活动状态：</div>
            <Switch checkedChildren="启动" unCheckedChildren="未启用" checked={this.state.model.state == 1 ? true : false} onChange={e => this.changeModel(e, 'state')} />
            <div className="sd_title"><span style={{ color: 'red' }}>*</span>是否加入线上台账：</div>
            <Switch checkedChildren="是" unCheckedChildren="否" checked={this.state.model.is_join_taizhang == 1 ? true : false} onChange={e => this.changeModel(e, 'is_join_taizhang')} />
            <div className="sd_title"><span style={{ color: 'red' }}>*</span>是否加入家长端活动列表：</div>
            <Switch checkedChildren="是" unCheckedChildren="否" checked={this.state.model.student_is_show == 1 ? true : false} onChange={e => this.changeModel(e, 'student_is_show')} />
            <div className="sd_title"><span style={{ color: 'red' }}>*</span>时间：</div>
            <RangePicker onChange={this.timeChange} value={[moment(this.state.model.start_time), moment(this.state.model.end_time)]} />
            <div className="sd_title"><span style={{ color: 'red' }}>*</span>活动限制：</div>
            <Select style={{ width: '100%' }} placeholder="请选择" onChange={e => this.changeModel(e, 'type_id')} value={this.state.model.type_id ? this.state.model.type_id : undefined}>
              <Option value={'1'}>每个用户一次机会</Option>
              <Option value={'2'}>每日一次机会</Option>
              <Option value={'3'}>不限制</Option>
              <Option value={'4'}>每个用户单独设置</Option>
            </Select>
            <div className="sd_title"><span style={{ color: 'red' }}>*</span>活动类型：</div>
            <Select style={{ width: '100%' }} placeholder="请选择" onChange={e => this.changeModel(e, 'category_id')} value={this.state.model.category_id ? this.state.model.category_id : undefined}>
              <Option value={'1'}>活动</Option>
              <Option value={'2'}>课程</Option>
              <Option value={'3'}>其它</Option>
            </Select>
            <div className="sd_title"><span style={{ color: 'red' }}>*</span>是否必须是在读学员：</div>
            <Switch checkedChildren="是" unCheckedChildren="否" checked={this.state.model.is_must_student == 1 ? true : false} onChange={e => this.changeModel(e, 'is_must_student')} />
            <div className="sd_title"><span style={{ color: 'red' }}>*</span>是否上门用户：</div>
            <Switch checkedChildren="是" unCheckedChildren="否" checked={this.state.model.is_must_visit == 1 ? true : false} onChange={e => this.changeModel(e, 'is_must_visit')} />
            <div className="sd_title"><span style={{ color: 'red' }}>*</span>成功信息：</div>
            <Input value={this.state.field_json_str ? this.state.field_json_str.success_message : ''} onChange={this.messageChange}></Input>
            <div className="sd_title">活动列表预览图片：
              <Upload {...props} onChange={e => this.upload(e, 'cover_image')}>
                <Button size='small'>
                  <UploadOutlined /> 上传
                </Button>
              </Upload>
            </div>
            <Input value={this.state.model.cover_image} allowClear onChange={e => this.changeModel(e, 'cover_image')}></Input>
            <div className="sd_title">背景图片：
              <Upload {...props} onChange={e => this.upload(e, 'background_body_image')}>
                <Button size='small'>
                  <UploadOutlined /> 上传
                </Button>
              </Upload>
            </div>
            <Input value={this.state.model.background_body_image} allowClear onChange={e => this.changeModel(e, 'background_body_image')}></Input>
            <div className="sd_title">头部图片：
              <Upload {...props} onChange={e => this.upload(e, 'top_image')}>
                <Button size='small'>
                  <UploadOutlined /> 上传
                </Button>
              </Upload>
            </div>
            <Input value={this.state.model.top_image} allowClear onChange={e => this.changeModel(e, 'top_image')}></Input>
            <div className="sd_title"><span style={{ color: 'red' }}>*</span>标题：</div>
            <Input value={this.state.model.name} allowClear onChange={e => this.changeModel(e, 'name')}></Input>
            <div className="sd_title">活动说明：</div>
            <TextArea autoSize={{ minRows: 2, maxRows: 6 }} value={this.state.model.introduce} allowClear onChange={e => this.changeModel(e, 'introduce')}></TextArea>
            <div className="sd_title"><span style={{ color: 'red' }}>*</span>按钮文字：</div>
            <Input value={this.state.field_json_str.button_text} allowClear onChange={e => this.changefieldJSON(e, 'button_text')}></Input>
            <div className="sd_title"><span style={{ color: 'red' }}>*</span>按钮文字颜色：</div>
            <ChromePicker color={this.state.field_json_str.button_text_color} onChange={e => this.changeColor(e, 'button_text_color')} />
            <div className="sd_title"><span style={{ color: 'red' }}>*</span>按钮背景颜色：</div>
            <ChromePicker color={this.state.field_json_str.button_bg_color} onChange={e => this.changeColor(e, 'button_bg_color')} />
            <Divider orientation="left">广告落地页</Divider>

            <div id="div1"></div>

            <Divider orientation="left">表格内容</Divider>
            <Button style={{ margin: '5px 0' }} onClick={this.visibleChange}>添加</Button>
            <DndProvider backend={HTML5Backend}>
              <Table
                columns={columns}
                dataSource={this.state.field_list}
                components={this.components}
                onRow={(record, index) => ({
                  index,
                  moveRow: this.moveRow,
                })}
                bordered
                pagination={false}
                size='middle'
              />
            </DndProvider>
            <Divider orientation="left">底部广告</Divider>
            <div className="sd_title">图片地址：
              <Upload {...props} onChange={e => this.upload(e, 'adv_image')}>
                <Button size='small'>
                  <UploadOutlined /> 上传
                </Button>
              </Upload>
            </div>
            <Input value={this.state.model.adv_image} allowClear onChange={e => this.changeModel(e, 'adv_image')}></Input>
            <div className="sd_title">链接地址：</div>
            <Input value={this.state.model.adv_url} allowClear onChange={e => this.changeModel(e, 'adv_url')}></Input>
          </div>
          <div style={{ width: '50%', padding: '10px', overflow: 'auto', height: this.state.height + 'px' }}>
            <div id="enter_main" className="enter_main">
              {
                this.state.state ?
                  <div style={{ maxWidth: '700px', backgroundColor: 'rgb(255, 255, 255)', borderColor: 'rgb(130, 0, 64)', borderWidth: '0px', margin: '0 auto' }}>
                    <img style={{ width: '100%' }} src={this.state.model.top_image} alt="header image" />
                    <div className="main-content">
                      {
                        this.state.code == 0 ?
                          <img style={{ width: '50px' }} src={require('../../../img/success.png')} alt="" />
                          :
                          <img style={{ width: '50px' }} src={require('../../../img/fail.png')} alt="" />
                      }
                      <p style={{ fontSize: '1.5em', padding: '3px 0' }}>{this.state.message}</p>
                      <div>
                        <Button style={{ marginRight: '10px' }} disabled={this.state.code == -1 ? true : false}>资格领取</Button>
                        <Button>返回</Button>
                      </div>
                    </div>
                    <a href={this.state.model.adv_url}>
                      <img style={{ width: '100%' }} src={this.state.model.adv_image} alt="" />
                    </a>
                  </div>
                  :
                  <Form name="basic" layout="vertical" style={{ maxWidth: '700px', backgroundColor: 'rgb(255, 255, 255)', borderColor: 'rgb(130, 0, 64)', borderWidth: '0px', margin: '0 auto' }}>
                    <img style={{ maxWidth: '100%' }} src={this.state.model.top_image} alt="" />
                    <div style={{ padding: '1rem 1.2rem 0.4rem' }}>
                      <div style={{ textAlign: 'center', fontFamily: 'inherit', fontSize: '18px', fontWeight: 'normal', color: 'rgb(130, 0, 22)' }}>
                        {this.state.model.name}
                      </div>
                      <div style={{ color: '#ff8c00' }}>
                        <strong>
                          {this.state.model.introduce}
                        </strong>
                      </div>
                    </div>
                    <div id="html_str" style={{ padding: '10px' }}></div>
                    <div style={{ padding: '1rem' }}>
                      {
                        this.state.field_list.map((item, index,) => {
                          switch (Number(item.type_id)) {
                            case 1: return (
                              <Form.Item key={index} label={item.title} name={item.field} rules={[{ required: item.is_check == 1 ? true : false, message: '请填写此项' }]} extra={item.tips} style={{ width: '50%', fontSize: '16px' }}>
                                <Input />
                              </Form.Item>
                            ); break;
                            case 2: return (
                              <Form.Item key={index} label={item.title} name={item.field} rules={[{ required: item.is_check == 1 ? true : false, message: '请填写此项' }]} extra={item.tips} style={{ width: '50%', fontSize: '16px' }}>
                                <Select placeholder="请选择">
                                  {
                                    item.item_list.map(item => {
                                      return (
                                        <Option value={item.id} key={item.id}>{item.name}</Option>
                                      )
                                    })
                                  }
                                </Select>
                              </Form.Item>
                            ); break;
                            case 3: return (
                              <Form.Item key={index} label={item.title} name={item.field} rules={[{ required: item.is_check == 1 ? true : false, message: '请填写此项' }]} extra={item.tips} style={{ width: '50%', fontSize: '16px' }}>
                                <Radio.Group style={{ width: '100%' }}>
                                  {
                                    item.item_list.map(item => {
                                      return (
                                        <div className="choice-wrapper" key={item.id}>
                                          <Radio style={radioStyle} value={item.id}>{item.name}</Radio>
                                        </div>
                                      )
                                    })
                                  }
                                </Radio.Group>
                              </Form.Item>
                            ); break;
                            case 4: return (
                              <Form.Item key={index} label={item.title} name={item.field} rules={[{ required: item.is_check == 1 ? true : false, message: '请填写此项' }]} extra={item.tips} style={{ width: '50%', fontSize: '16px' }}>
                                <Checkbox.Group>
                                  <Row>
                                    {
                                      item.item_list.map(item => {
                                        return (
                                          <Col key={item.id} span={8}>
                                            <Checkbox value={item.id} style={{ lineHeight: '32px' }}>{item.name}</Checkbox>
                                          </Col>
                                        )
                                      })
                                    }
                                  </Row>
                                </Checkbox.Group>
                              </Form.Item>
                            ); break;
                            case 5: return (
                              <Form.Item key={index} label={item.title} name={item.field} rules={[{ required: item.is_check == 1 ? true : false, message: '请填写此项' }]} extra={item.tips} style={{ width: '50%', fontSize: '16px' }}>
                                <InputNumber style={{ width: '100%' }} type="password" />
                              </Form.Item>
                            ); break;
                            case 6: return (
                              <Form.Item key={index} label={item.title} name={item.field} valuePropName="fileList" rules={[{ required: item.is_check == 1 ? true : false, message: '请填写此项' }]} extra={item.tips} style={{ width: '50%', fontSize: '16px' }}>
                                <Upload name="logo" action="https://jiaoxueapi.yanuojiaoyu.com/api/convert/base64_to_img" listType="picture">
                                  <Button icon={<UploadOutlined />}>上传</Button>
                                </Upload>
                              </Form.Item>
                            ); break;
                            case 7: return (
                              <Form.Item key={index} label={item.title} name={item.field} style={{ width: '50%', fontSize: '16px' }}>
                                <div>{item.text}</div>
                              </Form.Item>
                            ); break;
                            case 9: return (
                              <Form.Item key={index} label={item.title} name={item.field} rules={[{ required: item.is_check == 1 ? true : false, message: '请填写此项' }]} extra={item.tips} style={{ width: '50%', fontSize: '16px' }}>
                                <DatePicker placeholder="请选择" style={{ width: '100%' }} />
                              </Form.Item>
                            ); break;
                            default: return null;
                          }
                        })
                      }

                      <Form.Item style={{ textAlign: 'center' }}>
                        <Button style={{ padding: '0px 55px', backgroundColor: `${this.state.field_json_str.button_bg_color}`, borderColor: `${this.state.field_json_str.button_bg_color}`, borderWidth: '0px', fontFamily: 'inherit', fontSize: '14px', fontWeight: 'normal', color: `${this.state.field_json_str.button_text_color}` }} htmlType="submit">{this.state.field_json_str.button_text}</Button>
                      </Form.Item>
                    </div>
                    <a href={this.state.model.adv_url}>
                      <img style={{ width: '100%' }} src={this.state.model.adv_image} alt="" />
                    </a>
                  </Form>
              }
            </div>
          </div>
        </div>
        <Modal title="添加内容" visible={this.state.visible} onOk={this.onOk} onCancel={this.visibleChange}>
          <div>标签：</div>
          <Select onChange={this.chooseField} style={{ width: '50%' }} value={typeof (this.state.index) == 'number' ? this.state.index : undefined} placeholder="请选择">
            {
              this.state.commonField.map((item, index) => {
                return (
                  <Option key={index} value={index}>{item.name}</Option>
                )
              })
            }
          </Select>
          {
            this.state.index == '18' ?
              <div>
                <div>标签名称：</div>
                <Input style={{ width: '50%' }} onChange={this.titleChange} value={this.state.title}></Input>
              </div>
              :
              ''
          }
          <div>添加类型：</div>
          <Select onChange={this.chooseType} style={{ width: '50%' }} value={this.state.type_id ? this.state.type_id : undefined} placeholder="请选择" disabled={this.state.index != 18}>
            {
              this.state.commonType.map(item => {
                return (
                  <Option key={item.id} value={item.id}>{item.name}</Option>
                )
              })
            }
          </Select>
          {
            ["2", "3", "4"].indexOf(this.state.type_id) != -1
              ?
              <div>
                <div>选项类型：
                  <div>
                    <div style={{ display: 'flex' }}>
                      <div style={{ flexShrink: '0' }}>字段名称：</div>
                      <Input size='small' onChange={this.addfieldChange} value={this.state.addField}></Input>
                      <Button size='small' onClick={this.addField} type='primary'>添加</Button>
                    </div>
                  </div>
                </div>
                <DndProvider backend={HTML5Backend}>
                  <Table
                    columns={columns2}
                    dataSource={this.state.field_value}
                    components={this.components}
                    onRow={(record, index) => ({
                      index,
                      moveRow: this.moveRow2,
                    })}
                    bordered
                    pagination={false}
                    size='small'
                  />
                </DndProvider>
              </div>
              :
              ''
          }
          <div>是否必填：</div>
          <Switch checkedChildren="是" unCheckedChildren="否" checked={this.state.checked} onChange={this.switchChange} />
          <div>提示：</div>
          <Input style={{ width: '50%' }} onChange={this.tipsChange} value={this.state.tips}></Input>
          {
            this.state.type_id == '2'
              ?
              ''
              :
              ''
          }
        </Modal>
      </div >
    );
  }
}

export default Submitdrawdetail;