import React, { Component } from 'react';
import {
    Input,
    Button,
    Select,
    Upload,
    DatePicker,
    Divider,
    Modal,
    Switch,
    Table,
    message,
    Popconfirm,
} from 'antd';
import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { UploadOutlined } from '@ant-design/icons';
import {
    get_lottery_detail,
    add_lottery_goods,
    edit_lottery_goods,
    del_lottery_goods,
    add_lottery_lottery,
    edit_lottery_lottery,
    set_goods_sort
} from '../../../axios/http'
import moment from 'moment'
import style from '../../../css/luckydrawdetail.module.css'
const { Option } = Select
const { TextArea } = Input;

const { RangePicker } = DatePicker;

let dragingIndex = -1;

class BodyRow extends React.Component {
    render() {
        const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
        const style = { ...restProps.style, cursor: 'move' };

        let { className } = restProps;
        if (isOver) {
            if (restProps.index > dragingIndex) {
                className += ' drop-over-downward';
            }
            if (restProps.index < dragingIndex) {
                className += ' drop-over-upward';
            }
        }

        return connectDragSource(
            connectDropTarget(<tr {...restProps} className={className} style={style} />),
        );
    }
}

const rowSource = {
    beginDrag(props) {
        dragingIndex = props.index;
        return {
            index: props.index,
        };
    },
};

const rowTarget = {
    drop(props, monitor) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Time to actually perform the action
        props.moveRow(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
}))(
    DragSource('row', rowSource, connect => ({
        connectDragSource: connect.dragSource(),
    }))(BodyRow),
);

class Luckydrawdetail extends Component {

    constructor(props) {
        super(props)
        this.state = {
            choujiang_id: this.props.choujiang_id ? this.props.choujiang_id : '',
            goods_list: [],
            choujiang_model: {
                start_time: moment().format('YYYY-MM-DD'),
                end_time: moment().format('YYYY-MM-DD'),
                state: '-1'
            },
            goods_model: {
                expiration_date: moment().format('YYYY-MM-DD')
            },
            isShowRule: false,
            isShowGoods: false,
            isShowGetGoods: false,
            visible: false
        }
    }

    componentDidMount = () => {
        if (this.state.choujiang_id) {
            this.get_lottery_detail()
        }
        window.addEventListener('resize', this.handleSize);
        this.handleSize()
    }

    get_lottery_detail = e => {
        get_lottery_detail({ choujiang_id: this.state.choujiang_id }).then(res => {
            this.setState({
                goods_list: res.data.goods_list,
                choujiang_model: res.data.model
            })
            this.setBG()
        })
    }

    moveRow = (dragIndex, hoverIndex) => {
        const { goods_list } = this.state;
        const dragRow = goods_list[dragIndex];
        this.setState(
            update(this.state, {
                goods_list: {
                    $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
                },
            }),
            () => {
                this.set_goods_sort()
            }
        );
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleSize);
        this.setState = (state, callback) => {
            return
        }
    }

    setBG = e => {
        document.getElementById('enter_main').style.backgroundImage = `url("${this.state.choujiang_model.background_body_image}")`
    }

    handleSize = () => {
        let height = document.body.clientHeight - 192
        this.setState({
            height
        });
    }

    changeModel = (e, id) => {
        let list = JSON.stringify(this.state.choujiang_model),
            newList = JSON.parse(list)
        newList[id] = typeof (e) == 'undefined' ? '' : typeof (e) == 'boolean' ? e ? '1' : '-1' : typeof (e) == 'object' ? e.target.value : e
        this.setState({
            choujiang_model: newList
        })
    }

    goodsChange = (e, id) => {
        let list = JSON.stringify(this.state.goods_model),
            newList = JSON.parse(list)
        newList[id] = typeof (e) == 'undefined' ? '' : typeof (e) == 'boolean' ? e ? 1 : -1 : typeof (e) == 'object' ? e.target ? e.target.value : moment(e).format('YYYY-MM-DD') : e
        console.log(newList[id])
        this.setState({
            goods_model: newList
        })
    }

    timeChange = e => {
        let list = JSON.stringify(this.state.choujiang_model),
            newList = JSON.parse(list)
        newList['start_time'] = moment(e[0]).format('YYYY-MM-DD')
        newList['end_time'] = moment(e[1]).format('YYYY-MM-DD')
        this.setState({
            choujiang_model: newList
        })
    }

    show = (id, status) => {
        status = !status
        this.setState({
            [id]: status
        })
        document.getElementById('box').style.overflow = `hidden`
    }

    goodChange = (index) => {
        let list = this.state.goods_list[index]
        this.setState({
            goods_model: list,
            visible: true
        })
    }

    del_lottery_goods = (index) => {
        let id = this.state.goods_list[index].id
        del_lottery_goods({ goods_id: id }).then(res => {
            if (res.code == 0) {
                message.success(res.message)
                this.get_lottery_detail()
            } else {
                message.error(res.message)
            }
        })
    }

    close = () => {
        this.setState({
            isShowRule: false,
            isShowGoods: false,
            isShowGetGoods: false
        })
        document.getElementById('box').style.overflow = `auto`
    }

    upload = (info, id) => {
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            let list = JSON.stringify(this.state.choujiang_model),
                newList = JSON.parse(list)
            newList[id] = info.file.response.data.full_path
            if (id == 'background_body_image') {
                document.getElementById('enter_main').style.backgroundImage = `url("${info.file.response.data.full_path}")`
            }
            this.setState({
                choujiang_model: newList
            })
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    set_goods_sort = () => {
        let str = []
        this.state.goods_list.map(item => {
            str.push(item.id)
        })
        set_goods_sort({ goods_ids_sort: str.join(',') }).then(res => {
            if (res.code == 0) {
                message.success(res.message)
            } else {
                message.error(res.message)
            }
        })
    }

    save = () => {
        let data = this.state.choujiang_model, f = false
        console.log(data)
        for (var key in data) {
            if (!data[key] && key != 'background_music' && key != 'is_allow_share') {
                f = true
                break;
            }
        }
        if (f) {
            message.warn('有选项未填写，请检查！')
            return
        }
        if (this.state.choujiang_id) {
            data['lottery_id'] = this.state.choujiang_id
            edit_lottery_lottery(data).then(res => {
                if (res.code == 0) {
                    message.success(res.message)
                    this.get_lottery_detail()
                } else {
                    message.error(res.message)
                }
            })
        } else {
            add_lottery_lottery(data).then(res => {
                if (res.code == 0) {
                    message.success(res.message)
                    this.get_lottery_detail()
                } else {
                    message.error(res.message)
                }
            })
        }
    }

    onOk = e => {
        let data = this.state.goods_model
        data['lottery_id'] = this.state.choujiang_id
        if (this.state.goods_model.id) {
            data['goods_id'] = this.state.goods_model.id
        }
        if (data.goods_id) {
            edit_lottery_goods(data).then(res => {
                if (res.code == 0) {
                    message.success(res.message)
                    this.visibleChange()
                } else {
                    message.warn(res.message)
                }
            })
        } else {
            add_lottery_goods(data).then(res => {
                if (res.code == 0) {
                    message.success(res.message)
                    this.visibleChange()
                } else {
                    message.warn(res.message)
                }
            })
        }
    }

    back = () => {
        this.props.back()
    }

    components = {
        body: {
            row: DragableBodyRow
        },
    };

    visibleChange = e => {
        let visible = !this.state.visible
        this.setState({
            visible: visible,
            goods_model: {
                expiration_date: moment().format('YYYY-MM-DD')
            }
        })
        this.get_lottery_detail()
    }

    render() {
        const props = {
            name: 'upload_control',
            action: 'https://jiaoxueapi.yanuojiaoyu.com/api/upload/upload_file',
            showUploadList: false,
            headers: {
                username: encodeURI(localStorage.getItem("username")),
                token: localStorage.getItem("token"),
                companyid: localStorage.getItem("company_id")
            },
            data: {
                type: 8
            }
        };

        const columns = [
            {
                title: '奖品名称',
                dataIndex: 'goods'
            },
            {
                title: '奖品介绍',
                dataIndex: 'introduce'
            },
            {
                title: '中奖率',
                dataIndex: 'percent'
            },
            {
                title: '过期时间',
                dataIndex: 'expiration_date'
            },
            {
                title: '操作',
                dataIndex: 'action',
                render: (text, record, index) => (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <span style={{ cursor: 'pointer', color: '#1890ff' }} onClick={() => this.goodChange(index)}>修改</span>/<Popconfirm placement="top" title='是否删除？' onConfirm={() => this.del_lottery_goods(index)} okText="是" cancelText="否"><span style={{ cursor: 'pointer', color: '#1890ff' }}>删除</span></Popconfirm>
                    </div>
                )
            }
        ]

        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '50%' }}>
                    <Button onClick={this.back} type="primary">返回</Button>
                    <Button onClick={this.save} type="primary">保存</Button>
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ width: '50%', padding: '10px', borderRight: '#aaa solid 1px', overflow: 'auto', height: this.state.height + 'px' }}>
                        <Divider orientation="left">基础内容</Divider>

                        <div className="sd_title">活动状态：</div>
                        <Switch checkedChildren="启动" unCheckedChildren="未启用" checked={this.state.choujiang_model.state == '1' ? true : false} onChange={e => this.changeModel(e, 'state')} />

                        <div className="sd_title">时间：</div>
                        <RangePicker onChange={this.timeChange} value={[moment(this.state.choujiang_model.start_time), moment(this.state.choujiang_model.end_time)]} />

                        <div className="sd_title">活动限制：</div>
                        <Select style={{ width: '100%' }} placeholder="请选择" onChange={e => this.changeModel(e, 'type_id')} value={this.state.choujiang_model.type_id ? this.state.choujiang_model.type_id : undefined}>
                            <Option value={'1'}>每个用户一次机会</Option>
                            <Option value={'2'}>每日一次机会</Option>
                            <Option value={'3'}>不限制</Option>
                            <Option value={'4'}>每个用户单独设置</Option>
                        </Select>

                        <div className="sd_title">背景图片：
                            <Upload {...props} onChange={e => this.upload(e, 'background_body_image')}>
                                <Button size='small'>
                                    <UploadOutlined /> 上传
                            </Button>
                            </Upload>
                        </div>
                        <Input value={this.state.choujiang_model.background_body_image} allowClear onChange={e => this.changeModel(e, 'background_body_image')}></Input>

                        <div className="sd_title">头部图片：
                            <Upload {...props} onChange={e => this.upload(e, 'top_image')}>
                                <Button size='small'>
                                    <UploadOutlined /> 上传
                                </Button>
                            </Upload>
                        </div>
                        <Input value={this.state.choujiang_model.top_image} allowClear onChange={e => this.changeModel(e, 'top_image')}></Input>

                        <div className="sd_title">标题：</div>
                        <Input value={this.state.choujiang_model.name} allowClear onChange={e => this.changeModel(e, 'name')}></Input>

                        <div className="sd_title">活动说明：</div>
                        <TextArea autoSize={{ minRows: 2, maxRows: 6 }} value={this.state.choujiang_model.introduce} allowClear onChange={e => this.changeModel(e, 'introduce')}></TextArea>

                        <Divider orientation="left">抽奖内容</Divider>

                        <div className="sd_title">抽奖转盘图片：
                            <Upload {...props} onChange={e => this.upload(e, 'background_image')}>
                                <Button size='small'>
                                    <UploadOutlined /> 上传
                            </Button>
                            </Upload>
                        </div>
                        <Input value={this.state.choujiang_model.background_image} allowClear onChange={e => this.changeModel(e, 'background_image')}></Input>

                        <div className="sd_title">活动奖品按钮图片：
                            <Upload {...props} onChange={e => this.upload(e, 'goods_button_ico')}>
                                <Button size='small'><UploadOutlined />上传</Button>
                            </Upload>
                        </div>
                        <Input value={this.state.choujiang_model.goods_button_ico} allowClear onChange={e => this.changeModel(e, 'goods_button_ico')}></Input>

                        <div className="sd_title">奖品图片：
                            <Upload {...props} onChange={e => this.upload(e, 'goods_image')}>
                                <Button size='small'><UploadOutlined />上传</Button>
                            </Upload>
                        </div>
                        <Input value={this.state.choujiang_model.goods_image} allowClear onChange={e => this.changeModel(e, 'goods_image')}></Input>

                        <div className="sd_title">抽奖规则按钮图片：
                            <Upload {...props} onChange={e => this.upload(e, 'rule_button_ico')}>
                                <Button size='small'>
                                    <UploadOutlined /> 上传
                            </Button>
                            </Upload>
                        </div>
                        <Input value={this.state.choujiang_model.rule_button_ico} allowClear onChange={e => this.changeModel(e, 'rule_button_ico')}></Input>

                        <div className="sd_title">抽奖规则图片：
                            <Upload {...props} onChange={e => this.upload(e, 'rule_image')}>
                                <Button size='small'>
                                    <UploadOutlined /> 上传
                            </Button>
                            </Upload>
                        </div>
                        <Input value={this.state.choujiang_model.rule_image} allowClear onChange={e => this.changeModel(e, 'rule_image')}></Input>

                        <div className="sd_title">获取奖品按钮图片：
                            <Upload {...props} onChange={e => this.upload(e, 'get_button_ico')}>
                                <Button size='small'><UploadOutlined />上传</Button>
                            </Upload>
                        </div>
                        <Input value={this.state.choujiang_model.get_button_ico} allowClear onChange={e => this.changeModel(e, 'get_button_ico')}></Input>

                        {
                            this.state.choujiang_id ?
                                <div>
                                    <Divider orientation="left">奖品内容</Divider>
                                    <Button style={{ margin: '5px 0' }} onClick={this.visibleChange}>添加</Button>
                                    <DndProvider backend={HTML5Backend}>
                                        <Table
                                            columns={columns}
                                            dataSource={this.state.goods_list}
                                            components={this.components}
                                            onRow={(record, index) => ({
                                                index,
                                                moveRow: this.moveRow,
                                            })}
                                            bordered
                                            pagination={false}
                                            size='middle'
                                        />
                                    </DndProvider>
                                </div>
                                :
                                ''
                        }

                        <Modal title="添加内容" visible={this.state.visible} onOk={e => this.onOk('')} onCancel={this.visibleChange}>
                            <div>奖品名称：</div>
                            <Input value={this.state.goods_model.goods} placeholder="请输入" onChange={e => this.goodsChange(e, 'goods')}></Input>
                            <div>奖品介绍：</div>
                            <Input value={this.state.goods_model.introduce} placeholder="请输入" onChange={e => this.goodsChange(e, 'introduce')}></Input>
                            <div>奖品提示：</div>
                            <Input value={this.state.goods_model.tips} placeholder="请输入" onChange={e => this.goodsChange(e, 'tips')}></Input>
                            <div>中奖率：</div>
                            <Input value={this.state.goods_model.percent} placeholder="请输入" onChange={e => this.goodsChange(e, 'percent')}></Input>
                            <div>到期日期：</div>
                            <DatePicker value={moment(this.state.goods_model.expiration_date)} onChange={e => this.goodsChange(e, 'expiration_date')}></DatePicker>
                            <div>类型：</div>
                            <Select style={{ width: '50%' }} value={this.state.goods_model.type_id ? this.state.goods_model.type_id : undefined} placeholder="请选择" onChange={e => this.goodsChange(e, 'type_id')}>
                                <Option value='1'>实物</Option>
                                <Option value='2'>抵用券</Option>
                                <Option value='3'>红包</Option>
                                <Option value='4'>积分</Option>
                                <Option value='5'>其他</Option>
                            </Select>
                        </Modal>

                    </div>
                    <div id='box' style={{ width: '50%', padding: '10px', overflow: 'auto', height: this.state.height + 'px', position: 'relative' }}>
                        <div id="enter_main" className={style.container}>
                            <div style={{ textAlign: 'center', fontSize: '16px' }}>{this.state.choujiang_model.name}</div>
                            <img className={style.top} src={this.state.choujiang_model.top_image} alt="" />
                            <div style={{ display: 'flex', justifyContent: 'space-around', margin: '10px 0' }}>
                                <img src={this.state.choujiang_model.goods_button_ico} onClick={e => this.show("isShowGoods", this.state.isShowGoods)} alt="" style={{ width: '90px', height: '42px' }} />
                                <img src={this.state.choujiang_model.rule_button_ico} onClick={e => this.show("isShowRule", this.state.isShowRule)} alt="" style={{ width: '90px', height: '42px' }} />
                                <img src={this.state.choujiang_model.get_button_ico} onClick={e => this.show("isShowGetGoods", this.state.isShowGetGoods)} alt="" style={{ width: '90px', height: '42px' }} />
                            </div>
                            <div className={style.box}>
                                <img id="zhuanpan" src={this.state.choujiang_model.background_image} className={style.zhuanpan} alt="" />
                                <img className={style.choujiang} onClick={this.choujiang} src={require("../../../img/choujiang.png")} alt="" />
                            </div>
                            <div className={style.end_time}>抽奖时间：{moment(this.state.choujiang_model.start_time).format('YYYY-MM-DD')}-{moment(this.state.choujiang_model.end_time).format('YYYY-MM-DD')}</div>
                            <img className={style.wodejiangpin} onClick={this.myGoods} src={require('../../../img/wodejiangpin.png')} alt="" />
                        </div>
                        {
                            this.state.isShowChou || this.state.isShowGetGoods ?
                                <div onClick={this.close} className={style.mark}></div>
                                :
                                ''
                        }
                        {
                            this.state.shownone ?
                                <div onClick={this.close} className={style.mark} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ backgroundColor: '#ff0437', border: '#ff0437 solid 1px', borderRadius: '5px', padding: '5px 10px', color: '#fff', maxWidth: '60%' }}>
                                        {this.state.message}
                                    </div>
                                </div>
                                :
                                ''
                        }
                        {
                            this.state.isShowChou ?
                                <div className={style.result}>
                                    <div className={style.result_box}>
                                        <div className={style.result_title}>{this.state.message}</div>
                                        <div className={style.code}>兑奖码：{this.state.resultList.code}</div>
                                        <div className={style.result_text}>{this.state.resultList.goods}</div>
                                        <div className={style.expiration_date}>{this.state.resultList.expiration_date}到期</div>
                                        <div className={style.introduce}>{this.state.resultList.introduce}</div>
                                        <div className={style.notice}>{this.state.resultList.tips}</div>
                                    </div>
                                    <div onClick={this.showGetGoods} style={{ backgroundColor: '#ff0437', color: '#fff', padding: '5px', marginTop: '10px', borderRadius: '10px' }}>立即领取</div>
                                </div>
                                :
                                ''
                        }
                        {
                            this.state.isShowRule ?
                                <div onClick={this.close} className={style.mark} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <img style={{ width: '313px' }} src={this.state.choujiang_model.rule_image} alt="" />
                                    <img onClick={this.close} style={{ width: '25px', height: '25px', marginTop: '10px' }} src={require("../../../img/close1.png")} alt="" />
                                </div>
                                :
                                ''
                        }
                        {
                            this.state.isShowGoods ?
                                <div onClick={this.close} className={style.mark} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <img style={{ width: '313px' }} src={this.state.choujiang_model.goods_image} alt="" />
                                    <img onClick={this.close} style={{ width: '25px', height: '25px', marginTop: '10px' }} src={require("../../../img/close1.png")} alt="" />
                                </div>
                                :
                                ''
                        }
                        {
                            this.state.isShowGetGoods ?
                                <div className={style.result} style={{ height: '227px' }}>
                                    <div style={{ width: '100%', backgroundColor: '#fff', borderRadius: '10px', padding: '10px' }}>
                                        <div onClick={this.show} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '48px', padding: '0 10px' }}>
                                            <div>{this.state.text}<span style={{ color: '#ff0437' }}>*</span></div>
                                            <img src={require("../../../img/jiantou.png")} alt="" />
                                        </div>
                                        <div>手机号：</div>
                                        <Input placeholder="请输入手机号"></Input>
                                        <div>学生姓名：</div>
                                        <Input placeholder="请输入学生姓名"></Input>
                                        <Button onClick={this.submit} style={{ width: '80%', height: '28px', margin: '8px 0', backgroundColor: '#ff0437', color: '#fff' }}>提交</Button>
                                        {
                                            this.state.isShowWarn ?
                                                <div style={{ color: 'red', fontSize: '14px' }}>请完善信息！</div>
                                                :
                                                ''
                                        }
                                    </div>
                                    <img onClick={this.close} style={{ width: '25px', height: '25px', marginTop: '10px' }} src={require("../../../img/close1.png")} alt="" />
                                </div>
                                :
                                ''
                        }
                    </div>
                </div >
            </div >
        );
    }
}

export default Luckydrawdetail;