import { Modal, Select, Row, Col, message, Switch } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import {
    get_all_subject,
    get_sub_subject,
    get_condition_by_sub_subject_id,
    set_condition,
    remove_condition
} from '../../axios/http'
import Tags from './tag'

const { Option } = Select

let params_model = {
    subject_id: '',
    sub_subject_id: '',
    sub_subject_list: [],
    grade_list: [],
    system_list: []
}

class Add extends Component {
    state = {
        params: params_model,
        all_subject: [],
        sub_subject: [],
        condition: {},
        teachingVisible: false,
    };

    componentDidMount() {
        this.get_all_subjectFun()
    }

    get_all_subjectFun = () => {
        get_all_subject().then(res => {
            if (res.code == 0) {
                this.setState({
                    all_subject: res.data.subject_list
                })
            }
        })
    }

    get_sub_subjectFun = (e) => {
        let data = {
            subject_id: e
        }
        get_sub_subject(data).then(res => {
            if (res.code == 0) {
                let list = [],
                    s = this.state.params
                res.data.list.map(item => {
                    list.push(item.name)
                })
                s['sub_subject_list'] = list
                this.setState({
                    sub_subject: res.data.list,
                    params: s
                })
            }
        })
    }

    get_condition_by_sub_subject_idFun = (e) => {
        let data = {
            sub_subject_id: e
        }
        get_condition_by_sub_subject_id(data).then(res => {
            if (res.code == 0) {
                let grade_list = [],
                    system_list = [],
                    s = this.state.params
                if (res.data.grade_list)
                    res.data.grade_list.map(item => {
                        grade_list.push(item.value)
                    })
                if (res.data.system_list)
                    res.data.system_list.map(item => {
                        system_list.push(item.value)
                    })
                s['grade_list'] = grade_list
                s['system_list'] = system_list
                this.setState({
                    condition: res.data,
                    params: s
                })
            }
        })
    }

    removal = (s, t) => {
        let newList = []
        t = JSON.stringify(t)
        s.map(item => {
            if (t.indexOf(item) == -1) {
                newList.push(item)
            }
        })
        return newList
    }

    onOk = () => {
        let { params, sub_subject, condition } = this.state
        params.sub_subject_list = this.removal(params.sub_subject_list, sub_subject)
        params.grade_list = this.removal(params.grade_list, condition.grade_list)
        params.system_list = this.removal(params.system_list, condition.system_list)
        set_condition({ json_str: JSON.stringify(this.state.params) }).then(res => {
            if (res.code == 0) {
                message.success('添加成功')
                this.setState({
                    teachingVisible: false,
                    sub_subject: []
                })
                this.props.get_all_subjectFun()
            } else {
                message.error(res.message)
            }
        })
    }

    onChange = (e, id) => {
        let s
        switch (id) {
            case 'subject_id':
                var t = JSON.stringify(this.state.params)
                s = JSON.parse(t)
                s[id] = typeof (e) == 'undefined' ? '' : typeof (e) == 'object' ? e.target.value : e;
                s['sub_subject_id'] = '';
                s['sub_subject_list'] = [];
                s['grade_list'] = [];
                s['system_list'] = [];
                this.setState({
                    params: s
                }, () => {
                    this.get_sub_subjectFun(e);
                })
                break;
            case 'sub_subject_id':
                var t = JSON.stringify(this.state.params)
                s = JSON.parse(t);
                s[id] = typeof (e) == 'undefined' ? '' : typeof (e) == 'object' ? e.target.value : e;
                this.setState({
                    params: s
                }, () => {
                    this.get_condition_by_sub_subject_idFun(e);
                })
                break;
            default:
                var t = JSON.stringify(this.state.params)
                s = JSON.parse(t)
                s[id] = typeof (e) == 'undefined' ? '' : typeof (e) == 'object' ? e.target.value : e
                this.setState({
                    params: s
                })
        }
    }

    listSet = (e, id) => {
        let s = this.state.params
        s[id] = e
        this.setState({
            params: s
        })
    }

    handleClose = (e, id) => {
        let subject_id = this.state.params.subject_id,
            sub_subject_id = this.state.params.sub_subject_id
        switch (id) {
            case 'sub_subject_list': id = 'sub_subject'; sub_subject_id = -1; break;
            case 'grade_list': id = 'grade'; break;
            case 'system_list': id = 'system'; break;
        }
        let data = {
            subject_id: subject_id,
            sub_subject_id: sub_subject_id,
            type_str: id,
            val: e
        }
        remove_condition(data).then(res => {
            if (res.code == 0) {
                message.success(`${e}删除成功！`)
                this.get_sub_subjectFun(this.state.params.subject_id)
                this.get_condition_by_sub_subject_idFun(this.state.params.sub_subject_id)
            } else {
                
            }
        })
    }

    render() {
        const { params, all_subject, sub_subject, teachingVisible, condition } = this.state;
        return (
            <>
                <FormOutlined onClick={() => { this.setState({ teachingVisible: true, params: params_model }); }} style={{ cursor: 'pointer', position: 'absolute', left: '-12px', top: '-12px' }} />
                <Modal
                    title={'添加/修改类型'}
                    cancelText='取消'
                    okText='确认'
                    visible={teachingVisible}
                    onOk={this.onOk}
                    onCancel={(e) => { this.setState({ teachingVisible: false, sub_subject: [] }); }}
                >
                    <Row>

                        <Col className="ant-col-css" span={8}>
                            <span style={{ lineHeight: '32px' }}>科目：</span>
                        </Col>
                        <Col className="ant-col-css" span={16}>
                            <Select placeholder='请选择' style={{ width: 165 }} onChange={e => this.onChange(e, 'subject_id')} value={params.subject_id ? Number(params.subject_id) : undefined}>
                                {all_subject.map((item, index) =>
                                    <Option key={index} value={Number(item.id)}>{item.name}</Option>
                                )}
                            </Select>
                        </Col>

                        <Col className="ant-col-css" span={8}>
                            <span style={{ lineHeight: '32px' }}>是否新建子科目：</span>
                        </Col>
                        <Col className="ant-col-css" span={16}>
                            <Switch checkedChildren="是" unCheckedChildren="否" defaultChecked={false} onChange={e => {
                                if (e) {
                                    let s = this.state.params; s['sub_subject_id'] = ''; s['sub_subject_id'] = '';
                                    s['sub_subject_list'] = [];
                                    s['grade_list'] = [];
                                    s['system_list'] = [];
                                    this.setState({ params: s })
                                } else {
                                    let s = this.state.params; s['sub_subject_id'] = ''; s['sub_subject_id'] = '';
                                    s['sub_subject_list'] = [];
                                    s['grade_list'] = [];
                                    s['system_list'] = [];
                                    this.setState({ params: s })
                                }
                                this.setState({ isadd: e })
                            }} />
                        </Col>
                        {
                            this.state.isadd ?
                                <div>
                                    <Col className="ant-col-css" span={8}>
                                        <span style={{ lineHeight: '32px' }}>子科目：</span>
                                    </Col>
                                    <Col className="ant-col-css" span={16} style={{ display: 'flex', flexWrap: 'wrap', height: 'auto', minHeight: '32px' }}>
                                        <Tags list_id={'sub_subject_list'} listSet={this.listSet} handleClose={this.handleClose} tags_list={params.sub_subject_list} />
                                    </Col>
                                </div>
                                :
                                <div>
                                    <Col className="ant-col-css" span={8}>
                                        <span style={{ lineHeight: '32px' }}>子科目：</span>
                                    </Col>
                                    <Col className="ant-col-css" span={16}>
                                        <Select placeholder='请选择' style={{ width: 165 }} onChange={e => this.onChange(e, 'sub_subject_id')} value={params.sub_subject_id ? Number(params.sub_subject_id) : undefined} >
                                            {sub_subject.map((item, index) =>
                                                <Option key={index} value={Number(item.id)}>{item.name}</Option>
                                            )}
                                        </Select>
                                    </Col>
                                    <Col className="ant-col-css" span={8}>
                                        <span style={{ lineHeight: '32px' }}>年级：</span>
                                    </Col>
                                    <Col className="ant-col-css" span={16} style={{ display: 'flex', flexWrap: 'wrap', height: 'auto', minHeight: '32px' }}>
                                        <Tags list_id={'grade_list'} listSet={this.listSet} handleClose={this.handleClose} tags_list={params.grade_list} />
                                    </Col>
                                    <Col className="ant-col-css" span={8}>
                                        <span style={{ lineHeight: '32px' }}>体系：</span>
                                    </Col>
                                    <Col className="ant-col-css" span={16} style={{ display: 'flex', flexWrap: 'wrap', height: 'auto', minHeight: '32px' }}>
                                        <Tags list_id={'system_list'} listSet={this.listSet} handleClose={this.handleClose} tags_list={params.system_list} />
                                    </Col>
                                </div>
                        }
                    </Row>
                </Modal>
            </>
        );
    }
}

export default Add