import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { Row, Col, Modal, Table, Input, Select, DatePicker, message, Upload, Button, Pagination, Tooltip } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { create_complaint, feedback_get_list, deal_complaint, get_complaint_detail, loginUserList, get_xiaoguanjia_grade, get_all_student } from '../../axios/http'
import '../../store/config'
const { Option } = Select
const baseURL = global.constants.baseURL

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

const Complaint = (props) => {
    const columns = [
        {
            title: '学生姓名',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            render: (text, value) => (
                <span className='otherTable_name' onClick={e => changeVisible('detail', value.id)}>
                    {text}
                </span>
            ),
        },
        {
            title: '老师姓名',
            width: 200,
            dataIndex: 'teacher',
            key: 'teacher',
            render: (text, value) => (
                value.teacher_id == 0 || value.teacher_id == null ? <span className='otherTable_name' onClick={e => changeVisible('detail', value.id)}>无需选择</span>
                    :
                    <span className='otherTable_name' onClick={e => changeVisible('detail', value.id)}>{text}</span>
            )
        },
        {
            title: '时间',
            width: 200,
            dataIndex: 'date',
            key: 'date'
        },
        {
            title: '状态',
            width: 100,
            dataIndex: 'state',
            key: 'state',
            render: text => (
                <span>{text == -1 ? '已提交' : text == 1 ? '已处理' : '已忽略'}</span>
            )
        },
        {
            title: '描述',
            dataIndex: 'problem',
            key: 'problem',
            render: text => (
                <Tooltip placement="top" title={text}>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '400px', display: 'inline-block' }}>{text}</span>
                </Tooltip>
            )
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            render: (e, val) => (
                <span className='otherTable_name' onClick={e => changeVisible('deal', val.id)}>立即处理</span>
            )
        }
    ]
    const params_value = {
        name: '',
        teacher: '',
        date: '',
        state: '',
        page: 1,
        page_size: 10
    }
    const params_create = {
        grade_id: '',
        student_id: '',
        teacher_id: '',
        date: moment(new Date()).format('YYYY-MM-DD'),
        problem: '',
        screenshot: '',
        company_id: localStorage.getItem('company_id')
    }
    const params_deal = {
        id: '',
        deal_date: moment(new Date()).format('YYYY-MM-DD'),
        state: '',
        deal_method: ''
    }
    const header = {
        token: localStorage.getItem("token"),
        username: encodeURI(localStorage.getItem("username")),
        companyid: localStorage.getItem("companyid"),
    }
    const visible_state = {
        create: false,
        deal: false,
        detail: false
    }
    const updata = {
        type: 6
    }
    const [get, setGet] = useState(params_value)
    const [create, setCreate] = useState(params_create)
    const [visible, setVisible] = useState(visible_state)
    const [data, setData] = useState()
    const [grade_list, setGrade_list] = useState()
    const [stu_list, setStu_list] = useState()
    const [teacher_list, setTeacher_list] = useState()
    const [loading, setLoading] = useState(false)
    const [imageUrl, setImageUrl] = useState()
    const [count, setCount] = useState(0)
    const [detail, setDetail] = useState(params_value)
    const [deal, setDeal] = useState(params_deal)
    const uploadButton = (<div>{loading ? <LoadingOutlined /> : <PlusOutlined />}<div style={{ marginTop: 8 }}>Upload</div></div>)
    useEffect(() => {
        feedback_get_listFun()
        get_user_listFun()
        get_xiaoguanjia_gradeFun()
    }, [])
    const get_xiaoguanjia_gradeFun = () => {
        get_xiaoguanjia_grade({ is_all: 1 }).then(res => {
            let list = res.data.list.map(item => {
                return <Option value={item.value.split('-')[1]} key={item.value.split('-')[1]}>{item.value.split('-')[1]}</Option>
            })
            setGrade_list(list)
        })
    }
    const get_user_listFun = () => {
        loginUserList().then(res => {
            let list = res.data.list.map(item => {
                if (item.id) {
                    return <Option value={item.id} key={item.id}>{item.name}</Option>
                }
            })
            setTeacher_list(list)
        })
    }
    const get_student_listFun = (e) => {
        get_all_student({ grade: e, status: 1 }).then(res => {
            let list = res.data.list.map(item => {
                return <Option value={item.student_id} key={item.student_id}>{item.name}</Option>
            })
            setStu_list(list)
        })
    }
    const feedback_get_listFun = (type) => {
        if (type == 'search') {
            get.page = 1
            setGet(get)
        }
        feedback_get_list(get).then(res => {
            setData(res.data.list)
            setCount(res.data.count)
        })
    }
    const create_complaintFun = () => {
        let f = false
        for (var key in create) {
            if (!create[key] && key != 'screenshot' && key != 'grade_id') {
                f = true
                break
            }
        }
        if (f) {
            message.warn('有数据为填写，请完善！')
            return
        }
        create_complaint(create).then(res => {
            if (res.code == 0) {
                message.success('添加成功！')
                feedback_get_listFun()
                changeVisible('create')
                setCreate(params_create)
            } else {
                message.warn(res.message)
            }
        })
    }
    const changeVisible = (val, id) => {
        let state = JSON.stringify(visible),
            json = JSON.parse(state)
        json[val] = !json[val]
        setVisible(json)
        switch (val) {
            case 'create': if (!json[val]) { setCreate(params_create) } else { get_student_listFun('') }; break;
            case 'detail': if (json[val]) { get_complaint_detail({ id: id }).then(res => { if (res.code == 0) { setDetail(res.data.model) } }) }; break;
            case 'deal': if (!json[val]) { setDeal(params_deal) } else { let data = deal; data.id = id; setDeal(data) }; break;
        }
    }
    const createChange = (e, val) => {
        let data = create
        data[val] = e ? val == 'date' ? moment(e).format('YYYY-MM-DD') : val == 'problem' ? e.target.value : e : ''
        setCreate({ ...data })
        if (val == 'grade_id') {
            get_student_listFun(data[val])
        }
    }
    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            return;
        }
        if (info.file.status === 'done') {
            let data = create
            data['screenshot'] = info.file.response.data.full_path
            setLoading(false)
            setImageUrl(info.file.response.data.full_path)
            setCreate({ ...data })
        }
    }
    const changePage = e => {
        get.page = e
        setGet(get)
        feedback_get_listFun()
    }
    const changeDeal = (e, val) => {
        let data = deal
        data[val] = val == 'deal_date' ? moment(e).format('YYYY-MM-DD') : val == 'deal_method' ? e.target.value : e
        setDeal({ ...data })
    }
    const dealOK = e => {
        deal_complaint(deal).then(res => {
            if (res.code == 0) {
                message.success('处理成功！')
                changeVisible('deal')
                feedback_get_listFun()
                setDeal(params_deal)
            } else {
                message.warn(res.message)
            }
        })
    }
    const searchChange = (e, id, type) => {
        let data = JSON.stringify(get),
            json = JSON.parse(data)
        json[id] = type == 'input' ? e.target.value : type == 'date' ? e == '' ? '' : moment(e).format('YYYY-MM-DD') : e
        setGet(json)
    }
    return (
        <div>
            <Modal
                title="投诉录入"
                visible={visible.create}
                onOk={create_complaintFun}
                onCancel={e => changeVisible('create')}
                okText="确认"
                cancelText="取消"
            >
                <Row>
                    <Col className="ant-col-css" span={8}>
                        <span>年级：</span>
                    </Col>
                    <Col className="ant-col-css" span={16}>
                        <Select placeholder="请选择" style={{ width: 165 }} value={!create.grade_id ? undefined : create.grade_id} onChange={e => createChange(e, 'grade_id')} allowClear>
                            {grade_list}
                        </Select>
                    </Col>
                    <Col className="ant-col-css" span={8}>
                        <span><span style={{ color: 'red' }}>*</span>学生：</span>
                    </Col>
                    <Col className="ant-col-css" span={16}>
                        <Select placeholder="请选择" style={{ width: 165 }} value={!create.student_id ? undefined : create.student_id} onChange={e => createChange(e, 'student_id')} allowClear showSearch optionFilterProp="children">
                            {stu_list}
                        </Select>
                    </Col>
                    <Col className="ant-col-css" span={8}>
                        <span><span style={{ color: 'red' }}>*</span>老师：</span>
                    </Col>
                    <Col className="ant-col-css" span={16}>
                        <Select style={{ width: 165 }} placeholder="请选择" value={!create.teacher_id ? undefined : create.teacher_id} onChange={e => createChange(e, 'teacher_id')} showSearch allowClear optionFilterProp="children">
                            <Option value='0'>无需选择</Option>
                            {teacher_list}
                        </Select>
                    </Col>
                    <Col className="ant-col-css" span={8}>
                        <span><span style={{ color: 'red' }}>*</span>投诉日期：</span>
                    </Col>
                    <Col className="ant-col-css" span={16}>
                        <DatePicker onChange={e => createChange(e, 'date')} value={moment(create.date, 'YYYY-MM-DD')} />
                    </Col>
                    <Col className="ant-col-css" span={8}>
                        <span><span style={{ color: 'red' }}>*</span>投诉描述：</span>
                    </Col>
                    <Col className="ant-col-css" span={16}>
                        <Input onChange={e => createChange(e, 'problem')} value={create.problem} />
                    </Col>
                    <Col className="ant-col-css" span={8} style={{ height: '112px' }}>
                        <span>投诉截图：</span>
                    </Col>
                    <Col className="ant-col-css" span={16} style={{ height: '112px' }}>
                        <Upload
                            name="upload_control"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action={`${baseURL}/api/upload/upload_file`}
                            data={updata}
                            headers={header}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                        >
                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                    </Col>
                </Row>
            </Modal>
            <Modal
                title="投诉详情"
                visible={visible.detail}
                onOk={e => changeVisible('detail')}
                onCancel={e => changeVisible('detail')}
                okText="确认"
                cancelText="取消"
            >
                <Row style={{ marginBottom: '10px' }}>
                    <Col span={8}>
                        <span>学生姓名：</span>
                    </Col>
                    <Col span={16}>
                        {detail.name}
                    </Col>
                </Row>
                <Row style={{ marginBottom: '10px' }}>
                    <Col span={8}>
                        <span>老师姓名：</span>
                    </Col>
                    <Col span={16}>
                        {detail.teacher_id == '0' || detail.teacher_id == null ? '无需选择' : detail.teacher}
                    </Col>
                </Row>
                <Row style={{ marginBottom: '10px' }}>
                    <Col span={8}>
                        <span>投诉日期：</span>
                    </Col>
                    <Col span={16}>
                        {detail.date}
                    </Col>
                </Row>
                <Row style={{ marginBottom: '10px' }}>
                    <Col span={8}>
                        <span>投诉描述：</span>
                    </Col>
                    <Col span={16}>
                        {detail.problem}
                    </Col>
                </Row>
                <Row style={{ marginBottom: '10px' }}>
                    <Col span={8}>
                        <span>投诉截图：</span>
                    </Col>
                    <Col span={16}>
                        <img src={detail.screenshot} style={{ maxWidth: '300px' }} />
                    </Col>
                </Row>
            </Modal>
            <Modal
                title="投诉处理"
                visible={visible.deal}
                onOk={dealOK}
                onCancel={e => changeVisible('deal')}
                okText="确认"
                cancelText="取消"
            >
                <Row>
                    <Col className="ant-col-css" span={8}>
                        <span>处理日期：</span>
                    </Col>
                    <Col className="ant-col-css" span={16}>
                        <DatePicker onChange={e => changeDeal(e, 'deal_date')} value={moment(deal.deal_date, 'YYYY-MM-DD')} />
                    </Col>
                    <Col className="ant-col-css" span={8}>
                        <span><span style={{ color: 'red' }}>*</span>处理状态：</span>
                    </Col>
                    <Col className="ant-col-css" span={16}>
                        <Select style={{ width: 165 }} onChange={e => changeDeal(e, 'state')} value={deal.state}>
                            <Option value={'1'}>已处理</Option>
                            <Option value={'2'}>已忽略</Option>
                        </Select>
                    </Col>
                    <Col className="ant-col-css" span={8}>
                        <span>处理方法：</span>
                    </Col>
                    <Col className="ant-col-css" span={16}>
                        <Input onChange={e => changeDeal(e, 'deal_method')} value={deal.deal_method} />
                    </Col>
                </Row>
            </Modal>
            <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
                <div className="flex">
                    <div style={{ marginRight: '10px' }}>
                        <span>学生：</span>
                        <Input style={{ width: 150 }} placeholder="请输入" onChange={e => searchChange(e, 'name', 'input')} allowClear></Input>
                    </div>
                    <div style={{ marginRight: '10px' }}>
                        <span>老师：</span>
                        <Input style={{ width: 150 }} placeholder="请输入" onChange={e => searchChange(e, 'teacher', 'input')} allowClear></Input>
                    </div>
                    <div style={{ marginRight: '10px' }}>
                        <span>时间：</span>
                        <DatePicker onChange={e => searchChange(e, 'date', 'date')} allowClear />
                    </div>
                    <div style={{ marginRight: '10px' }}>
                        <span>状态：</span>
                        <Select style={{ width: 180 }} onChange={e => searchChange(e, 'state', 'select')} placeholder="请选择状态" allowClear>
                            <Option value="-1">已提交</Option>
                            <Option value="1">已处理</Option>
                            <Option value="2">已忽略</Option>
                        </Select>
                    </div>
                    <Button onClick={e => feedback_get_listFun('search')} type="primary">查找</Button>
                </div>
                <Button onClick={e => changeVisible('create')} type="primary">添加</Button>
            </div>
            <Table columns={columns} dataSource={data} bordered size="middle" pagination={false} />
            <div className="m-Pleft m-flex">
                <Pagination current={Number(get.page)} onChange={changePage} total={Number(count)} />
            </div>
        </div >
    )
}
export default Complaint
