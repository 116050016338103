import React, { useEffect, useState, useContext } from 'react'
import { Modal, Input, Select, Button, Row, Col, message } from 'antd';
import {
    get_all_subject,
    get_sub_subject,
    get_condition_by_sub_subject_id,
    get_teaching_type_list,
    create_teachingtype,
    edit_teachingtype,
    del_teachingtype
} from '../../axios/http'
import myContext from '../../actions/createContext'

const { TextArea } = Input;
const { Option } = Select

let params_model = {
    name: '',
    grade_id: '',
    subject_id: '',
    sub_subject_id: '',
    year: '',
    system_id: '',
    period_id: '',
    class_type_id: '',
    company_id: '',
    page: 1,
    page_size: 10
}

let teach_params_model = {
    teachingtype_id: '',
    name: '',
    grade_id: '',
    subject_id: '',
    sub_subject_id: '',
    year: '',
    system_id: '',
    period_id: '',
    introduction: '',
    class_type_id: '',
    company_id: '',
    resources_json_str: '',
}

const Syllabusdetail = (props) => {

    const [params, paramsSet] = useState(params_model)
    const [teach_params, teach_paramsSet] = useState(teach_params_model)
    const [index_list, index_listSet] = useState([0, 0, 0, 0, 0, 0])
    const [all_subject, all_subjectSet] = useState([])
    const [sub_subject, sub_subjectSet] = useState([])
    const [condition, conditionSet] = useState({})
    const [teachingtype_id, teachingtype_idSet] = useState()
    const [teachingVisible, teachingVisibleSet] = useState(false)
    const [iseditor, iseditorSet] = useState(false)
    const teachEditor = useContext(myContext);

    useEffect(() => {
        if (JSON.stringify(teachEditor) != '{}') {
            teachEditor['teachingtype_id'] = teachEditor.id
            teach_paramsSet(teachEditor)
            get_all_subjectFun()
            get_sub_subjectFun(teachEditor.subject_id)
            get_condition_by_sub_subject_idFun(teachEditor.sub_subject_id)
            teachingVisibleSet(true)
            iseditorSet(true)
        }
    }, [teachEditor])

    const get_all_subjectFun = () => {
        get_all_subject().then(res => {
            if (res.code == 0) {
                all_subjectSet(res.data.subject_list)
            }
        })
    }

    const get_sub_subjectFun = (e) => {
        let data = {
            subject_id: e
        }
        get_sub_subject(data).then(res => {
            if (res.code == 0) {
                sub_subjectSet(res.data.list)
            }
        })
    }

    const get_condition_by_sub_subject_idFun = (e) => {
        let data = {
            sub_subject_id: e
        }
        get_condition_by_sub_subject_id(data).then(res => {
            if (res.code == 0) {
                conditionSet(res.data)
            }
        })
    }

    const create_teachingtypeFun = () => {
        let data = teach_params
        create_teachingtype(data).then(res => {
            if (res.code == 0) {
                message.success('创建成功！')
                teachingVisibleSet(false)
                props.get_teaching_type_listFun()
            } else {
                message.warn(res.message)
            }
        })
    }

    const edit_teachingtypeFun = () => {
        let data = teach_params
        edit_teachingtype(data).then(res => {
            if (res.code == 0) {
                message.success('修改成功！')
                teachingVisibleSet(false)
                props.get_teaching_type_listFun()
            } else {
                message.warn(res.message)
            }
        })
    }

    const teachingonOk = () => {
        if (iseditor) {
            edit_teachingtypeFun()
        } else {
            create_teachingtypeFun()
        }
    }

    const del_teachingtypeFun = () => {
        let data = {
            teachingtype_id: teachingtype_id
        }
        del_teachingtype(data).then(res => {
            if (res.code == 0) {
                console.log(res.data)
            }
        })
    }

    const onChange = (e, id) => {
        switch (id) {
            case 'subject_id':
                get_sub_subjectFun(e);
                var t = JSON.stringify(teach_params), s = JSON.parse(t)
                s[id] = typeof (e) == 'undefined' ? '' : typeof (e) == 'object' ? e.target.value : e;
                s['sub_subject_id'] = '';
                s['class_type_id'] = '';
                s['company_id'] = '';
                s['grade_id'] = '';
                s['period_id'] = '';
                teach_paramsSet(s);

                break;
            case 'sub_subject_id':
                get_condition_by_sub_subject_idFun(e);
                var t = JSON.stringify(teach_params), s = JSON.parse(t);
                s[id] = typeof (e) == 'undefined' ? '' : typeof (e) == 'object' ? e.target.value : e;
                s['class_type_id'] = '';
                s['company_id'] = '';
                s['grade_id'] = '';
                s['period_id'] = '';
                teach_paramsSet(s);
                break;
            default:
                var t = JSON.stringify(teach_params), s = JSON.parse(t)
                s[id] = typeof (e) == 'undefined' ? '' : typeof (e) == 'object' ? e.target.value : e;
                teach_paramsSet(s);
        }
    }

    return (
        <div style={{ position: 'relative', height: '32px' }}>

            <Button onClick={() => { teachingVisibleSet(true); get_all_subjectFun(); teach_paramsSet(teach_params_model); iseditorSet(false); }} style={{ position: 'absolute', right: '0' }}>添加</Button>

            <Modal
                title={iseditor ? '修改' : '创建' + '教学大纲类型'}
                cancelText='取消'
                okText='确认'
                visible={teachingVisible}
                onOk={teachingonOk}
                onCancel={(e) => { teachingVisibleSet(false); props.teachEditorFun(e, {}); iseditorSet(false); }}
            >
                <Row>
                    <Col className="ant-col-css" span={8}>
                        <span>名称：</span>
                    </Col>
                    <Col className="ant-col-css" span={16}>
                        <Input placeholder='请选择' style={{ width: 165 }} onChange={e => onChange(e, 'name')} value={teach_params.name} />
                    </Col>
                    <Col className="ant-col-css" span={8}>
                        <span>科目：</span>
                    </Col>
                    <Col className="ant-col-css" span={16}>
                        <Select placeholder='请选择' style={{ width: 165 }} onChange={e => onChange(e, 'subject_id')} value={teach_params.subject_id ? Number(teach_params.subject_id) : undefined}>
                            {all_subject.map((item, index) =>
                                <Option key={index} value={Number(item.id)}>{item.name}</Option>
                            )}
                        </Select>
                    </Col>
                    <Col className="ant-col-css" span={8}>
                        <span>子科目：</span>
                    </Col>
                    <Col className="ant-col-css" span={16}>
                        <Select placeholder='请选择' style={{ width: 165 }} onChange={e => onChange(e, 'sub_subject_id')} value={teach_params.sub_subject_id ? Number(teach_params.sub_subject_id) : undefined} >
                            {sub_subject.map((item, index) =>
                                <Option key={index} value={Number(item.id)}>{item.name}</Option>
                            )}
                        </Select>
                    </Col>

                    {condition.class_type_list ? <Row style={{ width: '100%' }}>
                        <Col className="ant-col-css" span={8}>
                            <span>类别：</span>
                        </Col>
                        <Col className="ant-col-css" span={16}>
                            <Select placeholder='请选择' style={{ width: 165 }} onChange={e => onChange(e, 'class_type_id')} value={teach_params.class_type_id ? Number(teach_params.class_type_id) : undefined} >
                                {condition.class_type_list.map((item, index) =>
                                    <Option key={index} value={Number(item.id)}>{item.name}</Option>
                                )}
                            </Select>
                        </Col>
                    </Row> : ''}

                    {condition.company_list ? <Row style={{ width: '100%' }}>
                        <Col className="ant-col-css" span={8}>
                            <span>校区：</span>
                        </Col>
                        <Col className="ant-col-css" span={16}>
                            <Select placeholder='请选择' style={{ width: 165 }} onChange={e => onChange(e, 'company_id')} value={teach_params.company_id || typeof (teach_params.company_id) == 'number' ? Number(teach_params.company_id) : undefined} >
                                {condition.company_list.map((item, index) =>
                                    <Option key={index} value={Number(item.company_id)}>{item.name}</Option>
                                )}
                            </Select>
                        </Col>
                    </Row> : ''}

                    {condition.grade_list ? <Row style={{ width: '100%' }}>
                        <Col className="ant-col-css" span={8}>
                            <span>年级：</span>
                        </Col>
                        <Col className="ant-col-css" span={16}>
                            <Select placeholder='请选择' style={{ width: 165 }} onChange={e => onChange(e, 'grade_id')} value={teach_params.grade_id ? Number(teach_params.grade_id) : undefined} >
                                {condition.grade_list.map((item, index) =>
                                    <Option key={index} value={Number(item.id)}>{item.value}</Option>
                                )}
                            </Select>
                        </Col>
                    </Row> : ''}

                    {condition.period_list ? <Row style={{ width: '100%' }}>
                        <Col className="ant-col-css" span={8}>
                            <span>学期：</span>
                        </Col>
                        <Col className="ant-col-css" span={16}>
                            <Select placeholder='请选择' style={{ width: 165 }} onChange={e => onChange(e, 'period_id')} value={teach_params.period_id ? Number(teach_params.period_id) : undefined} >
                                {condition.period_list.map((item, index) =>
                                    <Option key={index} value={Number(item.id)}>{item.name}</Option>
                                )}
                            </Select>
                        </Col>
                    </Row> : ''}

                    {condition.system_list ? <Row style={{ width: '100%' }}>
                        <Col className="ant-col-css" span={8}>
                            <span>课程体系：</span>
                        </Col>
                        <Col className="ant-col-css" span={16}>
                            <Select placeholder='请选择' style={{ width: 165 }} onChange={e => onChange(e, 'system_id')} value={teach_params.system_id ? Number(teach_params.system_id) : undefined} >
                                {condition.system_list.map((item, index) =>
                                    <Option key={index} value={Number(item.id)}>{item.value}</Option>
                                )}
                            </Select>
                        </Col>
                    </Row> : ''}

                    <Col className="ant-col-css" span={8}>
                        <span>年份：</span>
                    </Col>
                    <Col className="ant-col-css" span={16}>
                        <Input placeholder='请选择' style={{ width: 165 }} value={teach_params.year} onChange={e => onChange(e, 'year')} />
                    </Col>
                    <Col className="ant-col-css" span={8}>
                        <span>介绍：</span>
                    </Col>
                    <Col className="ant-col-css" style={{ height: 'auto' }} span={16}>
                        <TextArea style={{ resize: 'none' }} autoSize={{ minRows: 3, maxRows: 5 }} value={teach_params.introduction} onChange={e => onChange(e, 'introduction')} />
                    </Col>
                </Row>
            </Modal>

        </div>
    )
}

export default Syllabusdetail
