import React, { useState, useEffect } from 'react';
import { Table, Button } from 'antd';
import { report_detail, get_active_detail } from '../../../axios/http'
import { getUrlParams } from '../../../actions/getUrlParams'

const Reportdetail = (props) => {

  const [columns, columnsSet] = useState([])
  const [data, dataSet] = useState([])

  useEffect(() => {
    let data = {
      active_id: getUrlParams('active_id', props),
      submit_status: getUrlParams('submit_status', props),
      student_status: getUrlParams('student_status', props)
    }
    get_active_detail({ active_id: data.active_id }).then(res => {
      if (res.code === 0) {
        let field_json = JSON.parse(res.data.model.field_json_str).field_list, obj = []
        field_json.map(item => {
          if (item.item_list.length > 0) {
            obj.push({
              title: item.title,
              dataIndex: item.field,
              key: item.field,
              has_select: false,
              item_list: item.item_list,
              render: (text, record, index) => {
                let t = ''
                item.item_list.some(item2 => {
                  if (item2.id == text) {
                    t = item2.name
                    return true
                  }
                })
                return t
              }
            })
          } else {
            obj.push({
              title: item.title,
              dataIndex: item.field,
              key: item.field,
              has_select: false,
              item_list: item.item_list,
              render: (text) => {
                if (text) { } else { return '' }
                if (text.indexOf('png') != -1 || text.indexOf('jpeg') != -1) {
                  return <img style={{ maxHeight: '80px' }} src={text}></img>
                } else {
                  return text
                }
              }
            })
          }
        })
        const columns = [{
          title: '序号',
          dataIndex: 'id',
          key: 'id',
          render: (text, record, index) => (
            index + 1
          )
        },
        {
          title: '用户头像',
          dataIndex: 'headimgurl',
          key: 'headimgurl',
          render: (text, record, index) => (
            <img src={text} style={{ width: '50px' }} alt="" />
          )
        },
        {
          title: '用户名',
          dataIndex: 'nickname',
          key: 'nickname'
        },
        {
          title: '参与时间',
          dataIndex: 'add_time',
          key: 'add_time'
        },
        ...obj
        ];
        columnsSet(columns)
      }
    })
    report_detail(data).then(res => {
      if (res.code === 0) {
        let list = res.data.user_list
        dataSet(list)
      }
    })
  }, [])

  const back = e => {
    window.history.back(-1);
  }

  return (
    <div>
      <Button onClick={back} style={{ marginBottom: '15px' }}>返回</Button>
      <Table
        id='mytable'
        columns={columns}
        dataSource={data}
        bordered
        size="middle"
        pagination={false}
      />
    </div >
  )
}

export default Reportdetail;