import React, { Component } from 'react';
import { Table, Button, DatePicker, Modal, Upload, Input, Checkbox, Pagination, message, Tag, Select, Radio, InputNumber } from 'antd';
import {
    upload_task, paike,
    zidingyikejian,
    get_course_file,
    get_course_detail,
    creat_task,
    get_task,
    get_directory_file,
    get_directory,
    get_check_failed,
    read_message,
    set_no_task,
    get_headmaster_list,
    sync_xiaoguanjia_course
} from '../../axios/http'
import { InboxOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import Zmage from 'react-zmage'
import JiangyiLink from './myResources/index/index'
import locale from 'antd/es/date-picker/locale/zh_CN'
import moment from 'moment';
import produce from "immer"
import 'moment/locale/zh-cn';
import { tableToExcel } from '../../actions/tableToExcel'
import '../../store/config'
moment.locale('zh-cn')
// const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;
const baseUrl = global.constants.baseURL
const { Option } = Select
const { TextArea } = Input
const { Dragger } = Upload
const { confirm } = Modal;
const plainOptions = ['知识精讲', '三点剖析', '例题', '随练', '扩展'];
const dateFormat = 'YYYY-MM-DD'
function fun_date(aa) {
    var date1 = new Date()
    var date2 = new Date(date1)
    date2.setDate(date1.getDate() + aa)
    var time2 = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate()
    return time2
}
class bk extends Component {
    constructor(props) {
        super(props)
        this.state = {

            //这个是查询条件
            parmas: {
                starttime: null,
                endtime: null,
                isfinished: [],
                student_name: '',
                teacher_name: "",
                is_send_task: '',
                correcting_status: '',
                headmaster_id: '',
                postion_type_id: [],
                page: 1,
                page_size: 10,
            },
            task_model: {},
            imgViewList: [],
            notice_list: [],
            visible2: false,
            visible4: false,
            report_visible: false,
            visible5: false,
            file: '',
            title: '',
            model: {},
            course_url: '',
            modelName: '',
            //这个是自定义课件
            upParmas: {
                subject_id: '',
                grade_id: '',
                course_id: '',
                title: '',
                has_zhishijingjiang: -1,
                has_sandianpouxi: -1,
                has_liti: -1,
                has_suilian: -1,
                has_kuozhan: -1,
                file: ''
            },
            //这是上传直播地址的地方
            upParmas2: {
                course_id: '',
                course_url: ''
            },
            zuoyeParams: {
                course_id: '',
                ques_count: '',
                total_minute: '',
                is_in_lecture: '1',
                file: [],
                student_list: [{
                    student_id: '',
                    student_name: '',
                    place_type_id: '',
                    is_need_assistant: '',
                    assistant_teacher_id: ''
                }]
            },
            taskParams: {
                student_id: [],
                course_id: '',
                wrong_ques_count: '0',
                task_file: [],
                remark: ''
            },
            totalCount: 100,
            count: this.totalCount,
            obj: {
                has_zhishijingjiang: '知识精讲',
                has_sandianpouxi: '三点剖析',
                has_liti: '例题',
                has_suilian: '随练',
                has_kuozhan: '扩展',
            },
            time: new Date(),
            endtime: fun_date(7),
            fileList: [],
            fileList2: [],
            checkList: [],
            task_students: [],
            visible: false,
            tableKey: '',
            data: [
            ],
            master_list: [],
            has_task: '1',
            zmageList: [],
            columns2: [
                {
                    title: '姓名',
                    dataIndex: 'student_name',
                    key: 'student_name',
                    align: 'center',
                    render: (text, record, index) => (
                        <span>
                            {text}
                        </span>
                    ),
                },
                {
                    title: '错题数量',
                    key: 'wrong_ques_count',
                    align: 'center',
                    render: (text, record, index) => (
                        <span >
                            {
                                this.state.index == index ?
                                    <Input value={record.wrong_ques_count} style={{ width: 50 }} onChange={(e) => this.wrongQ(e, record, 'wrong_ques_count')}></Input>
                                    :
                                    record.wrong_ques_count ?
                                        <div>{record.state == 2 ? '未完成' : record.wrong_ques_count}</div>
                                        :
                                        <Input value={record.wrong_ques_count} style={{ width: 50 }} onChange={(e) => this.wrongQ(e, record, 'wrong_ques_count')}></Input>
                            }
                        </span>
                    ),
                },
                {
                    title: '备注',
                    key: 'remark',
                    align: 'center',
                    render: (text, record, index) => (
                        <span >
                            {
                                this.state.index == index ?
                                    <Input value={record.remark} style={{ width: 100 }} onChange={(e) => this.wrongQ(e, record, 'remark')}></Input>
                                    :
                                    record.wrong_ques_count ?
                                        <div>{record.remark}</div>
                                        :
                                        <Input value={record.remark} style={{ width: 100 }} onChange={(e) => this.wrongQ(e, record, 'remark')}></Input>
                            }
                        </span>
                    ),
                },
                {
                    title: '文件上传情况',
                    key: 'task_file',
                    align: 'center',
                    render: (text, record, index) => (
                        <span >
                            {
                                record.task_file ?
                                    <div>
                                        <Button size="small" type="primary" onClick={() => this.imageView(record.task_file)} ghost>查看文件</Button>
                                        <Button style={{ marginTop: 10 }} size="small" type="danger" ghost onClick={() => this.handleOk4(record.id)}>重新上传</Button>
                                    </div>
                                    :
                                    <Button size="small" type="primary" onClick={() => this.handleOk4(record.id)}>上传文件</Button>}
                        </span>
                    ),
                },
                {
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    render: (text, record, index) => (
                        <span>
                            {
                                this.state.index == index ?
                                    <div>
                                        <Button size="small" type="primary" onClick={() => this.finalOk(record, 1)}>确认提交</Button>
                                        <Button size="small" type="primary" onClick={() => this.finalOk(record, 2)}>未完成</Button>
                                    </div>
                                    :
                                    record.wrong_ques_count ?
                                        <Button size="small" type="primary" onClick={() => this.editor(index)}>修改</Button>
                                        :
                                        <div>
                                            <Button size="small" type="primary" onClick={() => this.finalOk(record, 1)}>确认提交</Button>
                                            <Button size="small" type="primary" onClick={() => this.finalOk(record, 2)}>未完成</Button>
                                        </div>
                            }
                        </span>
                    ),
                },
            ],
            student_list: []
        }
    }
    imageView = (info) => {
        if (info.split(',').length >= 1) {
            info.split(',').forEach(res => {
                if (res === 'null') {
                    return false
                }
                this.setState({
                    imgViewList: info.split(','),
                    visible5: true
                })
            })
        } else {
            this.setState({
                imgViewList: info,
                visible5: true
            })
        }
    }
    componentDidMount() {
        let myDate = new Date();
        let time = myDate.toLocaleDateString().split("/").join("-");
        const parmas = this.state.parmas
        parmas['starttime'] = time
        parmas['endtime'] = fun_date(7)
        paike(parmas).then(res => {
            const list = res.data.list.map((res, index) => {
                res.key = `${index}`
                return res
            })
            this.setState({
                data: list,
                totalCount: Number(res.data.total_count),
                parmas,
                count: Number(res.data.total_count)
            })
        })
        this.get_check_failed()
        this.get_headmaster_list()
    }

    get_headmaster_list = () => {
        get_headmaster_list().then(res => {
            this.setState({
                master_list: res.data.headmaster_list
            })
        })
    }
    //日期改变
    get_check_failed = () => {
        get_check_failed().then(res => {
            let list = res.data.list, f = false
            if (list.length != 0) {
                f = true
            }
            this.setState({
                notice_list: res.data.list,
                isShownnotice: f
            })
        })
    }
    editor = e => {
        this.setState({
            index: e
        })
    }
    onchange = (value, dateString) => {
        const parmas = this.state.parmas
        parmas['starttime'] = dateString
        this.setState({
            parmas
        })
    }
    onchangeEnd = (value, dateString) => {
        const parmas = this.state.parmas
        parmas['endtime'] = dateString
        this.setState({
            parmas
        })
    }
    onChangecheckbox = (e) => {
        console.log(e)
        this.setState({
            checkList: e
        })
    }
    showModal = (e) => {
        get_course_file({ course_id: e }).then(res => {
            let model = res.data.model, checkList = []
            if (model) {
                if (model.has_kuozhan == 1) {
                    checkList.push('扩展')
                }
                if (model.has_liti == 1) {
                    checkList.push('例题')
                }
                if (model.has_sandianpouxi == 1) {
                    checkList.push('三点剖析')
                }
                if (model.has_suilian == 1) {
                    checkList.push('随练')
                }
                if (model.has_zhishijingjiang == 1) {
                    checkList.push('知识精讲')
                }
                this.setState({
                    title: model.title,
                    file: model.file,
                    checkList: checkList
                })
            }
            this.setState({
                visible: true,
            })
            this.setState(
                produce(draft => {
                    draft.upParmas.course_id = e
                })
            )
            this.setState({
                fileList: [],
            });
        })
    };
    showModal2 = (e, state) => {
        get_course_detail({ course_id: e }).then(res => {
            const student_list = res.data.student_list.map(res => {
                return {
                    student_id: res.student_id,
                    student_name: res.name,
                    place_type_id: '',
                    is_need_assistant: '',
                    assistant_teacher_id: '',

                }
            })
            const user_byCanSet = res.data.employee_list.map(res => {
                return <Option key={res.name} value={res.employee_id} >{res.name}</Option>
            })
            this.setState(
                produce(draft => {
                    draft.zuoyeParams.student_list = student_list
                    draft.zuoyeParams.course_id = e
                })
            )
            this.setState({
                visible2: true,
                has_task: state,
                fileList: [],
                user_byCanSet,
                modelName: res.data.course_model.class_name
            });
        })
    }
    handleOk = () => {
        const fileList = this.state.fileList
        let path = ''
        fileList.forEach(file => {
            path += file.response.data.full_path + ',';
        })
        const checkList = this.state.checkList
        const obj = { ...this.state.obj }
        const upParmas = { ...this.state.upParmas }
        upParmas.file = path
        checkList.forEach(res => {
            Object.keys(obj).forEach(ele => {
                if (obj[ele] === res) {
                    upParmas[ele] = 1
                }
            })
        })
        zidingyikejian(upParmas).then(res => {
            if (res.code === 0) {
                const parmas = { ...this.state.parmas }
                paike(parmas).then(res => {
                    const list = res.data.list.map((res, index) => {
                        res.key = `${index}`
                        return res
                    })
                    this.setState({
                        data: list,
                        totalCount: Number(res.data.total_count),
                    })
                })
                message.success(res.message)
                this.handleCancel()
            } else {
                message.success(res.message)
                this.handleCancel()
            }
        })
    };
    handleCancel = e => {
        this.setState({
            visibleJ: false,
            visible: false,
            fileList: [],
            title: '',
            checkList: [],
            file: '',
            upParmas: {
                subject_id: '',
                grade_id: '',
                course_id: '',
                title: '',
                has_zhishijingjiang: -1,
                has_sandianpouxi: -1,
                has_liti: -1,
                has_suilian: -1,
                has_kuozhan: -1,
                file: ''
            },
        });
    };
    handleOk2 = () => {
        if (this.state.has_task == '-1') {
            const zuoyeParams = { ...this.state.zuoyeParams }
            let data = {
                course_id: zuoyeParams.course_id
            }
            set_no_task(data).then(res => {
                this.setState({
                    visible2: false,
                    fileList: [],
                    has_task: '1',
                    title: '',
                    checkList: [],
                    zuoyeParams: {
                        course_id: '',
                        ques_count: '',
                        total_minute: '',
                        is_in_lecture: '',
                        file: [],
                        student_list: [{
                            student_id: '',
                            student_name: '',
                            place_type_id: '',
                            is_need_assistant: '',
                            assistant_teacher_id: ''
                        }]
                    }
                })
            })
        } else {
            const zuoyeParams = { ...this.state.zuoyeParams }
            const files = this.state.fileList.map(res => {
                return res.response.data.full_path
            })
            zuoyeParams.file = files
            if (zuoyeParams.total_minute === '' || zuoyeParams.ques_count === '') {
                message.warning('请填写必填项')
                return
            }
            creat_task({ json_str: JSON.stringify(zuoyeParams) }).then(res => {
                if (res.code === 0) {
                    message.success(res.message)
                    const parmas = { ...this.state.parmas }
                    paike(parmas).then(res => {
                        const list = res.data.list.map((res, index) => {
                            res.key = `${index}`
                            return res
                        })
                        this.setState({
                            data: list,
                            totalCount: Number(res.data.total_count),
                        })
                    })
                    this.setState({
                        visible2: false,
                        fileList: [],
                        title: '',
                        has_task: '1',
                        checkList: [],
                        zuoyeParams: {
                            course_id: '',
                            ques_count: '',
                            total_minute: '',
                            is_in_lecture: '',
                            file: [],
                            student_list: [{
                                student_id: '',
                                student_name: '',
                                place_type_id: '',
                                is_need_assistant: '',
                                assistant_teacher_id: ''
                            }]
                        }
                    });
                } else {
                    message.error(res.message)
                    this.setState({
                        visible2: false,
                        fileList: [],
                        title: '',
                        checkList: [],
                        zuoyeParams: {
                            course_id: '',
                            ques_count: '',
                            total_minute: '',
                            is_in_lecture: '',
                            file: [],
                            student_list: [{
                                student_id: '',
                                student_name: '',
                                place_type_id: '',
                                is_need_assistant: '',
                                assistant_teacher_id: ''
                            }]
                        }
                    });
                }
            })
        }
    };
    handleOk3 = () => {
        const taskParams = this.state.taskParams
        const files = this.state.fileList.map(res => {
            return res.response.data.full_path
        })
        taskParams.task_file = files
        upload_task({ ...taskParams }).then(res => {
            if (res.code === 0) {
                message.success(res.message)
                this.setState({
                    taskParams: {
                        student_id: [],
                        course_id: '',
                        wrong_ques_count: 0,
                        task_file: [],
                        remark: ''
                    },
                    visible3: false
                })
                const parmas = this.state.parmas
                paike(parmas).then(res => {
                    const list = res.data.list.map((res, index) => {
                        res.key = `${index}`
                        return res
                    })
                    this.setState({
                        data: list,
                        totalCount: Number(res.data.total_count),
                        count: Number(res.data.total_count)
                    })
                })
            } else {
                message.error(res.message)
                this.setState({
                    taskParams: {
                        student_id: [],
                        course_id: '',
                        wrong_ques_count: 0,
                        task_file: [],
                        remark: ''
                    },
                    visible3: false
                })
            }
        })
    }
    handleOk4 = (id) => {
        const task_students = this.state.task_students
        task_students.forEach((res, index) => {
            if (res.id === id) {
                this.setState({
                    visible4: true,
                    tableKey: index
                })
            }
        })
    }
    handleCancel5 = () => {
        this.setState({
            visible5: false
        })
    }
    handleCancel4 = () => {
        this.setState({
            visible4: false,
            fileList: []
        })
    }
    handleCancel3 = () => {
        this.setState({
            visible3: false,
            taskParams: {
                student_id: [],
                course_id: '',
                wrong_ques_count: 0,
                task_file: [],
                remark: ''
            },
            fileList2: [],
            fileList: []
        })
    }
    handleCancel2 = e => {
        this.setState({
            visible2: false,
            has_task: '1',
            fileList2: [],
            course_url: '',
            upParmas2: {
                course_id: '',
                course_url: ''
            },
        });
    };
    handleChange = info => {
        let fileList = [...info.fileList];
        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        // fileList = fileList.slice(-2);
        // 2. Read from response and show file link
        fileList = fileList.map(file => {
            if (file.response) {
                if (file.response.code !== 106) {
                    file.url = file.response.data.full_path;
                } else {
                    message.warning('上传失败请上传jpg、jpeg、bmp、png、gif、pdf、doc、docx、ppt、xls、xlsx格式')
                }
            }
            // Component will show file.url as link
            return file
        })
        this.setState({ fileList });
    };
    changeTitle = (e) => {
        const upParmas = { ...this.state.upParmas }
        upParmas.title = e.target.value
        this.setState({
            title: e.target.value,
            upParmas
        })
    }
    changeTitleUrl2 = (e, res) => {
        const zuoyeParams = { ...this.state.zuoyeParams }
        zuoyeParams[res] = e
        this.setState({
            zuoyeParams
        })
    }
    changePage = page => {
        const parmas = { ...this.state.parmas }
        parmas.page = page
        paike(parmas).then(res => {
            const list = res.data.list.map((res, index) => {
                res.key = `${index}`
                return res
            })
            this.setState({
                data: list,
                totalCount: Number(res.data.total_count),
                parmas
            })
        })
    };
    search = () => {
        const parmas = this.state.parmas
        parmas.page = 1
        paike(parmas).then(res => {
            const list = res.data.list.map((res, index) => {
                res.key = `${index}`
                return res
            })
            this.setState({
                data: list,
                totalCount: Number(res.data.total_count),
                count: Number(res.data.total_count),
                parmas
            })
        })
    }
    task_detail = e => {
        const taskParams = { ...this.state.taskParams }
        taskParams['course_id'] = e
        get_task({ course_id: e }).then(res => {
            if (res.data.student_list.length == 0 || !res.data.task_model) {
                message.warn('数据有误！')
                return
            }
            this.setState({
                task_students: res.data.student_list,
                modelName: res.data.task_model.class_name,
                task_model: res.data.task_model,
                visible3: true,
                taskParams,
                taskCourse_id: e
            })
        })
    }
    show_task_detail = (e, classname, starttime) => {
        const taskParams = { ...this.state.taskParams }
        taskParams['course_id'] = e
        get_task({ course_id: e }).then(res => {
            if (res.code == 0) {
                this.setState({
                    student_list: res.data.student_list,
                    task_model: res.data.task_model ? res.data.task_model : {},
                    report_visible: true,
                    classname: classname,
                    starttime: starttime
                })
            } else {
                message.error(res.message)
            }
        })
    }
    changeStudent = e => {
        const taskParams = { ...this.state.taskParams }
        taskParams['student_id'] = e
        this.setState({
            taskParams
        })
    }
    wrongQ = (e, type, ele) => {
        const task_students = this.state.task_students
        const detail = e.target.value
        task_students.forEach((res, index) => {
            if (res.id === type.id) {
                this.setState(
                    produce(draft => {
                        draft.task_students[index][ele] = detail
                    })
                )
            }
        })
    }
    actionFunt = (...canshu) => {
        if (canshu[3] === '2') {
            return false
        }
        if (canshu[5] === '1' && canshu[4] === '1') {
            return <>
                <Button onClick={() => this.task_detail(canshu[2])} style={{ backgroundColor: '#fde500', color: '#000' }}>部分批改</Button>
                <Button onClick={() => this.show_task_detail(canshu[2], canshu[6], canshu[7])} style={{ backgroundColor: '#088f35', color: '#fff', marginLeft: '10px' }}>统计</Button>
            </>
        } else if (canshu[5] === '2' && canshu[4] === '1') {
            return <>
                <Button onClick={() => this.task_detail(canshu[2])}>重新批改</Button>
                <Button onClick={() => this.show_task_detail(canshu[2], canshu[6], canshu[7])} style={{ backgroundColor: '#088f35', color: '#fff', marginLeft: '10px' }}>统计</Button>
            </>
        } else if (canshu[5] === '-1' && canshu[4] === '1') {
            return <div>
                <Button onClick={() => this.task_detail(canshu[2])} style={{ backgroundColor: '#fde500', color: '#000' }}>批改</Button>
            </div>
        }
        if ((canshu[0] === '-1' || canshu[0] === '1') && canshu[1] === '1') {
            return <div>
                <Button onClick={() => this.showModal(canshu[2])} style={{ marginRight: '10px' }}>调整备课</Button>
                <Button onClick={() => this.showModal2(canshu[2], '1')} style={{ backgroundColor: '#fc7803', color: '#fff' }}>练习</Button>
            </div>
        } else if (canshu[0] === '1' && canshu[1] === '1') {
            return <Button onClick={() => this.showModal2(canshu[2], '1')} style={{ backgroundColor: '#fc7803', color: '#fff' }}>练习</Button>
        } else if (canshu[0] === '2' && canshu[1] === '1') {
            return <Button type="danger" onClick={() => this.showModal(canshu[2])}>重新备课</Button>
        } else {
            return <Button type="danger" onClick={() => this.showModal(canshu[2])}>备课</Button>
        }
    }
    actionText = (...canshu) => {
        let text = ''
        let color = ''
        if (canshu[0] === '-1' && canshu[1] === '1') {
            text = '审核中'
            color = 'geekblue'
        } else if (canshu[0] === '1' && canshu[1] === '1') {
            text = '审核通过'
            color = 'green'
        } else if (canshu[0] === '2' && canshu[1] === '1') {
            text = '审核未通过'
            color = 'volcano'
        } else {
            text = ''
            color = 'geekblue'
        }
        return <Tag color={color}>{text}</Tag>
    }
    setPageSize = e => {
        const parmas = this.state.parmas
        const count = this.state.totalCount
        parmas.page_size = e
        this.setState({
            parmas,
            count: Math.ceil(Number(count) / Number(e) * 10)
        })
        this.changePage(this.state.parmas.page)
    }
    numberonChange = e => {
        const parmas = this.state.parmas
        parmas.page = e.target.value
        this.setState({
            parmas
        })
    }
    catchFile = url => {
        window.open(url)
    }
    nameSearch = (e, type) => {
        const parmas = { ...this.state.parmas }
        parmas[type] = e.target.value
        this.setState({
            parmas
        })
    }
    changePos = e => {
        const parmas = { ...this.state.parmas }
        parmas.postion_type_id = e
        this.setState({
            parmas
        })
    }
    changefinish = e => {
        const parmas = { ...this.state.parmas }
        parmas.isfinished = e
        this.setState({
            parmas
        })
    }
    changebeike = e => {
        const parmas = { ...this.state.parmas }
        parmas.is_beike = e
        this.setState({
            parmas
        })
    }
    finished = e => {
        if (e === '1') {
            return '已完成'
        } else if (e === '2') {
            return '已取消'
        } else {
            return '未完成'
        }
    }
    onchangeStateRadio = e => {
        this.setState(
            produce(draft => {
                draft.zuoyeParams.is_in_lecture = e.target.value
            })
        )
    }
    changeType = (e, res, index) => {
        if (res === 'place_type_id' && e === '2') {
            this.setState(
                produce(draft => {
                    draft.zuoyeParams.student_list[index]['is_need_assistant'] = '-1'
                })
            )
        }
        this.setState(
            produce(draft => {
                draft.zuoyeParams.student_list[index][res] = e
            })
        )
    }
    finalSure = () => {
        const fileList = this.state.fileList
        const tableKey = this.state.tableKey
        this.setState(
            produce(draft => {
                draft.task_students[tableKey]['task_file'] = fileList.map(res => {
                    return res.url
                })
            })
        )
        this.setState({
            visible4: false
        })
    }
    finalOk = (info, state) => {
        const taskParams = { ...this.state.taskParams }
        taskParams['course_id'] = info.course_id
        taskParams.student_id = info.student_id
        taskParams.task_file = info.task_file
        taskParams.wrong_ques_count = info.wrong_ques_count
        taskParams.remark = info.remark
        taskParams.state = state
        if (taskParams.task_file === '' || taskParams.wrong_ques_count === '') {
            message.warning('请填写该学生错题数量及错题图片')
            return
        }
        upload_task({ ...taskParams }).then(res => {
            if (res.code === 0) {
                message.success(res.message)
                get_task({ course_id: this.state.taskCourse_id }).then(res => {
                    this.setState({
                        task_students: res.data.student_list,
                        modelName: res.data.task_model.class_name,
                        taskParams,
                    })
                })
                this.setState({
                    taskParams: {
                        student_id: [],
                        course_id: '',
                        wrong_ques_count: 0,
                        task_file: [],
                        remark: ''
                    },
                    index: -1
                })
            } else {
                message.error(res.message)
                get_task({ course_id: this.state.taskCourse_id }).then(res => {
                    this.setState({
                        task_students: res.data.student_list,
                        modelName: res.data.task_model.class_name,
                        taskParams,
                    })
                })
                this.setState({
                    taskParams: {
                        student_id: [],
                        course_id: '',
                        wrong_ques_count: 0,
                        task_file: [],
                        remark: ''
                    },
                    index: -1
                })
            }
        })
    }
    jiangyiGo = () => {
        const _this = this
        confirm({
            title: '在线备课',
            icon: <ExclamationCircleOutlined />,
            content: '点击确认将为你跳转至在线备课界面',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                _this.props.history.push(`/jiangyi?online=${_this.state.upParmas.course_id}`)
            },
            onCancel() {
            },
        });
    }
    jiangyiZiyuan = () => {
        this.setState({
            visibleJ: true
        })
        // get_directory_file({type_id:'3'}).then(res=>{
        // })
    }
    cancelJ = () => {
        this.setState({
            visibleJ: false
        })
    }
    ziyuanUp = (upParmas) => {
        zidingyikejian(upParmas).then(res => {
            if (res.code === 0) {
                const parmas = { ...this.state.parmas }
                paike(parmas).then(res => {
                    const list = res.data.list.map((res, index) => {
                        res.key = `${index}`
                        return res
                    })
                    this.setState({
                        data: list,
                        totalCount: Number(res.data.total_count)
                    })
                })
                message.success(res.message)
                this.handleCancel()
            } else {
                message.success(res.message)
                this.handleCancel()
            }
        })
    }
    showNotice = () => {
        this.setState({
            isShownnotice: false
        })
    }
    isreadChange = (e) => {
        read_message({ id: e.id }).then(res => {
            this.get_check_failed()
        })
    }

    set_no_task = e => {
        this.setState({
            has_task: e.target.value
        })
    }

    change = (e, id) => {
        let params = this.state.parmas
        params[id] = e ? typeof (e) == 'undefined' ? '' : typeof (e) == 'object' ? e.target ? e.target.value : moment(e).format('YYYY-MM-DD') : e : ''
        this.setState({
            params: params
        })
    }

    sync_xiaoguanjia_course = () => {
        window.open('http://bigdata.yidianedu.com/api/xiaoguanjia/sync_xiaoguanjia_course')
    }

    render() {
        const columns = [
            {
                title: '老师姓名',
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => a.name.length - b.name.length,
                render: (text) => (
                    <span>
                        {text ? text : 'null'}
                    </span>
                ),
            },
            {
                title: '班级',
                dataIndex: 'classname',
                key: 'classname',
                sorter: (a, b) => a.classname.length - b.classname.length,
            },
            {
                title: '上课时间',
                dataIndex: 'starttime',
                key: 'starttime',
            },
            {
                title: '排课时间',
                dataIndex: 'course_time',
                key: 'course_time',
            },
            {
                title: '是否备课',
                key: 'is_beike',
                sorter: (a, b) => a.is_beike - b.is_beike,
                render: (text) => (
                    <span>
                        {text.is_beike === '1' ? <span className="linkTab2" onClick={() => this.showModal(text.course_id)}>已备课</span> : '未备课'}
                    </span>
                ),
            },
            {
                title: '备课审核状态',
                key: 'beike_check_status',
                render: (text) => (
                    <span>
                        {this.actionText(text.beike_check_status, text.is_beike)}
                    </span>
                ),
            },
            {
                title: '是否上课',
                dataIndex: 'isfinished',
                key: 'isfinished',
                sorter: (a, b) => a.isfinished - b.isfinished,
                render: (text) => (
                    <span>
                        {this.finished(text)}
                    </span>
                ),
            },
            {
                title: '班主任',
                dataIndex: 'headmaster',
                key: 'headmaster'
            },

            {
                title: '是否结业',
                dataIndex: 'is_complete',
                key: 'is_complete',
                render: (text) => (
                    <div>{text == -1 ? '否' : '是'}</div>
                )
            },
            {
                title: '操作',
                key: 'action',
                width: 200,
                render: (text, record) => (
                    <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {
                            record.is_send_task == 2 ?
                                <Button type="primary" style={{ backgroundColor: '#000', color: '#fff', width: '64px', display: 'flex', justifyContent: 'center' }} onClick={() => this.showModal2(text.course_id, '-1')}>无练习</Button>
                                :
                                this.actionFunt(
                                    text.beike_check_status,
                                    text.is_beike,
                                    text.course_id,
                                    text.isfinished,
                                    text.is_send_task,
                                    text.correcting_status,
                                    text.classname,
                                    text.starttime
                                )
                        }
                    </span>
                ),
            },
        ];
        const props = {
            action: baseUrl + '/api/upload/upload_file',
            onChange: this.handleChange,
            multiple: true,
            name: 'upload_control',
            headers: {
                token: localStorage.getItem("token"),
                username: encodeURI(localStorage.getItem("username")),
                companyid: localStorage.getItem("companyid"),
            },
            data: {
                type: 9
            }
        };
        const notice_cloums = [
            {
                title: '文件名',
                dataIndex: 'title',
                key: 'title'
            },
            {
                title: '时间',
                dataIndex: 'starttime',
                key: 'starttime'
            }, {
                title: '操作',
                key: 'action',
                render: (res) => (
                    <span className='otherTable_name' onClick={() => this.isreadChange(res)}>不再提醒</span>
                )
            }
        ];
        const report_cloums = [
            {
                title: '序号',
                dataIndex: 'index',
                key: 'index',
                render: (text, record, index) => (
                    index + 1
                )
            }, {
                title: '姓名',
                dataIndex: 'student_name',
                key: 'student_name'
            }, {
                title: '题目总数',
                dataIndex: 'ques_count',
                key: 'ques_count',
                render: () => (
                    this.state.task_model.ques_count
                )
            }, {
                title: '错题数',
                dataIndex: 'wrong_ques_count',
                key: 'wrong_ques_count',
                render: (text, record) => {
                    return record.state == 2 ? <div style={{ color: '#ff4d4f' }}>未完成</div> : text ? text : <div style={{ color: '#ff4d4f' }}>未录入</div>
                }
            }, {
                title: '单位时间',
                dataIndex: 'total_minute',
                key: 'total_minute',
                render: () => (
                    this.state.task_model.total_minute
                )
            }, {
                title: '错误率',
                dataIndex: 'index',
                defaultSortOrder: 'ascend',
                key: 'index',
                sorter: (a, b) => {
                    a = a.state == 2 ? 99 : a.wrong_ques_count ? a.wrong_ques_count / this.state.task_model.ques_count : 99
                    b = b.state == 2 ? 99 : b.wrong_ques_count ? b.wrong_ques_count / this.state.task_model.ques_count : 99
                    return a - b
                },
                render: (text, record, index) => {
                    if (!record.wrong_ques_count) {
                        return <div style={{ color: '#ff4d4f' }}>错题数未录入</div>
                    }
                    if (record.state == 2) {
                        return <div style={{ color: '#ff4d4f' }}>未完成</div>
                    }
                    return (record.wrong_ques_count / this.state.task_model.ques_count * 100).toFixed(2) + '%'
                }
            }, {
                title: '评价',
                dataIndex: 'index',
                key: 'index',
                render: (text, record, index) => {
                    if (!record.wrong_ques_count) {
                        return <div style={{ color: '#ff4d4f' }}>错题数未录入</div>
                    }
                    if (record.state == 2) {
                        return <div style={{ color: '#ff4d4f' }}>未完成</div>
                    }
                    let a = ((this.state.task_model.ques_count - record.wrong_ques_count) / this.state.task_model.ques_count * 100).toFixed(2)
                    if (a > 80) {//10%
                        return <div style={{ color: '#52c41a' }}>优秀</div>
                    } else if (a > 60) {//40%
                        return <div style={{ color: '#096dd9' }}>良好</div>
                    } else {//50%
                        return <div style={{ color: '#faad14' }}>有待努力</div>
                    }
                }
            }
        ]

        return (
            <div>
                <Modal
                    title="备课上传"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    okText='确认'
                    cancelText='取消'
                >
                    <div className="m-flex">
                        <span className="m-row">标题：</span>
                        <Input style={{ marginBottom: 20 }} placeholder="请输入标题" value={this.state.title} onChange={this.changeTitle}></Input>
                    </div>
                    <div className="m-flex">
                        <span className="m-row">包含内容：</span>
                        <CheckboxGroup style={{ marginBottom: 20 }}
                            options={plainOptions}
                            onChange={this.onChangecheckbox}
                            value={this.state.checkList}
                        />
                    </div>
                    <Button size="small" onClick={this.jiangyiZiyuan}>
                        我的资源文件夹中选择
                    </Button>
                    <Button size="small" onClick={this.jiangyiGo} className="m-left">
                        未有文件/在线备课
                    </Button>
                    <Upload {...props} fileList={this.state.fileList} >
                        <Button size="small" className="m-left">
                            已有文件/上传文件
                        </Button>
                    </Upload>
                    {
                        this.state.file ?
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
                                <Input value={this.state.file} />
                                <Button style={{ marginLeft: '20px' }} type='primary' onClick={e => this.catchFile(this.state.file)}>打开</Button>
                            </div>
                            :
                            ''
                    }

                </Modal>
                <Modal
                    width={800}
                    title={`课堂练习`}
                    visible={this.state.visible2}
                    onOk={this.handleOk2}
                    onCancel={this.handleCancel2}
                    okText='确认'
                    cancelText='取消'
                >
                    <div style={{ lineHeight: '37px' }}>班级：{this.state.modelName}</div>
                    <div className="flex">
                        <div className="mr" style={{ height: '32px', display: 'flex', alignItems: 'center' }}>
                            <span className="m-row">练习情况:</span>
                            <Radio.Group onChange={this.set_no_task} value={this.state.has_task}>
                                <Radio value='1'>有</Radio>
                                <Radio value='-1'>没有</Radio>
                            </Radio.Group>
                        </div>
                    </div>
                    {
                        this.state.has_task == -1 ?
                            ''
                            :
                            <>
                                <div className="flex">
                                    <div className="mr">
                                        <span className="m-row">题目数量：</span>
                                        <InputNumber style={{ width: 120 }} placeholder="题目数量" value={this.state.zuoyeParams.ques_count} onChange={(e) => this.changeTitleUrl2(e, 'ques_count')}></InputNumber>
                                    </div>
                                    <div className="mr">
                                        <span className="m-row">练习时间：</span>
                                        <InputNumber style={{ width: 120 }} placeholder="练习时间(min)" value={this.state.zuoyeParams.total_minute} onChange={(e) => this.changeTitleUrl2(e, 'total_minute')}></InputNumber >
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="mr" style={{ height: '32px', display: 'flex', alignItems: 'center' }}>
                                        <span className="m-row">练习上传:</span>
                                        <Radio.Group onChange={this.onchangeStateRadio} value={this.state.zuoyeParams.is_in_lecture}>
                                            <Radio value='-1'>上传</Radio>
                                            <Radio value='1'>已包含在讲义中(勿须再上传)</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                                {this.state.zuoyeParams.is_in_lecture === '-1' ?
                                    <div className="m-bottom">
                                        <Upload {...props} fileList={this.state.fileList}>
                                            <Button>
                                                上传文件
                                            </Button>
                                        </Upload>
                                    </div>
                                    : ''
                                }
                                {this.state.zuoyeParams.student_list.map((res, index) =>
                                    <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }} key={index}>
                                        <div className="flex" >
                                            <span className="mr">{res.student_name}</span>
                                            <div className='flex'>
                                                <span className="mr">练习监督:</span>
                                                <div className="mr">
                                                    <Select placeholder="选择练习类型" style={{ width: 150 }} value={res.place_type_id} onChange={(e) => this.changeType(e, 'place_type_id', index)}>
                                                        <Option value="1">留校做</Option>
                                                        <Option value="2">回家做</Option>
                                                    </Select>
                                                </div>
                                            </div>

                                            {res.place_type_id === '1' ?
                                                <div className="m-flex">
                                                    <span className="m-left">是否要助教:</span>
                                                    <div className="m-left">
                                                        <Select placeholder="选择助教" style={{ width: 150 }} value={res.is_need_assistant} onChange={(e) => this.changeType(e, 'is_need_assistant', index)}>
                                                            <Option value="1">是</Option>
                                                            <Option value="-1">否</Option>
                                                        </Select>
                                                    </div>
                                                </div>
                                                :
                                                ''}
                                            {res.is_need_assistant === '1' ? <div className="m-left">
                                                <Select placeholder="助教列表" style={{ width: 150 }} value={res.assistant_teacher_id} onChange={(e) => this.changeType(e, 'assistant_teacher_id', index)}>
                                                    {this.state.user_byCanSet}
                                                </Select>
                                            </div> : ''}
                                        </div>
                                    </div>
                                )}
                            </>
                    }
                </Modal>
                <Modal
                    title='图片上传'
                    visible={this.state.visible4}
                    onOk={this.finalSure}
                    onCancel={this.handleCancel4}
                    okText='确认'
                    cancelText='取消'
                >
                    <Dragger {...props} fileList={this.state.fileList}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">点击或者拖拽上传</p>
                        <p className="ant-upload-hint">
                            上传的图片可多个上传
                        </p>
                    </Dragger>
                </Modal>
                <Modal
                    width={800}
                    title={`练习批改(${this.state.modelName})`}
                    visible={this.state.visible3}
                    onOk={this.handleOk3}
                    onCancel={this.handleCancel3}
                    okText='确认'
                    cancelText='取消'
                    footer={null}
                >
                    <div style={{ width: 270, display: "flex", justifyContent: 'space-between' }}>
                        <p>题目数量：{this.state.task_model.ques_count}</p>
                        <p>练习时长：{this.state.task_model.total_minute}分钟</p>
                    </div>
                    <Table rowKey={record => record.key} columns={this.state.columns2} dataSource={this.state.task_students} pagination={false} />
                </Modal>
                <Modal
                    width={800}
                    title={`${this.state.classname}(${this.state.starttime})`}
                    visible={this.state.report_visible}
                    onCancel={() => this.setState({ report_visible: false })}
                    okText='确认'
                    cancelText='取消'
                    footer={null}
                >
                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                        <p>题目数量：{this.state.task_model.ques_count}</p>
                        <p>练习时长：{this.state.task_model.total_minute}分钟</p>
                    </div>
                    <Table id='mytable' rowKey={record => record.key} columns={report_cloums} dataSource={this.state.student_list} pagination={false} />
                </Modal>
                <Modal
                    title='已上传图片列表'
                    visible={this.state.visible5}
                    onCancel={this.handleCancel5}
                    footer={null}
                >
                    {this.state.imgViewList.map((res, index) =>
                        <div style={{ width: '100%', height: 200 }} key={index}>
                            <Zmage src={res} style={{ width: '100%' }} />
                        </div>
                    )}
                </Modal>
                <Modal
                    width={900}
                    title='讲义列表'
                    visible={this.state.visibleJ}
                    onCancel={this.cancelJ}
                    footer={null}>
                    <JiangyiLink upParmas={this.state.upParmas} ziyuanUp={this.ziyuanUp}></JiangyiLink>
                </Modal>
                <Modal width={800}
                    title={'讲义审核未通过提醒'}
                    visible={this.state.isShownnotice}
                    onOk={this.showNotice}
                    onCancel={this.showNotice}
                    okText='确认'
                    cancelText='取消'
                >
                    <Table rowKey={record => record.key} columns={notice_cloums} dataSource={this.state.notice_list} pagination={false} />
                </Modal>
                <div className="m-bottom flex" >
                    <div className="mr">
                        <span>开始时间：</span>
                        <DatePicker locale={locale} onChange={this.onchange} defaultValue={moment(this.state.time, dateFormat)} />
                    </div>
                    <div className="mr">
                        <span>结束时间：</span>
                        <DatePicker locale={locale} onChange={this.onchangeEnd} defaultValue={moment(this.state.endtime, dateFormat)} />
                    </div>
                    <div className="mr">
                        <span>是否上课：</span>
                        <Select style={{ width: 180 }} onChange={this.changefinish} placeholder="请选择" value={this.state.parmas.isfinished}>
                            <Option value="">请选择条件</Option>
                            <Option value="1">已上课</Option>
                            <Option value="-1">未上课</Option>
                            <Option value="2">已取消</Option>
                        </Select>
                    </div>
                    <div className="mr">
                        <span>是否备课：</span>
                        <Select style={{ width: 180 }} onChange={this.changebeike} placeholder="请选择" value={this.state.parmas.is_beike}>
                            <Option value="">请选择条件</Option>
                            <Option value="1">已备课</Option>
                            <Option value="-1">未备课</Option>
                        </Select>
                    </div>
                    <div className="mr">
                        <span>是否布置练习：</span>
                        <Select style={{ width: 180 }} onChange={e => this.change(e, 'is_send_task')} placeholder="请选择" value={this.state.parmas.is_send_task || undefined} allowClear>
                            <Option value="-1">未布置</Option>
                            <Option value="1">已经布置</Option>
                            <Option value="2">无练习</Option>
                        </Select>
                    </div>
                    <div className="mr">
                        <span>批改状态：</span>
                        <Select style={{ width: 180 }} onChange={e => this.change(e, 'correcting_status')} placeholder="请选择" value={this.state.parmas.correcting_status || undefined} allowClear>
                            <Option value="-1">未批改</Option>
                            <Option value="1">批改部分</Option>
                            <Option value="2">全部批改完成</Option>
                        </Select>
                    </div>
                    <div className="mr">
                        <span>班主任：</span>
                        <Select style={{ width: 180 }} onChange={e => this.change(e, 'headmaster_id')} placeholder="请选择" value={this.state.parmas.headmaster_id || undefined} allowClear>
                            <option value={''}>无</option>
                            {
                                this.state.master_list.map((item, index) => {
                                    return <option key={index} value={item.employee_id}>{item.name}</option>
                                })
                            }
                        </Select>
                    </div>
                    <div className="mr">
                        <span>是否结业：</span>
                        <Select style={{ width: 180 }} onChange={e => this.change(e, 'is_complete')} placeholder="请选择" value={this.state.parmas.is_complete || undefined} allowClear>
                            <Option value="1">是</Option>
                            <Option value="-1">否</Option>
                        </Select>
                    </div>
                    <div className="mr">
                        <span>学生姓名：</span>
                        <Input style={{ width: 150 }} placeholder="请输入学生姓名" onChange={(e) => this.nameSearch(e, 'student_name')}></Input>
                    </div>
                    {localStorage.getItem('teacher_type') === '4' ?
                        <>
                            <Button type="primary" style={{ marginLeft: 10 }} onClick={this.search}>查询</Button>
                            <Button type="primary" style={{ marginLeft: 10 }} onClick={this.sync_xiaoguanjia_course}>同步</Button>
                        </>
                        :
                        <div className="m-flex">
                            <div className="mr">
                                <span >老师姓名：</span>
                                <Input style={{ width: 150 }} placeholder="请输入老师姓名" onChange={(e) => this.nameSearch(e, 'teacher_name')}></Input>
                            </div>
                            <div className="mr">
                                <span>在职类型：</span>
                                <Select style={{ width: 150 }} onChange={this.changePos} placeholder="请选择" value={this.state.parmas.postion_type_id}>
                                    <Option value="">请选择老师类型</Option>
                                    <Option value="-1">全职</Option>
                                    <Option value="1">兼职</Option>
                                    <Option value="2">专职</Option>
                                </Select>
                            </div>
                            <Button type="primary" style={{ marginLeft: 10 }} onClick={this.search}>查询</Button>
                            <Button type="primary" style={{ marginLeft: 10 }} onClick={this.sync_xiaoguanjia_course}>同步</Button>
                        </div>
                    }
                </div>
                <Table rowKey={record => record.key} columns={columns} dataSource={this.state.data} pagination={false} />
                <div className="m-Pleft m-flex">
                    <Pagination
                        current={this.state.parmas.page}
                        onChange={this.changePage}
                        total={this.state.count}
                        showTotal={total => `共 ${this.state.totalCount} 条`}
                    />
                    <div className="m-left">
                        <Select defaultValue="10" style={{ width: 100 }} onChange={this.setPageSize}>
                            <Option value="10">10/页</Option>
                            <Option value="20">20/页</Option>
                        </Select>
                    </div>
                    <div className="m-left">
                        <span >跳转至 </span>
                        <Input style={{ width: 60 }} onChange={this.numberonChange} ></Input>
                        <span> 页 </span>
                    </div>
                    <div className="m-left">
                        <Button onClick={this.search}>跳转</Button>
                    </div>
                </div>
            </div >
        );
    }
}
export default bk;