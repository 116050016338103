import React, { Component } from 'react';
import Submitdraw from './component/submitdraw'
import Submitdrawlist from './component/submitdrawlist'
import Submitgrantlist from './component/submitgrantlist'
import Submitdrawdetail from './component/submitdrawdetail'
import Sharelist from './component/sharelist'

class RegistrationActivities extends Component {
    constructor(props) {
        super(props)
        this.state = {
            path: 'Submitdraw',
            field_json_str: [],
            id: ''
        }
    }
    toDetail = (id) => {
        this.setState({
            path: 'Submitdrawdetail',
            id: id
        })
    }
    toList = (id) => {
        this.setState({
            path: 'Submitdrawlist',
            id: id
        })
    }
    toGrant = (id) => {
        this.setState({
            path: 'Submitgrantlist',
            id: id
        })
    }
    field_json_str = e => {
        this.setState({
            field_json_str: e
        })
    }
    back = e => {
        this.setState({
            path: 'Submitdraw'
        })
    }
    render() {
        return (
            <div>
                {(() => {
                    switch (this.state.path) {
                        case "Submitdrawlist": return <Submitdrawlist active_id={this.state.id} field_json_str={this.state.field_json_str} back={this.back} />; break;
                        case "Submitgrantlist": return <Submitgrantlist active_id={this.state.id} back={this.back} toShare={this.toShare} />; break;
                        case "Submitdrawdetail": return <Submitdrawdetail active_id={this.state.id} field_json_str={this.state.field_json_str} back={this.back} />; break;
                        default: return <Submitdraw toList={this.toList} toDetail={this.toDetail} toGrant={this.toGrant} field_json_str={this.field_json_str} />; break;
                    }
                }
                )()}
            </div>
        )
    }
}

export default RegistrationActivities;