import React, { Component } from 'react';

class index2 extends Component {
    render() {
        return (
            <div className="data-img2" style={{ width: '100%',minHeight:1024 }}>
                123
            </div>
        );
    }
}

export default index2;