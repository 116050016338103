import React, { Component } from 'react';
import produce from "immer"
import { Col, Row, Drawer, Button, Select, Input, Checkbox, Table, Modal, DatePicker, Radio, message, Pagination, Tag, InputNumber } from 'antd';
import { del_student_exam_result, exam_result_entry, getStudent_by_teacher, get_exam_type, exam_get_list, get_xiaoguanjia_grade, get_xiaoguanjia_subject, create_exam, del_exam, edit_exam, get_exam_result, get_exam_result_new } from '../../axios/http'
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN'
import 'moment/locale/zh-cn';
moment.locale('zh-cn')
const dateFormat = 'YYYY-MM-DD'
const { TextArea } = Input
const { Option } = Select
const { confirm } = Modal;
const today = moment(new Date()).format(dateFormat)

class ResultList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            exam_data: this.props.exam_data,
            subject_list: this.props.subject_list,
            subject_name: [],
            columns_obj: [],
            student_list: [],
            score_list: [],
            full_score_list: [],
            studentValue: [],
            score_base: [],
            del_list: []
        }
    }

    componentDidMount = () => {
        let xiaoguanjia_subject_ids = this.state.exam_data.xiaoguanjia_subject_ids.split(','), subject_name = [], subject_id = [], columns_obj = []
        xiaoguanjia_subject_ids.map((item, index) => {
            this.state.subject_list.some(item2 => {
                if (item == item2.xiaoguanjia_id) {
                    subject_name.push(item2.value.split('-')[1])
                    subject_id.push(item2.xiaoguanjia_id)
                    columns_obj.push({
                        title: item2.value.split('-')[1],
                        dataIndex: item2.xiaoguanjia_id,
                        key: item2.xiaoguanjia_id,
                        align: 'center'
                    })
                    return true
                }
            })
        })
        const score_base = subject_name.map((res, index) => {
            return {
                is_first_exam: '-1',
                score: '',
                subject_id: subject_id[index]
            }
        })
        this.setState({
            columns_obj,
            subject_name,
            score_base
        })
        this.getStudent_by_teacher()
        this.get_exam_result()
    }

    //获取学生列表
    getStudent_by_teacher = e => {
        let data = {
            teacher_employee_id: '',
            grade_id: this.state.exam_data.xiaoguanjia_grade_id,
            xiaoguanjia_subject_id: ''
        }
        getStudent_by_teacher(data).then(res => {
            this.setState({
                student_list: res.data.list
            })
        })
    }

    //获取成绩列表
    get_exam_result = e => {
        let data = {
            student_exam_id: this.state.exam_data.id
        }
        get_exam_result_new(data).then(res => {
            if (res.data.list) {
                let studentValue = [], score_table_list = []
                res.data.list.map(item => {
                    let s = {}
                    item.exam_result.map(item2 => {
                        s[item2.subject_id] = item2.score
                    })
                    s['is_first_exam'] = item.is_first_exam
                    s['student_id'] = item.student_id
                    s['student_name'] = item.student_name
                    s['total_full_score'] = item.total_full_score
                    s['total_score'] = item.total_score
                    studentValue.push(item.student_id)
                    score_table_list.push(s)
                })
                let arr = JSON.stringify(res.data.list),list = JSON.parse(arr), newList = JSON.parse(arr)
                for (var i = 0; i < list.length; i++) {
                    for (var j = 0; j < list[i].exam_result.length; j++) {
                        for (var k = 0; k < res.data.full_score_list.length; k++) {
                            if (res.data.full_score_list[k].xiaoguanjia_subject_id == list[i].exam_result[j].subject_id) {
                                newList[i].exam_result[k] = list[i].exam_result[j]
                                break
                            }
                        }
                    }
                }
                this.setState({
                    studentValue,
                    score_list: newList,
                    score_table_list: score_table_list,
                    full_score_list: res.data.full_score_list
                })
            }
        })
    }

    //返回
    back = e => {
        this.props.changeRoute('examList')
    }

    //Drawer显示状态
    visChange = e => {
        if (this.state.visible) {
            this.setState({
                del_list: []
            })
            this.get_exam_result()
        }
        this.setState({
            visible: !this.state.visible
        })
    }

    //选择或删除学生
    choose_student = (e, value) => {
        let list = this.state.score_list
        this.setState({
            studentValue: e
        })
        if (e.length < list.length) {
            let i, del_list = this.state.del_list
            if (e.length == 0) {
                i = 0
            } else {
                list.some((item, index) => {
                    let f = false
                    e.some(item2 => {
                        if (item.student_id == item2) {
                            f = true
                            return true
                        }
                    })
                    if (!f) {
                        i = index
                        return true
                    }
                })
            }
            del_list.push(list[i].student_id)
            list.splice(i, 1)
            this.setState({
                score_list: list,
                del_list: del_list
            })
        } else {
            let i = e.length - 1,
                id = e[i],
                name = value[i].props.children,
                exam_result_B = JSON.stringify(this.state.score_base),
                exam_result = JSON.parse(exam_result_B)
            let obj = {
                is_first_exam: '',
                student_id: id,
                student_name: name,
                exam_result: exam_result
            }
            list.push(obj)
            this.setState({
                score_list: list
            })
        }
    }

    //提交
    submit = e => {
        let score_list = this.state.score_list,
            f = JSON.stringify(score_list).indexOf('""')
        if (f == -1) {
            let data = {
                exam_id: this.state.exam_data.id,
                student_list: score_list,
                del_student_ids: this.state.del_list
            }
            exam_result_entry({ json_str: JSON.stringify(data) }).then(res => {
                if (res.code === 0) {
                    message.success("修改成功！")
                    this.visChange()
                } else {
                    message.warn(res.message)
                }
            })
        } else {
            message.warn("有选项未填写，请检查！")
        }
    }

    //修改成绩状态
    changeIsExam = (e, index) => {
        let list = this.state.score_list
        list[index].is_first_exam = e
        this.setState({
            score_list: list
        })
    }

    //修改成绩
    changeScore = (e, index, index2) => {
        let list = this.state.score_list
        list[index].exam_result[index2].score = e
        this.setState({
            score_list: list
        })
    }

    render() {
        const columns = [
            {
                title: '姓名',
                dataIndex: `student_name`,
                key: 'student_name',
                align: 'center'
            },
            ...this.state.columns_obj,
            {
                title: '总分',
                dataIndex: `total_score`,
                key: `total_score`,
                align: 'center'
            }
        ]
        const student_list = this.state.student_list.concat(this.state.score_list)
        var temp = {};   //用于name判断重复
        var result = [];  //最后的新数组
        student_list.map(function (item, index) {
            if (!temp[item.student_id]) {
                result.push(<Option key={item.student_id} value={item.student_id}>{item.name || item.student_name}</Option>);
                temp[item.student_id] = true;
            }
        });
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: 20 }}>
                    <Button onClick={this.back}>返回</Button>
                    <Button type="primary" onClick={this.visChange}>{this.state.score_list.length > 0 ? '编辑成绩' : '成绩录入'}</Button>
                </div>
                <Table columns={columns} dataSource={this.state.score_table_list} pagination={false} />
                <Drawer
                    title="成绩录入"
                    placement="right"
                    width={1024}
                    closable={false}
                    onClose={this.visChange}
                    visible={this.state.visible}
                    bodyStyle={{ paddingBottom: 80 }}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <div className="m-flex m-bottom" style={{ flexFlow: 'column' }}>
                                <span style={{ fontSize: 17, color: 'rgba(0,0,0,.85)' }}>学生姓名</span>
                                <Select placeholder="请输入学生姓名" value={this.state.studentValue} onChange={this.choose_student} mode="multiple" optionFilterProp="children" showSearch>
                                    {result}
                                </Select>
                            </div>
                        </Col>
                    </Row>
                    <div style={{ width: '100%', boxSizing: 'border-box', paddingLeft: 265 }}>
                        {this.state.subject_name.map((res, index) =>
                            <span span={3} style={{ fontSize: 13, display: 'inline-block', width: 83 }} key={index}>
                                {res}{this.state.full_score_list.length > 0 ? `(${this.state.full_score_list[index].full_score})` : ''}
                            </span>
                        )}
                    </div>
                    {
                        this.state.score_list.map((item, index) =>
                            <Row gutter={16} key={index}>
                                <Col span={6}>
                                    <span className="m-flex" style={{ alignItems: 'center' }}>
                                        {item.student_name}
                                        {item.student_name ?
                                            <div className="m-left" >
                                                <Select style={{ width: 120 }} placeholder="请选择录入类型" value={item.is_first_exam ? item.is_first_exam : undefined} onChange={(e) => this.changeIsExam(e, index)}>
                                                    <Option value='-1'>入学前录入</Option>
                                                    <Option value='1'>入学后录入</Option>
                                                </Select>
                                            </div>
                                            : ''}
                                    </span>
                                </Col>
                                {item.exam_result.map((res, index2) =>
                                    <Col span={2} key={index2}>
                                        <InputNumber
                                            style={{ appearance: 'none', width: '100%' }}
                                            step={0.1}
                                            min={0}
                                            max={this.state.full_score_list.length > 0 && this.state.full_score_list[index2] ? Number(this.state.full_score_list[index2].full_score) : 200}
                                            value={res.score}
                                            onChange={(e) => this.changeScore(e, index, index2)}
                                        />
                                    </Col>
                                )}
                            </Row>
                        )
                    }
                    <div style={{ textAlign: 'right', marginTop: 30 }}>
                        <Button style={{ marginRight: 8 }} onClick={this.visChange}>取消</Button>
                        <Button type="primary" onClick={this.submit}>确认</Button>
                    </div>
                </Drawer>
            </div>
        )
    }
}

export default ResultList;