import React, { Component } from 'react';
import { Row, Col, Table, Tabs, Result, Button, Tooltip, Modal, DatePicker, Input, Spin, message, Select, Pagination, Tag } from 'antd'
import { get_zuoyeba_view, get_answer_list, submit_answer, get_teacher_subject_list, studentwrong_ques_list, edit_answer, get_tag_list } from '../../axios/http'
import { QuestionCircleOutlined } from '@ant-design/icons'
import moment from 'moment';
import '../../css/othertable.css'
import '../../store/config'
const { Option } = Select;
const { TabPane } = Tabs
const { CheckableTag } = Tag;
class OtherTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            roomList: [],
            all_zuoyeba_count: "",
            un_set_count: "",
            student_id: '',
            jiadaMKey: false,
            dayiParams: {
                student_id: '',
                teacher_employee_id: '',
                page: 1,
                page_size: 5
            },
            isedit: false,
            date: moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD'),
            is_finished: '1',
            no_need_reason: '',
            remark: '',
            ques_count: '',
            minutes: '',
            wrong_ques_count: '',
            ask_ques_count: '',
            warn_count: '',
            jiedaList: [],
            isAddShow: false,
            canChange: false,
            subject_list: [],
            sub_id: '',
            tab_index: '3',
            wrongParams: {
                xiaoguanjia_student_id: '',
                page_size: 5,
                page: 1
            },
            wrongTotal: '',
            msgkey: "a",
            msgvisible: false,
            text: '',
            ans_total: 0,
            loading: false,
            tabs_loading: false,
            ans_page: 1,
            tagsData: [],
            selectedTags: [],
            xiaoguanjia_grade_id: ''
        }
    }
    componentDidMount() {
        this.tabChange('3')
        get_tag_list().then(res => {
            this.setState({
                tagsData: res.data.list
            })
        })
    }
    watchChangeSize = () => {
        var offsetHei = document.documentElement.clientHeight - 200;
        this.setState({
            height: offsetHei
        })
    }
    tabChange = e => {
        this.setState({
            tabs_loading: true
        })
        if (e === '1') {
            get_zuoyeba_view({ type_id: '1' }).then(res => {
                this.setState({
                    canChange: false,
                    roomList: res.data.list == null ? [] : res.data.list,
                    all_zuoyeba_count: res.data.all_zuoyeba_count,
                    un_set_count: res.data.un_set_count,
                    un_set_student_str: res.data.un_set_student_str,
                    single_zuoyeba_percent: res.data.single_zuoyeba_percent,
                    tab_index: e,
                    tabs_loading: false
                })
            })
        } else if (e === '2') {
            get_zuoyeba_view({ type_id: '2' }).then(res => {
                this.setState({
                    canChange: false,
                    roomList: res.data.list == null ? [] : res.data.list,
                    all_zuoyeba_count: res.data.all_zuoyeba_count,
                    un_set_count: res.data.un_set_count,
                    un_set_student_str: res.data.un_set_student_str,
                    single_zuoyeba_percent: res.data.single_zuoyeba_percent,
                    tab_index: e,
                    tabs_loading: false
                })
            })
        } else {
            get_zuoyeba_view({ type_id: '3' }).then(res => {
                if (res.data.list) {
                    res.data.list.forEach(ele => {
                        ele.sub_list.forEach(l2 => {
                            l2.teacher_name = ele.name
                            l2.teacher_id = ele.teacher_id
                        })
                    })
                    this.setState({
                        canChange: true,
                        roomList: res.data.list == null ? [] : res.data.list,
                        all_zuoyeba_count: res.data.all_zuoyeba_count,
                        un_set_count: res.data.un_set_count,
                        un_set_student_str: res.data.un_set_student_str,
                        single_zuoyeba_percent: res.data.single_zuoyeba_percent,
                        tab_index: e,
                        tabs_loading: false
                    })
                } else {
                    this.setState({
                        tabs_loading: false
                    })
                    return
                }

            })
        }
    }
    set = () => {
        this.props.history.push('/main/quanxian/CreatNewClass')
    }
    wrongRight = (e, id, xiaoguanjia_grade_id) => {
        this.setState({
            tabs_loading: true,
            student_id: e,
            teacher_id: id,
            xiaoguanjia_grade_id: xiaoguanjia_grade_id
        })
        if (this.state.canChange && id) {
            get_teacher_subject_list({ teacher_id: id }).then(res => {
                this.setState({
                    subject_list: res.data.list,
                    sub_id: res.data.list[0].xiaoguanjia_subject_id
                })
            })
        }
        const dayiParams = this.state.dayiParams
        dayiParams.student_id = e
        get_answer_list(dayiParams).then(res => {
            this.setState({
                student_id: e,
                jiedaList: res.data.list,
                jiedaMkey: true,
                date: moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD'),
                is_finished: '1',
                ans_total: Number(res.data.total_count),
                tabs_loading: false
            })
        })
        const wrongParams = this.state.wrongParams
        wrongParams.xiaoguanjia_student_id = e
        studentwrong_ques_list({ ...wrongParams }).then(res => {
            this.setState({
                wrong_list: res.data.list,
                wrongTotal: Number(res.data.count)
            })
        })
    }
    jiedaLuru = () => {
        this.setState({
            jiedaMkey: true
        })
    }
    jiedaOk = () => {
        this.setState({
            jiedaMkey: false,
            msgkey: 'a'
        })
    }
    jiedaCancel = () => {
        this.setState({
            jiedaMkey: false,
            msgkey: 'a'
        })
    }
    dateChange = (date) => {
        this.setState({
            date: moment(date).format('YYYY-MM-DD')
        })
    }
    finishedChange = (e) => {
        this.setState({
            is_finished: e
        })
    }
    countChange = ({ target: { value } }) => {
        this.setState({
            ques_count: value
        })
    }
    minutesChange = ({ target: { value } }) => {
        this.setState({
            minutes: value
        })
    }
    wrongChange = ({ target: { value } }) => {
        this.setState({
            wrong_ques_count: value
        })
    }
    askChange = ({ target: { value } }) => {
        this.setState({
            ask_ques_count: value
        })
    }
    showAdd = () => {
        this.setState({
            isAddShow: true
        })
    }
    remarkChange = ({ target: { value } }) => {
        this.setState({
            remark: value
        })
    }
    addOk = () => {
        if (this.state.isedit) {
            let data = {
                answer_id: this.state.answer_id,
                xiaoguanjia_grade_id: this.state.xiaoguanjia_grade_id,
                date: this.state.date,
                is_finished: this.state.is_finished,
                ques_count: this.state.ques_count,
                minutes: this.state.minutes,
                wrong_ques_count: this.state.wrong_ques_count,
                ask_ques_count: this.state.ask_ques_count,
                xiaoguanjia_subject_id: this.state.sub_id,
                no_need_reason: this.state.no_need_reason,
                remark: this.state.remark,
                warn_count: this.state.warn_count,
                tags: this.state.selectedTags
            }
            edit_answer(data).then(res => {
                if (res.code === 0) {
                    this.wrongRight(this.state.student_id, this.state.teacher_id, this.state.xiaoguanjia_grade_id)
                    this.tabChange(this.state.tab_index)
                    message.success('修改成功！')
                } else {
                    message.warn(res.message)
                }
            })
            this.addCancel()
            this.setState({
                isedit: false
            })
        } else {
            if (this.state.is_finished === '2' && this.state.no_need_reason === '') {
                message.warn('请选择无需提交原因！')
                return
            }
            let data = {
                student_id: this.state.student_id,
                xiaoguanjia_grade_id: this.state.xiaoguanjia_grade_id,
                date: this.state.date,
                is_finished: this.state.is_finished,
                ques_count: this.state.ques_count,
                minutes: this.state.minutes,
                wrong_ques_count: this.state.wrong_ques_count,
                ask_ques_count: this.state.ask_ques_count,
                xiaoguanjia_subject_id: this.state.sub_id,
                no_need_reason: this.state.no_need_reason,
                remark: this.state.remark,
                warn_count: this.state.warn_count,
                tags: this.state.selectedTags
            }
            submit_answer(data).then(res => {
                if (res.code === 101) {
                    message.error('有必填项为空，提交失败！');
                } if (res.code === -1) {
                    message.warn(res.message);
                    this.addCancel()
                } else {
                    this.wrongRight(this.state.student_id, this.state.teacher_id, this.state.xiaoguanjia_grade_id)
                    this.tabChange(this.state.tab_index)
                    this.addCancel()
                }
            })
        }
    }
    addCancel = () => {
        this.setState({
            isAddShow: false,
            loading: true
        })
        setTimeout(() => {
            this.setState({
                is_finished: '1',
                no_need_reason: '',
                ques_count: '',
                minutes: '',
                remark: '',
                wrong_ques_count: '',
                ask_ques_count: '',
                isedit: false,
                warn_count: '',
                selectedTags: [],
                loading: false
            })
        }, 800);
    }
    showEdit_answer = (value) => {
        if (this.state.tab_index === '3') {
            this.setState({
                answer_id: value.id,
                date: value.date,
                is_finished: value.is_finished,
                ques_count: value.ques_count,
                minutes: value.minutes,
                wrong_ques_count: value.wrong_ques_count,
                ask_ques_count: value.ask_ques_count,
                xiaoguanjia_subject_id: value.sub_id,
                no_need_reason: value.no_need_reason,
                remark: value.remark,
                selectedTags: value.tags ? value.tags.split(',') : [],
                isAddShow: true,
                warn_count: value.warn_count,
                isedit: true
            })
        }
    }
    subChange = (e) => {
        this.setState({
            sub_id: e
        })
    }
    changePage1 = e => {
        const dayiParams = this.state.dayiParams
        dayiParams.page = e
        get_answer_list(dayiParams).then(res => {
            this.setState({
                jiedaList: res.data.list,
                jiedaMkey: true,
                ans_total: res.data.total_count,
                ans_page: e
            })
        })
    }
    changePage2 = e => {
        const wrongParams = this.state.wrongParams
        wrongParams.page = e
        studentwrong_ques_list({ ...wrongParams }).then(res => {
            this.setState({
                wrong_list: res.data.list,
                wrongTotal: Number(res.data.count)
            })
        })
    }
    msgtabChange = e => {
        this.setState({
            msgkey: e
        })
    }
    checkText = (text) => {
        this.setState({
            text,
            msgvisible: true
        })
    }
    checkCancel = () => {
        this.setState({
            msgvisible: false
        })
    }
    no_need_reasonChange = e => {
        this.setState({
            no_need_reason: e
        })
    }
    warn_countChange = ({ target: { value } }) => {
        this.setState({
            warn_count: value
        })
    }
    tagsChange = (tag, checked) => {
        const { selectedTags } = this.state;
        const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
        this.setState({ selectedTags: nextSelectedTags });
    }
    render() {
        const columns = [
            {
                title: '教室',
                key: 'classroom_name',
                dataIndex: 'classroom_name',
                align: "center",
                width: 150,
                render: (value, row, index) => {
                    const obj = {
                        children: value,
                        props: {},
                    };
                    if (index == 0) {
                        obj.props.rowSpan = 50;
                    } else {
                        obj.props.rowSpan = 0
                    }
                    return obj;
                },
            },
            {
                title: '序号',
                align: "center",
                width: 70,
                render: (value, row, index) => {
                    const obj = {
                        children: Number(index + 1),
                        props: {},
                    };
                    return obj;
                },
            },
            {
                title: '姓名',
                render: (text) => (
                    <span className='otherTable_name' onClick={() => this.wrongRight(text.student_id, text.teacher_id, text.xiaoguanjia_grade_id)}>{text.name}</span>
                ),
                width: 100,
                key: 'name',
                align: "center",
            },
            {
                title: '答疑解惑',
                render: (text) => (
                    <Tooltip placement="top" title={text.day_subject_str}>
                        <span>{text.day_count}</span>
                    </Tooltip>
                ),
                width: 100,
                key: 'count',
                align: "center",
            },
            {
                title: '薄弱学科',
                render: (text) => (
                    <span>{text.current_class_str}</span>
                ),
                width: 100,
                key: 'current_class_str',
                align: "center",
            },
            {
                title: '年级',
                width: 100,
                dataIndex: 'fulltime_grade',
                key: 'fulltime_grade',
                align: "center"
            },
            {
                title: '班主任',
                width: 100,
                dataIndex: 'headmaster',
                key: 'headmaster',
                align: "center"
            },
            {
                title: '学管',
                width: 100,
                dataIndex: 'master',
                key: 'master',
                align: "center"
            },
            {
                title: '老师',
                align: "center",
                key: 'sujbect_str',
                dataIndex: 'sujbect_str'
            },
        ];
        const columns2 = [
            {
                title: '班主任',
                dataIndex: 'headmaster',
                key: 'headmaster',
                align: "center",
                width: 150,
                render: (value, row, index) => {
                    const obj = {
                        children: value,
                        props: {},
                    };
                    if (index == 0) {
                        obj.props.rowSpan = 50;
                    } else {
                        obj.props.rowSpan = 0
                    }
                    return obj;
                },
            },
            {
                title: '序号',
                align: "center",
                width: 75,
                render: (value, row, index) => {
                    const obj = {
                        children: Number(index + 1),
                        props: {},
                    };
                    return obj;
                },
            },
            {
                title: '姓名',

                key: 'name',
                width: 100,
                align: "center",
                render: (text) => (
                    <span className='otherTable_name' onClick={() =>this.wrongRight(text.student_id, text.teacher_id, text.xiaoguanjia_grade_id)}>{text.name}</span>
                ),
            },
            {
                title: '答疑解惑',
                render: (text) => (
                    <Tooltip placement="top" title={text.day_subject_str}>
                        <span>{text.day_count}</span>
                    </Tooltip>),
                width: 75,
                key: 'count',
                align: "center",
            },
            {
                title: '薄弱学科',
                render: (text) => (
                    <span>{text.current_class_str}</span>
                ),
                key: 'current_class_str',
                align: "center",
                width: 100,
            },
            {
                title: '年级',
                dataIndex: 'fulltime_grade',
                key: 'fulltime_grade',
                width: 100,
                align: "center"
            },
            {
                title: '房间',
                key: 'classroom_name',
                width: 100,
                dataIndex: 'classroom_name',
                align: "center",
            }
            ,
            {
                title: '学管',
                width: 100,
                dataIndex: 'master',
                key: 'master',
                align: "center"
            },
            {
                title: '老师',
                align: "center",
                key: 'sujbect_str',
                dataIndex: 'sujbect_str'
            },

        ];
        const columns3 = [
            {
                title: '老师',
                align: "center",
                key: 'teacher_name',
                dataIndex: 'teacher_name',
                width: 150,
                render: (value, row, index) => {
                    const obj = {
                        children: value,
                        props: {},
                    };
                    if (index == 0) {
                        obj.props.rowSpan = 50;
                    } else {
                        obj.props.rowSpan = 0
                    }
                    return obj;
                },
            },
            {
                title: '序号',
                align: "center",
                width: 75,
                render: (value, row, index) => {
                    const obj = {
                        children: Number(index + 1),
                        props: {},
                    };
                    return obj;
                },
            },
            {
                title: '姓名',

                width: 100,
                key: 'name',
                align: "center",
                render: (text) => (
                    <span className='otherTable_name' onClick={() => this.wrongRight(text.student_id, text.teacher_id, text.xiaoguanjia_grade_id)}>{text.name}</span>
                ),
            },
            {
                title: '答疑解惑',
                render: (text) => (
                    <Tooltip placement="top" title={text.day_subject_str}>
                        <span>{text.day_count}</span>
                    </Tooltip>),
                width: 75,
                key: 'count',
                align: "center",
            },
            {
                title: '薄弱学科',
                render: (text) => (
                    <span>{text.current_class_str}</span>
                ),
                width: 100,
                key: 'current_class_str',
                align: "center",
            },
            {
                title: '年级',
                dataIndex: 'fulltime_grade',
                key: 'fulltime_grade',
                width: 100,
                align: "center"
            },
            {
                title: '班主任',
                width: 100,
                dataIndex: 'headmaster',
                key: 'headmaster',
                align: "center"
            },
            {
                title: '学管',
                width: 100,
                dataIndex: 'master',
                key: 'master',
                align: "center"
            },
            {

                title: '房间',
                key: 'classroom_name',
                width: 100,
                dataIndex: 'classroom_name',
                align: "center",
            },
        ];
        const columns_jieda = [
            {
                title: '日期',
                dataIndex: 'date',
                key: 'date'
            },
            {
                title: '科目',
                dataIndex: 'xiaoguanjia_subject_name',
                key: 'xiaoguanjia_subject_name'
            },
            {
                title: '是否完成',
                dataIndex: 'is_finished',
                key: 'is_finished',
                render: (is_finished, value) => (
                    <Tooltip placement="top" title={value.remark}>
                        <span onClick={e => this.showEdit_answer(value)} style={{ cursor: 'pointer', color: '#1890ff' }}>
                            {is_finished == '-1' ? '未完成' : is_finished == '1' ? '已完成' : is_finished == '2' ? '无需提交' : '无作业'}
                        </span>
                    </Tooltip>
                )
            },
            {
                title: '题目总数',
                dataIndex: 'ques_count',
                key: 'ques_count',
            },
            {
                title: '完成时间',
                dataIndex: 'minutes',
                key: 'minutes'
            },
            {
                title: '错题量',
                dataIndex: 'wrong_ques_count',
                key: 'wrong_ques_count'
            },
            {
                title: '主动提问个数',
                dataIndex: 'ask_ques_count',
                key: 'ask_ques_count'
            }
        ];
        const columns_wrong = [{
            title: '科目',
            key: 'subject_name',
            align: 'center',
            render: (text) => (
                <span>
                    {text.subject_name.split('-').length > 0 ? text.subject_name.split('-')[1] : text.subject_name}
                </span>
            ),
        }, {
            title: '来源',
            key: 'upload_channel_id',
            align: 'center',
            render: (text) => (
                <span className="active" style={{ color: '#1890ff' }} onClick={() => this.checkText(text.text)}>
                    {text.upload_channel_id == '1' ? '电脑上传' : '手机上传'}
                </span>
            ),
        }, {
            title: '是否解析',
            dataIndex: 'state',
            key: 'state',
            align: 'center',
            render: (text) => (
                <span>
                    {text == '1' ? '已解析' : '未解析'}
                </span>
            ),
        }, {
            title: '时间',
            dataIndex: 'add_time',
            key: 'add_time',
            align: 'center',
            render: (text) => (
                <span>
                    {text ? text : 'null'}
                </span>
            )
        }];
        return (
            <div>
                <Modal
                    title='晚辅导管理'
                    visible={this.state.isAddShow}
                    onOk={this.addOk}
                    onCancel={this.addCancel}
                    style={{ Index: '99' }}
                >
                    <Row>
                        <Col className="ant-col-css" span={8}>
                            <span>科目：</span>
                        </Col>
                        <Col className="ant-col-css" span={16}>
                            <Select style={{ width: 165 }} value={this.state.sub_id} onChange={this.subChange} >
                                {
                                    this.state.subject_list.map((res, index) =>
                                        <Option key={index} value={res.xiaoguanjia_subject_id}>{res.xiaoguanjia_subject_name}</Option>
                                    )
                                }
                            </Select>
                            <Tooltip placement="top" title='如果没有你需要选择的科目，请联系教学主管在班级分配里面配置'>
                                <QuestionCircleOutlined style={{ marginLeft: '5px' }} />
                            </Tooltip>
                        </Col>
                        <Col className="ant-col-css" span={8}>
                            <span>日期：</span>
                        </Col>
                        <Col className="ant-col-css" span={16}>
                            <DatePicker value={moment(this.state.date)} onChange={this.dateChange} />
                        </Col>
                        <Col className="ant-col-css" span={8}>
                            <span>是否完成：</span>
                        </Col>
                        <Col className="ant-col-css" span={16} style={{ height: '32px' }}>
                            <Select style={{ width: 165 }} value={this.state.is_finished} onChange={this.finishedChange} >
                                <Option value={'-1'}>未完成</Option>
                                <Option value={'1'}>已经完成</Option>
                                <Option value={'2'}>无需提交</Option>
                                <Option value={'3'}>无作业</Option>
                            </Select>
                        </Col>
                        {
                            this.state.is_finished == '-1' || this.state.is_finished == '3' ?
                                ''
                                :
                                this.state.is_finished == '2' ?
                                    <div>
                                        <Col className="ant-col-css" span={8}>
                                            <span>无需提交原因：</span>
                                        </Col>
                                        <Col className="ant-col-css" span={16} style={{ height: '32px' }}>
                                            <Select style={{ width: 165 }} value={this.state.no_need_reason == 'null' ? '' : this.state.no_need_reason} onChange={this.no_need_reasonChange} >
                                                <Option value={'背诵默写类'}>背诵默写类</Option>
                                                <Option value={'在校(家)完成'}>在校(家)完成</Option>
                                                <Option value={'请假'}>请假</Option>
                                            </Select>
                                        </Col>
                                    </div>
                                    :
                                    <Row>
                                        <Col className="ant-col-css" span={8}>
                                            <span>题目数量：</span>
                                        </Col>
                                        <Col className="ant-col-css" span={16}>
                                            <Input type="number" value={this.state.ques_count} onChange={this.countChange} />
                                        </Col>
                                        <Col className="ant-col-css" span={8}>
                                            <span>完成分钟数：</span>
                                        </Col>
                                        <Col className="ant-col-css" span={16}>
                                            <Input type="number" value={this.state.minutes} onChange={this.minutesChange} />
                                        </Col>
                                        <Col className="ant-col-css" span={8}>
                                            <span>错题量：</span>
                                        </Col>
                                        <Col className="ant-col-css" span={16}>
                                            <Input type="number" value={this.state.wrong_ques_count} onChange={this.wrongChange} />
                                        </Col>
                                        <Col className="ant-col-css" span={8}>
                                            <span>主动提问个数：</span>
                                        </Col>
                                        <Col className="ant-col-css" span={16}>
                                            <Input type="number" value={this.state.ask_ques_count} onChange={this.askChange} />
                                        </Col>
                                    </Row>
                        }
                        <Col className="ant-col-css" span={8}>
                            <span>批评次数：</span>
                        </Col>
                        <Col className="ant-col-css" span={16}>
                            <Input type="number" value={this.state.warn_count} onChange={this.warn_countChange} />
                        </Col>
                        <Col className="ant-col-css" span={8}>
                            <span style={{ marginRight: 8 }}>批评标签:</span>
                        </Col>
                        <Col span={16} style={{ marginTop: '5px', padding: '5px 0' }}>
                            {
                                this.state.tagsData.map((item, index) => {
                                    return (
                                        <div style={{ display: 'flex' }} key={index}>
                                            <div>{item.name}：</div>
                                            <div>
                                                {
                                                    item.tags.map(tag => (
                                                        <CheckableTag
                                                            key={tag}
                                                            checked={this.state.selectedTags ? this.state.selectedTags.indexOf(tag) > -1 : false}
                                                            onChange={checked => this.tagsChange(tag, checked)}
                                                            style={{ cursor: 'pointer', marginBottom: '5px', borderBottom: this.state.selectedTags ? this.state.selectedTags.indexOf(tag) > -1 ? '' : '1px solid #ccc' : '' }}
                                                        >
                                                            {tag}
                                                        </CheckableTag>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Col>
                        <Col className="ant-col-css" span={8}>
                            <span>备注：</span>
                        </Col>
                        <Col className="ant-col-css" span={16}>
                            <Input type="text" value={this.state.remark} onChange={this.remarkChange} />
                        </Col>
                    </Row>
                </Modal>
                <Modal
                    title='答疑解惑列表'
                    visible={this.state.jiedaMkey}
                    onOk={this.jiedaOk}
                    onCancel={this.jiedaCancel}
                    width={800}
                >
                    <Spin spinning={this.state.loading}>
                        <Tabs activeKey={this.state.msgkey} onChange={this.msgtabChange}>
                            <TabPane tab="答疑解惑" key="a">
                                {this.state.canChange ? <Button onClick={this.showAdd} type="primary">添加</Button> : ''}
                                <Table pagination={false} columns={columns_jieda} dataSource={this.state.jiedaList} />
                                <Pagination className="m-Pleft" current={this.state.ans_page} defaultPageSize={5} onChange={this.changePage1} total={this.state.ans_total} />
                            </TabPane>
                            <TabPane tab="错题信息" key="b">
                                <Table pagination={false} rowKey={record => record.key} columns={columns_wrong} dataSource={this.state.wrong_list} />
                                <Pagination className="m-Pleft" current={this.state.wrongParams.page} defaultPageSize={this.state.wrongParams.page_size} onChange={this.changePage2} total={this.state.wrongTotal} />
                                <Modal
                                    title="错题详情"
                                    visible={this.state.msgvisible}
                                    onOk={this.checkCancel}
                                    onCancel={this.checkCancel}
                                    okText="确认"
                                    cancelText="关闭"
                                    width={650}
                                >
                                    <span dangerouslySetInnerHTML={{
                                        __html: this.state.text
                                    }}></span>
                                </Modal>
                            </TabPane>
                        </Tabs>
                    </Spin>
                </Modal>
                <Spin spinning={this.state.tabs_loading}>
                    <Tabs activeKey={this.state.tab_index} onChange={this.tabChange}>
                        <TabPane tab="老师" key="3">
                            <div>
                                {this.state.roomList.map((res, index) =>
                                    <div className='m-bottom' key={index}>
                                        <Table columns={columns3} dataSource={res.sub_list} bordered pagination={false} />
                                    </div>
                                )}
                                {this.state.roomList.length < 1 ?
                                    <Result
                                        title="你名下暂未分配晚辅导学生"
                                        extra={
                                            localStorage.getItem("permission") <= 2 || localStorage.getItem("teacher_type") <= 2 ?
                                                <Button type="primary" key="console" onClick={this.set}>去设置</Button>
                                                :
                                                ''
                                        }
                                    />
                                    :
                                    ''}
                            </div>
                            <span>晚辅导人数：{this.state.all_zuoyeba_count}</span>
                            <Tooltip title={this.state.un_set_student_str}>
                                <span className="m-left">未设置人数：<span style={{ color: '#f40' }}>{this.state.un_set_count}</span></span>
                                <span className="m-left">单纯晚辅导的学生占比：{this.state.single_zuoyeba_percent}</span>
                            </Tooltip>
                        </TabPane>
                        <TabPane tab="教室" key="2">
                            <div>
                                {this.state.roomList.map((res, index) =>
                                    <div className='m-bottom' key={index}>
                                        <Table columns={columns} dataSource={res.sub_list} bordered pagination={false} />
                                    </div>
                                )}
                                {this.state.roomList.length < 1 ?
                                    <Result
                                        title="你名下暂未分配晚辅导学生"
                                        extra={
                                            localStorage.getItem("permission") <= 2 || localStorage.getItem("teacher_type") <= 2 ?
                                                <Button type="primary" key="console" onClick={this.set}>去设置</Button>
                                                :
                                                ''
                                        }
                                    />
                                    : ''}
                            </div>
                            <span>晚辅导人数：{this.state.all_zuoyeba_count}</span>
                            <Tooltip title={this.state.un_set_student_str}>
                                <span className="m-left">未设置人数：<span style={{ color: '#f40' }}>{this.state.un_set_count}</span></span>
                                <span className="m-left">单纯晚辅导的学生占比：{this.state.single_zuoyeba_percent}</span>
                            </Tooltip>
                        </TabPane>
                        <TabPane tab="班主任" key="1">
                            <div>
                                {this.state.roomList.map((res, index) =>
                                    <div className='m-bottom' key={index}>
                                        <Table columns={columns2} dataSource={res.sub_list} bordered pagination={false} />
                                    </div>
                                )}
                                {this.state.roomList.length < 1 ?
                                    <Result
                                        title="你名下暂未分配晚辅导学生"
                                        extra={
                                            localStorage.getItem("permission") <= 2 || localStorage.getItem("teacher_type") <= 2 ?
                                                <Button type="primary" key="console" onClick={this.set}>去设置</Button>
                                                :
                                                ''
                                        }
                                    />
                                    : ''}
                            </div>
                            <span>晚辅导人数：{this.state.all_zuoyeba_count}</span>
                            <Tooltip title={this.state.un_set_student_str}>
                                <span className="m-left">未设置人数：<span style={{ color: '#f40' }}>{this.state.un_set_count}</span></span>
                                <span className="m-left">单纯晚辅导的学生占比：{this.state.single_zuoyeba_percent}</span>
                            </Tooltip>
                        </TabPane>
                    </Tabs>
                </Spin>
            </div >
        );
    }
}

export default OtherTable;