import React, { useEffect, useState } from 'react'
import { message, Table, Button, Modal, Select, Row, Col, Input, Tag, Checkbox } from 'antd';
import { set_room, get_set_room, get_room_list, get_shift_type } from '../../axios/http'
import { changeTreeDate } from '../../actions/changeTreeDate'

const { Option } = Select
const paramsModel = {
  xiaoguanjia_id: '',
  person_count: '',
  class_type_id_str: [],
  class_type_str: []
}

const Setclassroom = (props) => {

  const [visible, visibleSet] = useState(false)
  const [set_room_list, set_room_listSet] = useState([])
  const [room_list, room_listSet] = useState([])
  const [params, paramsSet] = useState(paramsModel)
  const [xiaoguanjia_id, xiaoguanjia_idSet] = useState()
  const [shift_type, shift_typeSet] = useState([])

  useEffect(() => {
    get_set_roomFun()
    get_room_listFun()
    get_shift_typeFun()
  }, [])

  const get_set_roomFun = e => {
    get_set_room().then(res => {
      if (res.code == 0) {
        let list = res.data.room_list
        set_room_listSet(list)
      } else {
        message.error(res.message)
      }
    })
  }

  const get_shift_typeFun = e => {
    get_shift_type().then(res => {
      if (res.code === 0) {
        let list = res.data.list
        list = changeTreeDate(list, 'label', 'value')
        list.map((item, index) => {
          list[index].value = index
        })
        shift_typeSet(list)
      }
    })
  }

  const get_room_listFun = e => {
    get_room_list().then(res => {
      if (res.code == 0) {
        let list = res.data.room_list
        room_listSet(list)
      } else {
        message.error(res.message)
      }
    })
  }

  const set_roomFun = e => {
    let data = params, class_type_id_str = [],
      class_type_str = []
    data.xiaoguanjia_id = xiaoguanjia_id
    data.class_type_str.map(item => {
      class_type_id_str.push(shift_type[item].xiaoguanjia_id)
      class_type_str.push(shift_type[item].label)
    })
    data.class_type_id_str = class_type_id_str
    data.class_type_str = class_type_str
    set_room(data).then(res => {
      if (res.code == 0) {
        message.success('设置成功!')
        visibleSet(false)
        paramsSet(paramsModel)
        get_set_roomFun()
        get_room_listFun()
      } else {
        message.error(res.message)
      }
    })
  }

  const columns = [
    {
      title: '房间名',
      dataIndex: 'room'
    },
    {
      title: '房间容量',
      dataIndex: 'person_count'
    },
    {
      title: '房间类型',
      dataIndex: 'class_type_str'
    },
    {
      title: '房间状态',
      dataIndex: 'is_enabled',
      render: (text, record, index) => (
        <div>{text == '1' ? '可排课' : '不能排课'}</div>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      render: (text, record, index) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <span style={{ cursor: 'pointer', color: '#1890ff' }} onClick={() => { let s = record; paramsSet(s); xiaoguanjia_idSet(record.xiaoguanjia_id); visibleSet(true); }}>修改</span>
        </div>
      )
    }
  ]

  const valueChange = (e, id) => {
    let value = typeof (e) == 'undefined' ? '' : typeof (e) == 'object' ? e.target ? e.target.value : e : e,
      s = params
    s[id] = value
    paramsSet(s)
  }

  return (
    <div>
      <div>
        <div>
          <Table
            columns={columns}
            dataSource={set_room_list}
            bordered
            pagination={false}
            size='middle'
          />
        </div>
        {/* <div style={{ width: '28%', border: '1px solid #e8e8e8' }}>
          <div style={{ color: 'rgba(0,0,0,0.85)', fontWeight: '500', textAlign: 'center', background: '#fafafa', border: '1px solid #e8e8e8', padding: '12px 8px' }}>暂未设置</div>
          <div style={{ padding: '10px' }}>
            {
              room_list.map((item, index) => (
                <Tag key={index} onClick={() => { xiaoguanjia_idSet(item.room_id); visibleSet(true); }} style={{ fontSize: '16px', marginBottom: '10px', cursor: 'pointer' }}>{item.name}</Tag>
              ))
            }
          </div>
        </div> */}
      </div>
      <Modal
        title="修改密码"
        cancelText='取消'
        okText='确认'
        visible={visible}
        onOk={set_roomFun}
        onCancel={e => { visibleSet(false); paramsSet(paramsModel); }}
      >
        <Row className="modal-line">
          <Col span={8}>房间：</Col>
          <Col span={16}>
            <Select placeholder='请选择' disabled={true} value={xiaoguanjia_id} style={{ width: '100%' }}>
              {
                room_list.map((item, index) => (
                  <Option key={index} value={item.room_id}>{item.name}</Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>人数：</Col>
          <Col span={16}>
            <Input placeholder='请输入' value={params.person_count} onChange={e => valueChange(e, 'person_count')} />
          </Col>
          <Col span={8}>班级类型：</Col>
          <Col span={16}>
            <Checkbox.Group options={shift_type} value={params.class_type_str} onChange={e => valueChange(e, 'class_type_str')} />
          </Col>
        </Row>
      </Modal>
    </div>
  )
}

export default Setclassroom
