import React, { Component, useState, useEffect } from 'react';
import MathJax from 'react-mathjax3'
import { ApartmentOutlined, UploadOutlined,ExclamationCircleOutlined  } from '@ant-design/icons'
import { Menu, Dropdown, Checkbox, Avatar, BackTop, List, Button, Divider, Select, Table, Pagination, Modal, Tabs, Descriptions, Input, message, Row, Col, Radio, DatePicker, Upload, Image } from 'antd';
import { get_all_master, studentwrong_ques_list, get_xiaoguanjia_subject, set_memory_capacity, get_student_detail, get_student_list, get_recommend_collect, get_recommend_collect_question, loginUserList, get_xiaoguanjia_grade, exam_get_list, exam_result_entry, submit_chat_record, get_chat_record_list, set_continue, set_rep_data, get_rep_data ,set_learning_plan,set_attachment,del_attachment} from '../../axios/http'
import moment from 'moment'
import { tableToExcel } from '../../actions/tableToExcel'
import '../../css/student.css'

const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { confirm } = Modal;
const optionsWithDisabled = [
  { label: '记忆容量', value: 'memory_capacity' },
  { label: '活动次数', value: 'active_count' },
  { label: '成绩次数', value: 'exam_count' },
  { label: '剩余学费', value: 'remain_money' },
  { label: '学管沟通次数', value: 'chat_count' },
  { label: '预计成交时间', value: 'expected_date' },
  { label: '预计成交额', value: 'expected_money' },
  { label: '最后学管沟通时间', value: 'last_chat_time' }
]
const { RangePicker } = DatePicker;
const propsSet = {
  name: 'upload_control',
  action: 'https://jiaoxueapi.yanuojiaoyu.com/api/upload/upload_file',
  showUploadList: false,
  headers: {
    username: encodeURI(localStorage.getItem("username")),
    token: localStorage.getItem("token"),
    companyid: localStorage.getItem("company_id")
  },
  data: {
    type: 10
  }
};
const propsSet1 = {
  name: 'upload_control',
  action: 'https://jiaoxueapi.yanuojiaoyu.com/api/upload/upload_file',
  showUploadList: false,
  headers: {
    username: encodeURI(localStorage.getItem("username")),
    token: localStorage.getItem("token"),
    companyid: localStorage.getItem("company_id")
  },
  data: {
    type: 11
  }
};

const propsUpfilesSet = {
  name: 'upload_control',
  action: global.constants.baseURL + '/api/upload/upload_file',
  showUploadList: false,
  headers: {
    username: encodeURI(localStorage.getItem("username")),
    token: localStorage.getItem("token"),
    companyid: localStorage.getItem("company_id")
  },
  data: {
    type: 11
  }
};

class index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      remain_money: 0,
      studentList: [],
      shijuanList: [],
      list: [],
      teacherList: [],
      totalCount: 0,
      tabsindex: 1,
      cdKey: false,
      visible: true,
      visible2: false,
      visible3: false,
      studentInfoList: {
        student_model: {
          name: '',
          fulltime_grade: '',
          fulltime_class: '',
          fulltime_school: '',
          master: ''
        },
        class_list: [],
        active_list: [],
        attachment_list: []
      },
      class_list: [],
      active_list: [],
      exam_list: [],
      wrong_list: [],
      master: [],
      wrongTotal: 0,
      params: {
        teacher_employee_id: '',
        grade_id: '',
        type_id: '1',
        class_name: '',
        student: '',
        fulltime_school: '',
        fulltime_class: '',
        status: '1',
        master_id: '',
        sorttype: '',
        page: 1,
        page_size: '10'
      },
      setStudentParams: {
        student_id: '',
        memory_capacity: ''
      },
      wrongParams: {
        xiaoguanjia_subject_id: '',
        xiaoguanjia_grade_id: '',
        xiaoguanjia_class_id: '',
        xiaoguanjia_student_id: '',
        analysis_teacher_id: '',
        page_size: 5,
        page: 1
      },
      drawerParamResult: {

      },
      columns: [
        {
          title: '姓名',
          key: 'name',
          align: 'center',
          render: (text, record) => (
            <span>
              <span className="active" style={{ color: '#1890ff' }} onClick={() => this.studentInfo(text.student_id, record.remain_money)}>{text.name ? text.name : 'null'}</span>
            </span>
          ),
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          align: 'center',
          render: (text) => (
            <span>{this.statuAppear(text)}</span>
          ),
        },
        {
          title: '学校',
          dataIndex: 'fulltime_school',
          key: 'fulltime_school',
          align: 'center',
          render: (text) => (
            <span>
              {text ? text : 'null'}
            </span>
          ),
        },
        {
          title: '年级',
          dataIndex: 'fulltime_grade',
          key: 'fulltime_grade',
          align: 'center',
          render: (text) => (
            <span>
              {text ? text : 'null'}
            </span>
          ),
        },
        {
          title: '班级',
          dataIndex: 'fulltime_class',
          key: 'fulltime_class',
          align: 'center',
          render: (text) => (
            <span>
              {text ? text : 'null'}
            </span>
          ),
        },
        {
          title: '班级数',
          dataIndex: 'attend_class_count',
          key: 'attend_class_count',
          align: 'center',
          render: (text) => (
            <span>
              {text ? text : 'null'}
            </span>
          ),
        },
        {
          title: '报名日期',
          dataIndex: 'indate',
          key: 'indate',
          align: 'center',
          render: (text) => (
            <span>
              {text ? text : 'null'}
            </span>
          ),
          sorter: true,
        },
        {
          title: '学管',
          dataIndex: 'master',
          key: 'master',
          align: 'center',
          render: (text) => (
            <span>
              {text ? text : 'null'}
            </span>
          ),
        }
      ],
      newColumns: [{
        title: '姓名',
        key: 'name',
        align: 'center',
        render: (text, record) => (
          <span>
            <span className="active" style={{ color: '#1890ff' }} onClick={() => this.studentInfo(text.student_id, record.remain_money)}>{text.name ? text.name : 'null'}</span>
          </span>
        ),
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: (text) => (
          <span>{this.statuAppear(text)}</span>
        ),
      },
      {
        title: '学校',
        dataIndex: 'fulltime_school',
        key: 'fulltime_school',
        align: 'center',
        render: (text) => (
          <span>
            {text ? text : 'null'}
          </span>
        ),
      },
      {
        title: '年级',
        dataIndex: 'fulltime_grade',
        key: 'fulltime_grade',
        align: 'center',
        render: (text) => (
          <span>
            {text ? text : 'null'}
          </span>
        ),
      },
      {
        title: '班级',
        dataIndex: 'fulltime_class',
        key: 'fulltime_class',
        align: 'center',
        render: (text) => (
          <span>
            {text ? text : 'null'}
          </span>
        ),
      },
      {
        title: '班级数',
        dataIndex: 'attend_class_count',
        key: 'attend_class_count',
        align: 'center',
        render: (text) => (
          <span>
            {text ? text : 'null'}
          </span>
        ),
      },
      {
        title: '记忆容量',
        dataIndex: 'memory_capacity',
        key: 'memory_capacity',
        align: 'center',
        render: (text) => (
          <span>
            {text ? text : 'null'}
          </span>
        ),
      },
      {
        title: '成绩次数',
        dataIndex: 'exam_count',
        key: 'exam_count',
        align: 'center',
        render: (text) => (
          <span>
            {text ? text : 'null'}
          </span>
        ),
        sorter: (a, b) => a.exam_count - b.exam_count,
      },
      {
        title: '报名日期',
        dataIndex: 'indate',
        key: 'indate',
        align: 'center',
        render: (text) => (
          <span>
            {text ? text : 'null'}
          </span>
        ),
        sorter: true,
      },
      {
        title: '学管',
        dataIndex: 'master',
        key: 'master',
        align: 'center',
        render: (text) => (
          <span>
            {text ? text : 'null'}
          </span>
        ),
      },
      {
        title: '操作',
        key: 'action',
        align: 'center',
        width: 190,
        render: (text) => (
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="primary" onClick={() => this.detail(text)}>错题训练</Button>
            <Button className="m-left" type="primary" onClick={() => this.delete(text)}>报表</Button>
          </span>
        ),
      }],
      actionC: {
        title: '操作',
        key: 'action',
        align: 'center',
        render: (text) => (
          <span>
            <Button className="m-left" type="primary" onClick={() => this.detail(text)}>AI错题训练</Button>
            <Button className="m-left" type="primary" onClick={() => this.delete(text)}>报表</Button>
          </span>
        ),
      },
      columns2: [{
        title: '活动名称',
        key: 'title',
        dataIndex: 'title',
        align: 'center',
        render: (text) => (
          <span>
            {text ? text : 'null'}
          </span>
        ),
      },
      {
        title: '活动时间',
        dataIndex: 'start_date',
        key: 'start_date',
        align: 'center',
        render: (text) => (
          <span>
            {text ? text : 'null'}
          </span>
        ),
      },],
      columns3: [{
        title: '班级名称',
        key: 'name',
        dataIndex: 'name',
        align: 'center',
        render: (text) => (
          <span>
            {text ? text : 'null'}
          </span>
        ),
      },
      {
        title: '开班时间',
        dataIndex: 'course_time',
        key: 'course_time',
        align: 'center',
        render: (text) => (
          <span>
            {text ? text : 'null'}
          </span>
        ),
      },],
      columns4: [
        {
          title: '科目',
          key: 'subject_name',
          align: 'center',
          render: (text) => (
            <span>
              {text.subject_name.split('-').length > 0 ? text.subject_name.split('-')[1] : text.subject_name}
            </span>
          ),
        },
        {
          title: '来源',
          key: 'upload_channel_id',
          align: 'center',
          render: (text) => (
            <span className="active" style={{ color: '#1890ff' }} onClick={() => this.checkText(text.text)}>
              {text.upload_channel_id === '1' ? '电脑上传' : '手机上传'}
            </span>
          ),
        },
        {
          title: '是否解析',
          dataIndex: 'state',
          key: 'state',
          align: 'center',
          render: (text) => (
            <span>
              {text === '1' ? '已解析' : '未解析'}
            </span>
          ),
        },
        {
          title: '时间',
          dataIndex: 'add_time',
          key: 'add_time',
          align: 'center',
          render: (text) => (
            <span>
              {text ? text : 'null'}
            </span>
          ),
        },],
      text: ''
    }
  }

  checkText = (text) => {
    this.setState({
      text,
      visible3: true
    })
  }

  checkCancel = () => {
    this.setState({
      visible3: false
    })
  }

  componentDidMount() {
    const params = this.state.params
    get_xiaoguanjia_grade().then(res => {
      const grandList = res.data.list.map(res => {
        return <Option value={res.xiaoguanjia_id} key={res.xiaoguanjia_id}>{res.value.split('-')[1]}</Option>
      })
      grandList.unshift(<Option value='' key=''>请选择年级</Option>)
      this.setState({
        grandList
      })
    })
    this.checkonChange(['remain_money'])
    if (localStorage.getItem("permission") === '4' || localStorage.getItem("permission") === '3') {
      get_student_list({ ...params }).then(res => {
        this.setState({
          studentList: res.data.list,
          saveStudentList: res.data.list
        })
      })
    } else {
      loginUserList().then(res => {
        const list = []
        res.data.list.forEach(res => {
          if (res.xiaoguanjia_employee_id !== null) {
            list.push(<Option value={res.xiaoguanjia_employee_id} key={res.xiaoguanjia_employee_id}>{res.name}</Option>)
          }
        })
        this.setState({
          teacherList: list
        })
      })
    }
    get_xiaoguanjia_subject({ is_all: 1 }).then(res => {
      this.setState({
        subject_list: res.data.list
      })
    })
    get_student_list({ ...params }).then(res => {
      this.setState({
        studentList: res.data.list,
        saveStudentList: res.data.list,
        params,
        totalCount: Number(res.data.total_count)
      })
    })
    get_all_master().then(res => {
      const master = res.data.master_list.map(res => {
        return <Option value={res.master_id} key={res.master_id}>{res.master}</Option>
      })
      this.setState({
        master
      })
    })
    window.addEventListener('resize', this.handleSize);
    this.handleSize()
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleSize);
  }

  handleSize = () => {
    this.setState({
      height: document.body.clientHeight,
    });
  }

  moveOrAdd = (id) => {

  }

  addQuestoin = (e, id) => {

  }

  deleteQuestoin = (e, id) => {

  }

  add = (e) => {
    if (this.state.appear === e) {
      this.setState({
        appear: ''
      })
    } else {
      this.setState({
        appear: e
      })
    }
  }

  info = e => {
    get_recommend_collect({ xiaoguanjia_student_id: e }).then(res => {
      this.setState({
        shijuanList: res.data.list,
        cdKey: true,
        list: []
      })
    })
  }

  listView = e => {
    get_recommend_collect_question({ wrong_recommend_collection_id: e }).then(res => {
      this.setState({
        list: res.data.list
      })
    })
  }

  back = () => {
    this.setState({
      cdKey: false
    })
  }

  handleChange = (e, id) => {
    let params = this.state.params
    params[id] = typeof (e) == 'undefined' ? '' : typeof (e) == 'boolean' ? e ? 1 : -1 : typeof (e) == 'object' ? e.target.value : e
    get_student_list({ ...params }).then(res => {
      this.setState({
        studentList: res.data.list,
        saveStudentList: res.data.list,
        params,
        totalCount: Number(res.data.total_count)
      })
    })
  }

  changePage = e => {
    const params = this.state.params
    params.page = e
    get_student_list({ ...params }).then(res => {
      this.setState({
        studentList: res.data.list,
        saveStudentList: res.data.list,
        params,
        totalCount: Number(res.data.total_count)
      })
    })
  }

  setPageSize = (current, pageSize) => {
    const params = this.state.params
    if (pageSize != this.state.totalCount) {
      params.page = 1
      params.page_size = pageSize
    } else {
      params.page = 0
      params.page_size = Number(this.state.totalCount)
    }
    get_student_list({ ...params }).then(res => {
      this.setState({
        studentList: res.data.list,
        saveStudentList: res.data.list,
        params,
        totalCount: Number(res.data.total_count)
      })
    })
  }

  search = () => {
    const params = this.state.params
    get_student_list({ ...params }).then(res => {
      this.setState({
        studentList: res.data.list,
        saveStudentList: res.data.list,
        params,
        totalCount: Number(res.data.total_count)
      })
    })
  }

  changePage2 = e => {
    const wrongParams = this.state.wrongParams
    wrongParams.page = e
    studentwrong_ques_list({ ...wrongParams }).then(res => {
      this.setState({
        wrong_list: res.data.list,
        wrongTotal: Number(res.data.count)
      })
    })
  }

  detail = (e) => {
    get_recommend_collect({ xiaoguanjia_student_id: e.student_id }).then(res => {
      this.setState({
        shijuanList: res.data.list,
        cdKey: true,
        list: []
      })
    })
  }

  delete = (e) => {
    window.open(`/#/dataImg?id=${e.student_id}&status=${this.state.params.status}&grade=${e.fulltime_grade}`)
  }

  stumOK = () => {
    const setStudentParams = this.state.setStudentParams
    set_memory_capacity({ ...setStudentParams }).then(res => {
      if (res.code === 0) {
        message.success(res.message)
      } else {
        message.error(res.message)
      }
    })
    this.setState({
      visible2: false
    })
  }

  stumCancel = () => {
    this.setState({
      visible2: false
    })
  }

  callback = (key) => {
    console.log(key)
    this.setState({
      tabsindex: key
    })
  }

  studentInfo = (res, remain_money) => {
    this.setState({
      remain_money
    })
    const setStudentParams = { ...this.state.setStudentParams }
    const wrongParams = this.state.wrongParams
    const memory_capacity = this.state.memory_capacity
    const subject_list = this.state.subject_list
    setStudentParams.student_id = res
    wrongParams.xiaoguanjia_student_id = res
    get_student_detail({ student_id: res }).then(ele => {
      setStudentParams.memory_capacity = ele.data.student_model.memory_capacity
      const active_list = ele.data.active_list.reduce((item, res) => {
        if (res.title !== null) {
          item.push(res)
        }
        return item
      }, [])
      const class_list = ele.data.class_list.reduce((item, res) => {
        if (res.name !== null) {
          item.push(res)
        }
        return item
      }, [])
      
      if (ele.data.exam_list) {
        this.setState({
          setStudentParams,
          studentInfoList: ele.data,
          visible2: true,
          exam_list: ele.data.exam_list,
          active_list,
          class_list,
          rep_list: ele.data.rep_list
        })
      } else {
        this.setState({
          setStudentParams,
          studentInfoList: ele.data,
          visible2: true,
          exam_list: [],
          active_list,
          class_list,
          rep_list: ele.data.rep_list
        })
      }
    })
    studentwrong_ques_list({ ...wrongParams }).then(res => {
      this.setState({
        wrong_list: res.data.list,
        wrongTotal: Number(res.data.count)
      })
    })
  }

  remember = (e) => {
    const setStudentParams = this.state.setStudentParams
    setStudentParams.memory_capacity = e
    this.setState({
      setStudentParams
    })
  }

  statuAppear = e => {
    switch (e) {
      case '-1':
        return '已删除'
      case '0':
        return '试听'
      case '1':
        return '在读'
      case '2':
        return '休学'
      case '3':
        return '退学'
      default:
        return '已退校'
    }
  }

  checkonChange = e => {
    const columns = this.state.columns

    function name(type) {
      let name = ''
      switch (type) {
        case 'memory_capacity':
          return name = '记忆容量'
        case 'active_count':
          return name = '活动次数'
        case 'exam_count':
          return name = '成绩次数'
        case 'remain_money':
          return name = '剩余学费'
        case 'chat_count':
          return name = '学管沟通次数'
        case 'last_chat_time':
          return name = '最后学管沟通时间'
      }
    }
    if (e.length >= 1) {
      const list = e.map(res => {
        return {
          title: name(res),
          dataIndex: res,
          key: res,
          align: 'center',
          sorter: true,
          render: (text) => (
            <span>
              {text ? text : 'null'}
            </span>
          ),
        }
      })
      let newArr = columns.concat(list, this.state.actionC)
      this.setState({
        newColumns: newArr
      })
    } else {
      this.setState({
        newColumns: columns.concat(this.state.actionC)
      })
    }
  }

  tableChange = (pagination, filters, sorter) => {
    let s = this.state.params, t
    switch (sorter.field) {
      case 'indate': sorter.order == "ascend" ? t = 1 : t = 2; break;
      case 'remain_money': sorter.order == "ascend" ? t = 3 : t = 4; break;
      case 'chat_count': sorter.order == "ascend" ? t = 5 : t = 6; break;
      case 'last_chat_time': sorter.order == "ascend" ? t = 7 : t = 8; break;
      default: break;
    }
    s.sorttype = t
    get_student_list({ ...s }).then(res => {
      this.setState({
        studentList: res.data.list,
        saveStudentList: res.data.list,
        params: s,
        totalCount: Number(res.data.total_count)
      })
    })
  }

  visible2Set = () => {
    this.setState({
      visible2: false
    })
  }

  render() {
    const menu = (
      <Menu>
        <div style={{ width: 100, marginLeft: '5px' }}>
          <Checkbox.Group
            options={optionsWithDisabled}
            defaultValue={['remain_money']}
            onChange={this.checkonChange}
          />
        </div>
      </Menu>
    )
    return (
      <div style={{ position: 'relative' }}>
        {this.state.cdKey ?
          <div>
            <Button onClick={this.back}>返回</Button>
            <div className="knowlage">
              <div className="tree" >
                <div className='m-know-list'>
                  <List
                    style={{ height: 534, width: 280 }}
                    className="list-hover"
                    itemLayout="vertical"
                    dataSource={this.state.shijuanList}
                    size='small'
                    renderItem={item => (
                      <List.Item
                        onClick={() => this.listView(item.id)}
                      >
                        <List.Item.Meta
                          avatar={<Avatar src={require('../../img/shijuan.png')} />}
                          description={item.title}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </div>
              <div id='scroll-y' className="list" style={{ height: this.state.height - 200, width: '100%' }}>
                <div>
                  {/* <div className='m-flex m-bottom'>
                                        <Button onClick={this.preview}>预览试卷</Button>
                                        <div className='m-left'>
                                            <Button type='primary' onClick={this.collect}>收藏试卷</Button>
                                        </div>
                                    </div> */}
                  <ListT data={this.state.list} fun={this.add} deleteQuestoin={this.deleteQuestoin} appear={this.state.appear} addQuestoin={this.addQuestoin} moveOrAdd={this.moveOrAdd} isShow={false}></ListT>
                  {/* </div> */}
                  <BackTop style={{ zIndex: '999' }} target={() => document.getElementById('scroll-y')} />
                </div>
              </div>
            </div>
          </div>
          :
          <div>
            {
              localStorage.getItem("permission") === '4' || localStorage.getItem("permission") === '3' ?
                <div className="flex">
                  <div className="mr">
                    <Select placeholder="请选择年级" style={{ width: 180 }} onChange={e => this.handleChange(e, 'grade_id')} optionFilterProp="children" showSearch>
                      {this.state.grandList}
                    </Select>
                  </div>
                  <div className="mr">
                    <Input placeholder="请输入学生姓名" onChange={(e) => this.handleChange(e, 'student')}></Input>
                  </div>
                  <div className="mr">
                    <Select placeholder="作业吧周末课切换" style={{ width: 180 }} value={this.state.params.type_id} onChange={e => this.handleChange(e, 'type_id')} optionFilterProp="children">
                      <Option value="1">周末课</Option>
                      <Option value="2">作业吧</Option>
                    </Select>
                  </div>
                  <div className="mr">
                    <Input style={{ width: "280px" }} placeholder="请输入学校" onChange={(e) => this.handleChange(e, 'fulltime_school')}></Input>
                  </div>
                  <div className="mr">
                    <Input placeholder="请输入班级" onChange={(e) => this.handleChange(e, 'fulltime_class')}></Input>
                  </div>
                  <div className="mr">
                    <Input placeholder="请输入机构班级" onChange={(e) => this.handleChange(e, 'class_name')}></Input>
                  </div>
                  <div className="mr">
                    <Select placeholder="状态" style={{ width: 180 }} value={this.state.params.status} onChange={e => this.handleChange(e, 'status')} optionFilterProp="children" showSearch>
                      <Option value="">请选择状态</Option>
                      {/* <Option value="-1">已删除</Option> */}
                      <Option value="0">试听</Option>
                      <Option value="1">在读</Option>
                      <Option value="2">停课</Option>
                      <Option value="3">休学</Option>
                      <Option value="99">已退学</Option>
                    </Select>
                  </div>
                  <div className="mr">
                    <Select placeholder="选择学管" style={{ width: 180 }} value={this.state.params.master_id} onChange={e => this.handleChange(e, 'master_id')} optionFilterProp="children" showSearch>
                      <Option value=''>请选择学管</Option>
                      {this.state.master}
                    </Select>
                  </div>
                  <div className="mr">
                    <Button onClick={this.search} type="primary">查询</Button>
                  </div>
                </div>
                :
                <div className="flex">
                  <div className="mr">
                    <Select placeholder="请选择老师" style={{ width: 180 }} onChange={e => this.handleChange(e, 'teacher_employee_id')} optionFilterProp="children" showSearch allowClear>
                      {this.state.teacherList}
                    </Select>
                  </div>
                  <div className="mr">
                    <Input style={{ width: "125px" }} placeholder="请输入学生姓名" onChange={(e) => this.handleChange(e, 'student')} allowClear></Input>
                  </div>
                  <div className="mr">
                    <Select placeholder="作业吧周末课切换" style={{ width: 125 }} value={this.state.params.type_id} onChange={e => this.handleChange(e, 'type_id')} optionFilterProp="children" showSearch>
                      <Option value="1">周末课</Option>
                      <Option value="2">作业吧</Option>
                    </Select>
                  </div>
                  <div className="mr">
                    <Input placeholder="请输入学校" onChange={(e) => this.handleChange(e, 'fulltime_school')} allowClear></Input>
                  </div>
                  <div className="mr">
                    <Select placeholder="请选择年级" style={{ width: 125 }} onChange={e => this.handleChange(e, 'grade_id')} optionFilterProp="children" showSearch allowClear>
                      {this.state.grandList}
                    </Select>
                  </div>
                  <div className="mr">
                    <Input style={{ width: "125px" }} placeholder="请输入班级" onChange={(e) => this.handleChange(e, 'fulltime_class')} allowClear></Input>
                  </div>
                  <div className="mr">
                    <Input placeholder="请输入机构班级" onChange={(e) => this.handleChange(e, 'class_name')}></Input>
                  </div>
                  <div className="mr">
                    <Select placeholder="状态" style={{ width: 125 }} defaultValue={this.state.params.status} onChange={e => this.handleChange(e, 'status')} optionFilterProp="children" showSearch allowClear>
                      {/* <Option value="-1">已删除</Option> */}
                      <Option value="0">试听</Option>
                      <Option value="1">在读</Option>
                      <Option value="2">停课</Option>
                      <Option value="3">休学</Option>
                      <Option value="99">已退学</Option>
                    </Select>
                  </div>
                  <div className="mr">
                    <Select placeholder="选择学管" style={{ width: 125 }} onChange={e => this.handleChange(e, 'master_id')} optionFilterProp="children" showSearch allowClear>
                      {this.state.master}
                    </Select>
                  </div>
                  <div className="mr">
                    <Button onClick={e => tableToExcel('mytable')} style={{ marginRight: '10px' }} type="primary">导出当前表格</Button>
                    <Button onClick={this.search} type="primary">查询</Button>
                  </div>
                </div>
            }
            <div style={{ position: 'relative', width: '100%', height: '24px' }}>
              <div style={{ position: 'absolute', right: '0px' }}>
                <Dropdown overlay={menu}>
                  <div>
                    <ApartmentOutlined />
                    <span className='active m-left' style={{ color: '#1890ff' }}>自定义数据展示</span>
                  </div>
                </Dropdown>
              </div>
            </div>
            <Table id='mytable' rowKey={record => record.key} columns={this.state.newColumns} dataSource={this.state.studentList} pagination={false} onChange={this.tableChange} />
            <div className="m-Pleft m-flex">
              <Pagination
                current={this.state.params.page}
                onChange={this.changePage}
                total={this.state.totalCount}
                showTotal={total => `共 ${this.state.totalCount || 0} 条`}
                showSizeChanger
                pageSizeOptions={['10', '20', '30', this.state.totalCount]}
                onShowSizeChange={this.setPageSize}
              />
            </div>
          </div>
        }
        <StudentModal
          subject_list={this.state.subject_list}
          checkCancel={this.checkCancel}
          text={this.state.text}
          visible3={this.state.visible3}
          changePage2={this.changePage2}
          wrongParams={this.state.wrongParams}
          wrongTotal={this.state.wrongTotal}
          wrong_list={this.state.wrong_list}
          columns4={this.state.columns4}
          columns2={this.state.columns2}
          columns3={this.state.columns3}
          active_list={this.state.active_list}
          class_list={this.state.class_list}
          rep_list={this.state.rep_list}
          exam_list={this.state.exam_list}
          remember={this.remember}
          setStudentParams={this.state.setStudentParams}
          studentInfoList={this.state.studentInfoList}
          visible={this.state.visible2}
          callback={this.callback}
          stumOK={this.stumOK}
          visible2Set={this.visible2Set}
          stumCancel={this.stumCancel}
          studentInfo={this.studentInfo}
          teacherList={this.state.teacherList}
          remain_money={this.state.remain_money}
          attachment_list={this.state.studentInfoList.attachment_list}
        ></StudentModal>
      </div>
    );
  }
}
const StudentModal = (props) => {
  const get_chat_paramsModel = {
    student_id: '',
    teacher_id: '',
    type_id: '',
    start_date: '',
    end_date: '',
    page: 1,
    page_size: 10
  }
  const submit_chat_valuesModel = {
    student_id: '',
    type_id: '',
    is_useful: '',
    un_useful_reason: '',
    chat_type: '',
    next_chat_type: '',
    next_chat_date: '',
    next_chat_status: '',
    content: '',
    images: ''
  }
  const submit_uploadFileModel = {
    student_id:'',
    attachment_title:'',
    attachment_url:''
  }

  const [addVisible, setAddVisible] = useState(false)
  const [chatVisible, chatVisibleSet] = useState(false)
  const [examlist, setExamlist] = useState([])
  const [index, setIndex] = useState()
  const [subnamelist, setSubnamelist] = useState([])
  const [scorelist, setScorelist] = useState({})
  const [isfirst, setIsfirst] = useState()
  const [chat_list, chat_listSet] = useState([])
  const [total, totalSet] = useState(0)
  const [get_chat_params, get_chat_paramsSet] = useState(get_chat_paramsModel)
  const [submit_chat_values, submit_chat_valuesSet] = useState(submit_chat_valuesModel)
  const [moneyParams, moneyParamsSet] = useState({})
  const [tabsIndex, tabsIndexSet] = useState('1')
  const [enVisible, enVisibleSet] = useState(false)

  const [planVisible, planVisibleSet] = useState(false)
  const [showPlanVisible, showPlanVisibleSet] = useState(false)
  const [planParams,planParamsSet] = useState({student_id:'',learning_plan:''})
  const [first_class, first_classSet] = useState({
    first_class_teacher_image: '',
    first_class_student_image: ''
  })
  const [sub_list, sub_listSet] = useState([])
  const [class_id, class_idSet] = useState()
  const [rep_img, rep_imgSet] = useState({})

  const [uploadFileParamters, uploadFileParamtersSet] = useState(submit_uploadFileModel)
  const [attachment_list, attachment_listSet] = useState([])
  useEffect(() => {

    if (props.class_list.length > 0) {
      class_idSet(props.class_list[0].class_id)
      first_classSet({
        first_class_teacher_image: rep_img[props.class_list[0].class_id] ? rep_img[props.class_list[0].class_id]['first_class_teacher_image'] : '',
        first_class_student_image: rep_img[props.class_list[0].class_id] ? rep_img[props.class_list[0].class_id]['first_class_student_image'] : ''
      })
    }
    if (props.rep_list) {
      console.log(props.rep_list)
    }
    if (props.studentInfoList.student_model.fulltime_grade) {
      let s = {}
      s.expected_date = props.studentInfoList.student_model.expected_date
      s.expected_money = props.studentInfoList.student_model.expected_money
      s.remark = props.studentInfoList.student_model.remark
      moneyParamsSet(s)
      attachment_listSet(props.studentInfoList.attachment_list);
      exam_get_list({ grade: props.studentInfoList.student_model.fulltime_grade }).then(res => {
        if (props.studentInfoList.exam_list) {
          let list = []
          res.data.list.map(item => {
            let f = true
            props.studentInfoList.exam_list.some(item2 => {
              if (item.id == item2.id) {
                f = false
                return true
              }
            })
            if (f) {
              list.push(item)
            }
          })
          setExamlist(list)
        } else {
          setExamlist(res.data.list)
        }
      })
    }
    get_xiaoguanjia_subject({ is_all: 1 }).then(res => {
      sub_listSet(res.data.list)
    })
  }, [props])

  useEffect(() => {
    if (props.studentInfoList.student_model.fulltime_grade) {
      let data = get_chat_params
      data.student_id = props.studentInfoList.student_model.student_id
      get_chat_record_list(data).then(res => {
        if (res.code === 0) {
          chat_listSet(res.data.list)
          totalSet(res.data.total_count)
        }
      })
    }
  }, [props, get_chat_params.page, chatVisible])

  const remember = (e) => {
    props.remember(e.target.value)
  }

  const stumOK = () => {
    switch (tabsIndex) {
      case '1': props.stumOK(); break;
      case '7': moneySave(); break;
      default: props.visible2Set()
    }
  }

  const stumCancel = () => {
    props.stumCancel()
  }

  const checkCancel = () => {
    props.checkCancel()
  }

  const subjectname = e => {
    const title = props.subject_list.reduce((item, ele) => {
      if (ele.xiaoguanjia_id === e) {
        item = ele.value.split('-')[1]
      }
      return item
    }, '')
    return title
  }

  const typeChange = (e) => {
    let list = examlist[e].xiaoguanjia_subject_ids.split(','), subnamelist = [], score_base_list = []
    list.map(item => {
      props.subject_list.some(item2 => {
        if (item == item2.xiaoguanjia_id) {
          let a = {
            value: item2.value.split('-')[1],
            xiaoguanjia_id: item2.xiaoguanjia_id
          },
            b = {
              score: 0,
              subject_id: item2.xiaoguanjia_id
            }
          subnamelist.push(a)
          score_base_list.push(b)
          return true
        }
      })
    })
    setSubnamelist(subnamelist)
    setScorelist(score_base_list)
    setIndex(e)
  }

  const firstChange = e => {
    setIsfirst(e)
  }

  const scoreChange = (e, index) => {
    let list = JSON.stringify(scorelist)
    let f = JSON.parse(list)
    f[index].score = e.target.value
    setScorelist(f)
  }

  const addVisibleChange = e => {
    setAddVisible(!addVisible)
    setSubnamelist([])
    setScorelist([])
    setIsfirst()
    setIndex()
  }

  const submitScore = () => {
    let data = {
      exam_id: examlist[index].id,
      student_list: [
        {
          is_first_exam: isfirst,
          student_id: props.studentInfoList.student_model.student_id,
          student_name: props.studentInfoList.student_model.name,
          exam_result: scorelist
        }
      ]
    }
    exam_result_entry({ json_str: JSON.stringify(data) }).then(res => {
      if (res.code === 0) {
        message.success("添加/修改成功！")
        addVisibleChange()
        props.studentInfo(props.studentInfoList.student_model.student_id)
      } else {
        message.warn(res.message)
      }
    })
  }

  const chat_recordChange = (e, id) => {
    let s1 = JSON.stringify(submit_chat_values), s = JSON.parse(s1)
    s[id] = e ? typeof (e) == 'undefined' ? '' : typeof (e) == 'object' ? e.target ? e.target.value : moment(e).format('YYYY-MM-DD') : e : ''
    submit_chat_valuesSet(s)
  }

  const chatSubmit = () => {
    let data = submit_chat_values
    data.student_id = props.studentInfoList.student_model.student_id
    submit_chat_record(data).then(res => {
      if (res.code === 0) {
        message.success('添加成功！')
        chatVisibleSet(false)
      } else {
        message.warn(res.message)
      }
    })
  }

  const uploadImage = info => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} 上传成功！`);
      let s1 = JSON.stringify(submit_chat_values), s = JSON.parse(s1)
      s['images'] = info.file.response.data.full_path
      submit_chat_valuesSet(s)
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} 上传失败！`);
    }
  }

  const chatPageSet = e => {
    let s1 = JSON.stringify(get_chat_params), s = JSON.parse(s1)
    s.page = e
    get_chat_paramsSet(s)
  }

  const chat_paramsChange = (e, id) => {
    let s1 = JSON.stringify(get_chat_params), s = JSON.parse(s1)
    s[id] = e ? typeof (e) == 'undefined' ? '' : typeof (e) == 'object' ? e.target ? e.target.value : moment(e).format('YYYY-MM-DD') : e : ''
    get_chat_paramsSet(s)
  }

  const timeChange = e => {
    let s1 = JSON.stringify(get_chat_params), s = JSON.parse(s1)
    s.start_date = moment(e[0]).format('YYYY-MM-DD')
    s.end_date = moment(e[1]).format('YYYY-MM-DD')
    get_chat_paramsSet(s)
  }

  const search = e => {
    if (get_chat_params.page !== 1) {
      let s1 = JSON.stringify(get_chat_params), s = JSON.parse(s1)
      s.page = 1
      get_chat_paramsSet(s)
    } else {
      let data = get_chat_params
      data.student_id = props.studentInfoList.student_model.student_id
      get_chat_record_list(data).then(res => {
        if (res.code === 0) {
          chat_listSet(res.data.list)
          totalSet(res.data.total_count)
        }
      })
    }
  }

  const moneyChange = (e, id) => {
    let s1 = JSON.stringify(moneyParams), s = JSON.parse(s1)
    s[id] = e ? typeof (e) == 'undefined' ? '' : typeof (e) == 'object' ? e.target ? e.target.value : moment(e).format('YYYY-MM-DD') : e : ''
    moneyParamsSet(s)
  }

  const moneySave = e => {
    let data = moneyParams
    data.student_id = props.studentInfoList.student_model.student_id
    set_continue(data).then(res => {
      if (res.code === 0) {
        message.success('保存成功！')
        props.visible2Set()
      } else {
        message.warn(res.message)
      }
    })
  }

  const tabsChange = e => {
    tabsIndexSet(e)
  }

  const uploadenimg = (info, id) => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} 上传成功！`);
      let s1 = JSON.stringify(first_class), s = JSON.parse(s1)
      s[id] = info.file.response.data.full_path
      first_classSet(s)
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} 上传失败！`);
    }
  }

  const submit = e => {
    let data = first_class
    data['xiaoguanjia_class_id'] = class_id
    data['xiaoguanjia_student_id'] = props.studentInfoList.student_model.student_id
    console.log(data)
    if (!data.xiaoguanjia_class_id) {
      message.warn('第一次课程未选择！')
      return
    }
    set_rep_data(data).then(res => {
      if (res.code == 0) {
        message.success('提交成功！')
        enVisibleSet(false)
        first_classSet({
          first_class_teacher_image: '',
          first_class_student_image: ''
        })
      } else {
        message.error(res.message)
      }
    })
  }

  const showREP = e => {
    enVisibleSet(true)
    get_rep_data({
      student_id: props.studentInfoList.student_model.student_id
    }).then(res => {
      let json = {}
      res.data.list.map((item, index) => {
        json[item.class_id] = {}
        json[item.class_id]['first_class_student_image'] = item.first_class_student_image
        json[item.class_id]['first_class_teacher_image'] = item.first_class_teacher_image
      })
      rep_imgSet(json)
    })
  }
  
  const learning_planChange = (e, pram) => {
    let s1 = JSON.stringify(planParams), s = JSON.parse(s1)
    s[pram] = e ? typeof (e) == 'undefined' ? '' : typeof (e) == 'object' ? e.target ? e.target.value : moment(e).format('YYYY-MM-DD') : e : ''
    planParamsSet(s)
  }
  const showPlan = e => {
    showPlanVisibleSet(true)
      get_student_detail({
        student_id: props.studentInfoList.student_model.student_id
      }).then(res => {
        props.studentInfoList.student_model.learning_plan =  res.data.student_model.learning_plan
        planParamsSet({learning_plan : res.data.student_model.learning_plan})
      })
  }

  const editPlan = e => {
    planVisibleSet(true)
      get_student_detail({
        student_id: props.studentInfoList.student_model.student_id
      }).then(res => {
         props.studentInfoList.student_model.learning_plan =  res.data.student_model.learning_plan
         planParamsSet({learning_plan : res.data.student_model.learning_plan})
      })
  }


  const submitPlan = e => {
    let data = planParams
    data['student_id'] = props.studentInfoList.student_model.student_id
    if (!data.learning_plan) {
      message.warn('学习规划内容不能为空！')
      return
    }
    set_learning_plan(data).then(res => {
      if (res.code == 0) {
        message.success('提交成功！')
        planVisibleSet(false)
      } else {
        message.error(res.message)
      }
    })
  }

  const uploadFile = info => {
   
    if (info.file.status === 'done') {

      if(info.file.response.code == '0'){
        let s1 = JSON.stringify(uploadFileParamters), s = JSON.parse(s1)
        s['student_id'] = props.studentInfoList.student_model.student_id
        s['attachment_title'] = info.file.name
        s['attachment_url'] = info.file.response.data.full_path
        uploadFileParamtersSet(s);
        //submit_chat_valuesSet(s)
        set_attachment(s).then(res => {
          if (res.code == 0) {
            message.success('上传成功！')
            //刷新列表
            queryFileList()
          } else {
            message.error(res.message)
          }
      });
      }else{
        if(info.file.response.code == 106){
          message.error(`不支持此文件类型！`);
        }else{
          message.success(`${info.file.name} 上传失败！`);
        }
      
      }

    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} 上传失败！`);
    }
  }

  const showFileInfo = e => {
    window.location.href = e;
  }
  const deleteFileInfo = info => {

    confirm({
      title: '确定要删除此文件吗?',
      icon: <ExclamationCircleOutlined />,
      okText: '确定',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
            del_attachment({attachment_id:info}).then(res=>{
              if (res.code == 1) {
                message.success('删除成功！')
              queryFileList()
              } else {
                message.error(res.message)
              }
      
          })
      },
      onCancel() {
         
      },
    });



  }

  const queryFileList = info => {
     //刷新页面
     get_student_detail({
      student_id: props.studentInfoList.student_model.student_id
    }).then(res => {
      attachment_listSet(res.data.attachment_list)
    })
  }
  

  return (
    <div>
      <Modal
        width={1000}
        title="学员信息"
        visible={props.visible}
        onOk={stumOK}
        onCancel={stumCancel}
        cancelText="取消"
        okText="确认"
      >
        <Tabs defaultActiveKey="1" onChange={tabsChange}>
          <TabPane tab="基本信息" key="1">
            <Descriptions title="学生信息">
              <Descriptions.Item label="姓名">{props.studentInfoList.student_model.name}</Descriptions.Item>
              <Descriptions.Item label="年级">{props.studentInfoList.student_model.fulltime_grade}</Descriptions.Item>
              <Descriptions.Item label="班级">{props.studentInfoList.student_model.fulltime_class}</Descriptions.Item>
              <Descriptions.Item label="学校">{props.studentInfoList.student_model.fulltime_school}</Descriptions.Item>
              <Descriptions.Item label="学管">{props.studentInfoList.student_model.master}</Descriptions.Item>
            </Descriptions>
            <span>记忆容量：</span>
            <Input placeholder="填写记忆容量" style={{ width: 200 }} value={props.setStudentParams.memory_capacity} onChange={remember} />
          </TabPane>
          <TabPane tab="班级信息" key="2">
            <Table rowKey={record => record.key} columns={props.columns3} dataSource={props.class_list} pagination={false} scroll={{ y: 300 }} />
          </TabPane>
          <TabPane tab="活动信息" key="3">
            <Table rowKey={record => record.key} columns={props.columns2} dataSource={props.active_list} pagination={false} scroll={{ y: 300 }} />
          </TabPane>
          <TabPane tab="成绩信息" key="4">
            <Button onClick={addVisibleChange}>添加成绩</Button>
            {props.exam_list.map((res, index) =>
              <Descriptions title={res.name} key={index}>
                {res.subject_exam_list.map((ele, l2) =>
                  <Descriptions.Item key={l2} label={subjectname(ele.xiaoguanjia_subject_id)}>{ele.score === 'null' ? '' : ele.score}</Descriptions.Item>
                )}
              </Descriptions>
            )}
            <Modal
              width={800}
              title="成绩添加"
              visible={addVisible}
              onOk={submitScore}
              onCancel={addVisibleChange}
              cancelText="取消"
              okText="确认"
            >
              <div className="m-flex m-bottom" style={{ flexWrap: 'nowrap' }}>
                <span className="m-row" style={{ textAlign: 'right' }}>考试名称：</span>
                <Select placeholder="请选择考试" onChange={typeChange} style={{ width: '400px' }} value={typeof (index) == 'number' ? index : undefined} optionFilterProp="children" showSearch>
                  {
                    examlist.map((item, index) => {
                      return (
                        <Option value={index} key={index}>{item.name}</Option>
                      )
                    })
                  }
                </Select>
              </div>
              {
                subnamelist.length > 0 ?
                  <div>
                    <div className="m-flex m-bottom" style={{ flexWrap: 'nowrap' }}>
                      <span className="m-row" style={{ textAlign: 'right' }}>入学前后：</span>
                      <Select placeholder="是否是入学前成绩" onChange={firstChange} style={{ width: '400px' }} value={isfirst ? isfirst : undefined}>
                        <Option value={'-1'}>入学前</Option>
                        <Option value={'1'}>入学后</Option>
                      </Select>
                    </div>
                    <div className="m-flex m-bottom" style={{ flexWrap: 'nowrap' }}>
                      <span className="m-row" style={{ textAlign: 'right', flexShrink: '0' }}>考试项目：</span>
                      <Row style={{ width: '100%' }}>
                        {
                          subnamelist.map((item, index) => {
                            return (
                              <Col span={6} key={item.xiaoguanjia_id} style={{ display: "flex", alignItems: 'center', marginBottom: '10px' }}>
                                <div style={{ flexShrink: '0' }}>{item.value}：</div>
                                <span style={{ flexShrink: '0' }}><Input type='number' style={{ width: '50px', lineHeight: '21px', height: '21px', flexShrink: '0' }} onChange={e => scoreChange(e, index)} value={scorelist.length > 0 ? scorelist[index].score : ''} />分</span>
                              </Col>
                            )
                          })
                        }
                      </Row>
                    </div>
                  </div>
                  :
                  ''
              }

            </Modal>
          </TabPane>
          <TabPane tab="错题信息" key="5">
            <Table rowKey={record => record.key} columns={props.columns4} dataSource={props.wrong_list} pagination={false} />
            <Pagination className="m-Pleft" current={props.wrongParams.page} defaultPageSize={props.wrongParams.page_size} onChange={props.changePage2} total={props.wrongTotal} />
            <Modal
              title="错题详情"
              visible={props.visible3}
              onOk={checkCancel}
              onCancel={checkCancel}
              okText="确认"
              cancelText="关闭"
              width={650}
            >
              <span style={{ width: '100%' }} dangerouslySetInnerHTML={{
                __html: props.text
              }}></span>
            </Modal>
          </TabPane>
          <TabPane tab="教师沟通" key="6">
            <div className="flex">
              {/* <div className="mr">
              <Select placeholder="请选择老师" style={{ width: 180 }} onChange={e => chat_paramsChange(e, 'teacher_id')} optionFilterProp="children" showSearch allowClear>
                {props.teacherList}
              </Select>
            </div> */}
              <div className="mr">
                <Select placeholder="请选择类型" style={{ width: 180 }} onChange={e => chat_paramsChange(e, 'type_id')} optionFilterProp="children" showSearch allowClear>
                  <Option value='1'>售前</Option>
                  <Option value='2'>售后</Option>
                </Select>
              </div>
              <div className="mr">
                <RangePicker onChange={timeChange} />
              </div>
              <div className="mr">
                <Button onClick={search} type="primary">查询</Button>
              </div>
              <div className="mr">
                <Button onClick={e => chatVisibleSet(true)}>添加</Button>
              </div>
            </div>
            <div>
              {
                chat_list.map((item, index) => (
                  <div key={index}>
                    <Descriptions title={item.date}>
                      <Descriptions.Item label="沟通老师">{item.teacher}</Descriptions.Item>
                      <Descriptions.Item label="沟通类型">{item.type_id === '1' ? '售前' : '售后'}</Descriptions.Item>
                      <Descriptions.Item label="沟通情况">{item.is_useful === '1' ? '有效沟通' : '无效沟通'}</Descriptions.Item>
                      {
                        item.is_useful === '2' ?
                          <Descriptions.Item label="无效沟通原因">
                            {
                              item.un_useful_reason === '1' ?
                                '电话未接' :
                                item.un_useful_reason === '2' ?
                                  '无法接通' :
                                  item.un_useful_reason === '3' ?
                                    '占线' :
                                    item.un_useful_reason === '4' ?
                                      '关机' :
                                      item.un_useful_reason === '5' ?
                                        '停机' : '空号'
                            }
                          </Descriptions.Item>
                          :
                          ''
                      }
                      <Descriptions.Item label="沟通类型">
                        {
                          item.chat_type === '1' ? '电话' :
                            item.chat_type === '1' ? 'QQ' :
                              item.chat_type === '1' ? '微信' :
                                '面谈'
                        }
                      </Descriptions.Item>
                      <Descriptions.Item label="下次沟通类型">
                        {
                          item.next_chat_type === '1' ? '电话' :
                            item.next_chat_type === '1' ? 'QQ' :
                              item.next_chat_type === '1' ? '微信' :
                                '面谈'
                        }
                      </Descriptions.Item>
                      <Descriptions.Item label="下次沟通状态">
                        {
                          item.next_chat_status === '-1' ? '待定' : '确定'
                        }
                      </Descriptions.Item>
                      {
                        item.next_chat_status === '1' ?
                          <Descriptions.Item label="下次沟通日期">
                            {item.next_chat_date}
                          </Descriptions.Item>
                          :
                          ''
                      }
                      {
                        item.images ?
                          <Descriptions.Item label="沟通截图">
                            <img src={item.images} alt="" style={{ maxWidth: '100px', maxHeight: '200px' }} />
                          </Descriptions.Item>
                          :
                          ''
                      }
                    </Descriptions>
                  </div>
                ))
              }
            </div>
            <Pagination className="m-Pleft" current={get_chat_params.page} defaultPageSize={get_chat_params.page_size} onChange={chatPageSet} total={total} />
            <Modal
              title="添加"
              visible={chatVisible}
              onOk={chatSubmit}
              onCancel={e => chatVisibleSet(false)}
              okText="确认"
              cancelText="关闭"
              width={650}
            >
              <Row>
                <Col className="ant-col-css" span={8}>
                  <span>沟通类型：</span>
                </Col>
                <Col className="ant-col-css" span={16}>
                  <Radio.Group onChange={e => chat_recordChange(e, 'type_id')}>
                    <Radio value={'1'}>售前</Radio>
                    <Radio value={'2'}>售后</Radio>
                  </Radio.Group>
                </Col>
                <Col className="ant-col-css" span={8}>
                  <span>沟通情况：</span>
                </Col>
                <Col className="ant-col-css" span={16}>
                  <Radio.Group onChange={e => chat_recordChange(e, 'is_useful')}>
                    <Radio value={'1'}>有效沟通</Radio>
                    <Radio value={'2'}>无效沟通</Radio>
                  </Radio.Group>
                </Col>
                {
                  submit_chat_values.is_useful === '2' ?
                    <>
                      <Col className="ant-col-css" span={8}>
                        <span>无效沟通原因：</span>
                      </Col>
                      <Col className="ant-col-css" span={16}>
                        <Select style={{ width: '100%' }} placeholder='请选择' onChange={e => chat_recordChange(e, 'un_useful_reason')} allowClear>
                          <Option value={'1'}>电话未接</Option>
                          <Option value={'2'}>无法接通</Option>
                          <Option value={'3'}>占线</Option>
                          <Option value={'4'}>关机</Option>
                          <Option value={'5'}>停机</Option>
                          <Option value={'6'}>空号</Option>
                        </Select>
                      </Col>
                    </>
                    :
                    ''
                }
                <Col className="ant-col-css" span={8}>
                  <span>沟通类型：</span>
                </Col>
                <Col className="ant-col-css" span={16}>
                  <Select style={{ width: '100%' }} placeholder='请选择' onChange={e => chat_recordChange(e, 'chat_type')} allowClear>
                    <Option value={'1'}>电话</Option>
                    <Option value={'2'}>QQ</Option>
                    <Option value={'3'}>微信</Option>
                    <Option value={'4'}>面谈</Option>
                  </Select>
                </Col>
                <Col className="ant-col-css" span={8}>
                  <span>下次沟通类型：</span>
                </Col>
                <Col className="ant-col-css" span={16}>
                  <Select style={{ width: '100%' }} placeholder='请选择' onChange={e => chat_recordChange(e, 'next_chat_type')} allowClear>
                    <Option value={'1'}>电话</Option>
                    <Option value={'2'}>QQ</Option>
                    <Option value={'3'}>微信</Option>
                    <Option value={'4'}>面谈</Option>
                  </Select>
                </Col>
                <Col className="ant-col-css" span={8}>
                  <span>下次沟通状态：</span>
                </Col>
                <Col className="ant-col-css" span={16}>
                  <Select style={{ width: '100%' }} placeholder='请选择' onChange={e => chat_recordChange(e, 'next_chat_status')} allowClear>
                    <Option value={'-1'}>待定</Option>
                    <Option value={'1'}>已确定</Option>
                  </Select>
                </Col>
                {
                  submit_chat_values.next_chat_status === '1' ?
                    <>
                      <Col className="ant-col-css" span={8}>
                        <span>下次沟通日期：</span>
                      </Col>
                      <Col className="ant-col-css" span={16}>
                        <DatePicker onChange={e => chat_recordChange(e, 'next_chat_date')} style={{ width: '100%' }} />
                      </Col>
                    </>
                    :
                    ''
                }
                <Col className="ant-col-css" span={8}>
                  <span>沟通内容：</span>
                </Col>
                <Col className="ant-col-css" span={16}>
                  <TextArea
                    placeholder="请输入"
                    autoSize={{ minRows: 2 }}
                    onChange={e => chat_recordChange(e, 'content')}
                    allowClear
                  />
                </Col>
                <Col className="ant-col-css" span={8}>
                  <span>图片：</span>
                </Col>
                <Col className="ant-col-css" span={16}>
                  <Upload {...propsSet} onChange={uploadImage}>
                    <Button size='small'>
                      <UploadOutlined />上传</Button>
                  </Upload>
                </Col>
              </Row>
            </Modal>
          </TabPane>
          <TabPane tab="续费相关" key="7">
            <Descriptions title="续费详情">
              <Descriptions.Item label="剩余学费">{props.remain_money}</Descriptions.Item>
              <Descriptions.Item label="预计成交额">
                <Input placeholder="请输入" value={moneyParams.expected_money} style={{ width: '80%' }} onChange={e => moneyChange(e, 'expected_money')} />
              </Descriptions.Item>
              <Descriptions.Item label="预计成交时间">
                <DatePicker value={moneyParams.expected_date ? moment(moneyParams.expected_date) : ''} style={{ width: '80%' }} onChange={e => moneyChange(e, 'expected_date')} />
              </Descriptions.Item>
              <Descriptions.Item label="备注">
                <Input placeholder="请输入" value={moneyParams.remark} style={{ width: '80%' }} onChange={e => moneyChange(e, 'remark')} />
              </Descriptions.Item>
              <Descriptions.Item label=""></Descriptions.Item>
              <Descriptions.Item label=""></Descriptions.Item>
            </Descriptions>
          </TabPane>
          <TabPane tab="学管沟通" key="8">
            <Descriptions title="沟通详情">
              <Descriptions.Item label="沟通次数">{props.studentInfoList.student_model.chat_count}</Descriptions.Item>
              <Descriptions.Item label="最后沟通时间">{props.studentInfoList.student_model.last_chat_time}</Descriptions.Item>
              <Descriptions.Item label=""></Descriptions.Item>
              <Descriptions.Item label="最后沟通内容">{props.studentInfoList.student_model.last_chat_content}</Descriptions.Item>
            </Descriptions>
          </TabPane>
          <TabPane tab="REP学情" key="9">
            <div className="my_table">
              <table width="100%">
                <thead>
                  <tr>
                    <th>服务阶段</th>
                    <th>服务类别</th>
                    <th>平台支持</th>
                  </tr>
                </thead>
                <tbody>
                <tr>
                    <td>学习规划</td>
                    <td>学习管理长期规划</td>
                    <td>
                      <span style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' }} onClick={editPlan}>编辑</span>
                      <span style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' ,paddingLeft:'10px'}} onClick={showPlan}>查看</span>
                    </td>
                  </tr>
                  <tr>
                    <td  rowSpan="1">首次课</td>
                    <td>是否满意</td>
                    <td>
                      <div style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' }} onClick={showREP}>附件</div>
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan="5">每次课</td>
                    <td>课前提醒</td>
                    <td>系统+学管服务</td>
                  </tr>
                  <tr>
                    <td>课时确认</td>
                    <td>群内服务</td>
                  </tr>
                  <tr>
                    <td>课后点评</td>
                    <td>系统+群内服务</td>
                  </tr>
                  <tr>
                    <td>答疑服务</td>
                    <td>系统+群内服务</td>
                  </tr>
                  <tr>
                    <td>督学服务</td>
                    <td>面授</td>
                  </tr>
                  <tr>
                    <td rowSpan="2">每月</td>
                    <td>阶段性测试/试卷分析</td>
                    <td>面授</td>
                  </tr>
                  <tr>
                    <td>教学计划</td>
                    <td>群内服务</td>
                  </tr>
                  <tr>
                    <td>大考</td>
                    <td>家长会/试卷分析</td>
                    <td>面授</td>
                  </tr>
                  <tr>
                    <td>公开课</td>
                    <td>好习惯养成开营仪式</td>
                    <td>面授</td>
                  </tr>
                  <tr>
                    <td rowSpan="4">学管服务</td>
                    <td>调课请假</td>
                    <td>群内服务+电话</td>
                  </tr>
                  <tr>
                    <td>活动讲座</td>
                    <td>群内服务+电话</td>
                  </tr>
                  <tr>
                    <td>课程咨询</td>
                    <td>群内服务+电话</td>
                  </tr>
                  <tr>
                    <td>课程结转</td>
                    <td>群内服务+电话</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </TabPane>
          <TabPane tab="附件" key="10" onTabClick={queryFileList}>
            <div className="my_table">
              <div className="upfile" > 
                   <Upload {...propsUpfilesSet} onChange={e => uploadFile(e, 'uploadFiles')}>
                        <Button type="primary" icon ={<UploadOutlined/>}>上传附件</Button>
                    </Upload>
              </div>
              <table width="100%" style={{textAlign: 'center'}}>
                <thead>
                  <tr>
                    <th >文件名称</th>
                    <th>上传人</th>
                    <th>上传日期</th>
                    <th>操作</th>
                  </tr>
                </thead>

                <tbody>
                  {
                     attachment_list.map((item, index) => (
                      <tr  key={item.id}>
                      <td>{item.attachment_title}</td>
                      <td>{item.teacher_name}</td>
                      <td>{item.add_time}</td>
                      <td>
                        <span style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' }} onClick={e => deleteFileInfo(item.id)}>删除</span>
                        <span style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' ,paddingLeft: '5px'}} onClick={e =>(showFileInfo(item.attachment_url))}>查看</span>
                      </td>
                    </tr> 
                        ))
                  }
                </tbody>
              </table>
            </div>
          </TabPane>
          
        </Tabs>
      </Modal>
      <Modal
        width={1000}
        title="首次课附件信息"
        visible={enVisible}
        onOk={submit}
        onCancel={() => {
          enVisibleSet(false);
          first_classSet({
            first_class_teacher_image: '',
            first_class_student_image: ''
          })
        }}
        cancelText="取消"
        okText="确认"
      >
        <div className={'my-tabs'}>
          <Tabs
            tabPosition={"left"}
            onTabClick={e => {
              class_idSet(e);
              first_classSet({
                first_class_teacher_image: rep_img[e] ? rep_img[e]['first_class_teacher_image'] : '',
                first_class_student_image: rep_img[e] ? rep_img[e]['first_class_student_image'] : ''
              })
            }}
            activeKey={class_id}
          >
            {
              props.class_list.map((item, index) => (
                <TabPane tab={item.indate.slice(2, 4) + '年_' + item.shift_name} key={item.class_id}>
                  {/* <TabPane tab={item.name} key={item.class_id}> */}
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <div style={{ width: '100%', textAlign: 'center' }}>
                        老师评价表
                        <Upload {...propsSet1} onChange={e => uploadenimg(e, 'first_class_teacher_image')}>
                          <UploadOutlined style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' }} />
                        </Upload>
                      </div>
                      <Image width={264} src={first_class.first_class_teacher_image ? first_class.first_class_teacher_image : rep_img[item.class_id] ? rep_img[item.class_id]['first_class_teacher_image'] : ''} alt="" />
                    </div>
                    <div style={{ width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <div style={{ width: '100%', textAlign: 'center' }}>
                        学生评价表
                        <Upload {...propsSet1} onChange={e => uploadenimg(e, 'first_class_student_image')}>
                          <UploadOutlined style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' }} />
                        </Upload>
                      </div>
                      <Image width={264} src={first_class.first_class_student_image ? first_class.first_class_student_image : rep_img[item.class_id] ? rep_img[item.class_id]['first_class_student_image'] : ''} alt="" />
                    </div>
                  </div>
                </TabPane>
              ))
            }
          </Tabs>
        </div>
      </Modal>
      <Modal  
        width={1000}
        title="学习规划"
        visible={planVisible}
        onCancel={() => {
         planVisibleSet(false);
          planParamsSet({
            learning_plan: "",
          })
        }} 
        onOk={submitPlan} 
        cancelText='关闭'
        okText='保存'
      >
        <div className={'my-tabs'}>
            <TextArea
                    value={planParams.learning_plan}
                    autoSize={{ maxRows: 15,minRows:15 }}
                    onChange= {e => learning_planChange(e,'learning_plan')}
                    allowClear
                  >
                  </TextArea>
        </div>
      </Modal>
      <Modal  
        width={1000}
        title="查看学习规划"
        visible={showPlanVisible}
        onCancel={() => {
          showPlanVisibleSet(false);
          planParamsSet({
            learning_plan: "",
          })
        }} 
        onOk={() => {
          showPlanVisibleSet(false);
          planParamsSet({
            learning_plan: "",
          })
        }} 
        footer
        
      >
        <div className={'my-tabs'}>
            <TextArea
                    value={planParams.learning_plan}
                    autoSize={{ maxRows: 15,minRows:15 }}
                  />
        </div>
      </Modal>


    </div>
  )
}
const ListT = (props) => {
  const total = props.data.map((res, index) =>
    <MathJax.Context
      key={index}
      input='tex'
      onError={(MathJax, error) => {
        console.warn(error);
        console.log("Encountered a MathJax error, re-attempting a typeset!");
        MathJax.Hub.Queue(
          MathJax.Hub.Typeset()
        );
      }}
      // script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.0/MathJax.js"
      options={{
        messageStyle: 'none',
        extensions: ['tex2jax.js'],
        jax: ['input/TeX', 'output/HTML-CSS'],
        tex2jax: {
          inlineMath: [['$', '$'], ['\\(', '\\)']],
          displayMath: [['$$', '$$'], ['\\[', '\\]']],
          processEscapes: true,
        },
        TeX: {
          extensions: ['AMSmath.js', 'AMSsymbols.js', 'noErrors.js', 'noUndefined.js']
        }
      }}>
      <div className="listT" onClick={() => { props.fun(res.ques_id) }} >
        <div className="know-name-m" >
          <span className="know-name">{index + 1 + '、' + res.paper_name}</span>
          <MathJax.Html html={res.ques_content + res.ques_options} />
        </div>
        <Divider dashed />
        <Knowlage collect={props.collect} paper_name={res.paper_name} moveOrAdd={props.moveOrAdd} id={res.ques_id} ques_number={res.ques_number} ques_difficulty_text={res.ques_difficulty_text} index={index} ques_knowledge_name={res.ques_knowledge_name} btn={props.addQuestoin} btn2={props.deleteQuestoin}></Knowlage>
        <div className={props.appear === res.ques_id ? '' : 'question-active'} >
          <Divider dashed />
          <div>
            <p className="line-shu">答案</p>
            <MathJax.Html html={res.ques_answer} />

          </div>
          <div>
            <p className="line-shu">解析</p>
            <MathJax.Html html={res.ques_analysis} />
          </div>
        </div>
      </div>
    </MathJax.Context>
  )
  return (
    <div>
      {total ? total : ''}
    </div>
  )
}
const Knowlage = (props) => {
  return (
    <div className="shop-btn">
      <div className="know-title-div">
        <p className="know-title">
          知识点:
          <span>{props.ques_knowledge_name}</span>
        </p>
        <p className="know-title">
          难度:
          <span>{props.ques_difficulty_text}</span>
        </p>
        <p className="know-title">
          组卷:
          <span>{props.ques_number}次</span>
        </p>
      </div>
      {/* <div className="m-flex">
        <Button className="z-index" type='primary' onClick={(e) => props.collect(e, props.paper_name, props.id)}>收藏</Button>
        <div className="m-left"></div>
        <Button className="z-index" type={props.moveOrAdd(props.id) ? 'danger' : 'primary'} onClick={props.moveOrAdd(props.id) ? (e) => props.btn2(e, props.id) : (e) => props.btn(e, props.id)}>{props.moveOrAdd(props.id) ? '移除试题篮' : '加入试题篮'}</Button>
      </div> */}
    </div>
  )
}
export default index;