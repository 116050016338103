import React, { Component } from 'react';
import { Route, Link, Switch, withRouter } from 'react-router-dom'
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Layout, Menu, Avatar, Dropdown, message, Modal, Input, Select } from 'antd';
import { logout, change_password_byself } from '../axios/http'

import {
  AlertOutlined,
  AppstoreOutlined,
  BookOutlined,
  BorderBottomOutlined,
  ContainerOutlined,
  DatabaseOutlined,
  EditTwoTone,
  HomeOutlined,
  LogoutOutlined,
  MailOutlined,
  PieChartOutlined,
  RadarChartOutlined,
  SettingOutlined,
  SlidersOutlined,
  UserOutlined,
  SnippetsOutlined,
  FileDoneOutlined
} from '@ant-design/icons';

import Tk from './tk/index'
import Tksystem from './tk/index2'
import Tkown from './tk/index3'
import Tkmine from './tk/index4'
import Zujuan from './zujuan/zujuan'
import Recommended from './resourceCenter/recommended/index/index'
import RecommendedReal from './resourceCenter/recommended/real/real'
import RecommendedShare from './resourceCenter/recommended/share/share'
import RecommendedJigousiku from './resourceCenter/recommended/jigousiku/jigousiku'
import Myresources from './resourceCenter/myResources/index/index'
import MyresourcesWenjianjia from './resourceCenter/myResources/wenjianku/wenjianku'
import Myzujuan from './resourceCenter/myResources/zujuanList/index'
import BK from './bk/bk'
import Prograss from './bk/prograss'
import Kj from './bk/kejian'
import Quanxian from './quanxian/quanxian'
import Jiaoyanzu from './quanxian/jiaoyanzuquanxian'
import CP from './cp'
import ZY from './zy'
import Tkquestion from './tk/braftEditor'
import Test from '../commont/tset/test'
import Zj from './zhangjie'
import ErrorSet from './wrongQuestions/errorSet/index'
import ExerciseBook from './wrongQuestions/exerciseBook/index'
import ExerciseBookInfo from './wrongQuestions/exerciseBook/info'
import ReportForm from './wrongQuestions/reportForm/index'
import CreatNewClass from './quanxian/creatnewclass'
import ZujuanError from '../commont/wrongQuestions/errorSet/zujuan/zujuan'
import StudentMine from '../commont/myStudent'
import EnteringQuestion from '../commont/myStudent/Enterquestion'
import AllExamRecord from '../commont/myStudent/AllExamRecord'
import Teacherdatareport from '../commont/teacherdatareport/teacherdatareport'
import HomePage from '../commont/homePage/myself'
import OtherTable from '../commont/othertable/index'
import Complaint from '../commont/complaint/complaint'
import ActivityManagement from './activityManagement/index'
import RegistrationActivities from './registrationActivities/index'
import Behavioraldata from './myStudent/Behavioraldata'
import Syllabuslist from './syllabus/syllabuslist'
import Setclassroom from './setcourse/setclassroom'
import Setteacherstyle from './setcourse/setteacherstyle'
import Setstudents from './setcourse/setstudents'
import Reportlist from './registrationActivities/component/reportlist'
import Reportdetail from './registrationActivities/component/reportdetail'
import yanuo_excel from './yanuo_excel'
import ruidi_excel from './ruidi_excel'
import Student_report from './student_report/student_report'

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;
const { Option } = Select
class main extends Component {
  constructor(opt) {
    super(opt)
    this.state = {
      height: '',
      collapsed: false,
      visible: false,
      newPassword: '',
      oldPassword: '',
      company_list: JSON.parse(localStorage.getItem('company_list')),
      company_name: localStorage.getItem('company'),
      imgKey: true,
      isChangepwd: false,
      company_id: ''
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleSize);
    this.handleSize()
    if (localStorage.getItem('isChangepwd') == 'true') {
      this.setState({
        visible: true,
        isChangepwd: localStorage.getItem('isChangepwd')
      })
    }
    localStorage.setItem('enter', '')
    const children = this.state.company_list.map((res, index) => {
      return <Option key={res.company} value={res.company} >{res.company}</Option>
    })
    if (document.location.href.split('/#/')[0] === 'http://jiaoxue.readyart.cn' || localStorage.getItem('company_id') === '6') {
      localStorage.setItem('company_id', '6')
      localStorage.setItem('company', '瑞迪艺术科技城校区')
      this.setState({
        imgKey: false,
        company_name: '瑞迪艺术科技城校区',
        company_id: 6
      })
      console.log(this.state.imgKey)
    }
    this.setState({
      children
    })
  }

  componentWillUnmount() {
    // 移除监听事件
    window.removeEventListener('resize', this.handleSize);

  }

  // 自适应浏览器的高度
  handleSize = () => {
    this.setState({
      height: document.body.clientHeight,
    });
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  handleClick = (e) => { }

  logOut = () => {
    logout().then(res => {
      localStorage.setItem("token", '')
      localStorage.setItem("username", '')
      localStorage.setItem("teacher_type", '')
      localStorage.setItem("permission", '')
      this.props.history.replace("/")
      localStorage.setItem('enter', '')
      message.success(res.data.message)
    })
  }

  editOwnPassWord = () => {
    const params = {
      old_password: this.state.oldPassword,
      new_password: this.state.newPassword
    }
    // let a = /^(?=.*?[A-Z])(?=.*?[0-9]).{6,6}$/,
    //     b = /^(?=.*?[a-z])(?=.*?[0-9]).{6,6}$/
    if (params.new_password == '123456' || params.new_password.length < 6) {
      message.warn('密码过于简单，请设置至少大于6位!')
      return
    }
    localStorage.setItem('isChangepwd', false)
    change_password_byself(params).then(res => {
      if (res.code === 0) {
        message.success('修改成功')
        this.setState({
          visible: false,
          newPassword: '',
          oldPassword: ''
        })
      } else {
        message.error('修改失败')
      }
    })
  }

  showModal = () => {
    this.setState({
      visible: true
    })
  }

  cancel = () => {
    if (this.state.isChangepwd) {
      message.warn("请先修改密码！")
      return
    }
    this.setState({
      visible: false,
      newPassword: '',
      oldPassword: ''
    })
  }

  changePassword = (e, type) => {
    if (type === 'newPassword') {
      this.setState({
        newPassword: e.target.value
      })
    } else {
      this.setState({
        oldPassword: e.target.value
      })
    }
  }

  selsectCompany = e => {
    const company_list = this.state.company_list
    if (e) {
      company_list.forEach((res) => {
        if (res.company === e) {
          localStorage.setItem('company_id', res.company_id)
          localStorage.setItem('company', res.company)
          this.setState({
            company_id: res.company_id
          })
          window.location.reload()
        }
      })
      this.setState({
        company_name: e
      })
    }
  }

  ImgData = () => {
    window.open('http://zt.zhongxiaoxue.cn/node/build/#/dataImg2')
  }

  homepage = () => {
    this.props.history.replace(`/main/homepage?id=${localStorage.getItem("id")}`)
  }

  render() {

    const menu = (
      <Menu>
        <Menu.Item onClick={this.homepage}><HomeOutlined />首页</Menu.Item>
        <Menu.Item onClick={this.showModal}><EditTwoTone />修改密码</Menu.Item>
        <Menu.Item onClick={this.logOut}><LogoutOutlined twoToneColor='#f40' />退出登录</Menu.Item>
      </Menu>
    )

    return (
      <Layout style={{ height: this.state.height }}>
        <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
          {
            this.state.imgKey ?
              <div className="logoicon active" onClick={this.homepage}></div>
              :
              <div className="logoicon2 active" onClick={this.homepage}></div>
          }

          {
            localStorage.getItem('teacher_type') == 6 ?
              <Menu onClick={this.handleClick} mode="vertical" theme="dark">
                <SubMenu
                  key="sub2"
                  title={
                    <span>
                      <AppstoreOutlined />
                      <span>备课管理</span>
                    </span>
                  }
                >
                  <Menu.Item key="5">
                    <Link to="/main/bk">课程表</Link>
                  </Menu.Item>
                </SubMenu>
              </Menu>
              :
              <Menu onClick={this.handleClick} mode="vertical" theme="dark">
                <Menu.Item key="232">
                  <PieChartOutlined />
                  <span>我的学员</span>
                  <Link to="/main/StudentMine"></Link>
                </Menu.Item>
                <SubMenu
                  key="sub7"
                  title={
                    <span>
                      <SlidersOutlined />
                      <span>目标管理</span>
                    </span>
                  }
                >
                  <Menu.Item key="2322">
                    <BorderBottomOutlined />
                    <span>成绩录入</span>
                    <Link to="/main/EnteringQuestion"></Link>
                  </Menu.Item>
                  <Menu.Item key="2323">
                    <BorderBottomOutlined />
                    <span>全科成绩</span>
                    <Link to="/main/AllExamRecord"></Link>
                  </Menu.Item>
                  <Menu.Item key="2324">
                    <BorderBottomOutlined />
                    <span>行为数据</span>
                    <Link to="/main/Behavioraldata"></Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  key="sub1"
                  title={
                    <span>
                      <MailOutlined />
                      <span>自学流程</span>
                    </span>
                  }
                >
                  <Menu.Item key="1">
                    <Link to="/main">知识点</Link>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <Link to="/main/tk/system">真题试卷</Link>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <Link to="/main/tk/own">机构私库</Link>
                  </Menu.Item>
                  <Menu.Item key="4">
                    <Link to="/main/tk/mine">我的题目</Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  key="sub22"
                  title={
                    <span>
                      <RadarChartOutlined />
                      <span>错题管理</span>
                    </span>
                  }
                >
                  <Menu.Item key="225">
                    <Link to='/main/wrongQuestion/errorSet'>小亚错题集</Link>
                  </Menu.Item>
                  <Menu.Item key="226">
                    <Link to='/main/wrongQuestion/reportForm'>小亚报表</Link>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item key="23222">
                  <BorderBottomOutlined />
                  <span>REP托管</span>
                  <Link to="/main/otherTable"></Link>
                </Menu.Item>
                <SubMenu
                  key="sub2"
                  title={
                    <span>
                      <AppstoreOutlined />
                      <span>REP督学</span>
                    </span>
                  }
                >
                  <Menu.Item key="5">
                    <Link to="/main/bk">课程表</Link></Menu.Item>
                  <Menu.Item key="6">
                    <Link to="/main/bk/prograss">审核进度</Link>
                  </Menu.Item>
                  {localStorage.getItem('teacher_type') === '4' ? '' : <Menu.Item key="7">
                    <Link to="/main/bk/kejian">组卷审核</Link>
                  </Menu.Item>}
                </SubMenu>
                <Menu.Item key="228">
                  <ContainerOutlined />
                  <span>REP学员数据报表</span>
                  <Link to='/dataImg2'></Link>
                </Menu.Item>
                {
                  this.state.company_id == 6 ?
                    <Menu.Item key="25">
                      <SnippetsOutlined />
                      <span>成长规划</span>
                      <Link to="/main/ruidi_excel"></Link>
                    </Menu.Item>
                    :
                    <Menu.Item key="25">
                      <SnippetsOutlined />
                      <span>成长规划</span>
                      <Link to="/main/yanuo_excel"></Link>
                    </Menu.Item>
                }
                <SubMenu
                  key="sub8"
                  title={
                    <span>
                      <SettingOutlined />
                      <span>活动管理</span>
                    </span>
                  }
                >
                  <Menu.Item key="79">
                    <Link to="/main/registrationActivities">互动文章</Link>
                  </Menu.Item>
                  <Menu.Item key="78">
                    <Link to="/main/activityManagement">抽奖管理</Link>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item key="3">
                  <FileDoneOutlined />
                  <span>学生端报表</span>
                  <Link to="/main/student_report"></Link>
                </Menu.Item>
                <Menu.Item key="233">
                  <BookOutlined />
                  <span>数据统计报表</span>
                  <Link to="/main/teacherdatareport"></Link>
                </Menu.Item>
                <Menu.Item key="242">
                  <DatabaseOutlined />
                  <span>素质中心</span>
                  <Link to="/main/Syllabuslist"></Link>
                </Menu.Item>
                <Menu.Item key="227">
                  <ContainerOutlined />
                  <span>学习素材
                  </span>
                  <Link to='/main/wrongQuestion/exerciseBook'></Link>
                </Menu.Item>
                <SubMenu
                  key="sub6"
                  title={
                    <span>
                      <SlidersOutlined />
                      <span>资源管理</span>
                    </span>
                  }
                >
                  <Menu.Item key="14">
                    <Link to="/main/resourceCenter/zj">我的资源</Link>
                  </Menu.Item>
                  <Menu.Item key="20">
                    <Link to="/main/resourceCenter/myresources/wenjianjia">我的资源文件夹</Link>
                  </Menu.Item>
                  {localStorage.getItem("permission") === '1' || localStorage.getItem("permission") === '2' ? <Menu.Item key="15">
                    <Link to="/main/zhangjie">章节知识点关联</Link>
                  </Menu.Item> : ''}
                </SubMenu>
                <Menu.Item key="24">
                  <AlertOutlined />
                  <span>投诉管理</span>
                  <Link to="/main/complaint"></Link>
                </Menu.Item>
                {localStorage.getItem("permission") === '1' || localStorage.getItem("permission") === '2' || localStorage.getItem('teacher_type') === '1' || localStorage.getItem('teacher_type') === '2' ?
                  <SubMenu
                    key="sub5"
                    title={
                      <span>
                        <SettingOutlined />
                        <span>权限管理</span>
                      </span>
                    }
                  >
                    <Menu.Item key="11">
                      <Link to="/main/quanxian">权限分配</Link>
                    </Menu.Item>
                    <Menu.Item key="12">
                      <Link to="/main/jiaoyanzu">教研组</Link>
                    </Menu.Item>
                    <Menu.Item key="13">
                      <Link to="/main/quanxian/CreatNewClass">班级分配</Link>
                    </Menu.Item>
                  </SubMenu> : ''}
              </Menu>
          }
        </Sider>
        <Layout>
          <Header style={{ background: '#fff', padding: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
              <LegacyIcon
                className="trigger"
                type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                onClick={this.toggle}
              />
              {/* localStorage.getItem("permission") === '1' || localStorage.getItem("permission") === '2' */}
              {this.state.company_list ? this.state.company_list.length > 1 ?
                <span>
                  <Select style={{ width: 170 }} showSearch optionFilterProp="children" onChange={this.selsectCompany} value={this.state.company_name} placeholder="请选择校区" >
                    {this.state.children}
                  </Select>
                  <span style={{ marginLeft: 10 }}>REP管理系统</span>
                  <span style={{ fontSize: 8, marginLeft: 10 }}>V 2.6.0</span>
                </span>
                :
                <span>{this.state.company_name}REP管理系统<span style={{ fontSize: 10, marginLeft: 10 }}>V 2.6.0</span></span>
                :
                ''
              }
            </div>
            <div className="m-flex" style={{ alignItems: 'center', justifyContent: 'space-between', marginRight: 50 }}>
              {localStorage.getItem('permission') <= 2 || localStorage.getItem('teacher_type') <= 2 || localStorage.getItem('teacher_type') == 5 ?
                <a onClick={() => { window.open('http://bigdata.yidianedu.com/admin/sync_login/index?user=' + localStorage.getItem('username') + '&token=' + localStorage.getItem('token')) }}><PieChartOutlined />大数据</a>
                : ''}
              <div style={{ width: 30 }}></div>
              <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />}></Avatar>
              <div style={{ width: 30 }}></div>
              <Dropdown overlay={menu}>
                <div>
                  {localStorage.getItem('username')}{localStorage.getItem('teacher_type') == 6 ? '(兼职)' : ''}
                </div>
              </Dropdown>
              <Modal
                title="修改密码"
                cancelText='取消'
                okText='确认'
                visible={this.state.visible}
                onOk={this.editOwnPassWord}
                onCancel={this.cancel}
              >
                <div className="m-flex m-bottom" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                  <div className="m-row">老密码：</div>
                  <Input value={this.state.oldPassword} placeholder='请输入老密码' onChange={(e) => this.changePassword(e, 'oldPassword')}></Input>
                </div>
                <div className="m-flex m-bottom" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                  <div className="m-row">新密码：</div>
                  <Input value={this.state.newPassword} placeholder='请输入新密码' onChange={(e) => this.changePassword(e, 'newPassword')}></Input>
                  <div style={{ color: '#bbb', fontSize: '10px' }}>新密码至少大于6位！</div>
                </div>
              </Modal>
            </div>
          </Header>
          <Content
            style={{
              margin: '24px 16px', padding: 24, background: '#fff', minHeight: 280, overflow: 'auto'
            }}
          >
            <Switch>
              <Route path="/main/homepage" component={HomePage} />
              {/* 题库管理 */}
              <Route path='/main' exact component={Tk} />
              <Route path="/main/tk/system" component={Tksystem} />
              <Route path="/main/tk/own" component={Tkown} />
              <Route path="/main/tk/mine" component={Tkmine} />
              <Route path="/main/question" component={Tkquestion} />
              <Route path="/main/test" component={Test} />
              {/* 资源中心的推荐资源 */}
              <Route path="/main/resourceCenter/recommended" exact component={Recommended}></Route>
              <Route path="/main/resourceCenter/recommended/jigousiku" component={RecommendedJigousiku}></Route>
              <Route path="/main/resourceCenter/recommended/share" component={RecommendedShare}></Route>
              <Route path="/main/resourceCenter/recommended/real" component={RecommendedReal}></Route>
              <Route path="/main/zhangjie" component={Zj}></Route>
              {/* 资源中心的我的资源 */}
              <Route path="/main/resourceCenter/myresources/wenjianjia" exact component={Myresources}></Route>
              <Route path="/main/resourceCenter/myresources" component={MyresourcesWenjianjia}></Route>
              <Route path="/main/resourceCenter/zj" component={Myzujuan}></Route>

              <Route path="/main/bk" exact component={BK} />
              <Route path="/main/bk/prograss" component={Prograss} />
              <Route path="/main/bk/kejian" component={Kj} />
              <Route path="/main/quanxian" exact component={Quanxian} />
              <Route path="/main/quanxian/CreatNewClass" component={CreatNewClass} />

              <Route path="/main/jiaoyanzu" component={Jiaoyanzu} />
              {/* 错题管理 */}
              <Route path="/main/wrongQuestion/errorSet" exact component={ErrorSet} />
              <Route path="/main/wrongQuestion/exerciseBook" exact component={ExerciseBook} />
              <Route path="/main/wrongQuestion/exerciseBook/info" component={ExerciseBookInfo} />
              {/* 我的学员 */}
              <Route path="/main/StudentMine" component={StudentMine} />
              <Route path="/main/EnteringQuestion" component={EnteringQuestion} />
              <Route path="/main/AllExamRecord" component={AllExamRecord} />
              <Route path="/main/Teacherdatareport" component={Teacherdatareport} />
              <Route path="/main/zy" component={ZY} />
              <Route path="/main/cp" component={CP} />
              <Route path="/main/otherTable" component={OtherTable} />
              <Route path="/main/complaint" component={Complaint} />
              <Route path="/main/activityManagement" component={ActivityManagement} />
              <Route path="/main/registrationActivities" component={RegistrationActivities} />
              <Route path="/main/Behavioraldata" component={Behavioraldata} />
              {/* 教学大纲 */}
              <Route path="/main/syllabuslist" component={Syllabuslist} />
              {/* 排课系统 */}
              <Route path="/main/setclassroom" component={Setclassroom} />
              <Route path="/main/setteacherstyle" component={Setteacherstyle} />
              <Route path="/main/setstudents" component={Setstudents} />
              {/* 活动报表 */}
              <Route path="/main/reportlist" component={Reportlist} />
              <Route path="/main/reportdetail" component={Reportdetail} />
              {/* excel报表 */}
              <Route path="/main/yanuo_excel" component={yanuo_excel} />
              <Route path="/main/ruidi_excel" component={ruidi_excel} />

              <Route path="/main/wrongQuestion/errorSet/zujuan" component={ZujuanError} />
              <Route path="/main/zujuan" component={Zujuan} />
              <Route path="/main/wrongQuestion/reportForm" component={ReportForm} />
              <Route path="/main/student_report" component={Student_report} />
            </Switch>
          </Content>
        </Layout>
      </Layout >
    );
  }
}
export default withRouter(main)