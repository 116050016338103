export const tableToExcel = id => {
  const str = document.getElementById(id).innerHTML
  const worksheet = 'Sheet1'
  const uri = 'data:application/vnd.ms-excel;base64,';
  const base64 = s => window.btoa(unescape(encodeURIComponent(s)));
  // 下载的表格模板数据
  const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office" 
  xmlns:x="urn:schemas-microsoft-com:office:excel" 
  xmlns="http://www.w3.org/TR/REC-html40">
  <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
  <x:Name>${worksheet}</x:Name>
  <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
  </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
  </head><body><table>${str}</table></body></html>`;
  // 下载模板
  window.location.href = uri + base64(template)
}