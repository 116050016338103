import React, { Component } from 'react';
import { Table, Spin, Pagination, message, Modal } from 'antd';
import {
  get_grant_user_list
} from '../../../axios/http'

class Grant extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      name: '',
      state: '',
      page: 1,
      page_size: 10,
      count: 0,
      recommend_uid: '',
      loading: true,
      visible: false
    }
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      visible: nextProps.visible,
      recommend_uid: nextProps.recommend_uid,
      grant_active_id: nextProps.grant_active_id
    }, () => {
      if (this.props.visible) {
        this.get_grant_user_list()
      }
    })
  }

  get_grant_user_list = () => {
    this.setState({
      loading: true
    })
    let data = {
      recommend_uid: this.state.recommend_uid,
      active_id:this.state.grant_active_id,
      page: this.state.page,
      page_size: this.state.page_size
    }
    get_grant_user_list(data).then(res => {
      if (res.code == 0) {
        this.setState({
          data: res.data.grant_user_list,
          loading: false,
          count: res.data.total_count
        })
      } else {
        message.error(res.message)
      }
    })
  }

  changePage = (e) => {
    this.setState({
      page: e
    }, () => {
      this.get_grant_user_list()
    })
  }

  render() {
    const columns = [
      {
          title: '用户头像',
          dataIndex: 'headimgurl',
          key: 'headimgurl',
          render: (text, record, index) => (
              <img src={text} style={{ width: '50px' }} alt="" />
          )
      },
      {
          title: '用户名',
          dataIndex: 'nickname',
          key: 'nickname'
      },
      {
          title: '访问次数',
          dataIndex: 'visit_count',
          key: 'visit_count',
          sorter: true
      },
      {
          title: '访问时长',
          dataIndex: 'visit_seconds',
          key: 'visit_seconds',
          sorter: true
      },
      {
          title: '分享次数',
          dataIndex: 'share_count',
          key: 'share_count',
      },
      {
          title: '分享被查看人次',
          dataIndex: 'share_visit_uv',
          key: 'share_visit_uv',
      },
      {
          title: '分享被授权查看人次',
          dataIndex: 'share_user_count',
          key: 'share_user_count',
      },
      {
          title: '所在城市',
          dataIndex: 'city',
          key: 'city'
      },
      {
          title: '最后访问时间',
          dataIndex: 'last_visit_time',
          key: 'last_visit_time'
      },
      {
          title: '是否提交',
          dataIndex: 'is_submit',
          key: 'is_submit',
          render: (text, record, index) => (
              <div>{text == '-1' ? '未提交' : '已提交'}</div>
          )
      }
  ];
    return (
      <div> 
        <Modal
          visible={this.state.visible}
          title="分享被授权查看次数详情"
          cancelText='取消'
          okText='确认'
          width={800}
          onOk={e => this.props.cancel()}
          onCancel={e => this.props.cancel()}
        >
          <Spin spinning={this.state.loading}>
            <Table
              columns={columns}
              dataSource={this.state.data}
              bordered
              size="middle"
              pagination={false}
            />
            <div className="m-Pleft m-flex">
              <Pagination current={Number(this.state.page)} onChange={this.changePage} total={Number(this.state.count)} />
            </div>
          </Spin>
        </Modal>
      </div>
    )
  }
}

export default Grant;