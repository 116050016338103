import React, { Component } from 'react';
import { Button, Checkbox, Modal, Tree, message } from 'antd'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import SelectA from '../tk/selection'
import TreeA from '../tk/editorTree'
import List from './jiangyiList'
import '../../css/jiangyi.css'
import produce from "immer"
import { get_standard_bySection, get_preparation_detail, get_course_sectionList, get_ques_by_ques_ids, save_preparation, get_directory } from '../../axios/http'
const { confirm } = Modal;
class componentName extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tree: [],
            visible: false,
            visible3: false,
            ques_knowledge_idList: [],
            ques_knowledge_ids: [],
            know_lageNameList: [],
            unitList: [],
            mapList: [],
            homeList: [],
            editKey: false,
            questionList: [],
            params: {
                key_words: ''
            },
            jiangyiParams: {
                resources_id: "",
                standard_ids: "",
                title: "",
                course_id: "",
                content: ""
            },
            pubilcmodules: [],
            modules: [],
            left_moduled: []

        }
    }
    componentDidMount() {
        get_directory().then(res => {
            //处理tree数据结构
            const recursion = (data) => {
                data.forEach(res => {
                    res['title'] = res.name
                    res['key'] = res.id
                    if (res.children) {
                        recursion(res.children)
                    }
                })
            }
            recursion(res.data.list)
            this.setState({
                treeA: res.data.list
            })
        })
        if (this.props.location.search.split('=')[1] === 'true') {
            const model = JSON.parse(localStorage.getItem('editInfo'))
            const jiangyiParams = { ...this.state.jiangyiParams }
            const ques_knowledge_ids = model.standard_ids.split(',') || model.standard_ids.split('')
            jiangyiParams.title = model.title
            jiangyiParams.resources_id = model.resources_id
            jiangyiParams.content = JSON.parse(model.content)
            jiangyiParams.standard_ids = model.standard_ids.split(',') || model.standard_ids.split('')
            this.setState({
                pubilcmodules: JSON.parse(model.content)
            })
            setTimeout(() => {
                let newModules = []
                let mapList = []
                let unitList = []
                let homeList = []
                jiangyiParams.content.forEach(l1 => {
                    l1.modules.forEach(res => {
                        if (res.module_type === "homework") {
                            homeList = homeList.concat(res)
                        } else if (res.module_type === "unit") {
                            unitList = unitList.concat(res)
                        } else {
                            if (res.is_custom) {
                                mapList = mapList.concat(res)
                            }
                        }
                    })
                })

                if (homeList.length > 1) {
                    homeList.forEach((res) => {
                        homeList[0].questions = homeList[0].questions.concat(res.questions)
                    })
                }
                newModules = newModules.concat(mapList, unitList, homeList[0])
                const question = JSON.parse(model.content).map(res => { return res.quesList.map(res2 => { return res2 }) })
                get_ques_by_ques_ids({ ques_ids: [...question] }).then(res => {
                    this.setState({
                        questionList: res.data.ques_list
                    })
                })
                this.setState({
                    visible: false,
                    modules: newModules,
                    unitList,
                    mapList,
                    homeList,
                    standard_ids: jiangyiParams.standard_ids,
                    jiangyiParams
                })
                const moduled = JSON.parse(model.content).map(res => {
                    const a = res.modules.reduce((item, res2) => {
                        if (res2.module_key !== "unitMap" && res2.module_key !== "homework") {
                            item.push(res2)
                        }
                        return item
                    }, [])
                    return a
                })
                this.setState({
                    left_moduled: moduled,
                })
            }, 1200);
            this.setState({
                jiangyiParams,
                editKey: this.props.location.search.split('=')[1],
                ques_knowledge_ids
            })
        } else if (this.props.location.search.split('=')[0] === 'online') {
            const jiangyiParams = this.state.jiangyiParams
            jiangyiParams.course_id = this.props.location.search.split('=')[1]
            this.setState({
                jiangyiParams
            })
        }
    }
    onChange = (e, index, index2) => {
        this.setState(
            produce(draft => {
                draft.left_moduled[index][index2].module_show = !draft.left_moduled[index][index2].module_show
                draft.unitList[index].module_show = !draft.unitList[index].module_show
            })
        )
    }
    onChange2 = (e, index, index2, index3) => {
        this.setState(
            produce(draft => {
                draft.left_moduled[index][index2].unit_modules[index3].module_show = !draft.left_moduled[index][index2].unit_modules[index3].module_show
                draft.unitList[index].unit_modules[index3].module_show = !draft.unitList[index].unit_modules[index3].module_show
            })
        )
    }
    onChange3 = (e, index, index2, index3, index4) => {
        this.setState(
            produce(draft => {
                draft.left_moduled[index][index2].unit_modules[index3].questions[index4].module_show = !draft.left_moduled[index][index2].unit_modules[index3].questions[index4].module_show
                draft.unitList[index].unit_modules[index3].questions[index4].module_show = !draft.unitList[index].unit_modules[index3].questions[index4].module_show
            })
        )
    }
    selectonChange = value => {
        get_course_sectionList({ subject_id: value.length > 1 ? value[1] : value[0] }).then(res => {
            const treeCheckKey = res.data.course_section_list.map(res => {
                return res.section_id
            })
            this.setState({
                tree: res.data.course_section_list,
                selectValue: value,
                treeCheckKey
            })
        })
    }
    know_lagechangeList = (e) => {
        this.setState({
            ques_knowledge_idList: e
        })
    }
    know_lageId = e => {
        const params = { ...this.state.params }
        params.ques_knowledge_ids = JSON.stringify(e)
        this.setState({
            params
        })
    }
    know_lageName = e => {
        this.setState({
            know_lageNameList: e
        })
    }
    deleteKnowlage = (index) => {
        const know_lageNameList = [...this.state.know_lageNameList]
        const ques_knowledge_ids = [...this.state.ques_knowledge_ids]
        const ques_knowledge_idList = [...this.state.ques_knowledge_idList]
        ques_knowledge_ids.splice(index, 1)
        know_lageNameList.splice(index, 1)
        ques_knowledge_idList.splice(index, 1)
        this.setState({
            ques_knowledge_ids,
            know_lageNameList,
            ques_knowledge_idList
        })
    }
    openKM = () => {
        this.setState({
            visible: true
        })

    }
    handleOk = () => {
        let modules = []
        let question = []
        const jiangyiParams = { ...this.state.jiangyiParams }
        jiangyiParams.standard_ids = this.state.ques_knowledge_idList
        this.state.ques_knowledge_idList.forEach(res => {
            get_preparation_detail({ standard_id: res }).then(ele => {
                modules = modules.concat(JSON.parse(ele.data.model).data.modules) //这是显示左侧操作栏的模型数据
                question = question.concat(JSON.parse(ele.data.model).data.quesList)//所有题目id
                this.setState({
                    pubilcmodules: this.state.pubilcmodules.concat(JSON.parse(ele.data.model).data)
                })
            })
        })
        setTimeout(() => {
            let newModules = []
            let mapList = []
            let unitList = []
            let homeList = []
            modules.forEach(res => {
                if (res.module_type === "homework") {
                    homeList = homeList.concat(res)
                } else if (res.module_type === "unit") {
                    unitList = unitList.concat(res)
                } else {
                    if (res.is_custom) {
                        mapList = mapList.concat(res)
                    }
                }
            })
            const moduled = modules.reduce((item, res2) => {
                if (res2.module_key !== "unitMap" && res2.module_key !== "homework") {
                    item.push([res2])
                }
                return item
            }, [])
            if (homeList.length > 1) {
                homeList.forEach((res) => {
                    homeList[0].questions = homeList[0].questions.concat(res.questions)
                })
            }
            newModules.push([{ mapList, unitList }])
            get_ques_by_ques_ids({ ques_ids: question }).then(res => {
                this.setState({
                    questionList: res.data.ques_list
                })
            })
            // JSON.parse(model.content).map(res => { return res.modules })
            this.setState({
                visible: false,
                modules: newModules,
                left_moduled: moduled,
                unitList,
                mapList,
                homeList,
                standard_ids: question,
                jiangyiParams
            })
        }, 1200);
    }
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }
    checkSelect = (value, e) => {
        const treeCheckKey = this.state.treeCheckKey
        const tree = this.state.tree
        //用于判断是否为最外层的点击
        if (treeCheckKey.includes(value[0])) {
            return
        }
        //
        if (e.selected) {
            tree.forEach((res, index) => {
                res.children.forEach((res2, index2) => {
                    if (res2.section_id === value[0]) {
                        get_standard_bySection({ section_id: value[0] }).then(ele => {
                            this.setState(
                                produce(draft => {
                                    draft.tree[index].children[index2].children = ele.data.list
                                })
                            )
                        })

                    }
                })
            })
        } else {
            return
        }
    }
    module = () => {
    }
    saveJiangyi = () => {
        if (this.state.jiangyiParams.standard_ids === '') {
            message.warning('尚未选择知识元无法另存')
            return
        }
        const pubilcmodules = this.state.pubilcmodules
        pubilcmodules.forEach((res, index) => {
            res.modules.forEach((res2, index2) => {
                this.state.unitList.forEach(res3 => {
                    if (res2.id === res3.id) {
                        this.setState(
                            produce(draft => {
                                draft.pubilcmodules[index].modules[index2] = res3
                            })
                        )
                    }
                })
            })
        })
        this.setState({
            visible3: true
        })
    }
    save = () => {
        const jiangyiParams = { ...this.state.jiangyiParams }
        jiangyiParams.content = JSON.stringify(this.state.pubilcmodules)
        save_preparation(jiangyiParams).then(res => {
            if (res.code === 0) {
                message.success(`${res.message},稍后为你跳转到资源文件夹`)
                setTimeout(() => {
                    this.props.history.push(`main/resourceCenter/myresources/wenjianjia`)
                }, 2000);
            } else {
                message.error(res.message)
            }
        })
    }
    editJiangyi = () => {
        const pubilcmodules = this.state.pubilcmodules
        const jiangyiParams = { ...this.state.jiangyiParams }
        const _this = this
        pubilcmodules.forEach((res, index) => {
            res.modules.forEach((res2, index2) => {
                this.state.unitList.forEach(res3 => {
                    if (res2.id === res3.id) {
                        this.setState(
                            produce(draft => {
                                draft.pubilcmodules[index].modules[index2] = res3
                            })
                        )
                    }
                })
            })
        })
        jiangyiParams.content = JSON.stringify(this.state.pubilcmodules)
        confirm({
            title: '修改讲义',
            icon: <ExclamationCircleOutlined />,
            content: '确认要修改吗？',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                save_preparation(jiangyiParams).then(res => {
                    if (res.code === 0) {
                        message.success(`${res.message},稍后为你跳转到资源文件夹`)
                        setTimeout(() => {
                            _this.props.history.push(`main/resourceCenter/myresources/wenjianjia`)
                        }, 2000);
                    } else {
                        message.error(res.message)
                    }
                })
            },
            onCancel() {
            },
        });
    }
    onSelect = e => {
        //我选择某个文件夹的时候给目录id赋值
        const jiangyiParams = { ...this.state.jiangyiParams }
        jiangyiParams.resources_id = e[0]
        this.setState({
            jiangyiParams
        })
    }
    title = (e) => {
        const jiangyiParams = { ...this.state.jiangyiParams }
        jiangyiParams.title = e.target.value
        this.setState({
            jiangyiParams
        })
    }
    moveFile = () => {
        const jiangyiParams = { ...this.state.jiangyiParams }
        if (jiangyiParams.resources_id === '') {
            message.warning('请选择另保存的路径')
            return
        }
        if (jiangyiParams.title === '') {
            message.warning('请输入讲义标题')
            return
        }
        this.save()
    }
    cancleMoveFile = () => {
        this.setState({
            visible3: false
        })
    }
    back_bk = () => {
        const _this = this
        confirm({
            title: '返回',
            icon: <ExclamationCircleOutlined />,
            content: '点击确认将为你跳转至备课界面',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                _this.props.history.push(`/main/bk`)
            },
            onCancel() {
            },
        });
    }
    render() {
        return (
            <div >
                <Modal
                    title='添加到我的资源文件夹'
                    visible={this.state.visible3}
                    onOk={this.moveFile}
                    onCancel={this.cancleMoveFile}
                    okText="确认"
                    cancelText="取消"
                >
                    <Tree
                        onSelect={this.onSelect}
                        treeData={this.state.treeA}
                    />
                </Modal>
                <Modal
                    width={900}
                    title='选择知识元'
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    okText="确认"
                    cancelText="取消"
                >
                    <div className="m-flex" style={{ alignItems: 'center', marginBottom: 20 }}>
                        <span>学科：</span>
                        <SelectA selectonChange={this.selectonChange} data={JSON.parse(localStorage.getItem('default_subject_list'))} value={this.state.selectValue}></SelectA>
                    </div>
                    <div className="content_area">
                        <div className="tree_area">
                            <TreeA checkSelect={this.checkSelect} data={this.state.tree} ques_knowledge_idList={this.state.ques_knowledge_idList} know_lagechangeList={this.know_lagechangeList} know_lageName={this.know_lageName} know_lageId={this.know_lageId}></TreeA>
                        </div>
                        <div className="unit_area">
                            <span>已选知识元：</span>
                            <span style={{ color: "#f60" }}>{this.state.know_lageNameList.length}</span>
                        </div>
                        {this.state.know_lageNameList.map((res, index) =>
                            <div className="unit-origion" key={index}>
                                <div className="unit-name">{res}</div>
                                <span className="unit-delete" onClick={() => this.deleteKnowlage(index)}>
                                    <DeleteOutlined />
                                </span>
                            </div>
                        )}
                    </div>
                </Modal>
                <div className="jiangyi-header">
                    <div className="lecture_con m-flex" style={{ justifyContent: 'space-between' }}>
                        <button className="button-b" onClick={this.back_bk}>返回</button>
                        <div style={{ width: 180, justifyContent: 'space-between' }} className="m-flex">
                            <button className="button-b">查看课件</button>
                            {this.state.editKey === 'true' ? <button className='button-o' onClick={this.editJiangyi}>修改</button> : <button className='button-o' onClick={this.saveJiangyi}>另存为</button>}
                        </div>
                    </div>
                </div>
                <div className="m-mian-JY">
                    <div className="jiangyi-main">
                        <div className='jiangyi-main-left'>
                            <div className="jiangyi-tree">
                                <div className="tree_header">
                                    <span className="tree_title">讲义目录</span>
                                </div>
                                <div className="tree_content">
                                    <div className="note">
                                        不选中不下载哦，如需下载请先选中
                                    </div>
                                    <div className="module">
                                        {this.state.left_moduled.map((resL, l1) =>
                                            resL.map((res, index) =>
                                                <div className="laer1" key={index}>
                                                    <div className="laer1_hover">
                                                        <div className="module_title">
                                                            {res.module_type === 'homework' ? res.module_name + '(练习)' : res.module_name}
                                                            <div>
                                                                <Checkbox onChange={(e) => this.onChange(e, l1, index)} style={{ marginLeft: 5 }} checked={res.module_show}></Checkbox>
                                                            </div>
                                                        </div>
                                                        {res.module_type === "unit" ?
                                                            res.unit_modules.map((res2, index2) =>
                                                                <div className="module" key={index2}>
                                                                    <div className="laer1">
                                                                        <div className="laer2_hover">
                                                                            <div className="module_title2">
                                                                                <div className="m-flex" style={{ alignItems: 'center' }}>
                                                                                    <span className="dot"></span>
                                                                                    <span>{res2.module_name}</span>
                                                                                </div>
                                                                                <div>
                                                                                    <Checkbox onChange={(e) => this.onChange2(e, l1, index, index2)} style={{ marginLeft: 5 }} checked={res2.module_show}></Checkbox>
                                                                                </div>
                                                                            </div>
                                                                            {res2.module_type === "quesGroup" ?
                                                                                res2.questions.map((ele, index3) =>
                                                                                    ele.module_name !== '拓展' ?
                                                                                        <div key={index3} className="module_title3" style={{ fontSize: 14 }}>
                                                                                            <div className="m-flex" style={{ alignItems: 'center' }}>
                                                                                                <span className="vertical_line"></span>
                                                                                                <span className="dot2"></span>
                                                                                                <span>{ele.module_name}</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <Checkbox onChange={(e) => this.onChange3(e, l1, index, index2, index3)} style={{ marginLeft: 5 }} checked={ele.module_show}></Checkbox>
                                                                                            </div>
                                                                                        </div>
                                                                                        : ''
                                                                                )
                                                                                : ""}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                            : ''}
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                                <div className="tree_button">
                                    <div className="jiangyi_botton" onClick={this.openKM}>
                                        <div className="icon_unit"></div>
                                        <span>插入知识元</span>
                                    </div>
                                    {/* <div className="jiangyi_botton">
                                        <div className="icon_text"></div>
                                        <span>插入文本</span>
                                    </div>
                                    <div className="jiangyi_botton">
                                        <div className="icon_question"></div>
                                        <span>插入选题</span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className='jiangyi-main-right'>
                            <div className="lecture_logo"></div>
                            <div className="lecture_struct">
                                <div className="lecture_title is_edit">
                                    <div className="lecture_title_con">
                                        <input type="text" placeholder="请输入讲义名称" onChange={this.title} value={this.state.jiangyiParams.title} />
                                        {/* <span>请输入讲义名称</span> */}
                                    </div>
                                </div>
                                <div className="lecture_struct_content">
                                    <div className="lecture-module">
                                        {this.state.unitList.map(res =>
                                            <section style={{ display: res.module_show ? 'block' : 'none' }}>
                                                <div className="units-title-wrap">
                                                    <div className="edit-wrap">
                                                        <div className="units-title">
                                                            <b>知识元</b>
                                                            <span>{res.module_name}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="unit-module">
                                                    {res.unit_modules.map((res2, index5) =>
                                                        res2.module_type === "resource" ?
                                                            <section key={index5} style={{ display: res2.module_show ? 'block' : 'none' }}>
                                                                <div className="text_title_main">
                                                                    <div className="lecture_title" style={{ textAlign: 'left' }}>
                                                                        <div className="lecture_title_con">
                                                                            <span className="text_span text_span_unit">{res2.module_name}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="text_contents_editor" dangerouslySetInnerHTML={{ __html: res2.contents }}></div>
                                                            </section>
                                                            :
                                                            <section key={index5} style={{ display: res2.module_show ? 'block' : 'none' }}>
                                                                <div className="text_title_main">
                                                                    <div className="lecture_title" style={{ textAlign: 'left' }}>
                                                                        <div className="lecture_title_con">
                                                                            <span className="text_span text_span_unit">{res2.module_name}</span>
                                                                        </div>
                                                                    </div>
                                                                    {res2.questions.map((ele3, index6) =>
                                                                        ele3.module_name !== "拓展" ?
                                                                            <div key={index6} style={{ display: ele3.module_show ? 'block' : 'none' }}>
                                                                                <div className="group-con-item" >
                                                                                    <div className="group-con-title">
                                                                                        <p>{ele3.module_name}</p>
                                                                                        <div>
                                                                                            {/* <button className="ivu-btn-circle ivu-btn">调序</button> */}
                                                                                            {/* <button className="ivu-btn-primary ivu-btn">完成调序</button> */}
                                                                                            {/* <button className="ivu-btn-primary ivu-btn">选题</button> */}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <List questions={ele3.questions} questionList={this.state.questionList} module_name={ele3.module_name}></List>
                                                                            </div>
                                                                            :
                                                                            ''
                                                                    )}
                                                                </div>
                                                                <div className="text_contents_editor" dangerouslySetInnerHTML={{ __html: res2.contents }}></div>
                                                            </section>
                                                    )}
                                                </div>
                                            </section>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default componentName;