import React, { Component } from 'react';
import { Table, Row, Col, Input, Button, Spin, Pagination, message, Modal, Select, Popconfirm } from 'antd';
import {
    get_record_list,
    check_lottery,
    get_record_detail,
    remark_lottery_record,
    set_completed
} from '../../../axios/http'
const { Option } = Select

class Luckydrawlist extends Component {
    constructor(props) {
        super(props)
        this.state = {
            choujiang_id: this.props.choujiang_id,
            nickname: '',
            is_win: '',
            is_give: '',
            code: '',
            page: 1,
            page_size: 10,
            count: 0,
            loading: true,
            visible: false,
            id: '',
            is_student: '',
            student_company_id: '',
            student: '',
            mobile: '',
            remark: ''
        }
    }
    componentDidMount = () => {
        this.get_record_list()
    }
    get_record_list = () => {
        this.setState({
            loading: true
        })
        let data = {
            choujiang_id: this.state.choujiang_id,
            code: this.state.code,
            nickname: this.state.nickname,
            is_win: this.state.is_win,
            is_give: this.state.is_give,
            page: this.state.page,
            page_size: this.state.page_size
        }
        get_record_list(data).then(res => {
            if (res.code == 0) {
                this.setState({
                    data: res.data.record_list,
                    loading: false,
                    count: Number(res.data.total_count)
                })
            } else {
                message.error(res.message)
            }
        })
    }
    changePage = e => {
        this.setState({
            page: e
        }, () => {
            this.get_record_list()
        })
    }
    nameChange = e => {
        this.setState({
            nickname: e.target.value
        })
    }
    codeChange = (e) => {
        this.setState({
            code: e.target.value
        })
    }
    check_lottery = (record_id, nickname) => {
        let data = {
            record_id: record_id
        }
        check_lottery(data).then(res => {
            if (res.code == 0) {
                message.success(nickname + '核销成功！')
                this.get_record_list()
            } else {
                message.error(res.message)
            }
        })
    }
    cancel = e => {
        this.setState({
            visible: !this.state.visible,
            is_student: '',
            student_company_id: '',
            student: '',
            mobile: '',
            remark: ''
        })
    }
    is_studentChange = e => {
        this.setState({
            is_student: e
        })
    }
    student_company_idChange = e => {
        this.setState({
            student_company_id: e
        })
    }
    studentChange = e => {
        this.setState({
            student: e.target.value
        })
    }
    mobileChange = e => {
        this.setState({
            mobile: e.target.value
        })
    }
    remarkChange = e => {
        this.setState({
            remark: e.target.value
        })
    }
    back = () => {
        this.props.back()
    }
    get_record_detail = (record_id) => {
        get_record_detail({ record_id: record_id }).then(res => {
            if (res.code == 0) {
                this.setState({
                    record_id: record_id,
                    is_student: res.data.model.is_student,
                    student_company_id: res.data.model.student_company_id,
                    student: res.data.model.student || '',
                    mobile: res.data.model.mobile || '',
                    remark: res.data.model.remark || '',
                    visible: !this.state.visible
                })
            } else {
                message.error(res.message)
            }
        })
    }
    remark_lottery_record = e => {
        let data = {
            record_id: this.state.record_id,
            is_student: this.state.is_student,
            student_company_id: this.state.student_company_id,
            student: this.state.student,
            mobile: this.state.mobile,
            remark: this.state.remark
        }
        remark_lottery_record(data).then(res => {
            if (res.code == 0) {
                this.cancel()
                this.get_record_list()
            } else {
                message.error(res.message)
            }
        })
    }
    set_completed = id => {
        set_completed({ record_id: id }).then(res => {
            if (res.code == 0) {
                message.success('设置成功！')
                this.get_record_list()
            } else {
                message.error(res.message)
            }
        })
    }
    render() {
        const columns = [
            {
                title: '用户头像',
                dataIndex: 'headimgurl',
                key: 'headimgurl',
                render: (text, record, index) => (
                    <img src={text} style={{ width: '50px' }} alt="" />
                )
            },
            {
                title: '用户名',
                dataIndex: 'nickname',
                key: 'nickname'
            },
            {
                title: '兑奖码',
                dataIndex: 'code',
                key: 'code'
            },
            {
                title: '抽奖日期',
                dataIndex: 'add_time',
                key: 'add_time'
            },
            {
                title: '兑换截止日期',
                dataIndex: 'expiration_date',
                key: 'expiration_date'
            },
            {
                title: '奖项',
                dataIndex: 'goods',
                key: 'goods'
            },
            {
                title: '详情',
                dataIndex: 'introduce',
                key: 'introduce'
            },
            {
                title: '手机号',
                dataIndex: 'mobile',
                key: 'mobile'
            },
            {
                title: '任务',
                dataIndex: 'is_completed',
                key: 'is_completed',
                render: (text, record, index) => (
                    text
                        ?
                        <div>已完成</div>
                        :
                        <Popconfirm placement="top" title={'是否确认完成？'} onConfirm={e => this.set_completed(record.id)} okText="是" cancelText="否">
                            <span style={{ cursor: 'pointer', color: '#1890ff' }}>未完成</span>
                        </Popconfirm>
                )
            },
            {
                title: '操作',
                key: 'active',
                dataIndex: 'active',
                render: (text, record, index) => (
                    record.is_give == '1'
                        ?
                        <div style={{ whiteSpace: 'pre' }}>
                            <span style={{ cursor: 'pointer', color: '#1890ff' }} onClick={e => this.get_record_detail(record.id)}>完善信息</span> / <span>已核销</span>
                        </div>
                        :
                        <div style={{ whiteSpace: 'pre' }}>
                            <span style={{ cursor: 'pointer', color: '#1890ff' }} onClick={e => this.get_record_detail(record.id)}>完善信息</span> / <Popconfirm placement="top" title={'是否确认核销？'} onConfirm={e => this.check_lottery(record.id, record.nickname)} okText="是" cancelText="否">
                                <span style={{ cursor: 'pointer', color: '#1890ff' }}>核销</span>
                            </Popconfirm>
                        </div>

                )
            }
        ];
        return (
            <div>
                <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
                    <div className="flex">
                        <div style={{ marginRight: '10px' }}>
                            <span>用户名：</span>
                            <Input style={{ width: 150 }} placeholder="请输入" onChange={this.nameChange} allowClear></Input>
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <span>兑奖码：</span>
                            <Input style={{ width: 150 }} placeholder="请输入" onChange={this.codeChange} allowClear></Input>
                        </div>
                        <Button onClick={this.get_record_list} type="primary">查找</Button>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ marginRight: '10px' }}>共：{this.state.count}人</div>
                        <Button onClick={this.back} type="primary">返回</Button>
                    </div>
                </div>
                <Spin spinning={this.state.loading}>
                    <Table
                        columns={columns}
                        dataSource={this.state.data}
                        bordered
                        size="middle"
                        pagination={false}
                    />
                    <div className="m-Pleft m-flex">
                        <Pagination current={Number(this.state.page)} onChange={this.changePage} total={Number(this.state.count)} pageSize={this.state.page_size} />
                    </div>
                </Spin>
                <Modal
                    title="核销操作"
                    cancelText='取消'
                    okText='确认'
                    visible={this.state.visible}
                    onOk={this.remark_lottery_record}
                    onCancel={this.cancel}
                >
                    <Row>
                        <Col className="ant-col-css" span={8}>
                            <span>老学员：</span>
                        </Col>
                        <Col className="ant-col-css" span={16}>
                            <Select style={{ width: 165 }} value={this.state.is_student ? this.state.is_student : undefined} onChange={this.is_studentChange} placeholder="请选择" >
                                <Option value={'-1'}>否</Option>
                                <Option value={'1'}>是</Option>
                            </Select>
                        </Col>
                        <Col className="ant-col-css" span={8}>
                            <span>所属校区：</span>
                        </Col>
                        <Col className="ant-col-css" span={16}>
                            <Select style={{ width: 165 }} value={this.state.student_company_id ? this.state.student_company_id : undefined} onChange={this.student_company_idChange} placeholder="请选择" >
                                <Option value={'1'}>阳山校区</Option>
                                <Option value={'30'}>沧浪校区</Option>
                                <Option value={'6'}>瑞迪校区</Option>
                            </Select>
                        </Col>
                        <Col className="ant-col-css" span={8}>
                            <span>学生姓名：</span>
                        </Col>
                        <Col className="ant-col-css" span={16}>
                            <Input type="text" value={this.state.student} onChange={this.studentChange} placeholder="请输入" />
                        </Col>
                        <Col className="ant-col-css" span={8}>
                            <span>手机号：</span>
                        </Col>
                        <Col className="ant-col-css" span={16}>
                            <Input type="number" value={this.state.mobile} onChange={this.mobileChange} placeholder="请输入" />
                        </Col>
                        <Col className="ant-col-css" span={8}>
                            <span>备注：</span>
                        </Col>
                        <Col className="ant-col-css" span={16}>
                            <Input type="text" value={this.state.remark} onChange={this.remarkChange} placeholder="请输入" />
                        </Col>
                    </Row>
                </Modal>
            </div>
        )
    }
}

export default Luckydrawlist;