import React, { useEffect, useState } from 'react'
import { get_ques_by_ques_ids } from '../../axios/http'
import List from './previewList'
import MathJax from 'react-mathjax3'
const Wxpreview = (props) => {
    const [list, setList] = useState([])
    useEffect(() => {
        get_ques_by_ques_idsFun()
    }, [])
    const get_ques_by_ques_idsFun = () => {
        let data = {
            ques_ids: props.location.search.substring(1).slice(3)
        }
        get_ques_by_ques_ids(data).then(res => {
            const total = res.data.ques_list.map((res, index) =>
                <MathJax.Context
                    key={index}
                    input='ascii'
                    onError={(MathJax, error) => {
                        console.warn(error);
                        console.log("Encountered a MathJax error, re-attempting a typeset!");
                        MathJax.Hub.Queue(
                            MathJax.Hub.Typeset()
                        );
                    }}
                    // script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.0/MathJax.js"
                    options={{
                        messageStyle: 'none',
                        extensions: ['tex2jax.js'],
                        jax: ['input/TeX', 'output/HTML-CSS'],
                        tex2jax: {
                            inlineMath: [['$', '$'], ['\\(', '\\)']],
                            displayMath: [['$$', '$$'], ['\\[', '\\]']],
                            processEscapes: true,
                        },
                        TeX: {
                            extensions: ['AMSmath.js', 'AMSsymbols.js', 'noErrors.js', 'noUndefined.js']
                        }
                    }}>
                    <div >
                        {res.ques_options ?
                            <div style={{ display: 'flex' }}>
                                <span>{index + 1 + '、'}</span>
                                <MathJax.Html html={res.ques_content + res.ques_options} />
                            </div>
                            : <div style={{ display: 'flex' }}>
                                <span>{index + 1 + '、'}</span>
                                <MathJax.Html html={res.ques_content} />
                            </div>}

                    </div>
                </MathJax.Context>
            )
            setList(total)
        })
    }
    return (
        <div>{list}</div>
    )
}
export default Wxpreview;