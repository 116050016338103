import React, { Component } from 'react';
import { report_student } from '../../axios/http'
import { Carousel, Modal, Tabs, Pagination, Divider, Table, Tag } from 'antd'
import echarts from 'echarts/lib/echarts';
import Finished from '../data/finish'
import { wrong_get_list, get_student_lecture_list, get_all_exam_record } from '../../axios/http'
import { Route } from 'react-router-dom';
const { TabPane } = Tabs
function DivideArrayEquallyInto4Parts(originArr) {
    var data = originArr
    var result = [];
    for (var i = 0; i < data.length; i += 4) {
        result.push(data.slice(i, i + 4));
    }
    return result
}
class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            height: 0,
            model: {},
            active_list: [],
            class_list: [],
            data: [],
            width: 500,
            height: 250,
            tableData: [],
            score: {},
            plotCfg: {
                margin: [10, 100, 50, 120],
            },
            changeKey: '',
            countNumber: 0,
            totalCount: 0,
            paramResult: {
                xiaoguanjia_subject_id: [],
                xiaoguanjia_grade_id: [],
                xiaoguanjia_class_id: [],
                student: '',
                submit_teacher_id: '',
                // analysis_teacher_id: localStorage.getItem('id'),
                state: -1,
                page_size: '10',
                page: 1
            },
            params: {
                student_id: '',
                shift_id: '',
                page: 1,
                page_size: '10'
            },
            dataList: [],
            visible2: false,
            xAxis: [],
            full_score: []
        }
    }
    componentDidMount() {
        let s = this.props.location.search
        s = s.slice(1, s.length)
        s = s.split('&')
        let data = {}
        s.map(item => {
            var t = item.split('=')
            data[t[0]] = t[1]
        })
        let arr = this.state.paramResult
        arr['xiaoguanjia_student_id'] = data.id
        this.setState({
            paramResult: arr
        })
        this.get_all_exam_record(data)
        report_student({ student_id: data.id }).then(res => {
            this.setState({
                model: res.data.model,
                active_list: res.data.active_list,
                class_list: res.data.class_list ? DivideArrayEquallyInto4Parts(res.data.class_list) : []
            })
            return res.data.model
        }).then(ele => {
            const params = { ...this.state.params }
            params.student_id = ele.student_id
            get_student_lecture_list(params).then(ele2 => {
                this.setState({
                    tableData: ele2.data.list,
                    params,
                    totalCount2: Number(ele2.data.total_count)
                })
            })
        })
        wrong_get_list({
            xiaoguanjia_subject_id: '',
            xiaoguanjia_grade_id: '',
            xiaoguanjia_class_id: '',
            xiaoguanjia_student_id: data.id,
            // analysis_teacher_id: localStorage.getItem('id'),
            state: -1,
            page_size: 10,
            page: 1
        }).then(res => {
            this.setState({
                totalCount: Number(res.data.count),
                dataList: res.data.list
            })
        })
        window.addEventListener('resize', this.handleSize);
        this.handleSize()
    }
    handleSize = () => {
        let height = document.getElementById('img').height,
            width = document.getElementById('img').width
        var myChart = echarts.init(document.getElementById('main'));
        myChart.resize()
        this.setState({
            height1: height * 0.34,
            width1: width * 0.11,
            top1: height * 0.57,
            left1: width * 0.345,
            height2: height * 0.34,
            width2: width * 0.11,
            top2: height * 0.57,
            left2: width * 0.716,
            height: document.body.clientHeight,
        });
    }
    handleImageLoaded = e => {
        let height = e.target.height,
            width = e.target.width
        this.setState({
            height1: height * 0.34,
            width1: width * 0.11,
            top1: height * 0.57,
            left1: width * 0.345,
            height2: height * 0.34,
            width2: width * 0.11,
            top2: height * 0.57,
            left2: width * 0.716
        });
    }
    get_all_exam_record = (val) => {
        let data = {
            student_id: val.id,
            grade_id: '',
            grade: decodeURI(val.grade),
            student: '',
            fulltime_school: '',
            fulltime_class: '',
            teacher_employee_id: '',
            shift_id: '',
            status: val.status
        }
        get_all_exam_record(data).then(res => {
            let list = res.data.list
            let xAxis = [], full_score = []
            if (list.exam_list.length != 0) {
                list.exam_list.map(item => {
                    xAxis.push(item.exam_time.split('-')[1])
                })
                list.student_list[0].exam_list.map(item => {
                    let full = 0, score = 0
                    item.exam_result_list.map(item2 => {
                        if (item2.score != 0) {
                            full += Number(item2.full_score)
                            score += Number(item2.score)
                        }
                    })
                    full_score.push((score / full * 100).toFixed(2))
                })
            }
            if (document.getElementById('main')) {
                var myChart = echarts.init(document.getElementById('main'));
                myChart.setOption({
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '6%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            boundaryGap: false,
                            data: xAxis
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    textStyle: {
                        color: '#fff'
                    },
                    label: {
                        color: '#fff'
                    },
                    series: [
                        {
                            name: '当前成绩分',
                            type: 'line',
                            stack: '总量',
                            itemStyle: {
                                normal: {
                                    color: '#ffc353',
                                    lineStyle: {
                                        color: '#ffc353' //改变折线颜色
                                    }
                                }
                            },
                            areaStyle: {
                                color: '#ffc353',
                            },
                            label: {
                                normal: {
                                    show: true,
                                    position: 'top'
                                }
                            },
                            data: full_score
                        }

                    ]
                });
            }
            this.setState({
                score: res.data.list,
                xAxis,
                full_score
            })
        })
    }
    finished = e => {
        if (e === '1') {
            return '已完成'
        } else if (e === '2') {
            return '已取消'
        } else {
            return '未完成'
        }
    }
    actionText = (...canshu) => {
        let text = ''
        let color = ''
        if (canshu[0] === '-1' && canshu[1] === '1') {
            text = '审核中'
            color = 'geekblue'
        } else if (canshu[0] === '1' && canshu[1] === '1') {
            text = '审核通过'
            color = 'green'
        } else if (canshu[0] === '2' && canshu[1] === '1') {
            text = '审核未通过'
            color = 'volcano'
        } else {
            text = ''
            color = 'geekblue'
        }
        return <Tag color={color}>{text}</Tag>
    }
    componentWillUnmount() {
        // 移除监听事件
        window.removeEventListener('resize', this.handleSize);
        this.setState = (state, callback) => {
            return
        }
    }
    changeBorder = (f1) => {
        this.setState({
            changeKey: f1
        })
    }
    tabchangeCount = (e, id) => {
        const paramResult = { ...this.state.paramResult }
        paramResult.subject_id = id
        this.setState({
            countNumber: e,
            paramResult
        })
    }
    changePage = e => {
        const paramResult = { ...this.state.paramResult }
        paramResult.page = e
        wrong_get_list(paramResult).then(res => {
            this.setState({
                totalCount: Number(res.data.count),
                dataList: res.data.list
            })
        })
    }
    changePage2 = e => {
        const params = { ...this.state.params }
        params.page = e
        get_student_lecture_list(params).then(ele2 => {
            this.setState({
                tableData: ele2.data.list,
                params,
                totalCount2: Number(ele2.data.total_count)
            })
        })
    }
    wrong = () => {
        this.setState({
            visible: true
        })
    }
    jiangyi = () => {
        this.setState({
            visible2: true
        })
    }
    cancel2 = () => {
        this.setState({
            visible2: false
        })
    }
    cancel = () => {
        this.setState({
            visible: false
        })
    }
    toAllExamRecord = () => {
        this.props.history.push({ pathname: "/main/AllExamRecord/" + this.state.paramResult.xiaoguanjia_student_id });
    }
    render() {
        const { model, active_list, class_list } = this.state
        const columns = [
            {
                title: '老师姓名',
                dataIndex: 'teacher_name',
                key: 'teacher_name',
                sorter: (a, b) => a.name.length - b.name.length,
                render: (text) => (
                    <span>
                        {text ? text : 'null'}
                    </span>
                ),
            },
            {
                title: '班级',
                dataIndex: 'shift_name',
                key: 'shift_name',
                sorter: (a, b) => a.classname.length - b.classname.length,
            },
            {
                title: '上课时间',
                dataIndex: 'starttime',
                key: 'starttime',
            },
            {
                title: '下课时间',
                dataIndex: 'endtime',
                key: 'endtime',
            },
            {
                title: '是否备课',
                key: 'is_beike',
                sorter: (a, b) => a.is_beike - b.is_beike,
                render: (text) => (
                    <span>
                        {text.is_beike === '1' ? <span>已备课</span> : '未备课'}
                    </span>
                ),
            },
            {
                title: '备课审核状态',
                key: 'beike_check_status',
                render: (text) => (
                    <span>
                        {this.actionText(text.beike_check_status, text.is_beike)}
                    </span>
                ),
            },
            {
                title: '是否上课',
                dataIndex: 'isfinished',
                key: 'isfinished',
                sorter: (a, b) => a.isfinished - b.isfinished,
                render: (text) => (
                    <span>
                        {this.finished(text)}
                    </span>
                ),
            }
        ];
        return (
            <div className="data-img" style={{ minWidth: '1360px', height: '100vh' }}>
                <div style={{ width: '100%' }}>
                    <div style={{ position: 'relative', top: 32 }}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <img style={{ margin: '0 auto', height: 43, width: '100%' }} src={require('../../img/btbg@2x.png')} alt="" />
                        </div>
                        <p className="m-dataHeaderP">亚诺教育REP教学数据汇总平台</p>
                    </div>
                </div>
                <div style={{ position: 'relative', top: 67 }}>
                    <div style={{ width: '100%' }}>
                        <img className='m-topImg' src={require('../../img/dbbgtc@2x.png')} alt="" />
                    </div>
                    <div style={{ position: 'absolute', top: '21px', width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                        <div style={{ width: '12%', height: '80%', display: 'flex', alignItems: 'center' }}>
                            <img className="m-topHead" src={require('../../img/xm@2x.png')} alt="" />
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '0.4rem' }}>
                                <p className="m-topHead-first">{model.name}</p>
                                <p className="m-topHead-second">学生姓名</p>
                            </div>
                        </div>
                        <div style={{ width: '12%', height: '80%', display: 'flex', alignItems: 'center' }}>
                            <img className="m-topHead" src={require('../../img/nj@2x.png')} alt="" />
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '0.4rem' }}>
                                <p className="m-topHead-first">{model.fulltime_grade}{model.fulltime_class}</p>
                                <p className="m-topHead-second">班级</p>
                            </div>
                        </div>
                        <div style={{ width: '12%', height: '80%', display: 'flex', alignItems: 'center' }}>
                            <img className="m-topHead" src={require('../../img/xx@2x.png')} alt="" />
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '0.4rem' }}>
                                <p className="m-topHead-first">{model.fulltime_school}</p>
                                <p className="m-topHead-second">学校</p>
                            </div>
                        </div>

                        <div style={{ width: '12%', height: '80%', display: 'flex', alignItems: 'center' }}>
                            <img className="m-topHead" src={require('../../img/fw@2x.png')} alt="" />
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '0.4rem' }}>
                                <p className="m-topHead-first">-</p>
                                <p className="m-topHead-second">满意度</p>
                            </div>
                        </div>
                        <div style={{ width: '12%', height: '80%', display: 'flex', alignItems: 'center' }}>
                            <img className="m-topHead" src={require('../../img/myd@2x.png')} alt="" />
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '0.4rem' }}>
                                <p className="m-topHead-first">{model.memory_capacity}</p>
                                <p className="m-topHead-second">记忆容量</p>
                            </div>
                        </div>
                        <div style={{ width: '12%', height: '80%', display: 'flex', alignItems: 'center' }}>
                            <img className="m-topHead" src={require('../../img/jiyirongl@2x.png')} alt="" />
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '0.4rem' }}>
                                <p className="m-topHead-first">-</p>
                                <p className="m-topHead-second">服务频率</p>
                            </div>
                        </div>
                        <div style={{ width: '12%', height: '80%', display: 'flex', alignItems: 'center' }}>
                            <img className="m-topHead" src={require('../../img/fwsl@2x.png')} alt="" />
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '0.4rem' }}>
                                <p className="m-topHead-first">-</p>
                                <p className="m-topHead-second">今日访客</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ position: 'relative', top: 100, display: 'flex', justifyContent: 'space-between' }}>
                    <div className="m-left-third">
                        <div className="m-kc">
                            <div style={{ width: '100%' }}>
                                <Carousel>
                                    {class_list.map((res, index) =>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }} key={index}>
                                            <div className='m-kec' >
                                                {res.map((res2, f1) =>
                                                    // onClick={() => this.changeBorder(f1, res2.subject_id)}
                                                    <div className={this.state.changeKey === f1 ? 'm-kec-m-active m-kec-m' : "m-kec-m"} key={f1}>
                                                        <img className="m-kec-img" src={require('../../img/Stocks@2x.png')} alt="" />
                                                        <div>
                                                            <p className="m-kec-p" style={{ color: '#fff', margin: 0 }}>{res2.shift_type_name}</p>
                                                            <p className="m-kec-p2" style={{ color: '#46E2FF', margin: 0 }}>{res2.subject_name}</p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </Carousel>
                            </div>
                        </div>
                        <div style={{ position: 'relative' }}>
                            <div style={{ color: '#fff', position: 'absolute', right: '0', top: '20px', cursor: 'pointer', zIndex: '99' }} onClick={this.toAllExamRecord}>详情&gt;</div>
                            <div id="main" style={{ height: 180 }}></div>
                        </div>
                        <div className="m-chen" style={{ position: 'relative' }}>
                            <img style={{ margin: '0 auto', width: '100%' }} src={require('../../img/lALPD4d8pxhTyYHM2s0Bsw_435_218.png')} alt="" />
                            <div className="m-active">
                                {active_list.map((res, index) =>
                                    <p style={{ margin: 0 }}>{Number(index) + 1 + '.' + res.title}</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="m-dimg-left" style={{ position: 'relative' }} >
                        <div style={{ width: this.state.width1, height: this.state.height1, position: 'absolute', left: this.state.left1, top: this.state.top1 }} onClick={this.jiangyi}></div>
                        <div style={{ width: this.state.width2, height: this.state.height2, position: 'absolute', left: this.state.left2, top: this.state.top2 }} onClick={this.wrong}></div>
                        <img style={{ width: '100%' }} src={require('../../img/lAHPD3Irrzm_-FzNAm7NBYs_1419_622.gif')} id="img" onLoad={this.handleImageLoaded} alt="" />
                    </div>
                </div >
                <Modal
                    width={900}
                    title="备课情况"
                    visible={this.state.visible2}
                    onCancel={this.cancel2}
                    footer={null}
                >
                    <Table rowKey={record => record.key} columns={columns} dataSource={this.state.tableData} pagination={false} />
                    <Pagination className="m-Pleft" current={this.state.params.page} onChange={this.changePage2} total={this.state.totalCount2} />
                </Modal>
                <Modal
                    width={900}
                    title="错题情况"
                    visible={this.state.visible}
                    onCancel={this.cancel}
                    footer={null}
                >
                    <Tabs defaultActiveKey='1' onChange={this.tabchangeCount}>
                        <TabPane tab="未完成解析的错题" key="1">
                            <div className="m-card" style={{ maxHeight: 400, overflowY: 'scroll', display: 'flex', flexFlow: 'column ' }}>
                                {this.state.dataList.map(res =>
                                    <div key={res.id} >
                                        <div className="listT"  >
                                            <div className="know-name-m m-flex" >
                                                <div dangerouslySetInnerHTML={{ __html: res.text }}></div>
                                            </div>
                                            <Divider dashed />
                                            <div className="shop-btn">
                                                <div className="know-title-div">
                                                    <p className="know-title">错题学生姓名:<span>{res.student_str}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <Pagination className="m-Pleft" current={this.state.paramResult.page} onChange={this.changePage} total={this.state.totalCount} />
                        </TabPane>
                        <TabPane tab="已完成解析的错题" key="3">
                            <Finished count={this.state.countNumber} paramResult={this.state.paramResult}></Finished>
                        </TabPane>
                    </Tabs>
                </Modal>
            </div>
        );
    }
}

export default index;