import React, { useState, useEffect } from 'react';
import { Button, Table } from 'antd'
import { report } from '../../../axios/http'
import { getUrlParams } from '../../../actions/getUrlParams'

const type = ['授权未提交', '提交未核销', '已核销用户']

const Reportlist = (props) => {

  const columns_report = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => (
        index + 1
      )
    }, {
      title: '客户状态',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => (
        type[index]
      )
    },
    {
      title: '未上门',
      dataIndex: 'weishangmen',
      key: 'weishangmen',
      render: (text, record, index) => (
        <div style={{ cursor: 'pointer', color: '#1890ff' }} onClick={e => to_report_detail(index + 1, 1)}>{text}</div>
      )
    },
    {
      title: '上门未报名（台账）',
      dataIndex: 'shangmenweibaoming',
      key: 'shangmenweibaoming',
      render: (text, record, index) => (
        <div style={{ cursor: 'pointer', color: '#1890ff' }} onClick={e => to_report_detail(index + 1, 2)}>{text}</div>
      )
    },
    {
      title: '在读（校管家）',
      dataIndex: 'zaidu',
      key: 'zaidu',
      render: (text, record, index) => (
        <div style={{ cursor: 'pointer', color: '#1890ff' }} onClick={e => to_report_detail(index + 1, 3)}>{text}</div>
      )
    },
    {
      title: '已结业（校管家）',
      dataIndex: 'yijieye',
      key: 'yijieye',
      render: (text, record, index) => (
        <div style={{ cursor: 'pointer', color: '#1890ff' }} onClick={e => to_report_detail(index + 1, 4)}>{text}</div>
      )
    }];

  const [user_list, user_listSet] = useState([])

  useEffect(() => {
    get_report(getUrlParams('id', props))
  }, [])

  const get_report = id => {
    report({ active_id: id }).then(res => {
      if (res.code === 0) {
        user_listSet(res.data.user_list)
      }
    })
  }

  const back = e => {
    window.history.back(-1);
  }

  const to_report_detail = (submit_status, student_status) => {
    props.history.push(`/main/reportdetail?active_id=${getUrlParams('id', props)}&submit_status=${submit_status}&student_status=${student_status}`)
  }

  return (
    <div>
      <Button onClick={back} style={{ marginBottom: '15px' }}>返回</Button>
      <Table
        columns={columns_report}
        dataSource={user_list}
        bordered
        size="middle"
        pagination={false}
      />
    </div>
  )

}

export default Reportlist

