import React, { Component } from 'react';
import { PrinterOutlined, DownloadOutlined, EditOutlined, SnippetsOutlined } from '@ant-design/icons';
import { Table } from 'antd';
const datasource = [
    {
        id: 7528,
        gradeName: "初一",
        periodName: "春季",
        groupName: "长沙",
        subjectProductName: "初中数学"
    },
    {
        id: 4932,
        gradeName: "初一",
        periodName: "春季",
        groupName: "入学诊断-初数-人教",
        subjectProductName: "初中数学"
    },
    {
        id: 8051,
        gradeName: "初一",
        periodName: "春季",
        groupName: "入学诊断-初数-鲁教",
        subjectProductName: "初中数学"
    },
    {
        id: 5048,
        gradeName: "初一",
        periodName: "春季",
        groupName: "入学诊断-初数-青岛",
        subjectProductName: "初中数学"
    },
    {
        id: 4988,
        gradeName: "初一",
        periodName: "春季",
        groupName: "入学诊断-初数-冀教",
        subjectProductName: "初中数学"
    },
    {
        id: 4948,
        gradeName: "初一",
        periodName: "春季",
        groupName: "入学诊断-初数-浙教",
        subjectProductName: "初中数学"
    },
    {
        id: 8039,
        gradeName: "初一",
        periodName: "春季",
        groupName: "入学诊断-初数-鲁教",
        subjectProductName: "初中数学",
    },
    {
        id: 5421,
        gradeName: "初一",
        periodName: "春季",
        groupName: "入学诊断-初数-湘教",
        subjectProductName: "初中数学"
    },
    {
        id: 4976,
        gradeName: "初一",
        periodName: "春季",
        groupName: "入学诊断-初数-华师",
        subjectProductName: "初中数学"
    },
    {
        id: 4964,
        gradeName: "初一",
        periodName: "春季",
        groupName: "入学诊断-初数-北师",
        subjectProductName: "初中数学"
    }
]

class test extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchList: [{
                name: '学期',
                list: [{
                    id: 5,
                    name: "暑秋",
                    selected: 1
                }, {
                    id: 4,
                    name: "寒春",
                    selected: 0
                }]
            }, {
                name: '学科',
                list: [{
                    id: 22,
                    name: "小学数学",
                    selected: 1
                }, {
                    id: 7,
                    name: "初中数学",
                    selected: 0
                }, {
                    id: 23,
                    name: "小学语文",
                    selected: 0
                }, {
                    id: 6,
                    name: "初中语文",
                    selected: 0
                }, {
                    id: 21,
                    name: "小学英语",
                    selected: 0
                }, {
                    id: 34,
                    name: "初中英语",
                    selected: 0
                }, {
                    id: 35,
                    name: "高中英语",
                    selected: 0
                }, {
                    id: 11,
                    name: "初中物理",
                    selected: 0
                }, {
                    id: 13,
                    name: "初中化学",
                    selected: 0
                }, {
                    id: 102,
                    name: "小学数学编程",
                    selected: 0
                }]
            }, {
                name: '版本',
                h: 15,
                list: [{
                    id: 1,
                    name: "人教版",
                    selected: 1
                }, {
                    id: 2,
                    name: "北师版",
                    selected: 0
                }, {
                    id: 3,
                    name: "华师版",
                    selected: 0
                }, {
                    id: 4,
                    name: "浙教版",
                    selected: 0
                }, {
                    id: 6,
                    name: "冀教版",
                    selected: 0
                }, {
                    id: 7,
                    name: "沪教版",
                    selected: 0
                }, {
                    id: 8,
                    name: "鲁教版",
                    selected: 0
                }, {
                    id: 9,
                    name: "青岛版",
                    selected: 0
                }, {
                    id: 18,
                    name: "沪科版",
                    selected: 0
                }, {
                    id: 27,
                    name: "苏科版",
                    selected: 0
                }, {
                    id: 28,
                    name: "湘教版",
                    selected: 0
                }, {
                    id: 13,
                    name: "全国版",
                    selected: 0
                }, {
                    id: 12,
                    name: "其它",
                    selected: 0
                }]
            }, {
                name: '年级',
                h: 16,
                list: [{
                    id: 65,
                    name: "初一",
                    selected: 1
                }, {
                    id: 66,
                    name: "初二",
                    selected: 0
                }, {
                    id: 67,
                    name: "初三",
                    selected: 0
                }]
            }],
            selected: [0, 0, 0, 0]
        }
    }
    selected = (i, k) => {
        let list = this.state.selected
        list[i] = k
        console.log(list)
        this.setState({
            selected: list
        })
    }
    render() {
        const columns = [
            {
                title: '试卷名称',
                dataIndex: 'groupName',
                key: 'groupName',
            },
            {
                title: '学科',
                dataIndex: 'subjectProductName',
                key: 'subjectProductName',
            },
            {
                title: '年级·学期',
                dataIndex: 'intention',
                key: 'intention',
                render: (e, v, i) =>
                    <span>{v.gradeName}·{v.periodName}</span>
            },
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                width: 400,
                render: (e, v, i) => (
                    <ul style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', margin: '0' }}>
                        <li style={{ cursor: 'pointer', alignItems: 'center' }}>
                            <PrinterOutlined style={{ fontSize: '24px' }} />
                            <p>预览/打印</p>
                        </li>
                        <li style={{ cursor: 'pointer', alignItems: 'center' }}>
                            <DownloadOutlined style={{ fontSize: '24px' }} />
                            <p>试卷下载</p>
                        </li>
                        <li style={{ cursor: 'pointer', alignItems: 'center' }}>
                            <EditOutlined style={{ fontSize: '24px' }} />
                            <p>录入得分</p>
                        </li>
                        <a>
                            <SnippetsOutlined style={{ fontSize: '24px' }} />
                            <p>查看答案</p>
                        </a>
                    </ul>
                )
            }
        ];
        return (
            <div>
                <div className="search_condition hover-container" >
                    <div >
                        {
                            this.state.searchList.map((res, index) =>
                                <div key={index}>
                                    <div className="title">{res.name}</div>
                                    <ul className="item_wrapper">
                                        {res.list.map((res_list, index_list) =>
                                            <li className={this.state.selected[index] == index_list ? 'item active' : 'item '} key={index_list} onClick={() => this.selected(index, index_list)}>
                                                {res_list.name}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            )
                        }
                    </div>
                </div>
                <Table dataSource={datasource} columns={columns} />
            </div>
        );
    }
}

export default test;