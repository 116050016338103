import {
    instance,
    loginPost,
    downLoad,
    studentInstance,
    companyId
} from './axios'

//login为了获取token
export const login = (params) => {
    return loginPost.post('/api/user/login', params)
}

//logout
export const logout = (params) => {
    return loginPost.post('/api/user/logout', params)
}

//downLoad下载试卷
export const downLoadzujuan = (params) => {
    return downLoad.post(`/api/download/self_paper?self_paper_id=${params.self_paper_id}&token=${params.token}`)
}

//instance实例作为系统内部cookie验证每个接口
export const tree = (params) => {
    return instance.post('/api/system/get_tree', params)
}

//科目接口
export const subjectList = () => {
    return instance.post('/api/system/get_subject_list')
}

//题库查询筛选接口
export const tkList = (params) => {
    return instance.post('/api/question/get_search_condition', params)
}

//获取题库列表选接口
export const question = (params) => {
    return instance.post('/api/question/get_question', params)
}

//获取已添加试题
export const get_ques_ids_cart = () => {
    return instance.post('/api/question/get_ques_ids_cart ')
}

//获取真题试卷
export const ztshijuan = (params) => {
    return instance.post('/api/paper/get_paper_list', params)
}

//获取真题试卷info
export const get_paper_info = (params) => {
    return instance.post('/api/paper/get_paperinfo', params)
}

//添加到试题篮
export const add_question_cart = (params) => {
    return instance.post('/api/question/add_question_cart', params)
}

//删除某个问题
export const remove_question_cart = (params) => {
    return instance.post('/api/question/remove_question_cart', params)
}


//获取排课信息
export const paike = (params) => {
    return instance.post('/api/self_lecture/get_course', params)
}

//上传课件
export const zidingyikejian = (params) => {
    return instance.post('/api/self_lecture/upload_lecture', params)
}

//获取进度
export const jindu = (params) => {
    return instance.post('/api/self_lecture/get_list', params)
}

//获取进度
export const jiangyishenghe = (params) => {
    return instance.post('/api/self_lecture/check_self_lecture', params)
}

//上传课程地址
export const kechendizhi = (params) => {
    return instance.post('/api/self_lecture/upload_course_url', params)
}

//获取后台权限组列表
export const quanxianList = (params) => {
    return instance.post('/api/user/get_permission_group', params)
}

//获取后台登录用户列表
export const loginUserList = (params) => {
    return instance.post('/api/user/get_user_list', params)
}

//添加用户
export const add_user = (params) => {
    return instance.post('/api/user/add_user', params)
}

//获取所有年级
export const grade_id_List = (params) => {
    return instance.post('/api/system/get_grade_list', params)
}

//获取自定义科目
export const object_id_List = (params) => {
    return instance.post('/api/system/get_own_subject_list', params)
}

//删除账号
export const delete_user = (params) => {
    return instance.post('/api/user/del_user', params)
}

//获取用户详情
export const get_user_detail = (params) => {
    return instance.post('/api/user/get_user_detail', params)
}

//修改账号权限
export const edit_user = (params) => {
    return instance.post('/api/user/edit_user', params)
}

//管理员修改用户密码
export const change_password = (params) => {
    return instance.post('/api/user/change_password', params)
}

//自己重置密码
export const change_password_byself = (params) => {
    return instance.post('/api/user/change_password_byself', params)
}

//获取教研组
export const get_teaching_group = (params) => {
    return instance.post('/api/system/get_teaching_group', params)
}

//添加教研组
export const add_teaching_group = (params) => {
    return instance.post('/api/system/add_teaching_group', params)
}

//删除教研组
export const del_teaching_group = (params) => {
    return instance.post('/api/system/del_teaching_group', params)
}

//修改教研组
export const edit_teaching_group = (params) => {
    return instance.post('/api/system/edit_teaching_group', params)
}

//获取修改教研组
export const get_teaching_group_detail = (params) => {
    return instance.post('/api/system/get_teaching_group_detail', params)
}

//获取我的试题篮(按照题型分组)
export const get_question_cart = () => {
    return instance.post('/api/question/get_question_cart')
}

//移除某类题型
export const remove_question_type = (params) => {
    return instance.post('/api/question/remove_question_type', params)
}

//获取我的试题篮(按照题型分组)
export const get_next_cart = () => {
    return instance.post('/api/question/get_next_cart')
}

//按照类排序
export const set_ques_type_sort = (params) => {
    return instance.post('/api/question/set_ques_type_sort', params)
}

//按照类里的题目排序
export const set_ques_sort = (params) => {
    return instance.post('/api/question/set_ques_sort', params)
}

//修改试卷的类名
export const set_show_type_name = (params) => {
    return instance.post('/api/question/set_show_type_name', params)
}

//批量修改类型分数
export const set_pager_score = (params) => {
    return instance.post('/api/question/set_pager_score', params)
}
export const get_grade_list = () => {
    return instance.post('/api/system/get_grade_list')
}
export const get_own_subject_list = (params) => {
    return instance.post('/api/system/get_own_subject_list')
}

//试卷保存设置用于单个数据上传
export const set_pager_config = (params) => {
    return instance.post('/api/question/set_pager_config', params)
}

//试卷保存设置(一起上传)
export const set_self_pager = (params) => {
    return instance.post('/api/question/set_self_pager', params)
}


//获取可以设置跨校区的用户
export const get_user_by_set = () => {
    return instance.post('/api/user/get_user_by_set')
}

//获取公司列表
export const get_company_list = () => {
    return companyId.post('/api/system/get_company_list')
}

//多校区设置
export const set_user_school_rela = (params) => {
    return instance.post('/api/user/set_user_school_rela', params)
}


//通过建题的subject获取题型
export const get_ques_type_list = (params) => {
    return instance.post('/api/system/get_ques_type_list', params)
}

//创建自己的试题
export const add_question = (params) => {
    return instance.post('/api/question/add_question', params)
}

//删除自己的试题
export const del_question = (params) => {
    return instance.post('/api/question/del_question', params)
}

//获取详情接口
export const get_questioninfo = (params) => {
    return instance.post('/api/question/get_questioninfo', params)
}

//编辑试题
export const edit_question_question = (params) => {
    return instance.post('/api/question/edit_question', params)
}

//获取自定义组卷列表
export const get_list = (params) => {
    return instance.post('/api/self_paper/get_list', params)
}

//获取自定义组卷对应的题目
export const get_self_paper_question = (params) => {
    return instance.post('/api/self_paper/get_self_paper_question', params)
}

//审核自定义组卷
export const check_self_paper = (params) => {
    return instance.post('/api/self_paper/check_self_paper', params)
}

//预览组件
export const get_self_paperinfo = (params) => {
    return instance.post('/api/self_paper/get_self_paperinfo', params)
}

//删除自定义组卷
export const del_self_paper = (params) => {
    return instance.post('/api/self_paper/del_self_paper', params)
}

//根据科目id获取教材版本
export const get_version_by_subject_id = (params) => {
    return instance.post('/api/system/get_version_by_subject_id', params)
}

//根据科目id、教材版本获取教材
export const get_course_by_course_id = (params) => {
    return instance.post('/api/system/get_course_by_course_id', params)
}

//获取年级、章节模块
export const get_course_section = (params) => {
    return instance.post('/api/system/get_course_section', params)
}

//提交年级、章节模块跟知识点对应关系
export const submit_knowledge_section = (params) => {
    return instance.post('/api/system/submit_knowledge_section', params)
}

//获取一级设置的年级、章节模块跟知识点对应关系
export const get_knowledge_by_section_id = (params) => {
    return instance.post('/api/system/get_knowledge_by_section_id', params)
}

//获取校管家科目
export const get_xiaoguanjia_subject = (params) => {
    return instance.post('/api/wrong/get_xiaoguanjia_subject', params)
}

//获取校管家科目
export const get_xiaoguanjia_grade = (params) => {
    return instance.post('/api/wrong/get_xiaoguanjia_grade', params)
}

//获取校管家班型
export const get_shift_type = () => {
    return instance.post('/api/system/get_shift_type')
}

// 根据科目、年级选择校管家对应的班级
export const get_xiaoguanjia_class = (params) => {
    return instance.post('/api/wrong/get_xiaoguanjia_class', params)
}

//根据班级id获取校管家的学生
export const get_xiaoguanjia_student = (params) => {
    return instance.post('/api/wrong/get_xiaoguanjia_student', params)
}

//提交错题
export const submit_wrong_question = (params) => {
    return instance.post('/api/wrong/submit_wrong_question', params)
}

//查询所有错题
export const wrong_get_list = (params) => {
    return instance.post('/api/wrong/get_list', params)
}

//查询单个错题
export const get_detail = (params) => {
    return instance.post('/api/wrong/get_detail', params)
}

//删除错题
export const del_wrong_question = (params) => {
    return instance.post('/api/wrong/del_wrong_question', params)
}

//获取解析的选项
export const get_analysis_option = (params) => {
    return instance.post('/api/wrong/get_analysis_option', params)
}

//根据章节模块跟知识点对应关系
export const get_knowledge = (params) => {
    return instance.post('/api/system/get_knowledge_by_section_id', params)
}

//错题解析
export const analysis_question = (params) => {
    return instance.post('/api/wrong/analysis_question', params)
}

//创建练习册
export const create_book = (params) => {
    return instance.post('/api/book/create_book', params)
}

//练习册列表
export const book_get_list = (params) => {
    return instance.post('/api/book/get_list', params)
}

//根据练习册id获取对应的章节
export const get_chapter_list = (params) => {
    return instance.post('/api/book/get_chapter_list', params)
}

//获取练习册题目
export const get_chapter_question = (params) => {
    return instance.post('/api/book/get_chapter_question', params)
}

//创建练习册章节
export const create_chapter = (params) => {
    return instance.post('/api/book/create_chapter', params)
}

//章节编辑
export const edit_chapter = (params) => {
    return instance.post('/api/book/edit_chapter', params)
}

//提交练习册试题
export const create_question = (params) => {
    return instance.post('/api/book/create_question', params)
}

//练习册题目编辑
export const edit_question = (params) => {
    return instance.post('/api/book/edit_question', params)
}

//删除练习册试题
export const del_question_children = (params) => {
    return instance.post('/api/book/del_question', params)
}

//删除章节
export const del_chapter = (params) => {
    return instance.post('/api/book/del_chapter', params)
}

//编辑练习册
export const edit_book = (params) => {
    return instance.post('/api/book/edit_book', params)
}

//删除练习册
export const del_book = (params) => {
    return instance.post('/api/book/del_book', params)
}

//练习册章节里面题目排序
export const set_chapter_ques_sort = (params) => {
    return instance.post('/api/book/set_chapter_ques_sort', params)
}

//获取校管家原来作业吧（晚辅导）的列表
export const xiaoguanjia_zuoyeba_list = (params) => {
    return instance.post('/api/wrong/xiaoguanjia_zuoyeba_list', params)
}

//根据校管家的作业吧我们重新创建自己的新班
export const create_own_class = (params) => {
    return instance.post('/api/wrong/create_own_class', params)
}

//错题编辑
export const edit_wrong_question = (params) => {
    return instance.post('/api/wrong/edit_wrong_question', params)
}

//获取我们自定义设置的作业吧（晚辅导）班级列表
export const get_own_class_list = (params) => {
    return instance.post('/api/wrong/get_own_class_list', params)
}

//编辑班级
export const edit_own_class = (params) => {
    return instance.post('/api/wrong/edit_own_class', params)
}

//删除班级
export const del_own_class = (params) => {
    return instance.post('/api/wrong/del_own_class', params)
}

//找错题
export const get_recommend_question = (params) => {
    return instance.post('/api/question/get_recommend_question', params)
}

//AI找题
export const get_rela_question = (params) => {
    return instance.post('/api/wrong/get_rela_question', params)
}

//插入推荐相关试题
export const add_wrong_question_cart = (params) => {
    return instance.post('/api/wrong/add_wrong_question_cart', params)
}

//获取当前 错题试题篮列表
export const get_wrong_question_cartstion_cart = (params) => {
    return instance.post('/api/wrong/get_wrong_question_cart', params)
}

//获取ai提篮里的题id
export const getques_ids_cart = (params) => {
    return instance.post('/api/wrong/get_ques_ids_cart', params)
}

//移除错题篮题目
export const remove_wrong_cart = (params) => {
    return instance.post('/api/wrong/remove_wrong_cart', params)
}

//提交错题篮题目
export const submit_wrong_question_cart = (params) => {
    return instance.post('/api/wrong/submit_wrong_question_cart', params)
}

//小亚报表
export const get_report = (params) => {
    return instance.post('/api/report/get_index', params)
}

//创建我的资源
export const create_directory = (params) => {
    return instance.post('/api/resources/create_directory', params)
}

//编辑我的资源
export const edit_directory = (params) => {
    return instance.post('/api/resources/edit_directory', params)
}

//删除我的资源
export const del_directory = (params) => {
    return instance.post('/api/resources/del_directory', params)
}

//获取我的资源
export const get_directory = (params) => {
    return instance.post('/api/resources/get_directory', params)
}

//获取我的资源文件夹里的资源
export const get_directory_file = (params) => {
    return instance.post('/api/resources/get_directory_file', params)
}

//修改某个目录下的文件
export const edit_file = (params) => {
    return instance.post('/api/resources/edit_file', params)
}

//删除目录下的文件
export const del_directory_file = (params) => {
    return instance.post('/api/resources/del_directory_file', params)
}

//收藏接口
export const save_file = (params) => {
    return instance.post('/api/resources/save_file', params)
}

//获取备课文件
export const get_course_file = (params) => {
    return instance.post('/api/self_lecture/get_course_file', params)
}

//获取老师底下的学生
export const get_student_by_teacher = (params) => {
    return instance.post('/api/wrong/get_student_by_teacher', params)
}

//获取老师底下的学生的错题集
export const get_recommend_collect = (params) => {
    return instance.post('/api/wrong/get_recommend_collect', params)
}

//获取老师底下的学生的错题集对应错题
export const get_recommend_collect_question = (params) => {
    return instance.post('/api/wrong/get_recommend_collect_question', params)
}

//根据老师获取他所带的年级
export const get_grade_by_teacher = (params) => {
    return instance.post('/api/wrong/get_grade_by_teacher', params)
}

//真题试卷预览
export const preview_paper = (params) => {
    return instance.post('/api/paper/preview_paper', params)
}

//根据老师的校管家id和年级获取对应的学生列表
export const getStudent_by_teacher = (params) => {
    return instance.post('/api/wrong/get_student_by_teacher', params)
}

//获取所有校管家信息
export const get_all_zuoyeba = (params) => {
    return instance.post('/api/wrong/get_all_zuoyeba', params)
}

//作业吧接口上传学生
export const set_student_wrong_analysis = (params) => {
    return instance.post('/api/wrong/set_student_wrong_analysis', params)
}

//作业吧编辑上传学生
export const get_wrong_student_set = (params) => {
    return instance.post('/api/wrong/get_wrong_student_set', params)
}

//作业吧编辑上传学生
export const get_zuoyeba_set_subject = (params) => {
    return instance.post('/api/wrong/get_zuoyeba_set_subject', params)
}

//作业吧编辑上传学生
export const get_student_list = (params) => {
    return instance.post('/api/system/get_student_list', params)
}

//rep数据
export const report_student = (params) => {
    return instance.post('/api/report/student', params)
}

//获取考试列表
export const exam_get_list = (params) => {
    return instance.post('/api/exam/get_list', params)
}

//获取考试类型
export const get_exam_type = (params) => {
    return instance.post('/api/exam/get_exam_type', params)
}

//创建考试
export const create_exam = (params) => {
    return instance.post('/api/exam/create_exam', params)
}

//删除考试
export const del_exam = (params) => {
    return instance.post('/api/exam/del_exam', params)
}

//编辑考试
export const edit_exam = (params) => {
    return instance.post('/api/exam/edit_exam', params)
}

//编辑考试
export const get_exam_result = (params) => {
    return instance.post('/api/exam/get_exam_result', params)
}

//老师登录后首页
export const teacher_report = (params) => {
    return instance.post('/api/report/teacher', params)
}

//成绩录入
export const exam_result_entry = (params) => {
    return instance.post('/api/exam/exam_result_entry', params)
}

//删除学生
export const del_student_exam_result = (params) => {
    return instance.post('/api/exam/del_student_exam_result', params)
}

//base64格式转换
export const base64_to_img = (params) => {
    return instance.post('/api/convert/base64_to_img', params)
}

//学员信息
export const get_student_detail = (params) => {
    return instance.post('/api/report/get_student_detail', params)
}

//设置学员记忆容量
export const set_memory_capacity = (params) => {
    return instance.post('/api/report/set_memory_capacity', params)
}

//设置学员记忆容量
export const studentwrong_ques_list = (params) => {
    return instance.post('api/report/get_student_wrong_ques_list', params)
}

//获取学管id
export const get_all_master = (params) => {
    return instance.post('/api/system/get_all_master', params)
}

//备课学员
export const get_course_detail = (params) => {
    return instance.post('/api/task/get_course_detail', params)
}

//布置作业接口
export const creat_task = (params) => {
    return instance.post('/api/task/create', params)
}

//布置作业接口
export const get_task = (params) => {
    return instance.post('/api/task/get_task_detail', params)
}

//上传学生单个作业接口
export const upload_task = (params) => {
    return instance.post('/api/task/upload_task', params)
}

//讲义模块
export const get_preparation_detail = (params) => {
    return instance.post('/api/lecture/get_preparation_detail', params)
}

//讲义模块
export const get_course_sectionList = (params) => {
    return instance.post('/api/lecture/get_course_section_list', params)
}

//讲义模块
export const get_standard_bySection = (params) => {
    return instance.post('/api/system/get_standard_by_section_id', params)
}

//获取试题
export const get_ques_by_ques_ids = (params) => {
    return instance.post('/api/question/get_ques_by_ques_ids', params)
}
export const get_student_lecture_list = (params) => {
    return instance.post('/api/self_lecture/get_student_lecture_list', params)
}

//保存讲义
export const save_preparation = (params) => {
    return instance.post('/api/lecture/save_lecture', params)
}

//讲义详情
export const get_lecture_detail = (params) => {
    return instance.post('/api/lecture/get_lecture_detail', params)
}


//获取所有教室
export const get_room_list = (params) => {
    return instance.post('/api/system/get_room_list', params)
}

//秋季班
export const get_zuoyeba_view = (params) => {
    return instance.post('/api/wrong/get_zuoyeba_view', params)
}

//提交晚辅导答疑解惑
export const submit_answer = (params) => {
    return instance.post('/api/answer/submit_answer', params)
}

//修改晚辅导答疑解惑
export const edit_answer = (params) => {
    return instance.post('/api/answer/edit_answer', params)
}

//获取晚辅导答疑解惑
export const get_answer_list = (params) => {
    return instance.post('/api/answer/get_answer_list', params)
}

//获取错题知识点
export const get_wrong_knowledge_list = (params) => {
    return instance.post('/api/wrong/get_wrong_knowledge_list', params)
}

//获取错题知识点
export const get_check_failed = (params) => {
    return instance.post('/api/self_lecture/get_check_failed', params)
}

//获取错题知识点
export const read_message = (params) => {
    return instance.post('/api/self_lecture/read_message', params)
}

//获取老师统计
export const get_teacher_statistics = (params) => {
    return instance.post('/api/report/get_teacher_statistics', params)
}

//过去教教师科目
export const get_teacher_subject_list = (params) => {
    return instance.post('/api/answer/get_teacher_subject_list', params)
}

//晚辅导数据统计
export const wanfudao = (params) => {
    return instance.post('/api/report/wanfudao', params)
}

//晚辅导详情
export const wanfudao_detail = (params) => {
    return instance.post('/api/report/wanfudao_detail', params)
}

//晚辅导详情
export const un_submit_push = (params) => {
    return instance.post('/api/report/un_submit_push', params)
}

//获取学生成绩
export const get_all_exam_record = (params) => {
    return instance.post('/api/report/get_all_exam_record', params)
}

//提交投诉
export const create_complaint = (params) => {
    return instance.post('/api/feedback/create_complaint', params)
}

//获取投诉列表
export const feedback_get_list = (params) => {
    return instance.post('/api/feedback/get_list', params)
}

//处理投诉
export const deal_complaint = (params) => {
    return instance.post('/api/feedback/deal_complaint', params)
}

//获取某个投诉的详情
export const get_complaint_detail = (params) => {
    return instance.post('/api/feedback/get_complaint_detail', params)
}

//获取所有学生
export const get_all_student = (params) => {
    return instance.post('/api/system/get_all_student', params)
}

//获取晚辅指标
export const get_wrong_index = (params) => {
    return instance.post('/api/answer/get_wrong_index', params)
}

//抽奖活动列表
export const get_lottery_list = (params) => {
    return instance.post('/api/lottery/get_list', params)
}

//抽奖列表
export const get_record_list = (params) => {
    return instance.post('/api/lottery/get_record_list', params)
}

//抽奖活动详情
export const get_lottery_detail = (params) => {
    return instance.post('/api/zhongtai/lottery/get_detail', params)
}

//创建抽奖活动
export const add_lottery_lottery = (params) => {
    return instance.post('/api/zhongtai/lottery/add_lottery', params)
}

//修改抽奖活动
export const edit_lottery_lottery = (params) => {
    return instance.post('/api/zhongtai/lottery/edit_lottery', params)
}

//添加抽奖奖品
export const add_lottery_goods = (params) => {
    return instance.post('/api/zhongtai/lottery/add_lottery_goods', params)
}

//添加抽奖奖品
export const del_lottery_goods = (params) => {
    return instance.post('/api/zhongtai/lottery/del_lottery_goods', params)
}

//修改抽奖奖品
export const edit_lottery_goods = (params) => {
    return instance.post('/api/zhongtai/lottery/edit_lottery_goods', params)
}

//设置奖品排序
export const set_goods_sort = (params) => {
    return instance.post('/api/zhongtai/lottery/set_goods_sort', params)
}

//抽奖核销
export const check_lottery = (params) => {
    return instance.post('/api/lottery/check_lottery', params)
}

//抽奖信息完善
export const remark_lottery_record = (params) => {
    return instance.post('/api/lottery/remark_lottery_record', params)
}

//核销备注
export const get_record_detail = (params) => {
    return instance.post('/api/lottery/get_record_detail', params)
}

//抽奖任务完成
export const set_completed = (params) => {
    return instance.post('/api/lottery/set_completed', params)
}

//学生登录
export const student_login = (params) => {
    return loginPost.post('/api/student/login', params)
}

//学生退出
export const student_logout = (params) => {
    return loginPost.post('/api/student/logout', params)
}

//获取学生登录后错题列表
export const get_user_wrong_question = (params) => {
    return studentInstance.post('/api/student/get_user_wrong_question', params)
}

//获取学生PC端的学科
export const Sget_xiaoguanjia_subject = (params) => {
    return studentInstance.post('/api/student/get_xiaoguanjia_subject', params)
}

//获取学生PC端的年级
export const Sget_xiaoguanjia_grade = (params) => {
    return studentInstance.post('/api/student/get_xiaoguanjia_grade', params)
}

//获取警告标签
export const get_tag_list = (params) => {
    return instance.post('/api/answer/get_tag_list', params)
}

//报名活动列表通用字段
export const get_common_field = (params) => {
    return instance.post('/api/zhongtai/active/get_common_field', params)
}

//报名活动列表字段类型
export const get_common_type = (params) => {
    return instance.post('/api/zhongtai/active/get_common_type', params)
}

//报名活动列表
export const get_active_list = (params) => {
    return instance.post('/api/zhongtai/active/get_list', params)
}

//创建活动
export const create_active = (params) => {
    return instance.post('/api/zhongtai/active/create', params)
}

//修改活动
export const edit_active_active = (params) => {
    return instance.post('/api/zhongtai/active/edit_active', params)
}

//报名活动详情
export const get_active_detail = (params) => {
    return instance.post('/api/zhongtai/active/get_detail', params)
}

//报名活动详情列表
export const get_active_user_list = (params) => {
    return instance.post('/api/zhongtai/active/get_user_list', params)
}

//报名核销
export const check_active_active = (params) => {
    return instance.post('/api/zhongtai/active/check_active', params)
}

//获取授权线索
export const get_grant_user_list = (params) => {
    return instance.post('/api/zhongtai/active/get_grant_user_list', params)
}

//教学大纲模块

//获取所有科目
export const get_all_subject = (params) => {
    return instance.post('/api/teaching/get_all_subject', params)
}

//获取子科目
export const get_sub_subject = (params) => {
    return instance.post('/api/teaching/get_sub_subject', params)
}

//根据子科目获取查询条件
export const get_condition_by_sub_subject_id = (params) => {
    return instance.post('/api/teaching/get_condition_by_sub_subject_id', params)
}

//创建教学大纲类型
export const create_teachingtype = (params) => {
    return instance.post('/api/teaching/create_teachingtype', params)
}

//修改教学大纲类型
export const edit_teachingtype = (params) => {
    return instance.post('/api/teaching/edit_teachingtype', params)
}

//获取教学大纲类型列表
export const get_teaching_type_list = (params) => {
    return instance.post('/api/teaching/get_teaching_type_list', params)
}

//删除教学大纲类型
export const del_teachingtype = (params) => {
    return instance.post('/api/teaching/del_teachingtype', params)
}

//创建教学课程
export const create_teaching_lession = (params) => {
    return instance.post('/api/teaching/create_teaching_lession', params)
}

//修改教学课程
export const edit_teaching_lession = (params) => {
    return instance.post('/api/teaching/edit_teaching_lession', params)
}

//根据课程类型id获取课程列表
export const get_lession_list = (params) => {
    return instance.post('/api/teaching/get_lession_list', params)
}

//删除教学课程
export const del_teaching_lession = (params) => {
    return instance.post('/api/teaching/del_teaching_lession', params)
}

//新获取成绩
export const get_exam_result_new = (params) => {
    return instance.post('/api/exam/get_exam_result_new', params)
}

//设置教学大纲新参数
export const set_condition = (params) => {
    return instance.post('/api/teaching/set_condition', params)
}

//参数删除
export const remove_condition = (params) => {
    return instance.post('/api/teaching/remove_condition', params)
}

//获取授权用户分享列表
export const get_user_share_list = (params) => {
    return instance.post('/api/zhongtai/active/get_user_share_list', params)
}

//表单提交用户删除
export const del_form_user = (params) => {
    return instance.post('/api/zhongtai/active/del_form_user', params)
}

//教室设置
export const set_room = (params) => {
    return instance.post('/api/schedule/set_room', params)
}

//获取已经设置的房间信息
export const get_set_room = (params) => {
    return instance.post('/api/schedule/get_set_room', params)
}

//获取教师列表
export const get_teacher_list = (params) => {
    return instance.post('/api/schedule/get_teacher_list', params)
}

//获取老师风格
export const get_teaching_style = (params) => {
    return instance.post('/api/schedule/get_teaching_style', params)
}

//设置老师属性
export const set_teacher = (params) => {
    return instance.post('/api/schedule/set_teacher', params)
}

//获取已经设置的老师信息
export const get_set_teacher = (params) => {
    return instance.post('/api/schedule/get_set_teacher', params)
}

//设置老师上课时间
export const set_teacher_time = (params) => {
    return instance.post('/api/schedule/set_teacher_time', params)
}

//删除已经设置的老师时间
export const del_teacher_time = (params) => {
    return instance.post('/api/schedule/del_teacher_time', params)
}

//获取已经设置的老师时间列表
export const get_set_teacher_time = (params) => {
    return instance.post('/api/schedule/get_set_teacher_time', params)
}

//设置学生要求的条件
export const set_student_condition = (params) => {
    return instance.post('/api/schedule/set_student_condition', params)
}

//设置学生要求的时间
export const set_student_time = (params) => {
    return instance.post('/api/schedule/set_student_time', params)
}

//删除已经设置的学生要求时间
export const del_student_time = (params) => {
    return instance.post('/api/schedule/del_student_time', params)
}

//获取已经设置的学生时间列表
export const get_set_student_time = (params) => {
    return instance.post('/api/schedule/get_set_student_time', params)
}

//提交沟通记录
export const submit_chat_record = (params) => {
    return instance.post('/api/answer/submit_chat_record', params)
}

//获取某个学生的沟通记录列表
export const get_chat_record_list = (params) => {
    return instance.post('/api/answer/get_chat_record_list', params)
}

//续费相关
export const set_continue = (params) => {
    return instance.post('/api/report/set_continue', params)
}

//活动统计报表
export const report = (params) => {
    return instance.post('/api/zhongtai/active/report', params)
}

//复制活动
export const copy_active = (params) => {
    return instance.post('/api/zhongtai/active/copy_active', params)
}

//删除活动
export const del_active = (params) => {
    return instance.post('/api/zhongtai/active/del_active', params)
}

//删除活动
export const report_detail = (params) => {
    return instance.post('/api/zhongtai/active/report_detail', params)
}

//评价表提交
export const set_rep_data = (params) => {
    return instance.post('/api/report/set_rep_data', params)
}

//修改活动表单备注信息
export const remark_form = (params) => {
    return instance.post('/api/zhongtai/active/remark_form', params)
}

//获取设置的REP数据
export const get_rep_data = (params) => {
    return instance.post('/api/report/get_rep_data', params)
}

//学生端绑定情况
export const bind_student_data = (params) => {
    return instance.post('/api/report/bind_student_data', params)
}

//无练习
export const set_no_task = (params) => {
    return instance.post('/api/task/set_no_task', params)
}

//获取有错题的学生
export const get_index_student = (params) => {
    return instance.post('/api/report/get_index_student', params)
}

//获取班主任列表
export const get_headmaster_list = (params) => {
    return instance.post('/api/self_lecture/get_headmaster_list', params)
}

//同步课程表
export const sync_xiaoguanjia_course = (params) => {
    return instance.post('/api/xiaoguanjia/sync_xiaoguanjia_course', params)
}


//删除图片
export const del_file = (params) => {
    return instance.post('/api/upload/del_file', params)
}


//设置学习规划
export const set_learning_plan = (params) => {
    return instance.post('/api/report/set_learning_plan', params)
}

//设置学习规划
export const set_attachment = (params) => {
    return instance.post('/api/report/set_attachment', params)
}
//删除附件
export const del_attachment = (params) => {
    return instance.post('/api/report/del_attachment', params)
}
