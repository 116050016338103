import React, { Component } from 'react';
import { Table, Row, Col, Input, Button, Spin, Pagination, message, Modal, Select, Popconfirm, Tooltip } from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import {
  get_active_user_list,
  check_active_active,
  del_form_user,
  remark_form
} from '../../../axios/http'
import { tableToExcel } from '../../../actions/tableToExcel'

const { Option } = Select

class Submitdrawlist extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active_id: this.props.active_id,
      field_json: this.props.field_json_str ? this.props.field_json_str.field_list : [],
      columns: [],
      data: [],
      param_json: {},
      search_json: [],
      json: [],
      page: 1,
      page_size: 10,
      count: 0,
      loading: true,
      visible: false,
      record_id: '',
      index: '',
      remark_params: {
        form_id: '',
        student_company_id: '',
        is_student: '',
        student: '',
        mobile: '',
        remark: ''
      }
    }
  }

  componentDidMount = () => {
    let obj = [], param_json = {}
    this.state.field_json.map(item => {
      if (!item.item_list) {
        item.item_list = []
      }
      if (item.item_list.length > 0) {
        obj.push({
          title: item.title,
          dataIndex: item.field,
          key: item.field,
          has_select: false,
          item_list: item.item_list,
          render: (text, record, index) => {
            let t = ''
            item.item_list.some(item2 => {
              if (item2.id == text) {
                t = item2.name
                return true
              }
            })
            return t || text
          }
        })
      } else {
        obj.push({
          title: item.title,
          dataIndex: item.field,
          key: item.field,
          has_select: false,
          item_list: item.item_list,
          render: (text) => {
            if (text) { } else { return '' }
            if (text.indexOf('png') != -1 || text.indexOf('jpeg') != -1) {
              return <img style={{ maxHeight: '80px' }} src={text}></img>
            } else {
              return text
            }
          }
        })
      }
    })

    const columns = [
      {
        title: '用户头像',
        dataIndex: 'headimgurl',
        key: 'headimgurl',
        render: (text, record, index) => (
          <img src={text} style={{ width: '50px' }} alt="" />
        )
      },
      {
        title: '用户名',
        dataIndex: 'nickname',
        key: 'nickname'
      },
      {
        title: '参与时间',
        dataIndex: 'add_time',
        key: 'add_time'
      },
      ...obj,
      {
        title: '备注',
        dataIndex: 'remark',
        key: 'remark'
      },
      {
        title: '操作',
        key: 'active',
        dataIndex: 'active',
        render: (text, record, index) => (
          // <span style={{ cursor: 'pointer', color: '#1890ff' }} onClick={e => this.get_record_detail(record.id)}>完善信息</span> / 
          record.is_check == '1'
            ?
            <div style={{ whiteSpace: 'pre' }}>
              <span>已核销</span>
              /
              <span style={{ cursor: 'pointer', color: '#1890ff' }} onClick={e => this.get_record_detail(record.id, index)}>完善信息</span>
            </div>
            :
            <div style={{ whiteSpace: 'pre' }}>
              <Popconfirm placement="top" title={'是否确认核销？'} onConfirm={e => this.check_active_active(record.id, record.nickname)} okText="是" cancelText="否">
                <span style={{ cursor: 'pointer', color: '#1890ff' }}>核销</span>
              </Popconfirm>
              /
              <Popconfirm placement="top" title={'是否确认删除？'} onConfirm={e => this.del_form_user(record.id)} okText="是" cancelText="否">
                <span style={{ cursor: 'pointer', color: '#1890ff' }}>删除</span>
              </Popconfirm>
              /
              <span style={{ cursor: 'pointer', color: '#1890ff' }} onClick={e => this.get_record_detail(record.id, index)}>完善信息</span>
            </div>

        )
      }
    ];

    this.setState({
      columns,
      param_json,
      json: obj
    })

    this.get_active_user_list()
  }

  del_form_user = (id) => {
    let data = {
      form_id: id
    }
    del_form_user(data).then(res => {
      if (res.code === 0) {
        message.success('删除成功！')
        this.get_active_user_list()
      } else {
        message.error(res.message)
      }
    })
  }

  get_active_user_list = () => {
    this.setState({
      loading: true
    })

    let data = {
      active_id: this.state.active_id,
      json_str: JSON.stringify(this.state.param_json),
      page: this.state.page,
      page_size: this.state.page_size
    }

    get_active_user_list(data).then(res => {
      if (res.code == 0) {
        this.setState({
          data: res.data.user_list,
          loading: false,
          count: Number(res.data.total_count)
        })
      } else {
        message.error(res.message)
      }
    })
  }

  changePage = e => {
    this.setState({
      page: e
    }, () => {
      this.get_active_user_list()
    })
  }

  onOk = e => {
    this.get_active_user_list()
    this.cancel()
  }

  cancel = e => {
    this.setState({
      visible: !this.state.visible,
      is_student: '',
      student_company_id: '',
      student: '',
      mobile: '',
      remark: ''
    })
  }

  back = () => {
    this.props.back()
  }

  check_active_active = (record_id, nickname) => {
    let data = {
      active_common_form_id: record_id
    }
    check_active_active(data).then(res => {
      if (res.code == 0) {
        message.success(nickname + '核销成功！')
        this.get_active_user_list()
      } else {
        message.error(res.message)
      }
    })
  }

  addSearch = e => {
    let s = this.state.search_json,
      t = {
        title: '',
        field: '',
        item_list: []
      }
    s.push(t)
    this.setState({
      search_json: s
    })
  }

  fieldChange = (e, index) => {
    let s = JSON.stringify(this.state.search_json),
      s2 = JSON.parse(s)
    s2[index].field = typeof (e) == 'undefined' ? '' : this.state.json[e].key
    s2[index].item_list = typeof (e) == 'undefined' ? [] : this.state.json[e].item_list ? this.state.json[e].item_list : []
    this.setState({
      search_json: s2
    })
  }

  minuSearch = e => {
    let s = JSON.stringify(this.state.search_json),
      s2 = JSON.parse(s)
    let t = this.state.param_json
    delete t[s2[s2.length - 1].field]
    s2.splice(s2.length - 1, 1)
    this.setState({
      search_json: s2,
      param_json: t
    })
  }

  get_record_detail = (record_id, index) => {
    let s = this.state.remark_params
    s['form_id'] = record_id
    s['student_company_id'] = this.state.data[index].company_id
    s['is_student'] = this.state.data[index].is_student
    s['student'] = this.state.data[index].student
    s['mobile'] = this.state.data[index].mobile
    s['remark'] = this.state.data[index].remark
    this.setState({
      remark_params: s,
      visible: !this.state.visible
    })
  }

  remark_lottery_record = e => {
    console.log(this.state.remark_params)
    let data = this.state.remark_params
    remark_form(data).then(res => {
      if (res.code == 0) {
        message.success('提交成功！')
        this.get_active_user_list()
        this.setState({
          visible: false
        })
      } else {
        message.error(res.message)
      }
    })
  }

  searchChange = (e, id) => {
    let s = this.state.param_json
    if (e) {
      s[id] = typeof (e) == 'undefined' ? '' : typeof (e) == 'object' ? e.target.value : e
    } else {
      delete s[id]
    }
    this.setState({
      param_json: s
    })
  }

  sizeChange = (c, p) => {
    if (p == this.state.count) {
      this.setState({
        page: 0,
        page_size: p
      }, () => {
        this.get_active_user_list()
      })
    } else {
      this.setState({
        page: 1,
        page_size: p
      }, () => {
        this.get_active_user_list()
      })
    }
  }

  remarkChange = (e, id) => {
    let s = this.state.remark_params
    s[id] = typeof (e) == 'undefined' ? '' : typeof (e) == 'object' ? e.target.value : e
    this.setState({
      remark_params: s
    })
  }

  render() {
    return (
      <div>
        <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
          <div className="flex">
            <div style={{ marginRight: '10px' }}>
              <span>用户名：</span>
              <Input style={{ width: 150 }} placeholder="请输入" onChange={e => this.searchChange(e, 'nickname')} allowClear></Input>
            </div>
            {
              this.state.search_json.map((item, index) => {
                return (
                  <div style={{ marginRight: '10px' }} key={index}>
                    <Select onChange={e => this.fieldChange(e, index)} style={{ width: 100 }} placeholder="请选择" allowClear>
                      {
                        this.state.json.map((item2, index2) => {
                          return (
                            <Option key={index2} disabled={item2.has_select} value={index2}>{item2.title}</Option>
                          )
                        })
                      }
                    </Select>
                    ：
                    {
                      item.item_list.length > 0 ?
                        <Select disabled={item.field ? false : true} style={{ width: 100 }} placeholder="请输入" onChange={e => this.searchChange(e, item.field)} allowClear>
                          {
                            item.item_list.map((item2, index2) => {
                              return (
                                <Option key={item2.id} value={item2.id}>{item2.name}</Option>
                              )
                            })
                          }
                        </Select>
                        :
                        <Input disabled={item.field ? false : true} style={{ width: 100 }} placeholder="请输入" onChange={e => this.searchChange(e, item.field)} allowClear></Input>
                    }
                  </div>
                )
              })
            }
            {
              this.state.search_json.length == this.state.json.length
                ?
                ''
                :
                <Tooltip placement="top" title="点击添加搜索条件">
                  <PlusCircleOutlined onClick={this.addSearch} style={{ marginRight: '10px', cursor: 'pointer' }} />
                </Tooltip>
            }
            <Tooltip placement="top" title="去除一条搜索条件">
              <MinusCircleOutlined onClick={this.minuSearch} style={{ marginRight: '10px', cursor: 'pointer' }} />
            </Tooltip>
            <Select style={{ width: 100, marginRight: '10px' }} placeholder="请选择" onChange={e => this.searchChange(e, 'is_check')} allowClear>
              <Option value={-1}>未核销</Option>
              <Option value={1}>已核销</Option>
            </Select>
            <Button onClick={this.get_active_user_list} type="primary">查找</Button>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ marginRight: '10px' }}>共：{this.state.count}人</div>
            <Button onClick={e => tableToExcel('mytable')} style={{ marginRight: '10px' }} type="primary">导出当前表格</Button>
            <Button onClick={this.back} type="primary">返回</Button>
          </div>
        </div>
        <Spin spinning={this.state.loading}>
          <Table
            id='mytable'
            columns={this.state.columns}
            dataSource={this.state.data}
            bordered
            size="middle"
            pagination={false}
          />
          <div className="m-Pleft m-flex">
            <Pagination current={Number(this.state.page)} onChange={this.changePage} total={Number(this.state.count)} pageSize={this.state.page_size} pageSizeOptions={['10', '20', '30', this.state.count.toString()]} showSizeChanger onShowSizeChange={this.sizeChange} />
          </div>
        </Spin>
        <Modal
          title="完善信息"
          cancelText='取消'
          okText='确认'
          visible={this.state.visible}
          onOk={this.remark_lottery_record}
          onCancel={() => this.setState({ visible: false })}
        >
          <Row>
            <Col className="ant-col-css" span={8}>
              <span>老学员：</span>
            </Col>
            <Col className="ant-col-css" span={16}>
              <Select style={{ width: 165 }} value={this.state.remark_params.is_student ? this.state.remark_params.is_student : undefined} onChange={e => this.remarkChange(e, 'is_student')} placeholder="请选择" >
                <Option value={'-1'}>否</Option>
                <Option value={'1'}>是</Option>
              </Select>
            </Col>
            <Col className="ant-col-css" span={8}>
              <span>所属校区：</span>
            </Col>
            <Col className="ant-col-css" span={16}>
              <Select style={{ width: 165 }} value={this.state.remark_params.student_company_id ? this.state.remark_params.student_company_id : undefined} onChange={e => this.remarkChange(e, 'student_company_id')} placeholder="请选择" >
                <Option value={'1'}>阳山校区</Option>
                <Option value={'30'}>沧浪校区</Option>
                <Option value={'6'}>瑞迪校区</Option>
              </Select>
            </Col>
            <Col className="ant-col-css" span={8}>
              <span>学生姓名：</span>
            </Col>
            <Col className="ant-col-css" span={16}>
              <Input type="text" value={this.state.remark_params.student} onChange={e => this.remarkChange(e, 'student')} placeholder="请输入" />
            </Col>
            <Col className="ant-col-css" span={8}>
              <span>手机号：</span>
            </Col>
            <Col className="ant-col-css" span={16}>
              <Input type="number" value={this.state.remark_params.mobile} onChange={e => this.remarkChange(e, 'mobile')} placeholder="请输入" />
            </Col>
            <Col className="ant-col-css" span={8}>
              <span>备注：</span>
            </Col>
            <Col className="ant-col-css" span={16}>
              <Input type="text" value={this.state.remark_params.remark} onChange={e => this.remarkChange(e, 'remark')} placeholder="请输入" />
            </Col>
          </Row>
        </Modal>
      </div>
    )
  }
}

export default Submitdrawlist;