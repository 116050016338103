import React, { Component } from 'react';
import { Table, Spin, Pagination, message, Modal } from 'antd';
import {
  get_user_share_list
} from '../../../axios/http'

class Sharelist extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      name: '',
      state: '',
      page: 1,
      page_size: 10,
      count: 0,
      share_id: '',
      loading: true,
      visible: false
    }
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      visible: nextProps.visible,
      share_id: nextProps.share_id
    }, () => {
      if (this.props.visible) {
        this.get_user_share_list()
      }
    })
  }

  get_user_share_list = () => {
    this.setState({
      loading: true
    })
    let data = {
      active_user_id: this.state.share_id,
      page: this.state.page,
      page_size: this.state.page_size
    }
    get_user_share_list(data).then(res => {
      if (res.code == 0) {
        this.setState({
          data: res.data.share_list,
          loading: false,
          count: res.data.total_count
        })
      } else {
        message.error(res.message)
      }
    })
  }

  changePage = (e) => {
    this.setState({
      page: e
    }, () => {
      this.get_user_share_list()
    })
  }

  render() {
    const columns = [
      {
        title: '用户ip',
        dataIndex: 'ip',
        key: 'ip',
      },
      {
        title: '分享时间',
        dataIndex: 'add_time',
        key: 'add_time'
      }
    ];
    return (
      <div>
        <Modal
          visible={this.state.visible}
          title="分享次数详情"
          cancelText='取消'
          okText='确认'
          onOk={e => this.props.cancel()}
          onCancel={e => this.props.cancel()}
        >
          <Spin spinning={this.state.loading}>
            <Table
              columns={columns}
              dataSource={this.state.data}
              bordered
              size="middle"
              pagination={false}
            />
            <div className="m-Pleft m-flex">
              <Pagination current={Number(this.state.page)} onChange={this.changePage} total={Number(this.state.count)} />
            </div>
          </Spin>
        </Modal>
      </div>
    )
  }
}

export default Sharelist;