import React, { Component } from 'react';
import { Table, Input, Button, Spin, Pagination, message, Select, } from 'antd';
import {
  get_grant_user_list
} from '../../../axios/http'
import Sharelist from './sharelist'
import Grant from './grant'
import { tableToExcel } from '../../../actions/tableToExcel'

const { Option } = Select

class Submitgrantlist extends Component {
  constructor(props) {
    super(props)
    this.state = {
      param_json: {
        active_id: this.props.active_id,
        nickname: '',
        visit_count: '',
        visit_seconds: '',
        state: '',
        sort_type: '',
        page: 1,
        page_size: 10
      },
      grant_active_id: '',
      count: 0,
      share_id: '',
      loading: true,
      visible: false,
    }
  }

  componentDidMount = () => {
    this.get_grant_user_list()
  }

  get_grant_user_list = () => {
    this.setState({
      loading: true
    })
    let data = this.state.param_json
    get_grant_user_list(data).then(res => {
      if (res.code == 0) {
        this.setState({
          data: res.data.grant_user_list,
          loading: false,
          count: Number(res.data.total_count)
        })
      } else {
        message.error(res.message)
      }
    })
  }

  changePage = e => {
    let json = this.state.param_json
    json.page = e
    this.setState({
      param_json: json
    }, () => {
      this.get_grant_user_list()
    })
  }

  sizeChange = (c, p) => {
    let json = this.state.param_json
    if (p != this.state.count) {
      json.page = 1
      json.page_size = p
    } else {
      json.page = 0
      json.page_size = Number(this.state.count)
    }
    this.setState({
      param_json: json
    }, () => {
      this.get_grant_user_list()
    })
  }

  back = () => {
    this.props.back()
  }

  searchChange = (e, id) => {
    let s = this.state.param_json
    s[id] = typeof (e) == 'undefined' ? '' : typeof (e) == 'object' ? e.target.value : e
    this.setState({
      param_json: s
    })
  }

  tableChange = (pagination, filters, sorter) => {
    let s = this.state.param_json
    if (sorter.field == "visit_count") {
      if (sorter.order == "ascend") {
        s.sort_type = 4
      } else {
        s.sort_type = 3
      }
    } else {
      if (sorter.order == "ascend") {
        s.sort_type = 2
      } else {
        s.sort_type = 1
      }
    }
    this.setState({
      param_json: s
    }, () => {
      this.get_grant_user_list()
    })
  }

  toShare = id => {
    this.setState({
      share_id: id,
      visible: true
    })
  }

  toGrant = (id, grant_active_id) => {
    this.setState({
      recommend_uid: id,
      grant_active_id: grant_active_id,
      visible_grant: true
    })
  }

  search = e => {
    let json = this.state.param_json;
    json.page = 1;
    this.setState({
      param_json: json
    }, () => {
      this.get_grant_user_list()
    });
  }

  render() {
    const columns = [
      {
        title: '用户头像',
        dataIndex: 'headimgurl',
        key: 'headimgurl',
        render: (text, record, index) => (
          <img src={text} style={{ width: '50px' }} alt="" />
        )
      },
      {
        title: '用户名',
        dataIndex: 'nickname',
        key: 'nickname'
      },
      {
        title: '访问次数',
        dataIndex: 'visit_count',
        key: 'visit_count',
        sorter: true
      },
      {
        title: '访问时长',
        dataIndex: 'visit_seconds',
        key: 'visit_seconds',
        sorter: true
      },
      {
        title: '分享次数',
        dataIndex: 'share_count',
        key: 'share_count',
        render: (text, record, index) => (
          <div style={{ cursor: 'pointer', color: '#1890ff' }} onClick={e => this.toShare(record.id)}>{text}</div>
        )
      },
      {
        title: '分享被查看人次',
        dataIndex: 'share_visit_uv',
        key: 'share_visit_uv',
      },
      {
        title: '分享被授权查看人次',
        dataIndex: 'share_user_count',
        key: 'share_user_count',
        render: (text, record, index) => (
          <div style={{ cursor: 'pointer', color: '#1890ff' }} onClick={e => this.toGrant(record.id, record.active_id)}>{text}</div>
        )
      },
      {
        title: '所在城市',
        dataIndex: 'city',
        key: 'city'
      },
      {
        title: '最后访问时间',
        dataIndex: 'last_visit_time',
        key: 'last_visit_time'
      },
      {
        title: '是否提交',
        dataIndex: 'is_submit',
        key: 'is_submit',
        render: (text, record, index) => (
          <div>{text == '-1' ? '未提交' : '已提交'}</div>
        )
      }
    ];

    return (
      <div>
        <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
          <div className="flex">
            <div style={{ marginRight: '10px' }}>
              <span>用户名：</span>
              <Input style={{ width: 150 }} placeholder="请输入" onChange={e => this.searchChange(e, 'nickname')} allowClear></Input>
            </div>
            <div style={{ marginRight: '10px' }}>
              <span>大于该访问次数：</span>
              <Input style={{ width: 150 }} placeholder="请输入" onChange={e => this.searchChange(e, 'visit_count')} allowClear></Input>
            </div>
            <div style={{ marginRight: '10px' }}>
              <span>大于停留时长：</span>
              <Input style={{ width: 150 }} placeholder="请输入" onChange={e => this.searchChange(e, 'visit_seconds')} allowClear></Input>
            </div>
            <div style={{ marginRight: '10px' }}>
              <span>状态：</span>
              <Select style={{ width: 150 }} placeholder="请输入" onChange={e => this.searchChange(e, 'state')} allowClear>
                <Option value={'1'}>在线</Option>
                <Option value={'-1'}>离线</Option>
              </Select>
            </div>
            <div style={{ marginRight: '10px' }}>
              <span>是否提交：</span>
              <Select style={{ width: 150 }} placeholder="请输入" onChange={e => this.searchChange(e, 'is_submit')} allowClear>
                <Option value={'1'}>是</Option>
                <Option value={'-1'}>否</Option>
              </Select>
            </div>
            <Button onClick={e => this.search()} type="primary">查找</Button>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ marginRight: '10px' }}>共：{this.state.count}人</div>
            <Button onClick={e => tableToExcel('mytable')} style={{ marginRight: '10px' }} type="primary">导出当前表格</Button>
            <Button onClick={this.back} type="primary">返回</Button>
          </div>
        </div>
        <Spin spinning={this.state.loading}>
          <Table
            id='mytable'
            columns={columns}
            dataSource={this.state.data}
            bordered
            size="middle"
            pagination={false}
            onChange={this.tableChange}
          />
          <div className="m-Pleft m-flex">
            <Pagination current={Number(this.state.param_json.page)} onChange={e => this.changePage(e)} total={Number(this.state.count)} pageSize={this.state.param_json.page_size} pageSizeOptions={['10', '20', '30',this.state.count]} showSizeChanger onShowSizeChange={this.sizeChange} />
          </div>
        </Spin>
        <Sharelist visible={this.state.visible} share_id={this.state.share_id} cancel={e => { this.setState({ visible: false }) }} />
        <Grant visible={this.state.visible_grant} grant_active_id={this.state.grant_active_id} recommend_uid={this.state.recommend_uid} cancel={e => { this.setState({ visible_grant: false }) }} />
      </div>
    )
  }
}

export default Submitgrantlist;