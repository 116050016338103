import React, { Component } from 'react';
import ExamList from './ExamList';
import ResultList from './ResultList'
class Enterquestion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            route: 'examList',
            exam_data: '',
            subject_list: []
        }
    }
    changeRoute = e => {
        this.setState({
            route: e
        })
    }
    setExamData = e => {
        this.setState({
            exam_data: e
        })
    }
    setSubjectList = e => {
        this.setState({
            subject_list: e
        })
    }
    render() {
        return (
            <div>
                {
                    this.state.route == 'examList' ?
                        <ExamList changeRoute={this.changeRoute} setExamData={this.setExamData} setSubjectList={this.setSubjectList} />
                        :
                        <ResultList changeRoute={this.changeRoute} exam_data={this.state.exam_data} subject_list={this.state.subject_list} />
                }
            </div>
        )
    }
}

export default Enterquestion;