import React, { Component } from 'react';
import { Col, Row, Button, Select, Input, Checkbox, Table, Modal, DatePicker, Radio, message, Pagination, Tag } from 'antd';
import {
    get_exam_type,
    exam_get_list,
    get_xiaoguanjia_grade,
    get_xiaoguanjia_subject,
    create_exam,
    del_exam,
    edit_exam
} from '../../axios/http'
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn')
const dateFormat = 'YYYY-MM-DD'
const { TextArea } = Input
const { Option } = Select
const { confirm } = Modal;
const today = moment(new Date()).format(dateFormat)

const searchType = (e, type) => {
    switch (type) {
        case 'textArea': ;
        case 'input': ;
        case 'radio': return e.target.value;
        case 'date': return moment(e).format('YYYY-MM-DD')
        default: return e;
    }
}

const get_params_base = {
    name: '',
    xiaoguanjia_grade_id: '',
    type_id: '',
    exam_place_id: '',
    remark: '',
    page: 1,
    page_size: 10
}

const post_params_base = {
    student_exam_id: '',
    name: '',
    xiaoguanjia_grade_id: '',
    type_id: '',
    exam_place_id: '',
    xiaoguanjia_subject_ids: [],
    exam_time: today,
    full_score_str: {},
    remark: ''
}

class ExamList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            exam_type_list: [],
            subject_list: [],
            grade_list: [],
            get_params: get_params_base,
            exam_list: [],
            total: 0,
            edit: false,
            //Modal
            visible: false,
            checklist: {},
            post_params: post_params_base
        }
    }
    componentDidMount = () => {
        this.get_exam_type()
        this.get_xiaoguanjia_subject()
        this.get_xiaoguanjia_grade()
        this.exam_get_list()
    }

    //获取考试类型
    get_exam_type = () => {
        get_exam_type().then(res => {
            this.setState({
                exam_type_list: res.data.exam_type_list
            })
        })
    }

    //获取考试科目
    get_xiaoguanjia_subject = () => {
        get_xiaoguanjia_subject({ is_all: 1 }).then(res => {
            this.props.setSubjectList(res.data.list)
            this.setState({
                subject_list: res.data.list
            })
        })
    }

    //获取年级
    get_xiaoguanjia_grade = () => {
        get_xiaoguanjia_grade({ is_all: 1 }).then(res => {
            this.setState({
                grade_list: res.data.list
            })
        })
    }

    //获取考试列表
    exam_get_list = () => {
        exam_get_list(this.state.get_params).then(res => {
            this.setState({
                exam_list: res.data.list,
                total: Number(res.data.count)
            })
        })
    }

    //删除
    delete = e => {
        const that = this
        confirm({
            title: `删除${e.name}`,
            content: '你确定要删除吗',
            okText: '删除',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                del_exam({ student_exam_id: e.id }).then(res => {
                    if (res.code == 0) {
                        message.success('删除成功')
                        that.exam_get_list()
                    } else {
                        message.warning(res.message)
                    }
                })
            },

        });
    }

    //翻页
    changePage = e => {
        let get_params = { ...this.state.get_params }
        get_params.page = e
        this.setState({ get_params }, () => {
            this.exam_get_list()
        })
    }

    //跳转成绩录入
    toResultList = e => {
        this.props.changeRoute('resultList')
        this.props.setExamData(e)
    }

    //Modal
    creatExam = () => {
        if (this.state.visible) {
            let post_params = {
                student_exam_id: '',
                name: '',
                xiaoguanjia_grade_id: '',
                type_id: '',
                exam_place_id: '',
                xiaoguanjia_subject_ids: [],
                exam_time: today,
                full_score_str: {},
                remark: ''
            }
            this.setState({
                edit: false,
                post_params,
                checklist: {}
            })
        }
        this.setState({
            visible: !this.state.visible
        })
    }

    //修改查看
    detail = e => {
        const post_params = { ...this.state.post_params }
        post_params.student_exam_id = e.id
        post_params.name = e.name
        post_params.type_id = e.type_id
        post_params.xiaoguanjia_grade_id = e.xiaoguanjia_grade_id
        post_params.exam_time = e.exam_time
        post_params.exam_place_id = e.exam_place_id
        post_params.xiaoguanjia_subject_ids = e.xiaoguanjia_subject_ids.split(',')
        post_params.remark = e.remark
        let str = e.full_score_str,
            list = {},
            checklist = this.state.checklist
        if (str) {
            str = str.split(',')
            str.map(item => {
                let a = item.split(':')
                list[a[0]] = a[1]
                checklist[a[0]] = true
            })
            post_params.full_score_str = list
        } else {
            e.xiaoguanjia_subject_ids.split(',').map(item => {
                list[item] = 0
                checklist[item] = true
            })
            post_params.full_score_str = list
        }
        this.setState({
            post_params,
            visible: !this.state.visible,
            edit: true,
            checklist
        })
    }

    //填表
    change = (e, id, type) => {
        let post_params = JSON.parse(JSON.stringify(this.state.post_params))
        post_params[id] = searchType(e, type)
        this.setState({
            post_params
        })
    }

    //科目选择
    checkIndexChange = (e, id) => {
        const post_params = { ...this.state.post_params }
        let checklist = this.state.checklist,
            str = post_params.full_score_str
        if (checklist[id]) {
            delete str[id]
            post_params.full_score_str = str
            this.setState({
                post_params
            })
        } else {
            str[id] = 0
            post_params.full_score_str = str
            this.setState({
                post_params
            })
        }
        checklist[id] = !checklist[id]
        this.setState({
            checklist
        })
    }

    //对应科目总成绩修改
    scoreChange = (e, id) => {
        const post_params = { ...this.state.post_params }
        let str = post_params.full_score_str
        str[id] = e.target.value
        post_params.full_score_str = str
        this.setState({
            post_params
        })
    }

    //考试提交或修改
    onOk = e => {
        if (this.state.edit) {
            this.edit_exam()
        } else {
            this.create_exam()
        }
    }

    //创建考试
    create_exam = e => {
        const post_params = { ...this.state.post_params }
        let str = JSON.stringify(post_params.full_score_str)
        str = str.slice(1, str.length)
        str = str.slice(0, str.length - 1)
        post_params.full_score_str = str.replace(new RegExp(/(")/g), "")
        create_exam(post_params).then(res => {
            if (res.code === 0) {
                message.success('创建成功')
                let get_params = { ...this.state.get_params }
                get_params.page = 1
                this.setState({ get_params })
                this.exam_get_list()
                this.setState({
                    visible: false,
                    post_params: post_params_base,
                    checklist: {}
                })
            } else {
                message.success(res.message)
            }
        })
    }

    //修改考试
    edit_exam = e => {
        const post_params = { ...this.state.post_params }
        var arr = [], obj = {}
        this.state.subject_list.some(item => {
            post_params.xiaoguanjia_subject_ids.some(item2 => {
                if (item.xiaoguanjia_id == item2) {
                    arr.push(item.xiaoguanjia_id)
                    return true
                }
            })
            for (var key in post_params.full_score_str) {
                if (key == item.xiaoguanjia_id) {
                    obj[key] = post_params.full_score_str[key]
                    continue
                }
            }
        })
        post_params.xiaoguanjia_subject_ids = arr
        post_params.full_score_str = obj
        let str = JSON.stringify(post_params.full_score_str)
        str = str.slice(1, str.length)
        str = str.slice(0, str.length - 1)
        post_params.full_score_str = str.replace(new RegExp(/(")/g), "")
        edit_exam(post_params).then(res => {
            if (res.code === 0) {
                message.success('修改成功')
                let get_params = { ...this.state.get_params }
                get_params.page = 1
                this.setState({ get_params })
                this.exam_get_list()
                this.setState({
                    visible: false,
                    post_params: post_params_base,
                    checklist: {}
                })
            } else {
                message.success(res.message)
            }
        })
    }

    //筛选更改
    paramsSelect = (e, id) => {
        let get_params = this.state.get_params
        get_params[id] = typeof (e) == 'undefined' ? '' : typeof (e) == 'string' ? e : e.target.value
        this.setState({
            get_params
        })
    }

    render() {
        const columns = [
            {
                title: '名称',
                key: 'name',
                dataIndex: 'name',
                align: 'center',
                render: (text, val) => (
                    <span onClick={e => this.toResultList(val)} className="paper-hd-title-active">
                        {text}
                    </span>
                )
            },
            {
                title: '年级',
                dataIndex: 'xiaoguanjia_grade_id',
                key: 'xiaoguanjia_grade_id',
                align: 'center',
                render: (text) => (
                    <span>
                        {
                            this.state.grade_list.map((res, index) => {
                                if (text == res.xiaoguanjia_id) {
                                    return <Tag color='geekblue' key={index}>{res.value.split('-')[1]}</Tag>
                                }
                            })
                        }
                    </span>
                )
            },
            {
                title: '类别',
                dataIndex: 'type_id',
                key: 'type_id',
                align: 'center',
                render: (text) => (
                    <span>
                        {
                            this.state.exam_type_list.map((res, index) => {
                                if (res.id == text) {
                                    return <Tag color='geekblue' key={res.id}>{res.name}</Tag>
                                }
                            })
                        }
                    </span>
                )
            },
            {
                title: '项目',
                dataIndex: 'xiaoguanjia_subject_ids',
                key: 'xiaoguanjia_subject_ids',
                align: 'center',
                render: (text) => (
                    <span>
                        {
                            text.split(',').map(res => {
                                return this.state.subject_list.map(element => {
                                    if (element.xiaoguanjia_id == res) {
                                        return <Tag color='green' key={res}>{element.value.split('-')[1]}</Tag>
                                    }
                                })
                            })
                        }
                    </span>
                )
            },
            {
                title: '考试地点',
                dataIndex: 'exam_place_id',
                key: 'exam_place_id',
                align: 'center',
                render: (text) => (
                    <span>
                        {text === '1' ? '公立考试' : '机构私测'}
                    </span>
                )
            },
            {
                title: '考试时间',
                dataIndex: 'exam_time',
                key: 'exam_time',
                align: 'center',
                render: (text) => (
                    <span>
                        {text ? text : 'null'}
                    </span>
                )
            },
            {
                title: '备注',
                dataIndex: 'remark',
                key: 'remark',
                align: 'center',
                render: (text) => (
                    <span>
                        {text ? text : ''}
                    </span>
                )
            },
            {
                title: '操作',
                key: 'action',
                align: 'center',
                render: (text) => (
                    <span>
                        <Button className="m-left" type="primary" onClick={e => this.detail(text)}>编辑</Button>
                        <Button className="m-left" type="danger" onClick={e => this.delete(text)}>删除</Button>
                    </span>
                )
            }
        ]
        const exam_types = this.state.exam_type_list.map(res => {
            return <Option key={res.id} value={res.id.toString()} >{res.name}</Option>
        })
        const grade_types = this.state.grade_list.map(res => {
            return <Option key={res.xiaoguanjia_id} value={res.xiaoguanjia_id} >{res.value.split('-')[1]}</Option>
        })
        const subject_list = this.state.subject_list.map((item, index) => {
            return (
                <Col span={8} key={item.xiaoguanjia_id} style={{ display: "flex", alignItems: 'center' }}>
                    <Checkbox onClick={e => this.checkIndexChange(index, item.xiaoguanjia_id)} value={item.xiaoguanjia_id} style={{ flexShrink: '0' }}>{item.value.split('-')[1]}</Checkbox>
                    {
                        this.state.checklist[item.xiaoguanjia_id] ?
                            <span style={{ flexShrink: '0' }}>
                                <Input type='number' style={{ flexShrink: '0' }} style={{ width: '50px', lineHeight: '21px', height: '21px' }} value={this.state.post_params.full_score_str[item.xiaoguanjia_id]} onChange={e => this.scoreChange(e, item.xiaoguanjia_id)} />分
                            </span>
                            :
                            ''
                    }
                </Col>
            )
        })
        return (
            <div>
                <div className="m-bottom m-flex" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                    <div className="m-flex">
                        <div className="m-left">
                            <Input style={{ width: 120 }} onChange={e => this.paramsSelect(e, 'name')} allowClear placeholder="考试查询"></Input>
                        </div>
                        <div className="m-left">
                            <Select style={{ width: 120 }} allowClear placeholder="请选择年级" onChange={(e) => this.paramsSelect(e, 'xiaoguanjia_grade_id')}>
                                {grade_types}
                            </Select>
                        </div>
                        <div className="m-left">
                            <Select style={{ width: 120 }} allowClear placeholder="请选择类型" onChange={(e) => this.paramsSelect(e, 'type_id')}>
                                {exam_types}
                            </Select>
                        </div>
                        <div className="m-left">
                            <Select style={{ width: 120 }} allowClear placeholder="请选择地点" onChange={(e) => this.paramsSelect(e, 'exam_place_id')}>
                                <Option value='1'>公立考试</Option>
                                <Option value='2'>机构私测</Option>
                            </Select>
                        </div>
                        <div className="m-left">
                            <Input style={{ width: 120 }} onChange={e => this.paramsSelect(e, 'remark')} allowClear placeholder="备注查询"></Input>
                        </div>
                        <Button style={{ marginLeft: 10 }} onClick={this.exam_get_list}>查询</Button>
                    </div>
                    <Button type="primary" onClick={this.creatExam}>考试录入</Button>
                </div>
                <div>
                    <Table columns={columns} dataSource={this.state.exam_list} pagination={false} />
                    <Pagination className="m-Pleft" current={this.state.get_params.page} onChange={this.changePage} total={this.state.total} />
                </div>
                <Modal
                    title="考试录入"
                    visible={this.state.visible}
                    onOk={this.onOk}
                    onCancel={this.creatExam}
                    okText="确认"
                    cancelText="取消"
                >
                    <div className="m-flex m-bottom" style={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                        <span className="m-row" style={{ textAlign: 'right' }} >考试名称：</span>
                        <Input placeholder="请填写考试名称" onChange={e => this.change(e, 'name', 'input')} value={this.state.post_params.name}></Input>
                    </div>
                    <div className="m-flex m-bottom" style={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                        <span className="m-row" style={{ textAlign: 'right' }}>考试类型：</span>
                        <Select placeholder="请选择考试类型" onChange={e => this.change(e, 'type_id', 'select')} value={this.state.post_params.type_id ? this.state.post_params.type_id : undefined} style={{ width: '100%' }}>
                            {exam_types}
                        </Select>
                    </div>
                    <div className="m-flex m-bottom" style={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                        <span className="m-row" style={{ textAlign: 'right' }}>年级选择：</span>
                        <Select placeholder="请选择年级" onChange={e => this.change(e, 'xiaoguanjia_grade_id', 'select')} value={this.state.post_params.xiaoguanjia_grade_id ? this.state.post_params.xiaoguanjia_grade_id : undefined} style={{ width: '100%' }}>
                            {grade_types}
                        </Select>
                    </div>
                    <div className="m-flex m-bottom" style={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                        <span className="m-row" style={{ textAlign: 'right' }}>考试时间：</span>
                        <DatePicker onChange={e => this.change(e, 'exam_time', 'date')} value={moment(this.state.post_params.exam_time, dateFormat)} style={{ width: '100%' }} />
                    </div>
                    <div className="m-flex m-bottom" style={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                        <span className="m-row" style={{ textAlign: 'right' }}>考试地点：</span>
                        <Radio.Group onChange={e => this.change(e, 'exam_place_id', 'radio')} value={this.state.post_params.exam_place_id} style={{ width: '100%' }}>
                            <Radio value='1'>公立考试</Radio>
                            <Radio value='2'>机构私测</Radio>
                        </Radio.Group>
                    </div>
                    <div className="m-flex m-bottom" style={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                        <span className="m-row" style={{ textAlign: 'right' }}>考试项目：</span>
                        <Checkbox.Group onChange={e => this.change(e, 'xiaoguanjia_subject_ids', 'checkbox')} value={this.state.post_params.xiaoguanjia_subject_ids} style={{ width: '100%' }}>
                            <Row>
                                {subject_list}
                            </Row>
                        </Checkbox.Group>
                    </div>
                    <div className="m-flex m-bottom" style={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                        <span style={{ textAlign: 'right', width: '100px' }}>备注：</span>
                        <TextArea placeholder="备注" onChange={(e) => this.change(e, 'remark', 'textArea')} value={this.state.post_params.remark}></TextArea>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default ExamList;