import React, { useEffect, useState } from 'react'
import { message, Table, DatePicker, Modal, Select, Row, Col, Input, Pagination, Checkbox, Tag, Radio } from 'antd';
import { get_teaching_style, set_teacher, get_teacher_list, get_set_teacher } from '../../axios/http'
import moment from 'moment'

const { Option } = Select
const paramsModel = {
  name: '',
  username: '',
  permission: '',
  state: 1,
  personal_mobile: '',
  department_id: '',
  page: 1,
  page_size: 10
}
const setParamsModel = {
  oa_employee_id: '',
  sex: '',
  birthyear: moment().format('YYYY-MM-DD'),
  teaching_style_str: [],
  is_sign: '',
  remark: ''
}
const setTimeParamsModel = {
  schedule_teacher_id: '',
  is_ok: '',
  start_time: undefined,
  end_time: undefined
}
const yanuo_time = [
  '08:00-10:00',
  '10:10-12:10',
  '13:00-15:00',
  '15:10-17:10',
  '17:40-19:40',
  '18:00-20:00',
  '19:50-21:50'
]
const ruidi_time = [
  '09:00-10:00',
  '10:30-11:30',
  '13:00-14:00',
  '14:30-15:30',
  '16:00-17:00',
  '16:30-17:30',
  '18:00-19:00'
]

const Setteacherstyle = (props) => {

  const [visible, visibleSet] = useState(false)
  const [teaching_style_list, teaching_style_listSet] = useState([])
  const [teacher_list, teacher_listSet] = useState([])
  const [set_teacher_list, set_teacher_listSet] = useState([])
  const [params, paramsSet] = useState(paramsModel)
  const [id, idSet] = useState()
  const [count, countSet] = useState()
  const [setParams, setParamsSet] = useState(setParamsModel)
  const [time, timeSet] = useState([])
  const [timevisible, timevisibleSet] = useState(false)
  const [timeParams, timeParamsSet] = useState(setTimeParamsModel)

  useEffect(() => {
    get_teaching_styleFun()
    get_set_teacherFun()
    let company_id = localStorage.getItem('company_id')
    if (company_id == 6) {
      timeSet(ruidi_time)
    } else {
      timeSet(yanuo_time)
    }
  }, [])

  useEffect(() => {
    get_teacher_listFun()
  }, [params.page])

  const get_set_teacherFun = e => {
    get_set_teacher().then(res => {
      if (res.code == 0) {
        set_teacher_listSet(res.data.set_teacher_list)
      }
    })
  }

  const get_teacher_listFun = e => {
    let data = params
    get_teacher_list(data).then(res => {
      if (res.code === 0) {
        teacher_listSet(res.data.list)
        countSet(Number(res.data.total_count))
      }
    })
  }

  const get_teaching_styleFun = e => {
    get_teaching_style().then(res => {
      if (res.code === 0) {
        teaching_style_listSet(res.data.style_list)
      }
    })
  }

  const set_teacherFun = e => {
    let data = setParams
    data.oa_employee_id = id
    set_teacher(data).then(res => {
      if (res.code === 0) {
        visibleSet(false);
        get_set_teacherFun()
        get_teacher_listFun()
        setParamsSet(setParamsModel);
        message.success('设置成功！')
      } else {
        message.error(res.message)
      }
    })
  }

  const columns = [
    {
      title: '姓名',
      dataIndex: 'name'
    },
    {
      title: '性别',
      dataIndex: 'sex',
      render: (text) => (
        <div>{text == '1' ? '男' : '女'}</div>
      )
    },
    {
      title: '出生年份',
      dataIndex: 'birthyear'
    },
    {
      title: '授课风格',
      dataIndex: 'teaching_style_str'
    },
    {
      title: '是否签约',
      dataIndex: 'is_sign',
      render: (text) => (
        <div>{text == '-1' ? '否' : '是'}</div>
      )
    },
    {
      title: '上课时间',
      dataIndex: 'time',
      render: (text, record, index) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <span style={{ cursor: 'pointer', color: '#1890ff' }} onClick={() => { idSet(record.id); timevisibleSet(true); }}>设置</span>
        </div>
      )
    },
    {
      title: '备注',
      dataIndex: 'remark'
    },
    {
      title: '操作',
      dataIndex: 'action',
      render: (text, record, index) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <span style={{ cursor: 'pointer', color: '#1890ff' }} onClick={() => { idSet(record.id); setParamsSet(record); visibleSet(true); }}>修改</span>
        </div>
      )
    }
  ]

  const valueChange = (e, id) => {
    let value = typeof (e) == 'undefined' ? '' : typeof (e) == 'object' ? e.target ? e.target.value : e : e,
      s1 = JSON.stringify(setParams),
      s = JSON.parse(s1)
    s[id] = value
    setParamsSet(s)
  }

  const dateChange = (e, id) => {
    let value = moment(e).format('YYYY-MM-DD'),
      s1 = JSON.stringify(setParams),
      s = JSON.parse(s1)
    s[id] = value
    setParamsSet(s)
  }

  const changePage = e => {
    let s1 = JSON.stringify(params), s = JSON.parse(s1)
    s.page = e
    paramsSet(s)
  }

  const timeDateChange = (e, id) => {
    let t = JSON.stringify(timeParams), s = JSON.parse(t)
    s[id] = moment(e).format('YYYY-MM-DD')
    timeParamsSet(s)
  }

  const timeValueChange = (e, id) => {
    let t = JSON.stringify(timeParams), s = JSON.parse(t)
    s[id] = e.target.value
    timeParamsSet(s)
  }

  return (
    <div>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '70%' }}>
          <Table
            columns={columns}
            dataSource={set_teacher_list}
            bordered
            pagination={false}
            size='middle'
          />
          <div className="m-Pleft m-flex">
            <Pagination current={params.page} onChange={changePage} total={count} pageSize={params.page_size} />
          </div>
        </div>
        <div style={{ width: '28%', border: '1px solid #e8e8e8' }}>
          <div style={{ color: 'rgba(0,0,0,0.85)', fontWeight: '500', textAlign: 'center', background: '#fafafa', border: '1px solid #e8e8e8', padding: '12px 8px' }}>暂未设置</div>
          <div style={{ padding: '10px' }}>
            {
              teacher_list.map((item, index) => (
                <Tag key={index} onClick={() => { idSet(item.id); visibleSet(true); }} style={{ fontSize: '16px', marginBottom: '10px', cursor: 'pointer' }}>{item.name}</Tag>
              ))
            }
          </div>
        </div>
      </div>

      <Modal
        title="修改"
        cancelText='取消'
        okText='确认'
        visible={visible}
        onOk={set_teacherFun}
        onCancel={e => { visibleSet(false); setParamsSet(setParamsModel); }}
      >
        <Row className="modal-line">
          <Col span={8}>性别：</Col>
          <Col span={16}>
            <Select placeholder='请选择' value={setParams.sex ? setParams.sex : undefined} style={{ width: '100%' }} onChange={e => valueChange(e, 'sex')} >
              <Option value={'1'}>男</Option>
              <Option value={'2'}>女</Option>
            </Select>
          </Col>
          <Col span={8}>出生日期：</Col>
          <Col span={16}>
            <DatePicker allowClear={false} value={moment(setParams.birthyear)} onChange={e => dateChange(e, 'birthyear')} />
          </Col>
          <Col span={8}>授课风格：</Col>
          <Col span={16}>
            <Checkbox.Group options={teaching_style_list} value={setParams.teaching_style_str} onChange={e => valueChange(e, 'teaching_style_str')} />
          </Col>
          <Col span={8}>是否签约：</Col>
          <Col span={16}>
            <Select placeholder='请选择' value={setParams.is_sign ? setParams.is_sign : undefined} style={{ width: '100%' }} onChange={e => valueChange(e, 'is_sign')} >
              <Option value={'-1'}>否</Option>
              <Option value={'1'}>是</Option>
            </Select>
          </Col>
          <Col span={8}>备注：</Col>
          <Col span={16}>
            <Input placeholder='请输入' value={setParams.remark} onChange={e => valueChange(e, 'remark')} />
          </Col>
        </Row>
      </Modal>

      <Modal
        title="修改"
        cancelText='取消'
        okText='确认'
        visible={timevisible}
        onOk={set_teacherFun}
        onCancel={e => { timevisibleSet(false); }}
      >
        <Row className="modal-line">
          <Col span={8}>日期：</Col>
          <Col span={16}>
            <DatePicker allowClear={false} value={moment(timeParams.select_date)} onChange={e => timeDateChange(e, 'select_date')} />
          </Col>
          <Col span={8}>时间段：</Col>
          <Col span={16}>
            <Radio.Group onChange={e => timeValueChange(e, 'select_time')} value={timeParams.select_time}>
              {
                time.map(item => (
                  <Radio value={item}>{item}</Radio>
                ))
              }
            </Radio.Group>
          </Col>
        </Row>
      </Modal>

    </div>
  )
}

export default Setteacherstyle
