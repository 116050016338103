import React, { useEffect, useState } from 'react'
import { Table, Pagination, Tag, Modal, Form, Input, Checkbox, Select } from 'antd';
import { set_student_condition, set_student_time, del_student_time, get_set_student_time, loginUserList } from '../../axios/http'
import moment from 'moment'

const { Option } = Select

const paramsModel = {
  page: 0,
  page_size: 10
}
const setParamsModel = {
  student_id: '',
  teacher_oa_employee_id: '',
  teacher_sex: '',
  teacher_age_range: '',
  teacher_style: '',
  class_student_id: '',
  class_student_sex: ''
}
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const Setstudents = (props) => {
  const [form] = Form.useForm();
  const [set_student_list, set_student_listSet] = useState([])
  const [params, paramsSet] = useState(paramsModel)
  const [id, idSet] = useState()
  const [count, countSet] = useState(0)
  const [visible, visibleSet] = useState(true)
  const [setParams, setParamsSet] = useState(setParamsModel)
  const [use_list, use_listSet] = useState([])

  const columns = []

  useEffect(() => {
    loginUserList().then(res => {
      if (res.code === 0) {
        use_listSet(res.data.list)
      }
    })
  }, [])

  const changePage = e => {
    let s1 = JSON.stringify(params), s = JSON.parse(s1)
    s.page = e
    paramsSet(s)
  }

  const set_studentFun = e => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        console.log(values);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  }

  return (
    <div>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '70%' }}>
          <Table
            columns={columns}
            dataSource={set_student_list}
            bordered
            pagination={false}
            size='middle'
          />
          <div className="m-Pleft m-flex">
            <Pagination current={params.page} onChange={changePage} total={count} pageSize={params.page_size} />
          </div>
        </div>
        <div style={{ width: '28%', border: '1px solid #e8e8e8' }}>
          <div style={{ color: 'rgba(0,0,0,0.85)', fontWeight: '500', textAlign: 'center', background: '#fafafa', border: '1px solid #e8e8e8', padding: '12px 8px' }}>暂未设置</div>
          <div style={{ padding: '10px' }}>
            {
              set_student_list.map((item, index) => (
                <Tag key={index} onClick={() => { idSet(item.id); visibleSet(true); }} style={{ fontSize: '16px', marginBottom: '10px', cursor: 'pointer' }}>{item.name}</Tag>
              ))
            }
          </div>
        </div>
      </div>

      <Modal
        title="修改"
        cancelText='取消'
        okText='确认'
        visible={visible}
        onOk={set_studentFun}
        onCancel={e => { visibleSet(false); setParamsSet(setParamsModel); }}
      >
        <Form
          form={form}
          {...layout}
          name="basic"
        >
          <Form.Item
            label="指定老师"
            name="teacher_oa_employee_id"
          >
            <Select
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {
                use_list.map(item => (
                  <Option value={item.id} key={item.id}>{item.name}</Option>
                ))
              }
            </Select>
          </Form.Item>

          <Form.Item
            label="指定老师性别"
            name="teacher_sex"
          >
            <Select>
              <Option value={1}>男</Option>
              <Option value={2}>女</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="指定老师年龄"
            name="teacher_age_range"
          >
            <Input />
          </Form.Item>

        </Form>
      </Modal>

    </div>
  )
}

export default Setstudents
