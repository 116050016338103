import React, { Component } from 'react';
import List from './previewList'
class Preview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            previewData: JSON.parse(localStorage.getItem('previewData'))
        }
    }
    render() {
        return (
            <div>
                <List data={this.state.previewData} ></List>
            </div>
        )
    }
}
export default Preview;