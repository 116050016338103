import React, { Component } from 'react';
import { Button, Table, DatePicker, Select, Popover, Tag } from 'antd'
import {
    ExclamationCircleOutlined
} from '@ant-design/icons';
import { get_xiaoguanjia_grade, get_wrong_index } from '../../axios/http'
import moment from 'moment';
import echarts from 'echarts/lib/echarts';
const { Option } = Select

class Behavioraldata extends Component {
    constructor(props) {
        super(props)
        this.state = {
            startTime: moment().subtract(7, 'd').format('YYYY-MM-DD'),
            endTime: moment(new Date()).format('YYYY-MM-DD'),
            xiaoguanjia_grade_id: '',
            grade_list: [],
            wrong_index_list: [],
            xiaoguanjia_grade: ''
        }
    }
    componentDidMount() {
        get_xiaoguanjia_grade().then(res => {
            if (res.code == 0 && res.data.list.length > 0) {
                this.setState({
                    grade_list: res.data.list,
                    xiaoguanjia_grade_id: res.data.list[0].xiaoguanjia_id,
                    xiaoguanjia_grade: res.data.list[0].value.split('-')[1]
                })
            }
        })
        this.get_wrong_index()
        window.addEventListener('resize', this.handleSize);
        this.handleSize()
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleSize);
        this.setState = (state, callback) => {
            return
        }
    }
    handleSize = () => {
        let height = document.body.clientHeight - 257,
            width = document.body.clientWidth - 323
        this.setState({
            height,
            width
        });
        for (var i = 0; i < this.state.count; i++) {
            if (document.getElementById(i)) {
                var myChart = echarts.init(document.getElementById(i));
                myChart.resize()
            }
        }
    }
    get_wrong_index = () => {
        for (var i = 0; i < this.state.count; i++) {
            if (document.getElementById(i)) {
                var myChart = echarts.init(document.getElementById(i));
                myChart.dispose();
            }
        }
        let data = {
            xiaoguanjia_grade_id: this.state.xiaoguanjia_grade_id,
            xiaoguanjia_grade: this.state.xiaoguanjia_grade,
            start_date: this.state.startTime,
            end_date: this.state.endTime
        }
        get_wrong_index(data).then(res => {
            if (res.code == 0) {
                if (res.data.list) {
                    let list = JSON.stringify(res.data.list)
                    let json = JSON.parse(list)
                    json.forEach(item => {
                        item.student_list.push({
                            name: "平均值",
                            wrong_index: item.avg_index,
                            total_ask_count: item.avg_ask,
                            total_count: item.avg_count
                        })
                        item.student_list.forEach(item1 => {
                            item1['avg_index'] = item.avg_index
                        })
                    })
                    this.setState({
                        wrong_index_list: json
                    })
                    setTimeout(() => {
                        let json1 = JSON.parse(list)
                        json1.map((item1, index) => {
                            let xAxis = [],
                                full_score = []
                            item1.student_list.map(item => {
                                xAxis.push(item.name)
                                full_score.push(item.wrong_index)
                            })
                            var myChart = echarts.init(document.getElementById(index));
                            myChart.setOption({
                                tooltip: {
                                    trigger: 'axis',
                                    axisPointer: {
                                        type: 'cross',
                                        label: {
                                            backgroundColor: '#6a7985'
                                        }
                                    }
                                },
                                xAxis: [
                                    {
                                        type: 'category',
                                        data: xAxis
                                    }
                                ],
                                yAxis: {
                                    type: 'value'
                                },
                                series: [{
                                    name: '错误指标',
                                    type: 'line',
                                    stack: '指标数',
                                    lineStyle: {
                                        color: '#ff0437'
                                    },
                                    label: {
                                        normal: {
                                            show: true,
                                            position: 'top'
                                        }
                                    },
                                    markLine: {
                                        silent: true,
                                        lineStyle: {
                                            normal: {
                                                color: '#10d270'// 这儿设置安全基线颜色
                                            }
                                        },
                                        data: [{
                                            yAxis: json1[index].avg_index
                                        }],
                                        label: {
                                            normal: {
                                                formatter: '平均值'// 这儿设置安全基线
                                            }
                                        },
                                    },
                                    data: full_score
                                }]
                            });
                        })
                        this.setState({
                            count: json1.length
                        })
                    }, 0);
                } else {
                    this.setState({
                        wrong_index_list: []
                    })
                }
            }
        })
    }
    onChange = (e, id, type) => {
        let value = e
        switch (type) {
            case 'date': value = moment(value).format('YYYY-MM-DD'); break;
        }
        if (id == 'xiaoguanjia_grade_id') {
            this.state.grade_list.some(item => {
                if (item.xiaoguanjia_id == e) {
                    this.setState({
                        xiaoguanjia_grade: item.value.split('-')[1]
                    })
                    return true
                }
            })
        }
        this.setState({
            [id]: value
        })
    }
    render() {
        const columns = [
            {
                title: '学生姓名',
                align: "center",
                key: "name",
                dataIndex: "name",
                render: (text, record, index) => {
                    let content
                    if (record.total_warn_count && record.total_warn_count != '0') {
                        let tags_str = record.tags_str.split(',')
                        content = (
                            <div style={{ width: 302 }}>
                                <p>批评次数：{record.total_warn_count}</p>
                                {
                                    tags_str.map(item => {
                                        return <Tag key={item} color="#108ee9" style={{ marginBottom: '5px' }}>{item}</Tag>
                                    })
                                }
                            </div>
                        );
                    }
                    return (
                        <div>
                            <span style={{ marginRight: '10px' }}>
                                {text}
                            </span>
                            {
                                record.total_warn_count && record.total_warn_count != '0' ?
                                    // <Popover content={content} title="详情">
                                    <ExclamationCircleOutlined style={{ color: '#faad14' }} />
                                    // </Popover>
                                    :
                                    ''
                            }
                        </div>
                    )
                }
            },
            {
                title: '统计次数',
                align: "center",
                key: "total_count",
                dataIndex: "total_count",
                render: (text) => (
                    <div>{text ? text : '-'}</div>
                ),
                sorter: (a, b) => a.total_count - b.total_count,
            },
            {
                title: '错误指数',
                align: "center",
                key: "wrong_index",
                dataIndex: "wrong_index",
                render: (text, record, index) => {
                    return (
                        <div>
                            <span style={{ color: record.avg_index < text ? 'red' : '#000', marginRight: '10px' }}>
                                {text.toFixed(2)}
                            </span>
                        </div>
                    )
                },
                sorter: (a, b) => a.wrong_index - b.wrong_index,
            },
            {
                title: '提问次数',
                align: "center",
                key: "total_ask_count",
                dataIndex: "total_ask_count",
                sorter: (a, b) => a.total_ask_count - b.total_ask_count,
            },
            {
                title: '批评次数',
                align: "center",
                key: "total_warn_count",
                dataIndex: "total_warn_count",
                render: (text, record, index) => {
                    let content
                    if (record.total_warn_count && record.total_warn_count != '0') {
                        let tags_list = record.tags_list, tag = []
                        for (var key in tags_list) {
                            tag.push(
                                <div key={key} style={{ marginBottom: '5px', marginRight: '10px' }}>
                                    <Tag style={{ margin: '0' }} key={key} color="#108ee9">{key}</Tag>×{tags_list[key]}
                                </div>
                            )
                        }
                        content = (
                            <div style={{ width: 302 }}>
                                <p>批评次数：{record.total_warn_count}</p>
                                <div>
                                    {
                                        tags_list.map((item, index) => {
                                            return (
                                                <div style={{ display: 'flex' }} key={index}>
                                                    <div>{item.name}：</div>
                                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                        {
                                                            item.tags.map(tag => (
                                                                <div key={tag.name} style={{ marginBottom: '5px', marginRight: '10px' }}>
                                                                    <Tag style={{ margin: '0' }} key={tag.name} color="#108ee9">{tag.name}</Tag>×{tag.count}
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        );
                    }
                    return (
                        text == 0 ?
                            <div>{text ? text : '-'}</div>
                            :
                            <Popover content={content} title="详情" trigger="click">
                                <div style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' }}>{text ? text : '-'}</div>
                            </Popover>
                    )
                },
                sorter: (a, b) => a.total_warn_count - b.total_warn_count,
            },
        ];
        const grade_list = this.state.grade_list.map(item => {
            return (
                <Option key={item.xiaoguanjia_id} value={item.xiaoguanjia_id}>{item.value.split('-')[1]}</Option>
            )
        })
        return (
            <div>
                <div className="m-Hmain" id="box">
                    <div className="flex">
                        <div className="mr">
                            <Select placeholder="请选择年级" style={{ width: 100 }} onChange={e => this.onChange(e, 'xiaoguanjia_grade_id', 'select')} value={this.state.xiaoguanjia_grade_id}>
                                {grade_list}
                            </Select>
                        </div>
                        <div className="mr">
                            <span>开始时间：</span>
                            <DatePicker allowClear={false} onChange={e => this.onChange(e, 'startTime', 'date')} defaultValue={moment().subtract(6, 'd')} />
                        </div>
                        <div className="mr">
                            <span>结束时间：</span>
                            <DatePicker allowClear={false} onChange={e => this.onChange(e, 'endTime', 'date')} defaultValue={moment(new Date())} />
                        </div>
                        <Button className="mr" type="primary" onClick={this.get_wrong_index}>查询</Button>
                    </div>
                    <div style={{ display: 'flex', overflow: 'auto', height: this.state.height }}>
                        {this.state.wrong_index_list.length == 0 ?
                            <Table style={{ width: '100%' }} pagination={false} columns={columns} dataSource={this.state.wrong_index_list} bordered />
                            :
                            this.state.wrong_index_list.map((item, index) => {
                                return (
                                    <div style={{ flexShrink: '0', marginRight: '20px', width: '600px', position: 'relative' }} key={index}>
                                        <div style={{ fontSize: '24px', fontWeight: '800', textAlign: 'center' }}>{item.subject}<span style={{ fontSize: '16px', fontWeight: '400' }}>({this.state.wrong_index_list[index].student_list.length}人)</span></div>
                                        <Table pagination={false} columns={columns} dataSource={item.student_list} bordered scroll={{ y: `${this.state.height - 120}px` }} />
                                        <div id={index} style={{ height: '300px', width: '600px' }}></div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default Behavioraldata;