import React, { Component } from 'react';
import './App.css';
import RouterIndex from './router/router'
import zhCN from 'antd/es/locale/zh_CN'
import { ConfigProvider } from 'antd';
// import ErrorBoundary from './ErrorBoundary'
class App extends Component {
  render() {
    return (
      // <ErrorBoundary>
      <ConfigProvider locale={zhCN}>
        <RouterIndex />
      </ConfigProvider>
      // </ErrorBoundary>
    );
  }
}
export default App;
