import React, { Component } from 'react';
import { Table, Select, Input, Button, Spin, Pagination, message, Tooltip } from 'antd';
import {
    get_lottery_list
} from '../../../axios/http'
import copy from "copy-to-clipboard";

const { Option } = Select

class Luckydraw extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            state: '',
            page: 1,
            page_size: 10,
            count: 0,
            loading: true
        }
    }
    componentDidMount = () => {
        this.get_lottery_list()
    }
    get_lottery_list = () => {
        this.setState({
            loading: true
        })
        let data = {
            name: this.state.name,
            state: this.state.state,
            page: this.state.page,
            page_size: this.state.page_size
        }
        get_lottery_list(data).then(res => {
            if (res.code == 0) {
                this.setState({
                    data: res.data.list,
                    loading: false,
                    count: res.data.total_count
                })
            } else {
                message.error(res.message)
            }
        })
    }
    changePage = () => {

    }
    searchChange = (e, id) => {
        this.setState({
            [id]: typeof (e) == 'undefined' ? '' : typeof (e) == 'object' ? e.target.value : e
        })
    }
    toList = (id) => {
        this.props.toList(id)
    }
    toDetail = (id) => {
        this.props.toDetail(id)
    }
    render() {
        const columns = [
            {
                title: '活动ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: '名称',
                dataIndex: 'name',
                key: 'name',
                render: (text, record, index) => (
                    <div style={{ cursor: 'pointer', color: '#1890ff' }} onClick={e => this.toDetail(record.id, record)}>{text}</div>
                )
            },
            {
                title: '状态',
                dataIndex: 'state',
                key: 'state',
                render: (text) => (
                    <div>{text == 1 ? '启用' : '未启用'}</div>
                )
            },
            {
                title: '访客数量',
                dataIndex: 'visitor_count',
                key: 'visitor_count',
            },
            {
                title: '授权数量',
                dataIndex: 'user_count',
                key: 'user_count',
            },
            {
                title: '提交数量',
                dataIndex: 'submit_count',
                key: 'submit_count',
            },
            {
                title: '开始时间',
                dataIndex: 'start_time',
                key: 'start_time',
            },
            {
                title: '结束时间',
                dataIndex: 'end_time',
                key: 'end_time',
            },
            {
                title: '操作',
                key: 'active',
                dataIndex: 'active',
                render: (text, record, index) => (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ cursor: 'pointer', color: '#1890ff' }} onClick={e => this.toList(record.id)}>查看</div>/<Tooltip placement='top' title={'https://zhongtai.yidianedu.com/active/grant/index/' + record.id}>
                            <div style={{ cursor: 'pointer', color: '#1890ff' }} onClick={e => {
                                if (copy('https://zhongtai.yidianedu.com/choujiang/grant/index/' + record.id)) {
                                    message.success("复制成功，请使用微信打开！")
                                } else message.error("复制失败！")
                            }}>复制链接</div>
                        </Tooltip>
                    </div>
                )
            },
        ];
        return (
            <div>
                <div className="flex">
                    <div style={{ marginRight: '10px' }}>
                        <span>活动名称：</span>
                        <Input style={{ width: 150 }} placeholder="请输入" onChange={e => this.searchChange(e, 'name')} allowClear></Input>
                    </div>
                    <div style={{ marginRight: '10px' }}>
                        <span>状态：</span>
                        <Select style={{ width: 180 }} onChange={e => this.searchChange(e, 'state')} placeholder="请选择状态" allowClear>
                            <Option value="-1">未启用</Option>
                            <Option value="1">已启用</Option>
                        </Select>
                    </div>
                    <Button style={{ marginRight: '10px' }} onClick={this.get_lottery_list} type="primary">查找</Button>
                    <Button onClick={e => this.toDetail('', '{}')} type="primary">新建活动</Button>
                </div>
                <Spin spinning={this.state.loading}>
                    <Table
                        columns={columns}
                        dataSource={this.state.data}
                        bordered
                        size="middle"
                        pagination={false}
                    />
                    <div className="m-Pleft m-flex">
                        <Pagination current={Number(this.state.page)} onChange={this.changePage} total={Number(this.state.count)} />
                    </div>
                </Spin>
            </div>
        )
    }
}

export default Luckydraw;