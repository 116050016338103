import React, { useEffect, useState } from 'react'
import myContext from '../../actions/createContext'
import { Modal, Divider, Button, Table, Pagination, Row, Col, Input, Select, Upload, message, Tabs, Popconfirm } from 'antd';
import {
    get_all_subject,
    get_sub_subject,
    get_condition_by_sub_subject_id,
    get_teaching_type_list,
    create_teachingtype,
    edit_teachingtype,
    del_teachingtype,
    create_teaching_lession,
    edit_teaching_lession,
    get_lession_list,
    del_teaching_lession
} from '../../axios/http'
import Syllabusdetail from './syllabusdetail'
import Add from './add'
import '../../store/config'
import moment from 'moment'

const { Option } = Select

const baseUrl = global.constants.baseURL

const { TabPane } = Tabs;

let params_model = {
    name: '',
    grade_id: '',
    subject_id: '',
    sub_subject_id: '',
    year: '',
    system_id: '',
    period_id: '',
    class_type_id: '',
    company_id: '',
    page: 1,
    page_size: 100
}

let teach_params_model = {
    teachingtype_id: '',
    name: '',
    grade_id: '',
    subject_id: '',
    sub_subject_id: '',
    year: '',
    system_id: '',
    period_id: '',
    introduction: '',
    class_type_id: '',
    company_id: '',
    resources_json_str: '',
}

let teaching_lession_model = {
    lession_id: '',
    teachingtype_id: '',
    name: '',
    number: '',
    jiaoan_url: '',
    jiangyi_url: '',
    video_url: '',
    help_video_url: '',
}

const Syllabuslist = (props) => {

    const [params, paramsSet] = useState(params_model)
    const [teach_params, teach_paramsSet] = useState(teach_params_model)
    const [teaching_lession, teaching_lessionSet] = useState(teaching_lession_model)
    const [index_list, index_listSet] = useState([0, 0, 0, 0, 0, 0, 0])
    const [all_subject, all_subjectSet] = useState([])
    const [sub_subject, sub_subjectSet] = useState([])
    const [condition, conditionSet] = useState({
        class_type_list: [],
        company_list: [],
        grade_list: [],
        period_list: [],
        system_list: []
    })
    const [teaching_type_list, teaching_type_listSet] = useState([])
    const [lessionVisible, lessionVisibleSet] = useState(false)
    const [lession_list, lession_listSet] = useState([])
    const [iseditor, iseditorSet] = useState(false)
    const [teachEditor, teachEditorSet] = useState({})
    const [addlessionVisible, addlessionVisibleSet] = useState(false)
    const [lession_show, lession_showSet] = useState({
        jiaoan_url: '',
        video_url: '',
        help_video_url: ''
    })
    const [lession_show_visible, lession_show_visibleSet] = useState(false)

    useEffect(() => {
        get_all_subjectFun()
    }, [index_list])

    const get_all_subjectFun = () => {
        get_all_subject().then(res => {
            if (res.code == 0) {
                let string = params
                string.subject_id = res.data.subject_list[index_list[0]].id
                paramsSet(string)
                all_subjectSet(res.data.subject_list)
            } else {
                message.error(res.message)
            }
        })
    }

    useEffect(() => {
        if (all_subject.length > 0)
            get_sub_subjectFun()
    }, [all_subject])

    const get_sub_subjectFun = () => {
        let data = {
            subject_id: all_subject.length > 0 ? all_subject[index_list[0]].id : ''
        }
        get_sub_subject(data).then(res => {
            if (res.code == 0) {
                let string = params
                string.sub_subject_id = index_list[1] == -1 ? '' : res.data.list[index_list[1]].id
                paramsSet(string)
                sub_subjectSet(res.data.list)
            } else {
                message.error(res.message)
            }
        })
    }

    useEffect(() => {
        if (sub_subject.length > 0)
            get_condition_by_sub_subject_idFun()
    }, [sub_subject])

    const get_condition_by_sub_subject_idFun = () => {
        if (index_list[1] == -1) {
            let string = params
            string.grade_id = ''
            string.period_id = ''
            string.class_type_id = ''
            string.company_id = ''
            string.system_id = ''
            paramsSet(string)
            let data = {
                class_type_list: [],
                company_list: [],
                grade_list: [],
                period_list: [],
                system_list: []
            }
            conditionSet(data)
            get_teaching_type_listFun()
        } else {
            let data = {
                sub_subject_id: sub_subject.length > 0 ? sub_subject[index_list[1]].id : ''
            }
            get_condition_by_sub_subject_id(data).then(res => {
                if (res.code == 0) {
                    let string = params
                    string.grade_id = index_list[2] == -1 ? '' : res.data.grade_list.length > 0 ? res.data.grade_list[index_list[2]].id : ''
                    string.period_id = res.data.period_list.length > 0 ? res.data.period_list[index_list[3]].id : ''
                    string.class_type_id = res.data.class_type_list.length > 0 ? res.data.class_type_list[index_list[4]].id : ''
                    string.company_id = res.data.company_list.length > 0 ? res.data.company_list[index_list[5]].company_id : ''
                    string.system_id = res.data.system_list.length > 0 ? res.data.system_list[index_list[6]].id : ''
                    paramsSet(string)
                    conditionSet(res.data)
                    get_teaching_type_listFun()
                } else {
                    message.error(res.message)
                }
            })
        }
    }

    const get_teaching_type_listFun = () => {
        get_teaching_type_list(params).then(res => {
            if (res.code == 0) {
                teaching_type_listSet(res.data.list)
            } else {
                message.error(res.message)
            }
        })
    }

    const indexChange = (i, index) => {
        let list = index_list.join('-'), newList = list.split('-')
        if (i < 2) {
            for (var t = i + 1; t < newList.length; t++) {
                newList[t] = 0
            }
        }
        newList[i] = index
        index_listSet(newList)
    }

    const del_teachingtypeFun = (e, id) => {
        let data = {
            teachingtype_id: id
        }
        del_teachingtype(data).then(res => {
            if (res.code == 0) {
                console.log(res.data)
            } else {
                message.error(res.message)
            }
        })
    }

    const create_teaching_lessionFun = () => {
        let data = teaching_lession
        create_teaching_lession(data).then(res => {
            if (res.code == 0) {
                let id = teaching_lession.teachingtype_id
                teaching_lessionSet(teaching_lession_model);
                teachEditorSet({})
                get_lession_listFun(false, id)
                addlessionVisibleSet(false)
                iseditorSet(false)
            } else {
                message.error(res.message)
            }
        })
    }

    const edit_teaching_lessionFun = () => {
        let data = teaching_lession
        edit_teaching_lession(data).then(res => {
            if (res.code == 0) {
                let id = teaching_lession.teachingtype_id
                teaching_lessionSet(teaching_lession_model);
                teachEditorSet({})
                get_lession_listFun(false, id)
                addlessionVisibleSet(false)
                iseditorSet(false)
            } else {
                message.error(res.message)
            }
        })
    }

    const lessiononOk = () => {
        if (iseditor) {
            edit_teaching_lessionFun()
        } else {
            create_teaching_lessionFun()
        }
    }

    const get_lession_listFun = (e, id) => {
        if (e) {
            e.stopPropagation()
        }
        let data = {
            teachingtype_id: id
        }
        get_lession_list(data).then(res => {
            if (res.code == 0) {
                lession_listSet(res.data.list)
                lessionVisibleSet(true)
                onChange(id, 'teachingtype_id')
            }
        })
    }

    const del_teaching_lessionFun = (e, id) => {
        let data = {
            lession_id: id
        }
        del_teaching_lession(data).then(res => {
            if (res.code == 0) {
                message.success('删除成功！')
                get_lession_listFun(false, teaching_lession.teachingtype_id)
            } else {
                message.error(res.message)
            }
        })
    }

    const teachEditorFun = (e, item) => {
        e.stopPropagation()
        teachEditorSet(item)
    }

    const onChange = (e, id) => {
        var t = JSON.stringify(teaching_lession), s = JSON.parse(t)
        s[id] = typeof (e) == 'undefined' ? '' : typeof (e) == 'object' ? e.target.value : e;
        teaching_lessionSet(s);
    }

    const upload = {
        action: baseUrl + '/api/upload/upload_file',
        multiple: false,
        name: 'upload_control',
        showUploadList: false,
        headers: {
            token: localStorage.getItem("token"),
            username: encodeURI(localStorage.getItem("username")),
            companyid: localStorage.getItem("companyid"),
        },
        data: {
            type: 9
        }
    };

    const uploadChange = (info, id) => {
        if (info.file.status === 'done') {
            if (info.file.response.code == 0) {
                message.success(`${info.file.name} file uploaded successfully`)
                let list = JSON.stringify(teaching_lession),
                    newList = JSON.parse(list)
                newList[id] = info.file.response.data.full_path
                teaching_lessionSet(newList)
            } else {
                message.error(info.file.response.message)
            }
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    const lession_detailShow = (record) => {
        lession_showSet(record)
        lession_show_visibleSet(true)
    }

    const columns = [
        {
            title: '讲次',
            dataIndex: 'number',
            key: 'number',
            render: text => (
                <div>第{text}讲</div>
            )
        },
        {
            title: '名称',
            dataIndex: 'name',
            align: 'left',
            width: 400,
            ellipsis: true,
            key: 'name',
            render: (text, record, index) => (
                <span key={index} style={{ cursor: 'pointer', color: '#1890ff' }} onClick={(e) => { lession_detailShow(record) }}>{text}</span>
            )
        },
        {
            title: '添加时间',
            dataIndex: 'add_time',
            key: 'add_time',
            render: text => (
                <div>{moment(text).format('YYYY-MM-DD')}</div>
            )
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            render: (text, record, index) => (
                <div style={{ whiteSpace: 'pre' }} key={index}>
                    <span style={{ cursor: 'pointer', color: '#1890ff' }} onClick={(e) => {
                        let s = JSON.stringify(record),
                            t = JSON.parse(s);
                        t.lession_id = t.id;
                        teaching_lessionSet(t);
                        iseditorSet(true);
                        addlessionVisibleSet(true)
                    }}>修改</span>
                    &nbsp;&nbsp;/&nbsp;&nbsp;
                    <Popconfirm placement="top" title={'是否确认删除？'} onConfirm={(e) => del_teaching_lessionFun(e, record.id)} okText="是" cancelText="否">
                        <span style={{ cursor: 'pointer', color: '#1890ff' }} >删除</span>
                    </Popconfirm>
                </div>
            )
        }
    ];

    const downloadFile = url => {
        window.location.href = url
    }

    return (
        <div style={{ position: 'relative' }}>
            <Add get_all_subjectFun={get_all_subjectFun} />
            <div style={{ display: 'flex', alignItems: 'center' }} >
                <div style={{ fontSize: '14px', fontWeight: '700' }}>科目：</div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                    {all_subject.map((item, index) =>
                        <div className={index == index_list[0] ? 'syllabuslist_box syllabuslist_box_active' : 'syllabuslist_box'} key={index} onClick={e => indexChange(0, index)}>
                            {item.name}
                        </div>
                    )}
                </div>
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }} >
                <div style={{ fontSize: '14px', fontWeight: '700' }}>学科：</div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                    <div className={-1 == index_list[1] ? 'syllabuslist_box syllabuslist_box_active' : 'syllabuslist_box'} onClick={e => indexChange(1, -1)}>
                        全部
                    </div>
                    {sub_subject.map((item, index) =>
                        <div className={index == index_list[1] ? 'syllabuslist_box syllabuslist_box_active' : 'syllabuslist_box'} key={index} onClick={e => indexChange(1, index)}>
                            {item.name}
                        </div>
                    )}
                </div>
            </div>

            {condition.grade_list.length != 0 ? <div style={{ display: 'flex', alignItems: 'center' }} >
                <div style={{ fontSize: '14px', fontWeight: '700' }}>年级：</div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                    <div className={-1 == index_list[2] ? 'syllabuslist_box syllabuslist_box_active' : 'syllabuslist_box'} onClick={e => indexChange(2, -1)}>
                        全部
                    </div>
                    {condition.grade_list.map((item, index) =>
                        <div className={index == index_list[2] ? 'syllabuslist_box syllabuslist_box_active' : 'syllabuslist_box'} key={index} onClick={e => indexChange(2, index)}>
                            {item.value}
                        </div>
                    )}
                </div>
            </div> : ''}

            {condition.period_list.length != 0 ? <div style={{ display: 'flex', alignItems: 'center' }} >
                <div style={{ fontSize: '14px', fontWeight: '700' }}>学期：</div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                    {condition.period_list.map((item, index) =>
                        <div className={index == index_list[3] ? 'syllabuslist_box syllabuslist_box_active' : 'syllabuslist_box'} key={index} onClick={e => indexChange(3, index)}>
                            {item.name}
                        </div>
                    )}
                </div>
            </div> : ''}

            {condition.class_type_list.length != 0 ? <div style={{ display: 'flex', alignItems: 'center' }} >
                <div style={{ fontSize: '14px', fontWeight: '700' }}>类别：</div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                    {condition.class_type_list.map((item, index) =>
                        <div className={index == index_list[4] ? 'syllabuslist_box syllabuslist_box_active' : 'syllabuslist_box'} key={index} onClick={e => indexChange(4, index)}>
                            {item.name}
                        </div>
                    )}
                </div>
            </div> : ''}

            {condition.company_list.length != 0 ? <div style={{ display: 'flex', alignItems: 'center' }} >
                <div style={{ fontSize: '14px', fontWeight: '700' }}>校区：</div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                    {condition.company_list.map((item, index) =>
                        <div className={index == index_list[5] ? 'syllabuslist_box syllabuslist_box_active' : 'syllabuslist_box'} key={index} onClick={e => indexChange(5, index)}>
                            {item.name}
                        </div>
                    )}
                </div>
            </div> : ''}

            {condition.system_list.length != 0 ? <div style={{ display: 'flex', alignItems: 'center' }} >
                <div style={{ fontSize: '14px', fontWeight: '700' }}>体系：</div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                    {condition.system_list.map((item, index) =>
                        <div className={index == index_list[6] ? 'syllabuslist_box syllabuslist_box_active' : 'syllabuslist_box'} key={index} onClick={e => indexChange(6, index)}>
                            {item.value}
                        </div>
                    )}
                </div>
            </div> : ''}

            <myContext.Provider value={teachEditor}  >
                <Syllabusdetail get_teaching_type_listFun={get_teaching_type_listFun} teachEditorFun={teachEditorFun} />
            </myContext.Provider>

            <Divider />

            <div>
                <ul className="classtype-list">
                    {
                        teaching_type_list.map((item, index) =>
                            <li className="classtype-item" key={index} onClick={e => get_lession_listFun(e, item.id)}>
                                <span className="class-type-title">{item.name}</span>
                                <div className="tagWarp" onClick={e => teachEditorFun(e, item)}>修改</div>
                            </li>
                        )
                    }
                </ul>
            </div>

            <Modal
                title={'教学课程列表'}
                cancelText='取消'
                okText='确认'
                width={800}
                visible={lessionVisible}
                onOk={() => lessionVisibleSet(false)}
                onCancel={() => lessionVisibleSet(false)}
            >
                <Button onClick={() => { addlessionVisibleSet(true); let s = teaching_lession_model; s.teachingtype_id = teaching_lession.teachingtype_id; teaching_lessionSet(s); teachEditorSet({}); }} type="primary">添加</Button>
                <Table pagination={false} columns={columns} dataSource={lession_list} />
                {/* <Pagination className="m-Pleft" current={ans_page} defaultPageSize={5} onChange={this.changePage1} total={ans_total} /> */}
            </Modal>

            <Modal
                title={'添加教学课程'}
                cancelText='取消'
                okText='确认'
                width={600}
                visible={addlessionVisible}
                onOk={lessiononOk}
                onCancel={() => {
                    addlessionVisibleSet(false); iseditorSet(false); teaching_lessionSet(teaching_lession_model); teachEditorSet({});
                }}
            >
                <Row>
                    <Col className="ant-col-css" span={8}>
                        <span>课程名称：</span>
                    </Col>
                    <Col className="ant-col-css" span={16}>
                        <Input placeholder='请输入' style={{ width: 165 }} onChange={e => onChange(e, 'name')} value={teaching_lession.name} />
                    </Col>
                    <Col className="ant-col-css" span={8}>
                        <span>第几次课：</span>
                    </Col>
                    <Col className="ant-col-css" span={16}>
                        <Input placeholder='请输入' style={{ width: 165 }} onChange={e => onChange(e, 'number')} value={teaching_lession.number} />
                    </Col>
                    <Col className="ant-col-css" span={8}>
                        <span>教案文件：</span>
                    </Col>
                    <Col className="ant-col-css" style={{ display: 'block', height: 'auto' }} span={16}>
                        <Upload {...upload} onChange={e => uploadChange(e, 'jiaoan_url')}>
                            <Button size="small">已有文件/上传文件</Button>
                        </Upload>
                        <Input placeholder='请输入' style={{ marginTop: '5px' }} onChange={e => onChange(e, 'jiaoan_url')} value={teaching_lession.jiaoan_url} />
                    </Col>
                    <Col className="ant-col-css" span={8}>
                        <span>讲义文件：</span>
                    </Col>
                    <Col className="ant-col-css" style={{ display: 'block', height: 'auto' }} span={16}>
                        <Upload {...upload} onChange={e => uploadChange(e, 'jiangyi_url')}>
                            <Button size="small">已有文件/上传文件</Button>
                        </Upload>
                        <Input placeholder='请输入' style={{ marginTop: '5px' }} onChange={e => onChange(e, 'jiangyi_url')} value={teaching_lession.jiangyi_url} />
                    </Col>
                    <Col className="ant-col-css" span={8}>
                        <span>课堂实录：</span>
                    </Col>
                    <Col className="ant-col-css" style={{ display: 'block', height: 'auto' }} span={16}>
                        <Upload {...upload} onChange={e => uploadChange(e, 'video_url')}>
                            <Button size="small">已有文件/上传文件</Button>
                        </Upload>
                        <Input placeholder='请输入' style={{ marginTop: '5px' }} onChange={e => onChange(e, 'video_url')} value={teaching_lession.video_url} />
                    </Col>
                    <Col className="ant-col-css" span={8}>
                        <span>磨课视频：</span>
                    </Col>
                    <Col className="ant-col-css" style={{ display: 'block', height: 'auto' }} span={16}>
                        <Upload {...upload} onChange={e => uploadChange(e, 'help_video_url')}>
                            <Button size="small">已有文件/上传文件</Button>
                        </Upload>
                        <Input placeholder='请输入' style={{ marginTop: '5px' }} onChange={e => onChange(e, 'help_video_url')} value={teaching_lession.help_video_url} />
                    </Col>
                    <Col className="ant-col-css" span={8}>
                        <span>课程素材：</span>
                    </Col>
                    <Col className="ant-col-css" style={{ display: 'block', height: 'auto' }} span={16}>
                        <Upload {...upload} onChange={e => uploadChange(e, 'resources_url')}>
                            <Button size="small">已有文件/上传文件</Button>
                        </Upload>
                        <Input placeholder='请输入' style={{ marginTop: '5px' }} onChange={e => onChange(e, 'resources_url')} value={teaching_lession.resources_url} />
                    </Col>
                </Row>
            </Modal>
            <Modal
                title={'文件预览'}
                cancelText='取消'
                okText='确认'
                width={800}
                visible={lession_show_visible}
                onOk={() => { lession_show_visibleSet(false); }}
                onCancel={() => { lession_show_visibleSet(false); }}
            >
                <Tabs tabPosition={'left'}>
                    <TabPane tab="教案文件" key="1">
                        {
                            lession_show.jiaoan_url.indexOf('.pdf') == -1 ?
                                <iframe src={"https://view.officeapps.live.com/op/view.aspx?src=" + lession_show.jiaoan_url} frameBorder="0" style={{ width: '625px', height: '518px' }}></iframe>
                                :
                                <iframe src={lession_show.jiaoan_url} frameBorder="0" style={{ width: '625px', height: '518px' }}></iframe>
                        }
                        <Button onClick={e => downloadFile(lession_show.jiaoan_url)}>立即下载</Button>
                    </TabPane>
                    <TabPane tab="讲义文件" key="2">
                        <iframe src={"https://view.officeapps.live.com/op/view.aspx?src=" + lession_show.jiangyi_url} frameBorder="0" style={{ width: '625px', height: '518px' }}></iframe>
                        <Button onClick={e => downloadFile(lession_show.jiangyi_url)}>立即下载</Button>
                    </TabPane>
                    <TabPane tab="课堂实录" key="3">
                        {
                            lession_show.video_url.indexOf('.pdf') == -1 ?
                                <video src={lession_show.video_url} controls="controls" style={{ width: '625px', height: '550px' }}></video>
                                :
                                <iframe src={lession_show.video_url} frameBorder="0" style={{ width: '625px', height: '518px' }}></iframe>
                        }
                    </TabPane>
                    <TabPane tab="磨课视频" key="4">
                        {
                            lession_show.help_video_url.indexOf('.pdf') == -1 ?
                                <video src={lession_show.help_video_url} controls="controls" style={{ width: '625px', height: '550px' }}></video>
                                :
                                <iframe src={lession_show.help_video_url} frameBorder="0" style={{ width: '625px', height: '518px' }}></iframe>
                        }
                    </TabPane>
                    {
                        lession_show.resources_url ?
                            <TabPane tab="课程素材" key="5">
                                <div>{lession_show.resources_url}<Button onClick={e => downloadFile(lession_show.resources_url)}>立即下载</Button></div>
                            </TabPane> : ''
                    }
                </Tabs>
            </Modal>
        </div>
    )
}
export default Syllabuslist
