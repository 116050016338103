import React, { Component } from 'react';
import { Button, Table, DatePicker, Tabs, Progress, Modal, message, Tooltip, Select } from 'antd'
import { get_teacher_statistics, wanfudao, wanfudao_detail, get_wrong_index, get_xiaoguanjia_grade, get_xiaoguanjia_subject } from '../../axios/http'
import moment from 'moment';
import '../../css/teacherdatareport.css'
import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts/lib/echarts';
const { TabPane } = Tabs;
const { Option } = Select

class teacherdatareport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [],
            startTime: moment().startOf('month').format('YYYY-MM-DD'),
            startTime: '2020-11-01',
            endTime: moment(new Date()).format('YYYY-MM-DD'),
            tabsIndex: "1",
            wanfudao_date: moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD'),
            wan_data: [],
            detail_visible: false,
            detail_list: [],
            count: 0,
            xiaoguanjia_grade_id: 'dfa7cd18-ab16-4938-b089-c698d1253bd3',
            grade_list: [],
            subject_list: [],
            wrong_index_list: [],
            xiaoguanjia_grade: '初一'
        }
    }
    componentDidMount() {
        get_xiaoguanjia_grade().then(res => {
            this.setState({
                grade_list: res.data.list
            })
        })
        this.get_teacher_statistics()
        window.addEventListener('resize', this.handleSize);
        this.handleSize()
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleSize);
        this.setState = (state, callback) => {
            return
        }
    }
    handleSize = () => {
        let height = document.body.clientHeight - 327 - 27,
            width = document.body.clientWidth - 323
        this.setState({
            height,
            width
        });
        for (var i = 0; i < this.state.count; i++) {
            if (document.getElementById(i)) {
                var myChart = echarts.init(document.getElementById(i));
                myChart.resize()
            }
        }
    }
    get_wrong_index = () => {
        for (var i = 0; i < this.state.count; i++) {
            if (document.getElementById(i)) {
                var myChart = echarts.init(document.getElementById(i));
                myChart.dispose();
            }
        }
        let data = {
            xiaoguanjia_grade_id: this.state.xiaoguanjia_grade_id,
            xiaoguanjia_grade: this.state.xiaoguanjia_grade,
            start_date: this.state.startTime,
            end_date: this.state.endTime
        }
        get_wrong_index(data).then(res => {
            let list = JSON.stringify(res.data.list)
            let json = JSON.parse(list)
            json.forEach(item => {
                item.student_list.push({
                    name: "平均值",
                    wrong_index: item.avg_index,
                    total_ask_count: item.avg_ask
                })
                item.student_list.forEach(item1 => {
                    item1['avg_index'] = item.avg_index
                })
            })
            this.setState({
                wrong_index_list: json
            })
            setTimeout(() => {
                let json1 = JSON.parse(list)
                json1.map((item1, index) => {
                    let xAxis = [],
                        full_score = []
                    item1.student_list.map(item => {
                        xAxis.push(item.name)
                        full_score.push(item.wrong_index)
                    })
                    var myChart = echarts.init(document.getElementById(index));
                    myChart.setOption({
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                label: {
                                    backgroundColor: '#6a7985'
                                }
                            }
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: xAxis
                            }
                        ],
                        yAxis: {
                            type: 'value'
                        },
                        series: [{
                            name: '错误指标',
                            type: 'line',
                            stack: '指标数',
                            lineStyle: {
                                color: '#ff0437'
                            },
                            label: {
                                normal: {
                                    show: true,
                                    position: 'top'
                                }
                            },
                            markLine: {
                                silent: true,
                                lineStyle: {
                                    normal: {
                                        color: '#10d270'// 这儿设置安全基线颜色
                                    }
                                },
                                data: [{
                                    yAxis: json1[index].avg_index
                                }],
                                label: {
                                    normal: {
                                        formatter: '平均值'// 这儿设置安全基线
                                    }
                                },
                            },
                            data: full_score
                        }]
                    });
                })
                this.setState({
                    count: json1.length
                })
            }, 0);
        })
    }
    get_teacher_statistics = () => {
        let params = {
            starttime: this.state.startTime,
            endtime: this.state.endTime
        }
        get_teacher_statistics(params).then(res => {
            this.setState({
                list: res.data.list
            })
        })
    }
    wanfudao = () => {
        wanfudao({ date: this.state.wanfudao_date }).then(res => {
            this.setState({
                wan_data: res.data.model
            })
        })
    }
    startonchange = (e) => {
        this.setState({
            startTime: moment(e).format('YYYY-MM-DD')
        })
    }
    onchangeEnd = (e) => {
        this.setState({
            endTime: moment(e).format('YYYY-MM-DD')
        })
    }
    callback = (key) => {
        if (key == '1') {
            this.get_teacher_statistics()
        }
        if (key == '2') {
            this.wanfudao()
        }
        this.setState({
            tabsIndex: key
        })
    }
    wanfudaochange = e => {
        this.setState({
            wanfudao_date: moment(e).format('YYYY-MM-DD')
        })
    }
    wanfudao_detail = e => {
        let data = {
            date: this.state.wanfudao_date,
            type_id: e
        }
        this.setState({
            type_id: e
        })
        wanfudao_detail(data).then(res => {
            if (res.code == 0) {
                if (res.data.list) {
                    res.data.list.forEach((element, index) => {
                        element.index = index + 1
                    });
                }
                this.setState({
                    detail_date: res.data.date,
                    detail_list: res.data.list || [],
                    detail_visible: true
                })
            } else {
                message.error(res.message)
            }
        })
    }
    handleOk = e => {
        this.setState({
            detail_visible: false
        })
    }
    toExport = e => {
        window.open(`http://jiaoxueapi.yanuojiaoyu.com/api/report/un_submit_push?date=${this.state.wanfudao_date}&company_id=${localStorage.getItem('company_id')}`)
    }
    onChange = (e, id, type) => {
        let value = e
        switch (type) {
            case 'date': value = moment(value).format('YYYY-MM-DD'); break;
        }
        if (id == 'xiaoguanjia_grade_id') {
            this.state.grade_list.some(item => {
                if (item.xiaoguanjia_id == e) {
                    this.setState({
                        xiaoguanjia_grade: item.value.split('-')[1]
                    })
                    return true
                }
            })
        }
        this.setState({
            [id]: value
        })
    }
    render() {
        const { xiaoxue, xiaoxue_tixing, chuyi, chuyi_tixing, chuer, chuer_tixing, chusan, chusan_tixing, finished_count, not_finished_count, teacher_warning_message, total_count, finished_str, need_count, studying_count, try_count } = this.state.wan_data
        const columns = [
            {
                title: '教师名称',
                dataIndex: 'name',
                key: 'name'
            }, {
                title: '排课数量',
                dataIndex: 'paike_count',
                key: 'paike_count',
                sorter: (a, b) => a.paike_count - b.paike_count,
            }, {
                title: '讲义数量',
                dataIndex: 'self_lecture_count',
                key: 'self_lecture_count',
                sorter: (a, b) => a.self_lecture_count - b.self_lecture_count,
            }, {
                title: '练习布置',
                dataIndex: 'lianxi_count',
                key: 'lianxi_count',
                sorter: (a, b) => a.lianxi_count - b.lianxi_count,
            }, {
                title: '作业批改',
                dataIndex: 'pigai_count',
                key: 'pigai_count',
                sorter: (a, b) => a.pigai_count - b.pigai_count,
            }, {
                title: '错题数量',
                dataIndex: 'wrong_question_count',
                key: 'wrong_question_count',
                sorter: (a, b) => a.wrong_question_count - b.wrong_question_count,
            }, {
                title: '试卷数量',
                dataIndex: 'self_paper_count',
                key: 'self_paper_count',
                sorter: (a, b) => a.self_paper_count - b.self_paper_count,
            }, {
                title: '题目数量',
                dataIndex: 'self_question_count',
                key: 'self_question_count',
                sorter: (a, b) => a.self_question_count - b.self_question_count,
            }
        ];
        const detail_columns = [
            {
                title: '序号',
                dataIndex: 'index',
                key: 'index'
            },
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '年级',
                dataIndex: 'fulltime_grade',
                key: 'fulltime_grade',
            },
            {
                title: '日期',
                dataIndex: 'date',
                key: 'date',
                render: e => <span>{this.state.detail_date}</span>
            },
            {
                title: '备注',
                dataIndex: 'remark',
                key: 'remark',
                render: (res) => <span dangerouslySetInnerHTML={{ __html: res }}></span>
            }
        ];
        const option = {
            title: {
                text: `${this.state.wanfudao_date}晚辅导统计`,
                left: 'center'
            },
            legend: {
                orient: 'vertical',
                left: 'left',
                data: ['在读', '试听']
            },
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            color: ['#FF6C60', '#57C8F2'],
            series: [
                {
                    name: '晚辅导统计',
                    type: 'pie',
                    radius: '55%',
                    center: ['50%', '50%'],
                    data: [
                        { value: studying_count, name: `在读` },
                        { value: try_count, name: `试听` }],
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };
        const columns3 = [
            {
                title: '老师',
                align: "center",
                key: 'teacher',
                dataIndex: 'teacher',
                width: 150,
                render: (value, row, index) => {
                    const obj = {
                        children: value,
                        props: {},
                    };
                    if (index == 0) {
                        obj.props.rowSpan = 50;
                    } else {
                        obj.props.rowSpan = 0
                    }
                    return obj;
                },
            },
            {
                title: '序号',
                align: "center",
                width: 75,
                render: (value, row, index) => {
                    const obj = {
                        children: Number(index + 1),
                        props: {},
                    };
                    return obj;
                },
            },
            {
                title: '姓名',

                width: 100,
                key: 'name',
                align: "center",
                render: (text) => (
                    <span>{text.name}</span>
                ),
            },
            {
                title: '年级',
                dataIndex: 'fulltime_grade',
                key: 'fulltime_grade',
                width: 100,
                align: "center"
            },
            {
                title: '日期',
                dataIndex: 'date',
                key: 'date',
                width: 100,
                align: "center",
                render: e => <span>{this.state.detail_date}</span>
            },
            {
                title: '备注',
                key: 'remark',
                width: 100,
                dataIndex: 'remark',
                align: "center",
                render: (res) => <span dangerouslySetInnerHTML={{ __html: res }}></span>

            }
        ];
        const columns4 = [
            {
                title: '学生姓名',
                align: "center",
                key: "name",
                dataIndex: "name",
                width: 100
            },
            {
                title: '统计次数',
                align: "center",
                key: "total_ques_count",
                dataIndex: "total_ques_count",
                width: 100,
                render: (text) => (
                    <div>{text ? text : '-'}</div>
                ),
                sorter: (a, b) => a.total_ques_count - b.total_ques_count,
            },
            {
                title: '错误指数',
                align: "center",
                key: "wrong_index",
                dataIndex: "wrong_index",
                width: 100,
                render: (text, record, index) => {
                    return <div style={{ color: record.avg_index < text ? 'red' : '#000' }}>{text}</div>
                },
                sorter: (a, b) => a.wrong_index - b.wrong_index,
            },
            {
                title: '提问次数',
                align: "center",
                key: "total_ask_count",
                dataIndex: "total_ask_count",
                width: 100,
                sorter: (a, b) => a.total_ask_count - b.total_ask_count,
            }
        ];
        const grade_list = this.state.grade_list.map(item => {
            return (
                <Option key={item.xiaoguanjia_id} value={item.xiaoguanjia_id}>{item.value.split('-')[1]}</Option>
            )
        })
        return (
            <div>
                <div className="m-Hmain" id="box">
                    <Tabs activeKey={this.state.tabsIndex} onChange={this.callback}>
                        <TabPane tab="教师数据统计" key="1">
                            <div className="m-bottom flex" >
                                <div className="mr">
                                    <span>开始时间：</span>
                                    <DatePicker allowClear={false} onChange={this.startonchange} defaultValue={moment().startOf('month')} />
                                </div>
                                <div className="mr">
                                    <span>结束时间：</span>
                                    <DatePicker allowClear={false} onChange={this.onchangeEnd} defaultValue={moment(new Date())} />
                                </div>
                                <Button className="mr" type="primary" onClick={this.get_teacher_statistics}>查询</Button>
                            </div>
                            <div>
                                <Table rowKey={record => record.key} pagination={false} columns={columns} dataSource={this.state.list} />
                            </div>
                        </TabPane>
                        <TabPane tab="晚辅导统计" key="2">
                            <div className="m-bottom flex" >
                                <div className="mr">
                                    <span>日期：</span>
                                    <DatePicker allowClear={false} onChange={this.wanfudaochange} value={moment(this.state.wanfudao_date)} />
                                </div>
                                <Button className="mr" type="primary" onClick={this.wanfudao}>查询</Button>
                            </div>
                            <div className="m-Hmain-div">
                                <div className="m-Hmain-pie">
                                    <p className="m-Hmain-pic">学生统计</p>
                                    <ReactEcharts
                                        option={option}
                                        style={{ width: '100%', height: 250 }}
                                    />
                                    <p className='font_style'>总人数：<span className="m-Hmain-text span" onClick={e => this.wanfudao_detail('1')} style={{ marginRight: '10px' }}>{total_count}</span>在读：<span className="m-Hmain-text span" onClick={e => this.wanfudao_detail('2')} style={{ marginRight: '10px' }}>{studying_count}</span>试听：<span className="m-Hmain-text span" onClick={e => this.wanfudao_detail('3')} style={{ marginRight: '10px' }}>{try_count}</span></p>
                                </div>
                                <div className="m-Hmain-pie">
                                    <p className="m-Hmain-pic">配置老师完成率</p>
                                    <div>
                                        <Tooltip placement="top" title={xiaoxue_tixing}>
                                            <div style={{ marginBottom: '2em' }}>
                                                <p className='font_style'>小学：</p>
                                                <Progress style={{ cursor: 'pointer' }} onClick={e => this.wanfudao_detail('4')} percent={xiaoxue} />
                                            </div>
                                        </Tooltip>
                                        <Tooltip placement="top" title={chuyi_tixing}>
                                            <div style={{ marginBottom: '2em' }}>
                                                <p className='font_style'>初一：</p>
                                                <Progress style={{ cursor: 'pointer' }} onClick={e => this.wanfudao_detail('5')} percent={chuyi} />
                                            </div>
                                        </Tooltip>
                                        <Tooltip placement="top" title={chuer_tixing}>
                                            <div style={{ marginBottom: '2em' }}>
                                                <p className='font_style'>初二：</p>
                                                <Progress style={{ cursor: 'pointer' }} onClick={e => this.wanfudao_detail('6')} percent={chuer} />
                                            </div>
                                        </Tooltip>
                                        <Tooltip placement="top" title={chusan_tixing}>
                                            <div style={{ marginBottom: '2em' }}>
                                                <p className='font_style'>初三：</p>
                                                <Progress style={{ cursor: 'pointer' }} onClick={e => this.wanfudao_detail('7')} percent={chusan} />
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="m-Hmain-pie">
                                    <p className="m-Hmain-pic">答疑解惑完成率</p>
                                    <div>
                                        <div style={{ marginBottom: '2em', display: 'flex', alignItems: 'center' }}>
                                            <img style={{ width: '16px', height: '16px', marginRight: '10px' }} src={require('../../img/tuoyuan.png')} alt="" />
                                            <span className='font_style'>应完成：</span>
                                            <span className="m-Hmain-text span" onClick={e => this.wanfudao_detail('8')}>{need_count}</span>
                                        </div>
                                        <div style={{ marginBottom: '2em' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <img style={{ width: '16px', height: '16px', marginRight: '10px' }} src={require('../../img/tuoyuan.png')} alt="" />
                                                <span className='font_style'>已提交：</span>
                                                <span className="m-Hmain-text span" onClick={e => this.wanfudao_detail('9')}>{finished_count}</span>
                                            </div>
                                            <p style={{ marginLeft: '26px', marginTop: '5px' }}>{finished_str}</p>
                                        </div>
                                        <div style={{ marginBottom: '2em', display: 'flex', alignItems: 'center' }}>
                                            <img style={{ width: '16px', height: '16px', marginRight: '10px' }} src={require('../../img/tuoyuan.png')} alt="" />
                                            <span className='font_style'>未完成：</span>
                                            <Tooltip placement='top' title={teacher_warning_message}>
                                                <span className="m-Hmain-text span" onClick={e => this.wanfudao_detail('10')}>{not_finished_count}</span>
                                            </Tooltip>
                                        </div>
                                        <div style={{ marginBottom: '2em', display: 'flex', alignItems: 'center' }}>
                                            <img style={{ width: '16px', height: '16px', marginRight: '10px' }} src={require('../../img/tuoyuan.png')} alt="" />
                                            <span className='font_style'>完成率：</span>
                                            <span className="m-Hmain-text" style={{ fontSize: '19px', fontWeight: '400' }}>{need_count == 0 || need_count == null ? '100' : (finished_count / need_count * 100).toFixed(2)}%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
                <Modal
                    title="数据详情"
                    visible={this.state.detail_visible}
                    onOk={this.handleOk}
                    onCancel={this.handleOk}
                    width={1000}
                >
                    <div style={{ display: this.state.type_id == 10 ? 'flex' : 'none', marginBottom: '10px', zIndex: '99', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span style={{ color: '#aaa', marginLeft: '5px' }}>备注：由于部分学生同一个科目配置了多个老师，实际显示的数据条数可能会多于未完成显示的数字</span>
                        <Button type="primary" onClick={e => this.toExport()}>导出</Button>
                    </div>

                    {
                        this.state.type_id == '10' ?
                            this.state.detail_list.map((res, index) =>
                                <div className='m-bottom' key={index}>
                                    <Table columns={columns3} dataSource={res.student_list} bordered pagination={false} />
                                </div>
                            )
                            :
                            <Table dataSource={this.state.detail_list} columns={detail_columns}></Table>
                    }
                </Modal>
            </div>
        )
    }
}
export default teacherdatareport;