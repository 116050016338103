import React, { Component } from 'react';
import { Table, Select, Input, Button, Spin, Pagination, message, Tooltip, Popconfirm } from 'antd';
import {
    get_active_list,
    report,
    copy_active,
    del_active,
    report_detail
} from '../../../axios/http'
import copy from "copy-to-clipboard";
import Modal from 'antd/lib/modal/Modal';
import { withRouter } from 'react-router-dom'

const { Option } = Select

class Submitdraw extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            state: '',
            page: 1,
            page_size: 10,
            count: 0,
            loading: true,
            visible: false,
            user_list: {},
            type: ['授权未提交', '提交未核销', '已核销用户'],
            active_id: '',
            copy_visible: false,
            company_list: [],
            to_company_id: '',
            confirmLoading: false
        }
    }
    componentDidMount = () => {
        this.get_active_list()
        this.setState({
            company_list: JSON.parse(localStorage.getItem('company_list'))
        })
    }
    get_active_list = () => {
        this.setState({
            loading: true
        })
        let data = {
            name: this.state.name,
            state: this.state.state,
            page: this.state.page,
            page_size: this.state.page_size
        }
        get_active_list(data).then(res => {
            if (res.code == 0) {
                this.setState({
                    data: res.data.list,
                    loading: false,
                    count: res.data.total_count
                })
            } else {
                message.error(res.message)
            }
        })
    }
    changePage = (e) => {
        this.setState({
            page: e
        }, () => {
            this.get_active_list()
        })
    }
    searchChange = (e, id) => {
        this.setState({
            [id]: typeof (e) == 'undefined' ? '' : typeof (e) == 'object' ? e.target.value : e
        })
    }
    toDetail = (id, field_json_str) => {
        this.props.toDetail(id)
        this.props.field_json_str(JSON.parse(field_json_str))
    }
    toGrant = (id) => {
        this.props.toGrant(id)
    }
    toList = (id, field_json_str) => {
        this.props.toList(id)
        this.props.field_json_str(JSON.parse(field_json_str))
    }
    get_report = id => {
        this.props.history.push('/main/reportlist?id=' + id)
    }
    copy_visible_set = id => {
        this.setState({
            active_id: id,
            copy_visible: true
        })
    }
    copy_active = e => {
        this.setState({
            confirmLoading: true
        })
        if (!this.state.to_company_id) {
            message.warn('请选择校区')
            this.setState({
                confirmLoading: false
            })
            return
        }
        let data = {
            original_active_id: this.state.active_id,
            to_company_id: this.state.to_company_id
        }
        copy_active(data).then(res => {
            if (res.code == 0) {
                message.success('复制成功！')
                localStorage.setItem('company', this.state.to_company_name)
                localStorage.setItem('company_id', this.state.to_company_id)
                setTimeout(() => {
                    window.location.reload()
                }, 1500);
            } else {
                this.setState({
                    confirmLoading: false
                })
            }
        })
    }
    company_idSet = e => {
        if (e) {
            this.setState({
                to_company_id: this.state.company_list[e].company_id,
                to_company_name: this.state.company_list[e].company
            })
        } else {
            this.setState({
                to_company_id: '',
                to_company_name: ''
            })
        }
    }
    del_active = id => {
        del_active({ active_id: id }).then(res => {
            if (res.code === 0) {
                message.success('删除成功！')
                this.get_active_list()
            }
        })
    }
    get_report_detail = (submit_status, student_status) => {
        let data = {
            active_id: this.state.active_id,
            submit_status: submit_status,
            student_status: student_status
        }
        report_detail(data).then(res => {
            console.log(res.data)
        })
    }
    render() {
        const columns = [
            {
                title: '活动ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: '名称',
                dataIndex: 'name',
                key: 'name',
                render: (text, record, index) => (
                    <div style={{ cursor: 'pointer', color: '#1890ff' }} onClick={e => this.toDetail(record.id, record.field_json_str)}>{text}</div>
                )
            },
            {
                title: '状态',
                dataIndex: 'state',
                key: 'state',
                render: (text) => (
                    <div>{text == 1 ? '启用' : '未启用'}</div>
                )
            },
            {
                title: '访客数量',
                dataIndex: 'visitor_count',
                key: 'visitor_count',
            },
            {
                title: '授权数量',
                dataIndex: 'user_count',
                key: 'user_count',
                render: (text, record, index) => (
                    <div style={{ cursor: 'pointer', color: '#1890ff' }} onClick={e => this.toGrant(record.id)}>{text}</div>
                )
            },
            {
                title: '分享次数',
                dataIndex: 'share_count',
                key: 'share_count',
            },
            {
                title: '提交数量',
                dataIndex: 'submit_count',
                key: 'submit_count',
                render: (text, record, index) => (
                    <div style={{ cursor: 'pointer', color: '#1890ff' }} onClick={e => this.toList(record.id, record.field_json_str)}>{text}</div>
                )
            },
            {
                title: '核销数量',
                dataIndex: 'check_count',
                key: 'check_count'
            },
            {
                title: '开始时间',
                dataIndex: 'start_time',
                key: 'start_time',
            },
            {
                title: '结束时间',
                dataIndex: 'end_time',
                key: 'end_time',
            },
            {
                title: '操作',
                key: 'active',
                dataIndex: 'active',
                render: (text, record, index) => (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ cursor: 'pointer', color: '#1890ff' }} onClick={() => this.get_report(record.id)}>报表</div>
                        /
                        <Tooltip placement='top' title={
                            <div>
                                <img src={'http://jiaoxueapi.yanuojiaoyu.com/upload/qr/qrcode_' + record.id + '.png'}></img>
                                <div>{'https://zhongtai.yidianedu.com/active/grant/index/' + record.id}</div>
                            </div>
                        }>
                            <div style={{ cursor: 'pointer', color: '#1890ff' }} onClick={e => {
                                if (copy('https://zhongtai.yidianedu.com/active/grant/index/' + record.id)) {
                                    message.success("复制成功，请使用微信打开！")
                                } else message.error("复制失败！")
                            }}>复制链接</div>
                        </Tooltip>
                        /
                        <div style={{ cursor: 'pointer', color: '#1890ff' }} onClick={() => this.copy_visible_set(record.id)}>复制活动</div>
                        {/* /
                        <Popconfirm placement="top" title={'是否确认删除？'} onConfirm={() => this.del_active(record.id)} okText="是" cancelText="否">
                            <div style={{ cursor: 'pointer', color: '#1890ff' }}>删除</div>
                        </Popconfirm> */}
                    </div>
                )
            },
        ];
        const columns_report = [{
            title: '客户状态',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => (
                this.state.type[index]
            )
        },
        {
            title: '未上门',
            dataIndex: 'weishangmen',
            key: 'weishangmen',
            render: (text, record, index) => (
                <div style={{ cursor: 'pointer', color: '#1890ff' }} onClick={e => this.get_report_detail(1, index + 1)}>{text}</div>
            )
        },
        {
            title: '上门未报名（台账）',
            dataIndex: 'shangmenweibaoming',
            key: 'shangmenweibaoming',
            render: (text, record, index) => (
                <div style={{ cursor: 'pointer', color: '#1890ff' }} onClick={e => this.get_report_detail(2, index + 1)}>{text}</div>
            )
        },
        {
            title: '在读（校管家）',
            dataIndex: 'zaidu',
            key: 'zaidu',
            render: (text, record, index) => (
                <div style={{ cursor: 'pointer', color: '#1890ff' }} onClick={e => this.get_report_detail(3, index + 1)}>{text}</div>
            )
        },
        {
            title: '已结业（校管家）',
            dataIndex: 'yijieye',
            key: 'yijieye',
            render: (text, record, index) => (
                <div style={{ cursor: 'pointer', color: '#1890ff' }} onClick={e => this.get_report_detail(4, index + 1)}>{text}</div>
            )
        }];
        return (
            <div>
                <div className="flex">
                    <div style={{ marginRight: '10px' }}>
                        <span>活动名称：</span>
                        <Input style={{ width: 150 }} placeholder="请输入" onChange={e => this.searchChange(e, 'name')} allowClear></Input>
                    </div>
                    <div style={{ marginRight: '10px' }}>
                        <span>状态：</span>
                        <Select style={{ width: 180 }} onChange={e => this.searchChange(e, 'state')} placeholder="请选择状态" allowClear>
                            <Option value="-1">未启用</Option>
                            <Option value="1">已启用</Option>
                        </Select>
                    </div>
                    <Button style={{ marginRight: '10px' }} onClick={this.get_active_list} type="primary">查找</Button>
                    <Button onClick={e => this.toDetail('', '{}')} type="primary">新建活动</Button>
                </div>
                <Spin spinning={this.state.loading}>
                    <Table
                        columns={columns}
                        dataSource={this.state.data}
                        bordered
                        size="middle"
                        pagination={false}
                    />
                    <div className="m-Pleft m-flex">
                        <Pagination current={Number(this.state.page)} onChange={this.changePage} total={Number(this.state.count)} />
                    </div>
                </Spin>
                <Modal
                    title="活动报表"
                    visible={this.state.visible}
                    onOk={() => { this.setState({ visible: false }) }}
                    onCancel={() => { this.setState({ visible: false }) }}
                    okText="确认"
                    cancelText="取消"
                    width={600}
                >
                    <Table
                        columns={columns_report}
                        dataSource={this.state.user_list}
                        bordered
                        size="middle"
                        pagination={false}
                    />
                </Modal>
                <Modal
                    title="复制活动"
                    visible={this.state.copy_visible}
                    confirmLoading={this.state.confirmLoading}
                    onOk={() => this.copy_active()}
                    onCancel={() => { this.setState({ copy_visible: false }) }}
                    okText="确认"
                    cancelText="取消"
                    width={600}
                >
                    <span>目标校区：</span>
                    <Select style={{ width: 180 }} onChange={e => this.company_idSet(e)} placeholder="请选择状态" allowClear>
                        {
                            this.state.company_list.map((res, index) =>
                                <Option key={index} value={index} >{res.company}</Option>
                            )
                        }
                    </Select>
                </Modal>
            </div>
        )
    }
}

export default withRouter(Submitdraw);