import React, { Component } from 'react';
import { Table, Select, Input, Button, Spin } from 'antd';
import {
  get_all_exam_record,
  get_xiaoguanjia_grade,
  loginUserList,
  get_shift_type,
  get_all_master
} from '../../axios/http'
import { tableToExcel } from '../../actions/tableToExcel'

const { Option } = Select

class AllExamRecord extends Component {
  constructor(props) {
    super(props)
    this.state = {
      teacher_employee_id: '',
      grade_id: '68d7c3c7-cd73-4f99-8c08-5b92b5e082cd',
      student: '',
      fulltime_school: '',
      fulltime_class: '',
      status: '1',
      height: '',
      master: '',
      loading: true,
      masterlist: [],
      chooseStu: -1
    }
  }
  componentDidMount = () => {
    let student_id = this.props.location.pathname.split('/')
    if (student_id.length === 4) {
      student_id = student_id[3]
      this.setState({
        chooseStu: student_id
      }, () => {
        this.get_all_exam_record()
      })
    } else {
      this.get_all_exam_record()
    }
    this.get_all_master()
    this.get_user_list()
    this.get_xiaoguanjia_grade()
    this.get_shift_type()
    window.addEventListener('resize', this.handleSize);
    this.handleSize()
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleSize);
  }
  handleSize = () => {
    this.setState({
      height: document.body.clientHeight,
    });
  }
  get_all_master = () => {
    get_all_master().then(res => {
      this.setState({
        masterlist: res.data.master_list
      })
    })
  }
  get_xiaoguanjia_grade = () => {
    get_xiaoguanjia_grade({ is_all: 1 }).then(res => {
      const grandList = res.data.list.map(res => {
        return <Option value={res.xiaoguanjia_id} key={res.xiaoguanjia_id}>{res.value.split('-')[1]}</Option>
      })
      this.setState({
        grandList
      })
    })
  }
  get_all_exam_record = () => {
    this.setState({
      loading: true
    })
    let data = {
      student_id: this.state.chooseStu === -1 ? '' : this.state.chooseStu,
      grade_id: this.state.grade_id,
      student: this.state.student,
      fulltime_school: this.state.fulltime_school,
      fulltime_class: this.state.fulltime_class,
      teacher_employee_id: this.state.teacher_id || '',
      shift_id: this.state.shift_id || '',
      status: this.state.status,
      master: this.state.master
    }
    get_all_exam_record(data).then(res => {
      this.setState({
        total_count: res.data.list.student_list.length
      })
      if (res.data.list.exam_list.length === 0 || res.data.list.student_list.length === 0) {
        let columns = [],
          data = []
        this.setState({
          columns,
          data,
          loading: false
        })
        return
      }
      let exam_list = res.data.list.exam_list,
        student_list = res.data.list.student_list,
        obj = [],
        data = [],
        rowSpan = student_list[0].exam_list[0].exam_result_list.length + 1,
        total = student_list.length * (rowSpan),
        exam_total = student_list[0].exam_list.length
      exam_list.map((item, index) => {
        obj.push({
          title: item.name,
          children: [
            {
              title: '科目',
              dataIndex: `${item.id}_subject`,
              width: 80,
            },
            {
              title: '得分',
              dataIndex: `${item.id}_score`,
              width: 80,
              render: (value, row, index) => (
                <span style={{ color: row[`${item.id}_is_first_exam`] == '-1' ? 'red' : value == '0.0' ? 'blue' : '' }}>{value}</span>
              )
            },
            {
              title: '满分值',
              dataIndex: `${item.id}_full_score`,
              width: 80,
            },
            {
              title: '得分率',
              dataIndex: `${item.id}_scoring_rate`,
              width: 80,
            }
          ]
        })
      })
      let columns = [
        {
          title: '姓名',
          dataIndex: 'name',
          key: 'name',
          width: 100,
          fixed: 'left',
          render: (value, row, index) => {
            const obj = {
              children: value,
              props: {},
            };
            if (index % (rowSpan) == 0) {
              obj.props.rowSpan = (rowSpan);
            } else {
              obj.props.rowSpan = 0;
            }
            return obj;
          }
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          width: 70,
          render: (value, row, index) => {
            const obj = {
              children: value == "0" ? '试听' : value == "1" ? '在读' : value == "2" ? '停课' : value == "3" ? '休学' : value == "99" ? '已退学' : '',
              props: {},
            };
            if (index % (rowSpan) == 0) {
              obj.props.rowSpan = (rowSpan);
            } else {
              obj.props.rowSpan = 0;
            }
            return obj;
          }
        },
        {
          title: '报名时间',
          dataIndex: 'indate',
          key: 'indate',
          width: 100,
          render: (value, row, index) => {
            const obj = {
              children: value,
              props: {},
            };
            if (index % (rowSpan) == 0) {
              obj.props.rowSpan = (rowSpan);
            } else {
              obj.props.rowSpan = 0;
            }
            return obj;
          }
        },
        {
          title: '学校',
          dataIndex: 'fulltime_school',
          key: 'fulltime_school',
          width: 100,
          render: (value, row, index) => {
            const obj = {
              children: value,
              props: {},
            };
            if (index % (rowSpan) == 0) {
              obj.props.rowSpan = (rowSpan);
            } else {
              obj.props.rowSpan = 0;
            }
            return obj;
          }
        },
        {
          title: '班级',
          dataIndex: 'fulltime_class',
          key: 'fulltime_class',
          width: 70,
          render: (value, row, index) => {
            const obj = {
              children: value,
              props: {},
            };
            if (index % (rowSpan) == 0) {
              obj.props.rowSpan = (rowSpan);
            } else {
              obj.props.rowSpan = 0;
            }
            return obj;
          }
        },
        {
          title: '活动次数',
          dataIndex: 'active_count',
          key: 'active_count',
          width: 70,
          render: (value, row, index) => {
            const obj = {
              children: value,
              props: {},
            };
            if (index % (rowSpan) == 0) {
              obj.props.rowSpan = (rowSpan);
            } else {
              obj.props.rowSpan = 0;
            }
            return obj;
          }
        },
        {
          title: '学管',
          dataIndex: 'master',
          key: 'master',
          width: 70,
          render: (value, row, index) => {
            const obj = {
              children: value,
              props: {},
            };
            if (index % (rowSpan) == 0) {
              obj.props.rowSpan = (rowSpan);
            } else {
              obj.props.rowSpan = 0;
            }
            return obj;
          }
        },
        {
          title: '班型',
          dataIndex: 'class',
          key: 'class',
          width: 70
        },
        {
          title: '任课老师',
          dataIndex: 'teacher_name',
          key: 'teacher_name',
          width: 120,
        },
        ...obj,
        {
          title: '入学后考试次数',
          dataIndex: 'count',
          key: 'count',
          width: 70
        },
        {
          title: '变化幅度',
          dataIndex: 'range',
          width: 70,
          render: (value, row, index) => (
            <span style={{ color: row.status == '1' ? value[0] == '-' ? 'red' : '' : '' }}>{value}</span>
          )
        }
      ],
        columns_list = [
          {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            width: 80,
            render: (value, row, index) => {
              const obj = {
                children: <span style={{ color: '#1890ff', cursor: 'pointer' }} onClick={e => this.chooseStu(row.student_id)}>{value}</span>,
                props: {},
              };
              if (index % (rowSpan) == 0) {
                obj.props.rowSpan = (rowSpan);
              } else {
                obj.props.rowSpan = 0;
              }
              return obj;
            }
          },
          {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: 50,
            render: (value, row, index) => {
              const obj = {
                children: value == "0" ? '试听' : value == "1" ? '在读' : value == "2" ? '停课' : value == "3" ? '休学' : value == "99" ? '已退学' : '',
                props: {},
              };
              if (index % (rowSpan) == 0) {
                obj.props.rowSpan = (rowSpan);
              } else {
                obj.props.rowSpan = 0;
              }
              return obj;
            }
          },
          {
            title: '报名时间',
            dataIndex: 'indate',
            key: 'indate',
            width: 60,
            render: (value, row, index) => {
              const obj = {
                children: value,
                props: {},
              };
              if (index % (rowSpan) == 0) {
                obj.props.rowSpan = (rowSpan);
              } else {
                obj.props.rowSpan = 0;
              }
              return obj;
            }
          },
          {
            title: '学校',
            dataIndex: 'fulltime_school',
            key: 'fulltime_school',
            width: 90,
            render: (value, row, index) => {
              const obj = {
                children: value,
                props: {},
              };
              if (index % (rowSpan) == 0) {
                obj.props.rowSpan = (rowSpan);
              } else {
                obj.props.rowSpan = 0;
              }
              return obj;
            }
          },
          {
            title: '班级',
            dataIndex: 'fulltime_class',
            key: 'fulltime_class',
            width: 70,
            render: (value, row, index) => {
              const obj = {
                children: value,
                props: {},
              };
              if (index % (rowSpan) == 0) {
                obj.props.rowSpan = (rowSpan);
              } else {
                obj.props.rowSpan = 0;
              }
              return obj;
            }
          },
          {
            title: '活动次数',
            dataIndex: 'active_count',
            key: 'active_count',
            width: 70,
            render: (value, row, index) => {
              const obj = {
                children: value,
                props: {},
              };
              if (index % (rowSpan) == 0) {
                obj.props.rowSpan = (rowSpan);
              } else {
                obj.props.rowSpan = 0;
              }
              return obj;
            }
          },
          {
            title: '学管',
            dataIndex: 'master',
            key: 'master',
            width: 70,
            render: (value, row, index) => {
              const obj = {
                children: value,
                props: {},
              };
              if (index % (rowSpan) == 0) {
                obj.props.rowSpan = (rowSpan);
              } else {
                obj.props.rowSpan = 0;
              }
              return obj;
            }
          },
          {
            title: '班型',
            dataIndex: 'class',
            key: 'class',
            width: 50
          },
          {
            title: '任课老师',
            dataIndex: 'teacher_name',
            key: 'teacher_name',
            width: 80,
          },
          {
            title: '科目',
            dataIndex: `subject`,
            key: `subject`,
            width: 70
          },
          {
            title: '入学前得分率',
            dataIndex: 'before_rate',
            key: 'before_rate',
            width: 70,
            render: (value, row, index) => (
              <span style={{ color: value == '-' ? 'red' : '' }}>{value}</span>
            )
          },
          {
            title: '入学后得分率',
            dataIndex: 'after_rate',
            key: 'after_rate',
            width: 70,
            render: (value, row, index) => (
              <span style={{ color: value == '-' ? 'red' : '' }}>{value}</span>
            )
          },
          {
            title: '入学后考试次数',
            dataIndex: 'count',
            key: 'count',
            width: 70
          },
          {
            title: '变化幅度',
            dataIndex: 'range',
            width: 70,
            render: (value, row, index) => (
              <span style={{ color: row.status == '1' ? value[0] == '-' ? 'red' : '' : '' }}>{value}</span>
            )
          }
        ],
        s = [],
        all = [],
        before_index = -1
      for (let i = 0; i < total; i++) {
        let a = Math.floor(i / rowSpan),
          b = i % rowSpan,
          val = {
            student_id: student_list[a].student_id,
            name: student_list[a].name,
            indate: student_list[a].indate,
            fulltime_school: student_list[a].fulltime_school,
            fulltime_class: student_list[a].fulltime_class,
            status: student_list[a].status,
            active_count: student_list[a].active_count,
            master: student_list[a].master
          },
          exam_list = student_list[a].exam_list,
          score_list = [],
          first = 0,
          j = 0,
          count = 0,
          before_rate = 0,
          before_full = 0,
          after_rate = 0,
          after_full = 0
        for (j; j < exam_total; j++) {
          if (b == 0) {
            s[j] = 0
            all[j] = 0
          }
          if (b < rowSpan - 1) {
            student_list[a].current_class_list.some(item => {
              if (item.subject == exam_list[j].exam_result_list[b].subject) {
                val['class'] = item.type_name
                val['teacher_name'] = item.teacher_name
                return true
              }
            })
            val[`${exam_list[j].id}_subject`] = exam_list[j].exam_result_list[b].subject
            val[`subject`] = exam_list[j].exam_result_list[b].subject
            val[`${exam_list[j].id}_score`] = (Number(exam_list[j].exam_result_list[b].score)).toFixed(1)
            val[`${exam_list[j].id}_is_first_exam`] = exam_list[j].exam_result_list[b].is_first_exam
            val[`${exam_list[j].id}_full_score`] = (Number(exam_list[j].exam_result_list[b].full_score)).toFixed(1)
            var rate = exam_list[j].exam_result_list[b].score == 0 ? 0 : (exam_list[j].exam_result_list[b].score / exam_list[j].exam_result_list[b].full_score * 100).toFixed(1)
            val[`${exam_list[j].id}_scoring_rate`] = rate + '%'
            if (exam_list[j].exam_result_list[b].is_first_exam == '-1' && first == 0 || this.state.chooseStu != -1 && first == 0) {
              first = Number(rate)
              before_rate = Number(exam_list[j].exam_result_list[b].score)
              before_full = Number(exam_list[j].exam_result_list[b].full_score)
              before_index = j
            }
            if (rate != 0) {
              s[j] = s[j] + Number(exam_list[j].exam_result_list[b].score)
              all[j] = all[j] + Number(exam_list[j].exam_result_list[b].full_score)
              after_rate += Number(exam_list[j].exam_result_list[b].score)
              after_full += Number(exam_list[j].exam_result_list[b].full_score)
              score_list.push(Number(rate))
              count++
            }
          }
          if (b == rowSpan - 1) {
            val[`${exam_list[j].id}_subject`] = '总分'
            val[`subject`] = '总分'
            val[`${exam_list[j].id}_score`] = (s[j]).toFixed(1)
            val[`${exam_list[j].id}_is_first_exam`] = '1'
            val[`${exam_list[j].id}_full_score`] = all[j]
            var rate = s[j] == 0 ? 0 : (s[j] / all[j] * 100).toFixed(1)
            val[`${exam_list[j].id}_scoring_rate`] = rate + '%'
            if (before_rate == 0 && before_index != -1) {
              before_rate = Number(s[before_index])
              before_full = Number(all[before_index])
            }
            if (rate != 0 && first == 0) {
              first = Number(rate)
            }
            if (rate != 0) {
              after_rate += s[j]
              after_full += all[j]
              score_list.push(Number(rate))
              count++
            }
            before_index = -1
          }
        }
        let range = 0
        if (score_list.length > 1) {
          for (var k = 1; k < score_list.length; k++) {
            range = range + ((score_list[k] - first) / first * 100)
          }
          range = (range / (k - 1)).toFixed(1)
        }

        val['range'] = range == 'Infinity' ? '-' : this.state.chooseStu != -1 ? range == 0 ? `0.0%` : `${range}%` : range == 0 || before_rate == 0 ? `0.0%` : `${range}%`
        val['range_count'] = range == 0 ? 0 : k - 1
        val['before_rate'] = before_rate == 'Infinity' ? '-' : before_rate == 0 ? `0.0%` : Number(before_rate / before_full * 100).toFixed(1) + '%'
        after_rate -= before_rate
        after_full -= before_full
        val['after_rate'] = after_rate / after_full == 'Infinity' ? '-' : after_rate <= 0 ? `0.0%` : Number(after_rate / after_full * 100).toFixed(1) + '%'
        val['count'] = count == 0 ? 0 : count - 1
        data.push(val)
      }
      this.setState({
        columns,
        columns_list,
        data,
        rowSpan,
        loading: false
      })
    })
  }
  get_user_list = () => {
    loginUserList({
      name: '',
      username: '',
      permission: '',
      page_size: 1000
    }).then(res => {
      let list = res.data.list
      const teacher_list = list.map(item => {
        if (item.xiaoguanjia_employee_id)
          return <Option value={item.xiaoguanjia_employee_id} key={item.xiaoguanjia_employee_id}>{item.name}</Option>
      })
      this.setState({
        teacher_list
      })
    })
  }
  get_shift_type = e => {
    get_shift_type({}).then(res => {
      const shift_list = res.data.list.map(item => {
        return <option value={item.xiaoguanjia_id} key={item.xiaoguanjia_id}>{item.value}</option>
      })
      this.setState({
        shift_list
      })
    })
  }
  gradeChange = (e) => {
    this.setState({
      grade_id: e
    })
  }
  stuChange = (e) => {
    this.setState({
      student: e.target.value
    })
  }
  schoolChange = (e) => {
    this.setState({
      fulltime_school: e.target.value
    })
  }
  classChange = e => {
    this.setState({
      fulltime_class: e.target.value
    })
  }
  statusChange = e => {
    this.setState({
      status: e
    })
  }
  teacherChange = e => {
    this.setState({
      teacher_id: e
    })
  }
  shiftChange = e => {
    this.setState({
      shift_id: e
    })
  }
  masterChange = e => {
    this.setState({
      master: e.target.value
    })
  }
  chooseStu = value => {
    this.setState({
      chooseStu: value,
      loading: true
    }, () => {
      this.get_all_exam_record()
    })
  }
  back = e => {
    this.setState({
      chooseStu: -1,
      loading: true
    }, () => {
      this.get_all_exam_record()
    })
  }

  render() {
    let masterlist = this.state.masterlist.map((item, index) => {
      return (<Option value={item.master_id} key={index}>{item.master}</Option>)
    })
    return (
      <div>
        {
          this.state.chooseStu != -1 ?
            <div className="flex">
              <div className="mr">
                <Button onClick={e => this.back()}>返回</Button>
              </div>
            </div>
            :
            <div className="flex">
              <div style={{ marginRight: '10px' }}>
                <Select placeholder="请选择年级" style={{ width: 100 }} value={this.state.grade_id} onChange={e => this.gradeChange(e)}>
                  {this.state.grandList}
                </Select>
              </div>
              <div style={{ marginRight: '10px' }}>
                <Input placeholder="请输入学生姓名" style={{ width: 130 }} onChange={(e) => this.stuChange(e)}></Input>
              </div>
              <div style={{ marginRight: '10px' }}>
                <Input style={{ width: 130 }} placeholder="请输入学校" onChange={(e) => this.schoolChange(e)}></Input>
              </div>
              <div style={{ marginRight: '10px' }}>
                <Input placeholder="请输入班级" style={{ width: 130 }} allowClear onChange={(e) => this.classChange(e)}></Input>
              </div>
              <div style={{ marginRight: '10px' }}>
                <Select placeholder="状态" style={{ width: 80 }} value={this.state.status} onChange={e => this.statusChange(e)}>
                  <Option value="0">试听</Option>
                  <Option value="1">在读</Option>
                  <Option value="2">停课</Option>
                  <Option value="3">休学</Option>
                  <Option value="99">已退学</Option>
                </Select>
              </div>
              <div style={{ marginRight: '10px' }}>
                <Select
                  placeholder="请选择老师"
                  showSearch
                  optionFilterProp="children"
                  allowClear
                  style={{ width: 130 }}
                  value={this.state.teacher_id}
                  onChange={e => this.teacherChange(e)}
                >
                  {this.state.teacher_list}
                </Select>
              </div>
              <div style={{ marginRight: '10px' }}>
                <Input placeholder="请输入学管姓名" style={{ width: 130 }} allowClear onChange={(e) => this.masterChange(e)}></Input>
              </div>
              <div style={{ marginRight: '10px' }}>
                <Button onClick={e => tableToExcel('mytable')} style={{ marginRight: '10px' }} type="primary">导出当前表格</Button>
                <Button onClick={this.get_all_exam_record} type="primary">查询</Button>
              </div>
              <div>共有{this.state.total_count}个学生</div>
            </div>
        }
        <Spin spinning={this.state.loading}>
          {
            this.state.chooseStu != -1 ?
              <Table
                id='mytable'
                columns={this.state.columns}
                dataSource={this.state.data}
                bordered
                size="middle"
                pagination={false}
                scroll={{ x: 'calc(300px + 70%)', y: `${this.state.height - 239}px` }}
              />
              :
              <Table
                id='mytable'
                columns={this.state.columns_list}
                dataSource={this.state.data}
                bordered
                size="middle"
                pagination={false}
                scroll={{ x: 'calc(300px + 70%)', y: `${this.state.height - 239}px` }}
              />
          }
        </Spin>
      </div>
    )
  }
}

export default AllExamRecord;