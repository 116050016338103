import React, { Component } from 'react';
import Luckydraw from './component/luckydraw'
import Luckydrawlist from './component/luckydrawlist'
import Luckydrawdetail from './component/luckydrawdetail'

class Enterquestion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            path: 'Luckydraw'
        }
    }
    toList = (id) => {
        this.setState({
            path: 'Luckydrawlist',
            id: id
        })
    }
    toDetail = (id) => {
        this.setState({
            path: 'Luckydrawdetail',
            id: id
        })
    }
    back = e => {
        this.setState({
            path: 'Luckydraw'
        })
    }
    render() {
        return (
            <div>
                {(() => {
                    switch (this.state.path) {
                        case "Luckydrawlist": return <Luckydrawlist choujiang_id={this.state.id} back={this.back} />; break;
                        case "Luckydrawdetail": return <Luckydrawdetail choujiang_id={this.state.id} back={this.back} />; break;
                        default: return <Luckydraw toList={this.toList} toDetail={this.toDetail} />; break;
                    }
                }
                )()}
            </div>
        )
    }
}

export default Enterquestion;